import React, { FC } from "react";
import { Status, StatusContainer } from "./EmployeeStatus.styles";
import {
  employeeHealthyStatus,
  employeeSickStatus,
  statusEmployeeTypeOptions,
} from "../../constants/options";

export const EmployeeStatus: FC<PropsType> = (props) => {
  const { status } = props;

  const statusOption =
    statusEmployeeTypeOptions.find(({ id }) => id === status) ?? employeeHealthyStatus;
  const { id, name } = statusOption;

  return (
    <StatusContainer>
      <Status status={id}>
        {id === employeeHealthyStatus.id
          ? "Не болеет"
          : id === employeeSickStatus.id
          ? "Болеет"
          : name}
      </Status>
    </StatusContainer>
  );
};

type PropsType = {
  status?: string;
};
