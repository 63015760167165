import { queryClientAtom } from "jotai/query";
import { useAtomValue, useUpdateAtom } from "jotai/utils";
import { userApi } from "../api/apiUser";
import * as atoms from "../atoms/atomCurrentUser";
import { SettingsType, TypeSettingsType } from "../atoms/atomCurrentUser";
import { selectedColumnsState } from "../atoms/atoms";
import { timerForQueries } from "./timerForQueries";
import { TableMethodsType } from "../uiKit/Table/Table";
import { FormMethodsType } from "../uiKit/Form/Form";
import { useEffect } from "react";

const { currentUserQuery, currentUserAtom } = atoms;

export const useUserSettings = <DATUM, OBJ, FLDS, VOBJ>(
  methods: TableMethodsType<DATUM, OBJ> & FormMethodsType<DATUM, FLDS, VOBJ>
) => {
  const { queryKey, titlesInit } = methods;

  const queryClient = useAtomValue(queryClientAtom);

  const currentUser = useAtomValue(currentUserAtom);
  const setSelectedColumns = useUpdateAtom(selectedColumnsState);

  useEffect(() => {
    getUserSettings();
  }, [titlesInit?.length]);

  const getUserSettings = () => {
    const tableSettings =
      currentUser?.settings?.[
        (queryKey?.[queryKey.length - 1] as string).replace("State", "") as TypeSettingsType
      ]?.tableSettings ?? undefined;

    const getIdBySettingName = (settingName: string) =>
      titlesInit ? titlesInit.find(({ name }) => name === settingName)?.id : [];

    if (tableSettings && tableSettings.length !== 0) {
      const visibleIdsInSetting = tableSettings
        .filter(({ visible }) => visible)
        .map(({ name }) => getIdBySettingName(name))
        .filter((tableSetting) => tableSetting) as number[];

      const allIds = titlesInit ? titlesInit.map(({ id }) => id) : [];
      const idsInSetting = tableSettings.map(({ name }) => getIdBySettingName(name));

      const newIds =
        allIds.length > idsInSetting.length
          ? ([
              ...allIds.filter((id) => !idsInSetting.includes(id)),
              ...idsInSetting.filter((id) => id && !allIds.includes(id as number)),
            ].filter((id) => id) as number[])
          : undefined;

      setSelectedColumns(newIds ? [...visibleIdsInSetting, ...newIds] : visibleIdsInSetting);
    } else {
      // если отсутствуют настройки для конкретной таблицы то направляем запрос со всеми отображаемыми колонками
      if (!titlesInit) return;

      const defaultColumns = titlesInit.map(({ name }) => ({
        name: name,
        visible: true,
      }));

      const defaultColumnsVisibleIds = defaultColumns
        .filter(({ visible }) => visible)
        .map(({ name }) => getIdBySettingName(name))
        .filter((tableSetting) => tableSetting) as number[];

      setSelectedColumns(defaultColumnsVisibleIds);

      setSettings(currentUser.settings, {
        [(queryKey?.[queryKey.length - 1] as string).replace("State", "") as TypeSettingsType]: {
          tableSettings: defaultColumns,
        },
      });
    }
  };

  const setSettings = async (
    settingsObj: SettingsType | undefined,
    newSettings: Record<string, Record<string, Record<string, string | boolean>[]>>
  ) => {
    const settingsObjNew = { ...settingsObj, ...newSettings };
    const settingsStrNew = JSON.stringify(settingsObjNew);

    try {
      await userApi().update({ settings: settingsStrNew });
      await timerForQueries();
    } catch (err) {
      console.error(err);
    }
  };

  const setUserSettings = async (selectedColumnNumbers: number[]) => {
    const hasValidUserSettings = selectedColumnNumbers.length !== 0;

    if (!currentUser.settings || !hasValidUserSettings) return;

    const columns = titlesInit
      ? titlesInit
          .filter(({ id }) => id !== null)
          .map(({ id, name }) => ({
            name,
            visible: selectedColumnNumbers.includes(id),
          }))
      : [];

    setSettings(currentUser.settings, {
      [(queryKey?.[queryKey.length - 1] as string).replace("State", "") as TypeSettingsType]: {
        tableSettings: columns,
      },
    });

    await timerForQueries(300);
    await queryClient.fetchQuery(currentUserQuery());
  };

  return {
    getUserSettings,
    setUserSettings,
  };
};
