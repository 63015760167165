import { useField } from "formik";
import { useAtom } from "jotai";
import React, { FC, useEffect, useRef } from "react";
import { statusFileInputState } from "../../atoms/atoms";
import { OptionsType } from "../../utils/getOptions";
import { DocumentList, ListPropsType } from "./DocumentList";
import { Input } from "./DocumentsInput.styles";
import { InputEmpty } from "./InputEmpty";
import { InputError } from "./InputError";
import { InputProgress } from "./InputProgress";
import { InputSuccess } from "./InputSuccess";
import { useDocumentsInput } from "./useDocumentsInput";
import { DocumentsType } from "../../api/apiFile";

export const DocumentsInput: FC<PropsType> = (props) => {
  const { label, description, accept, maxSize, options } = props;
  const { optionsLabel, isEdit = false, name = "files" } = props;
  const { needDocumentsList = true, multiDocuments = true } = props;

  const [status, setStatus] = useAtom(statusFileInputState);

  const [{ value: documents }] = useField<DocumentsType | []>(name);

  const ref = useRef<HTMLInputElement>(null);

  const methods = useDocumentsInput({ accept, maxSize, multiDocuments });

  const { error, progress, fileNames, filesSize, addFile, handleRemove, onChange } = methods;

  useEffect(() => {
    documents.length && setStatus("wasFile");
    return () => setStatus("empty");
  }, []);

  const args = { element: ref, progress, error, fileNames, filesSize, handleRemove, name };

  return (
    <>
      {isEdit ? null : status === "error" ? (
        <InputError {...args} />
      ) : status === "progress" ? (
        <InputProgress {...args} />
      ) : status === "success" ? (
        <InputSuccess />
      ) : !multiDocuments && documents.length === 1 ? null : (
        <InputEmpty
          label={label}
          description={description}
          accept={accept}
          element={ref}
          addFile={addFile}
        />
      )}

      {documents.length && needDocumentsList ? (
        <DocumentList options={options} optionsLabel={optionsLabel} isEdit={isEdit} name={name} />
      ) : null}

      <Input
        ref={ref}
        name={name}
        type="file"
        accept={accept.join(",")}
        onChange={onChange}
        multiple={multiDocuments}
      />
    </>
  );
};

type PropsType = ListPropsType & {
  label: string | JSX.Element;
  description?: string | false;
  accept: string[];
  maxSize?: number;
  options?: OptionsType;
  optionsLabel?: string;
  isEdit?: boolean;
  name?: string;
  needDocumentsList?: boolean;
  multiDocuments?: boolean;
};
