import { EmployeeType } from "./apiEmployees";
import { EntitiesResponseType } from "./apiRequest";
import { IdType, NameType } from "./types";
import { AxiosResponse } from "axios";
import { izServicesApi } from "./api";
import { LittleOtherType } from "./apiDictionaries";

export const deletionReasonsApi = () => ({
  getAll: async (
    props: ReasonsPropsType
  ): Promise<AxiosResponse<EntitiesResponseType<ReasonType>>> => {
    const request = props;

    const response = await izServicesApi.post(`/vaccinationRemovalReasonsService/getAll`, {
      request,
    });

    const items = response.data.removalReasons;
    const totalCount = response.data.page.size;

    return { ...response, data: { items, totalCount } };
  },
});

// получаемые значения

export type ReasonType = IdType & {
  infection: LittleOtherType;
  user: NameType;
  time: string;
  employee: EmployeeType & { department: { shortName: string } };
  type: "APPOINTMENT" | "VACCINATION_FACT";
  appointmentDate?: string;
  reason: string;
};

// передаваемые значения

export type ReasonsPropsType = {
  since: string;
  until: string;
  page: number;
  size: number;
};
