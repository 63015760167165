import styled from "@emotion/styled/macro";
import { Dialog, DialogProps } from "@mui/material";
import { borders, colors, flex, margins, text } from "../../styles";

export const Container = styled(Dialog)<{ width: string }>`
  ${borders({ radius: 24 })};

  .MuiBackdrop-root {
    background: ${colors.primaryActiveElementOpacity};
    backdrop-filter: blur(10px);
    opacity: 0.6;
  }

  .MuiPaper-root {
    ${borders({ radius: 24 })};
    ${margins({ m: "0", p: "24" })};

    min-width: ${({ width }) => width};
    width: ${({ width }) => width};
    ${({ width }) => (width === "90%" ? "height: 85%" : "")};
    box-shadow: none;
    overflow: hidden;
  }
` as (props: DialogProps & { width: string }) => JSX.Element;

export const Content = styled.div`
  ${margins({ m: "0", p: "0" })}

  overflow-y: auto;

  &::-webkit-scrollbar {
    background: ${colors.white};
  }

  &::-webkit-scrollbar-thumb {
    ${borders({ totalRadius: true })};
    background: ${colors.grayscaleLabelOpacity77};
  }

  &::-webkit-scrollbar:vertical {
    width: 8px;
  }
`;

export const TitleAndCloseButton = styled.div`
  ${flex({ horizontal: "evenly", vertical: "center" })};
  ${margins({ m: "0 0 32 0" })};

  color: ${colors.grayscaleBeautifulBlack};
`;

export const Title = styled.h4`
  ${text({ size: 24, height: 34, weight: 800, color: colors.grayscaleBeautifulBlack })};
  white-space: pre-line;
`;
