export {
  NationalityDictionaryPage,
  HealthCenterDictionaryPage,
  PersonnelCategoriesDictionaryPage,
  DiseaseSeverityDictionaryPage,
  TreatmentPlaceDictionaryPage,
  DiseasesClassDictionaryPage,
  InfectionDiseaseDictionaryPage,
  InfectionVaccinationDictionaryPage,
} from "./DictionaryPages";
export { VaccinationSettingsPage, VaccinationNotificationPage } from "./AdministrationPages";
export { CalendarPage, CalendarDayPage } from "./CalendarPages";
export { UserProfilePage } from "./UserProfile";
export { InfographicPage } from "./InfographicPages";
export { DeleteReasonsPage } from "./DeleteReasonsPage";
export { VaccinationStatisticPage } from "./VaccinationStatisticPage";
