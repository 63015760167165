import React from "react";
import { RightModalBody } from "../RightModal/RightModalBody";
import { FormContainer } from "./Form.styles";
import { Formik, FormikValues } from "formik";
import { RightModalActions } from "../RightModal/RightModalActions";
import { FormButton } from "../Buttons";
import { RightModal } from "../RightModal";
import { SetStateAction } from "jotai";

export const ViewForm = <FLDS extends FormikValues, DATUM>(
  props: ViewFormPropsType<FLDS, DATUM>
) => {
  const { methods } = props;
  const { viewFormTitle, viewFormName, viewFields, viewInitialValues } = methods;
  const { setModalViewData, modalViewData } = methods;

  if (!modalViewData) return null;
  const handleClose = () => {
    setModalViewData(undefined);
  };

  return (
    <RightModal title={viewFormTitle} isOpen onClose={handleClose} needBlur>
      <Formik initialValues={viewInitialValues(modalViewData)} onSubmit={handleClose}>
        {() => (
          <>
            <RightModalBody>
              <form id={viewFormName}>
                <FormContainer>{viewFields && viewFields}</FormContainer>
              </form>
            </RightModalBody>

            <RightModalActions>
              <FormButton text="Закрыть" onClick={handleClose} type="button" />
            </RightModalActions>
          </>
        )}
      </Formik>
    </RightModal>
  );
};

export type ViewFormPropsType<FLDS, DATUM> = {
  methods: ViewFormMethodsType<FLDS, DATUM>;
};

export type ViewFormMethodsType<FLDS, DATUM> = {
  viewFormTitle: string;
  viewFormName: string;

  viewFields: JSX.Element;

  viewInitialValues: (arg: DATUM) => FLDS;
  modalViewData?: DATUM;
  setModalViewData: (datum: SetStateAction<DATUM | undefined>) => void;
};
