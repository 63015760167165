import { useField, useFormikContext } from "formik";
import React, { FC } from "react";
import { colors } from "../../styles";
import { today } from "../../utils/workingWithDates";
import { FieldContainer } from "../Form/Form.styles";
import { LabelField, LabelType } from "../Form/LabelField";
import { Calendar as CalendarIcon } from "../Icons";
import * as elements from "./DateInput.styles";

export const DateInput: FC<PropsType> = (props) => {
  const { name, placeholder, insideLabel, minDate = null, maxDate = null, outsideLabel } = props;
  const { disabled = false, color = colors.grayscaleInput } = props;

  const [{ value, onBlur }, { error }, { setTouched }] = useField<Date>(name);
  const { setFieldValue } = useFormikContext();

  const { ContainerCalendar, Calendar } = elements;
  const { PaperProps, InputAndIcon, Input, Icon, Error } = elements;

  const hasError = !!error;

  const defaultCalendarMonth =
    maxDate && maxDate < today ? maxDate : minDate && minDate > today ? minDate : undefined;

  return (
    <FieldContainer>
      <LabelField outsideLabel={outsideLabel} />
      <ContainerCalendar>
        <LabelField insideLabel={insideLabel} />

        <Calendar
          defaultCalendarMonth={defaultCalendarMonth}
          label={placeholder}
          value={value || null}
          minDate={minDate}
          maxDate={maxDate}
          mask="__.__.____"
          inputFormat="dd.MM.yyyy"
          onChange={(newValue) => {
            setTouched(true);
            setFieldValue(name, newValue, true);
          }}
          PaperProps={PaperProps}
          renderInput={(params) => (
            <InputAndIcon disabled={disabled}>
              <Input
                value={value}
                needlabel={+!!insideLabel}
                onBlur={onBlur}
                haserror={+hasError}
                {...params}
                name={name}
                autoComplete="off"
                backgroundcolor={color}
              />
              <Icon needlabel={+!!insideLabel}>
                <CalendarIcon />
              </Icon>
            </InputAndIcon>
          )}
          disabled={disabled}
        />

        {hasError ? <Error>{error}</Error> : null}
      </ContainerCalendar>
    </FieldContainer>
  );
};

type PropsType = LabelType & {
  name: string;
  placeholder: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  color?: string;
};
