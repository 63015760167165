import { useAtomValue, useUpdateAtom } from "jotai/utils";
import { currentUserAtom } from "../../../../atoms/atomCurrentUser";
import React from "react";
import { dateSwitcherTypeState } from "../../../../atoms/atoms";
import { VaccinationDatesType, vaccinationPlanServiceApi } from "../../../../api/apiVaccination";
import { formatDate, formatParseDate, now, parseDate } from "../../../../utils/workingWithDates";
import { useLoadableData } from "../../../../utils/useLoadableData";
import {
  calendarState,
  currentDateInterval,
  summaryVaccinationState,
  useCalendarQuery,
} from "../utils/atoms";
import { InfectionList } from "../InfectionList/InfectionList";
import { HeaderNavigation } from "../../../../uiKit";
import { activeVaccinationState } from "../../../AdministrationPages/VaccinationSettings/utils/atoms";
import { differenceInDays } from "date-fns";

export const useData = () => {
  // ------------------------------ АТОМЫ
  const { roleModel } = useAtomValue(currentUserAtom);

  const setDateSwitcherType = useUpdateAtom(dateSwitcherTypeState);
  const { data, loadableData } = useLoadableData(calendarState, []);
  const activeInfections = useAtomValue(activeVaccinationState);
  const summaryVaccination = useAtomValue(summaryVaccinationState);

  // ------------------------------ КОНСТАНТЫ

  const titlePage = "Календарь";

  const { queryKey } = useCalendarQuery();
  const api = vaccinationPlanServiceApi();

  const {
    canViewCalendar: canView,
    canEditCalendar: canEdit,
    canViewCalendarDay: canViewDay,
    canAddPeriodCalendar: canAddPeriod,
  } = roleModel;

  const loading = loadableData.state === "loading";

  // ------------------------------ РАЗВЕРНУТЫЕ ДАТЫ

  const vaccinationMap = data.reduce((vaccinationAccumulation, plan) => {
    const { period } = plan;

    if (period.length) {
      const sortedByDate = period.sort((a, b) =>
        differenceInDays(parseDate(a.date), parseDate(b.date))
      );

      const startDatePeriod = parseDate(sortedByDate[0].date);
      const startDate =
        startDatePeriod.getTime() > now.getTime() ? formatDate(startDatePeriod) : formatDate(now);
      const endDate = formatParseDate(sortedByDate[sortedByDate.length - 1].date);
      const startTrueDate = formatParseDate(sortedByDate[0].date);

      period.forEach((vaccinationDate) => {
        const { date } = vaccinationDate;
        addToArrayMap(vaccinationAccumulation, date, {
          ...vaccinationDate,
          vaccinationPlan: plan,
          startTrueDate,
          startDate,
          endDate,
        });
      });
    }

    return vaccinationAccumulation;
  }, new Map<string, VaccinationDatesType>());

  // ------------------------------ РАБОТА С MAP

  function addToArrayMap<T>(map: Map<string, T[]>, key: string, value: T) {
    const existing = map.get(key);

    if (existing) map.set(key, existing.concat(value));
    else map.set(key, [value]);
  }

  // date is 'yyyy-mm-dd'
  const getDayVaccinations = (date: string) => vaccinationMap.get(date);

  // ------------------------------ ШАПКА - СПИСОК БОЛЕЗНЕЙ

  const headerFooter = <InfectionList activeInfections={activeInfections} />;

  // ------------------------------ ШАПКА - НАВИГАЦИЯ

  const navigation = (
    <HeaderNavigation
      title={titlePage}
      needAddButton={!!activeInfections.length && canAddPeriod}
      needDateSwitcher={{ dateIntervalAtom: currentDateInterval, typeInterval: "twoDate" }}
      footer={headerFooter}
    />
  );

  const summaryData = [
    { title: summaryVaccination?.all ?? "—", description: "Всего сотрудников" },
    { title: summaryVaccination?.appointed ?? "—", description: "Записано" },
    { title: summaryVaccination?.vaccinated ?? "—", description: "Прошли" },
  ];

  return {
    api,
    queryKey,

    navigation,
    loading,

    canView,
    canEdit,
    canViewDay,

    setDateSwitcherType,

    activeInfections,

    getDayVaccinations,

    summaryData,
  };
};
