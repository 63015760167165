import styled from "@emotion/styled/macro";
import { borders, colors, flex, margins, text } from "../../styles";

export const Status = styled.span<{ status: string }>`
  ${margins({ p: "8 12" })};
  ${text({ size: 13, height: 18, weight: 700 })};
  ${borders({ totalRadius: true })};

  background: ${({ status }) =>
    status === "SICK"
      ? colors.redBackground
      : status === "HEALTHY"
      ? colors.primaryActiveElement
      : colors.grayscaleBackground};
`;

export const StatusContainer = styled.div`
  ${flex({ totalCenter: true })};
`;
