import React from "react";
import { colors } from "../../styles";

export const TriangleDown = ({ color = colors.grayscaleIcons }: { color?: string }) => (
  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.19772 0.000197773C10.0608 0.00657601 10.2753 0.0916612 9.59868 1.22596C9.58197 1.22596 6.06513 6.61905 6.06479 6.61078C5.18735 8.27031 4.81727 8.27032 3.90608 6.61906C3.88937 6.61906 0.357153 1.22596 0.357153 1.22596C-0.322218 0.079618 0.0231857 0.00479506 0.924828 0L9.19772 0.000197773Z"
      fill={color}
    />
  </svg>
);
