import { AxiosError } from "axios";
import { addDays } from "date-fns";
import { useAtom } from "jotai";
import { useAtomValue } from "jotai/utils";
import React, { useState } from "react";
import * as Yup from "yup";
import {
  OrganizationsSettingsType,
  VaccinationDateType,
  vaccinationDayApi,
  vaccinationPlanServiceApi,
} from "../../../../api/apiVaccination";
import { currentUserAtom } from "../../../../atoms/atomCurrentUser";
import {
  IsCorrectStartEndDate,
  IsDateNotPastFutureWithToday,
  required,
  thisDateIsToCorrect,
} from "../../../../constants/forValidationSchemes";
import {
  CheckInput,
  DateInput,
  SelectAutocompleteInput,
  StartAndEndDatesInput,
} from "../../../../uiKit";
import { FieldsBlock, FormSpacer } from "../../../../uiKit/Form/Form.styles";
import { NumberInput } from "../../../../uiKit/Inputs/NumberInput/NumberInput";
import { useHealthCenterOptions } from "../../../../utils/getOptions";
import { QueryKeyType, useQuery } from "../../../../utils/useQuery";
import {
  formatDateForBackend,
  formatTime,
  now,
  parseDate,
  parseTime,
  today,
} from "../../../../utils/workingWithDates";
import { TimesInput } from "../TimesInput/TimesInput";
import { currentAppointmentsState, modalDataState, selectedInfectionState } from "../utils/atoms";
import {
  hasEmployeeLimitEN,
  hasEmployeeLimitRU,
  hasEmployeeTimeEN,
  hasEmployeeTimeRU,
} from "../../../../constants/backendMessages";
import { ConfirmMessageType } from "../../../../uiKit/ConfirmModal/ConfirmModal";

export const useForms = (infections: OrganizationsSettingsType, queryKey: QueryKeyType) => {
  // ------------------------------ ХУКИ

  const selectedId = useAtomValue(selectedInfectionState);
  const appointments = useAtomValue(currentAppointmentsState);
  const [modalData, setModalData] = useAtom(modalDataState);
  const { res } = useAtomValue(currentUserAtom);
  const [notificationText, setNotificationText] = useState<ConfirmMessageType | undefined>(
    undefined
  );

  // ------------------------------ ХУКИ

  const healthCenterOptions = useHealthCenterOptions();

  // ------------------------------ КОНСТАНТЫ

  const { organization } = res;
  const { name: infectionName, id: infectionId } = infections.find(
    ({ infection }) => infection.id === selectedId
  )?.infection ?? { name: "", id: "" };

  const typeForm = modalData?.type;

  const addFormTitle = `Добавление периода вакцинации против заболевания «${infectionName}»`;
  const editFormTitle = `Редактирование ${
    typeForm === "limit" ? "лимитов дня" : "времени приема"
  } на вакцинацию против заболевания «${infectionName}»`;

  const formName = "calendarPeriod";

  // ------------------------------ ФОРМА - ПОЛЯ

  const fields = (isEdit = false) => (
    <FieldsBlock>
      {isEdit && (
        <DateInput name="date" placeholder="Выберите дату" disabled outsideLabel="День приема" />
      )}

      <SelectAutocompleteInput
        name="healthCenter"
        options={healthCenterOptions}
        placeholder="Выберите из доступных"
        outsideLabel="Медицинская организация"
        disabled={isEdit}
      />
      {!isEdit && (
        <StartAndEndDatesInput
          minDate={now}
          name={{ start: "startDate", stop: "endDate" }}
          label={{ start: "Дата начала", stop: "Дата окончания" }}
          merge
          outsideLabel="Период для записи"
        />
      )}

      {(typeForm === "time" || !isEdit) && (
        <TimesInput name="times" title="Время приема" isEdit={isEdit} appointments={appointments} />
      )}

      {(typeForm === "limit" || !isEdit) && (
        <>
          <NumberInput
            name="limit"
            minValue={1}
            description="Максимальное количество вакцинируемых в день"
            outsideLabel="Лимит дня"
          />

          {isEdit && modalData?.startDate !== modalData?.endDate && (
            <>
              <FormSpacer />
              <CheckInput
                name="forPeriod"
                label={`Применить настройки лимита дня ко всему периоду вакцинации: ${modalData?.startDate} - ${modalData?.endDate}`}
              />
            </>
          )}
        </>
      )}
    </FieldsBlock>
  );

  // ------------------------------ ОКНО УВЕДОМЛЕНИЯ

  const handleClose = () => {
    setNotificationText(undefined);
  };

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesAddForm = {
    healthCenter: "",
    infection: infectionId,
    startDate: today,
    endDate: addDays(today, 2),
    times: [{ startTime: null, endTime: null }],
    limit: 10,
    forPeriod: false,
  };

  const initialValuesEditForm = (props: VaccinationDateType) => {
    const { date, limit, vaccinationPlan, times } = props;
    const { healthCenter, infection } = vaccinationPlan;

    return {
      healthCenter: healthCenter.id,
      infection: infection.id,
      date: parseDate(date),
      startDate: parseDate(date),
      endDate: parseDate(date),
      times: times.map(({ id, startTime, endTime }) => ({
        id,
        startTime: parseTime(startTime),
        endTime: parseTime(endTime),
      })),
      limit,
      forPeriod: false,
    };
  };

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchema = Yup.object().shape({
    healthCenter: Yup.string().required(required),
    infection: Yup.string().required(required),
    startDate: modalData
      ? Yup.date()
      : Yup.date()
          .typeError(thisDateIsToCorrect)
          .required(required)
          .test(IsCorrectStartEndDate)
          .test(IsDateNotPastFutureWithToday("past")),
    endDate: modalData ? Yup.date() : Yup.date().typeError(thisDateIsToCorrect).required(required),
    times: Yup.array()
      .of(
        Yup.object().shape({
          startTime: Yup.date().nullable().required(required),
          endTime: Yup.date().nullable().required(required),
        })
      )
      .test(
        "all-correct-dates",
        "Необходимо указать корректные временные промежутки",
        (times) =>
          !times?.filter((time) =>
            time.startTime && time.endTime ? time.startTime >= time.endTime : true
          ).length
      )
      .test("overlay-dates", "Временные промежутки не должны пересекаться", (times) => {
        if (!times || (times?.length && times.length <= 1)) return true;

        const timesSorted = [...times].sort((a, b) =>
          a.startTime && b.startTime ? (a.startTime >= b.startTime ? 1 : -1) : 0
        );

        return timesSorted.every(({ startTime, endTime }, index) =>
          timesSorted[index + 1]?.startTime
            ? startTime &&
              endTime &&
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              timesSorted[index + 1].startTime! > endTime &&
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              timesSorted[index + 1].startTime! > startTime
            : true
        );
      })
      .required(required),
    limit: Yup.number().min(1).required(required),
    forPeriod: Yup.boolean(),
  });

  // ------------------------------ ФОРМА - ОТПРАВКА

  type InitialValuesType = {
    healthCenter: string;
    infection: string;
    startDate?: Date | null;
    endDate?: Date | null;
    date?: Date;
    times: TimesType;
    limit: number;
    forPeriod: boolean;
  };

  const create = useQuery().useCreate({ api: vaccinationPlanServiceApi(), queryKey });
  const updateTime = useQuery().useCustomRequest({
    api: vaccinationDayApi(),
    queryKey,
    key: "updateTime",
    isDeletion: false,
  });
  const updateLimits = useQuery().useCustomRequest({
    api: vaccinationDayApi(),
    queryKey,
    key: "updateLimits",
    isDeletion: false,
  });

  const onSubmit = async (props: InitialValuesType, isEdit?: boolean) => {
    const { healthCenter, infection, limit, forPeriod } = props;
    const { startDate, endDate, times: timesArray } = props;
    const { id } = modalData || {};

    const times = timesArray.map(({ id, startTime, endTime }) => ({
      id,
      startTime: formatTime(startTime),
      endTime: formatTime(endTime),
    }));

    times.sort((a, b) => (a.startTime > b.startTime ? 1 : -1));

    if (!isEdit) {
      const data = {
        planDto: {
          ...(healthCenter ? { healthCenter: { id: healthCenter } } : {}),
          ...(infection ? { infection: { id: infection } } : {}),
          limit: limit,
          ...(startDate && !isEdit ? { startDate: formatDateForBackend(startDate) } : {}),
          ...(endDate && !isEdit ? { endDate: formatDateForBackend(endDate) } : {}),
          ...(times.length ? { times } : {}),
          organization,
        },
      };

      await create.mutateAsync({ data });
    } else {
      if (typeForm === "time") {
        const added = times.filter(({ id }) => !id);
        const removed = modalData?.times.filter(
          ({ id: sourceId }) => !times.some(({ id }) => sourceId === id)
        );

        const data = {
          dateId: id,
          added: added,
          removed: removed,
        };
        try {
          await updateTime.mutateAsync(data);
        } catch (e) {
          const message = (e as AxiosError)?.response?.data.details?.[0];

          if (message === hasEmployeeTimeEN || message === hasEmployeeTimeRU)
            setNotificationText({
              header: "На время приема, которое вы пытаетесь удалить, записаны сотрудники.",
              body: "Вероятно, пока вы вносили изменения, кто-то из ваших коллег сделал запись. Чтобы удалить данный период, необходимо убрать из записи всех сотрудников. ",
            });
          throw e;
        }
      } else {
        const data = {
          dateId: id,
          limit,
          limitForPeriod: forPeriod,
        };

        try {
          await updateLimits.mutateAsync(data);
        } catch (e) {
          const message = (e as AxiosError)?.response?.data.details?.[0];

          if (message === hasEmployeeLimitEN || message === hasEmployeeLimitRU)
            setNotificationText({ header: message });
          throw e;
        }
      }
    }
  };

  return {
    addFormTitle,
    editFormTitle,
    formName,
    fields,

    initialValuesAddForm,
    initialValuesEditForm,
    validationSchema,

    onSubmit,

    modalData,
    setModalData,

    notificationText,
    handleClose,
  };
};

export type TimeType = {
  id?: string;
  startTime: Date | null; //`${number}${number}:${number}${number}` | null;
  endTime: Date | null; //`${number}${number}:${number}${number}` | null;
};
export type TimesType = TimeType[];
