import { AxiosResponse } from "axios";
import { getEqualsFilter } from "../utils/getQueryFilter";
import { izApi, izServicesApi } from "./api";
import { InfectionDiseaseType } from "./apiInfectionDiseases";
import { entitiesBase } from "./apiRequest";
import { IdType } from "./types";

export const componentApi = <E>() => ({
  ...entitiesBase<VaccinationComponentType, E>(izApi, "vs_Component"),
  getNext: async (props: NextComponentPropsType): Promise<AxiosResponse<IdType>> => {
    return izServicesApi.post(`/nextComponentService/getComponent`, { request: props });
  },
});

export const vaccineApi = <E>() => ({
  ...entitiesBase<VaccineType, E>(izApi, "vs_Vaccine"),
  getByInfection: async (
    infection: string,
    organization: string
  ): Promise<AxiosResponse<VaccineReVaccineType>> => {
    const filter = [
      getEqualsFilter(infection, "infection.id"),
      getEqualsFilter(organization, "organization"),
    ];

    const vaccineLoad = await vaccineApi().getAll({ filter });

    const vaccineData = vaccineLoad.data.items;
    const vaccine = vaccineData.filter(({ type }) => type === "VACCINATION")[0];
    const reVaccine = vaccineData.filter(({ type }) => type === "VACCINATION")[0];
    const vaccineComponents = vaccine?.components;
    const reVaccineComponents = reVaccine?.components;

    return {
      ...vaccineLoad,
      data: { vaccine, reVaccine, reVaccineComponents, vaccineComponents },
    };
  },
});

// получаемые значения

export type VaccinationComponentsType = VaccinationComponentType[];

export type VaccinationComponentType = IdType & {
  number: number;
  vaccine: VaccineType;
  interval: number;
};

export type VaccineType = IdType & {
  infection: InfectionDiseaseType;
  interval: number;
  type: VaccinationType;
  name: string;
  organization: string;
  components: VaccinationComponentsType;
};

export type VaccineReVaccineType = {
  vaccine: VaccineType;
  reVaccine: VaccineType;

  vaccineComponents: VaccinationComponentsType;
  reVaccineComponents: VaccinationComponentsType;
};

export type VaccinationType = "REVACCINATION" | "VACCINATION";

// передаваемые значения

export type NextComponentPropsType = {
  employeeId: string;
  infectionId: string;
};
