// ------------------------------ КАЛЕНДАРЬ

export const CALENDAR = "/calendar";
export const CALENDAR_DAY = (
  day: string | undefined,
  infection: string | undefined,
  healthCenter: string | undefined
) => `/calendar-day/${day ?? ":date"}/${infection ?? ":infection"}/${healthCenter ?? ":center"}`;

// ------------------------------ СПРАВОЧНИКИ

export const DICTIONARY = "/dictionaries";

export const DICTIONARY_NATIONALITY = `${DICTIONARY}/nationality`;
export const DICTIONARY_PERSONAL_CATEGORY = `${DICTIONARY}/personal-category`;
export const DICTIONARY_DISEASE_SEVERITIES = `${DICTIONARY}/disease-severities`;
export const DICTIONARY_DISEASES_CLASS = `${DICTIONARY}/diseases-class`;
export const DICTIONARY_INFECTION_DISEASES = `${DICTIONARY}/infection-diseases`;
export const DICTIONARY_INFECTION_VACCINATIONS = `${DICTIONARY}/infection-vaccinations`;
export const DICTIONARY_TREATMENTS_PLACE = `${DICTIONARY}/treatments-place`;
export const DICTIONARY_HEALTH_CENTER = `${DICTIONARY}/health-center`;

// ------------------------------ АДМИНИСТРИРОВАНИЕ

export const ADMINISTRATION = "/administration";

export const VACCINATION_SETTINGS = `${ADMINISTRATION}/vaccination-setting`;
export const VACCINATION_NOTIFICATION = `${ADMINISTRATION}/vaccination-notification`;

// ------------------------------ ПРОФИЛЬ ПОЛЬЗОВАТЕЛЯ

export const USER = `/user`;

export const USER_INFECTION_DISEASES = (id?: string) => `${USER}/infection-diseases/${id ?? ":id"}`;
export const USER_VACCINATION_RECORDS = (id?: string) =>
  `${USER}/vaccination-records/${id ?? ":id"}`;
export const USER_VACCINATIONS = (id?: string) => `${USER}/vaccinations/${id ?? ":id"}`;

// ------------------------------ ПРИЧИНЫ УДАЛЕНИЯ

export const DELETION_REASONS = "/deletion-reasons";

// ------------------------------ ДАННЫЕ О ВАКЦИНАЦИИ

export const VACCINATION_STATISTIC = "/vaccination-statistic";

// ------------------------------ ИНФОГРАФИКА

export const INFOGRAPHIC = "/infographic";
export const INFOGRAPHIC_VACCINATION = (
  type?: "organization" | "department" | "users",
  infection?: string,
  department?: string
) =>
  `/infographic-vaccination/${type ?? ":type"}/${infection ?? ":infection"}/${
    department ?? ":department"
  }`;
export const INFOGRAPHIC_DISEASES = (
  type?: "organization" | "department" | "users",
  infection?: string,
  department?: string
) =>
  `/infographic-diseases/${type ?? ":type"}/${infection ?? ":infection"}/${
    department ?? ":department"
  }`;
