import React, { FC } from "react";
import { VaccinationSettingsPropsType } from "../VaccinationSettings/VaccinationSettings";
import { PlusMinusButton, TableMenuButton, TableMenuItemButton } from "../../../../uiKit/Buttons";
import { Comment, PaperClip, Pencil, Trash } from "../../../../uiKit/Icons";
import * as elements from "../VaccinationSettings/VaccinationSettings.styles";
import { useExemption } from "../../Vaccinations/hooks/useExemption";
import { EmployeeVaccinationSettingType } from "../../../../api/apiEmployees";
import { ConfirmModal, Form, PopoverModal } from "../../../../uiKit";
import { MenuButtons } from "../../../../uiKit/Table/TableBodyRow/TableBodyRow.styles";
import { usePopoverHover } from "../../../../uiKit/PopoverHover/usePopoverHover";
import { formatParseDate } from "../../../../utils/workingWithDates";

export const ExemptionSetup: FC<PropsType> = (props) => {
  const { queryKey, employee, selectedInfection, settings, canEdit } = props;

  const { CardPart, Header, Title, Settings, SubTitle, AdditionalButton } = elements;

  const { closePopoverHover, propsPopoverHover, openPopoverHover } = usePopoverHover({});
  const { isOpen } = propsPopoverHover;
  const exemptionFormsMethods = useExemption(queryKey, settings, employee?.id, selectedInfection);

  const { openForm, setIsOpenForm, isOpenConfirmModal, setIsOpenConfirmModal } =
    exemptionFormsMethods;
  const { handleDeletion, exemption, modalData, setModalData } = exemptionFormsMethods;

  return (
    <>
      <CardPart>
        <Header>
          <Title>Медотвод</Title>
          {canEdit && (
            <PlusMinusButton
              type="plus"
              disabled={!!exemption}
              onClick={() => setIsOpenForm(true)}
            />
          )}
        </Header>
        {exemption && (
          <Settings>
            <SubTitle>{formatParseDate(exemption.date)}</SubTitle>
            {exemption.description && <Comment />}
            {exemption.document && <PaperClip />}
            <AdditionalButton>
              <TableMenuButton menuModalIsOpen={isOpen} onClick={openPopoverHover} />
            </AdditionalButton>
          </Settings>
        )}
      </CardPart>

      {openForm && (
        <Form
          methods={exemptionFormsMethods}
          onClose={() => {
            setModalData(undefined);
            setIsOpenForm(false);
          }}
          isEdit={!!modalData}
        />
      )}

      <PopoverModal {...propsPopoverHover}>
        <MenuButtons>
          <TableMenuItemButton
            text="Изменить"
            icon={<Pencil />}
            onClick={() => {
              setModalData(exemption);
              setIsOpenForm(true);
              closePopoverHover();
            }}
          />

          <TableMenuItemButton
            text="Удалить"
            icon={<Trash />}
            onClick={() => {
              setIsOpenConfirmModal(true);
              closePopoverHover();
            }}
          />
        </MenuButtons>
      </PopoverModal>

      {isOpenConfirmModal && (
        <ConfirmModal
          questionText="Вы действительно хотите удалить медотвод"
          successText="Готово"
          isOpen={isOpenConfirmModal}
          onClose={() => setIsOpenConfirmModal(false)}
          onDelete={handleDeletion}
        />
      )}
    </>
  );
};

type PropsType = VaccinationSettingsPropsType & {
  settings: EmployeeVaccinationSettingType | undefined;
};
