import styled from "@emotion/styled/macro";
import { Skeleton } from "@mui/material";
import { borders, colors, flex, grid, grid as gridStyles, margins } from "../../styles";

export const SkeletonElement = styled(Skeleton)`
  ${borders({ radius: 16 })};

  width: 100%;
  background: ${colors.white};
  transform: none;
  transform-origin: unset;
  --webkit-transform: none;
`;

export const SkeletonForWideTable = styled(SkeletonElement)`
  ${borders({ radius: 4 })};
  ${margins({ m: "0 auto 3 16" })};

  width: 230px;
  height: 15px;

  &:nth-of-type(2) {
    ${margins({ m: "0 auto 1 16" })};

    width: 100px;
    height: 14px;
  }

  &:last-child {
    ${margins({ m: "0 auto 0 16" })};

    width: 40px;
  }
`;

export const SkeletonForWideTableStatus = styled(SkeletonElement)`
  ${borders({ totalRadius: true })};
  ${margins({ m: "16 auto" })};

  width: 70px;
  height: 34px;
`;

export const SkeletonForWideTableDate = styled(SkeletonElement)`
  ${borders({ radius: 4 })};
  ${margins({ m: "16 auto" })};

  width: 90px;
  height: 14px;
`;

export const SkeletonForWideTableCard = styled(SkeletonElement)`
  ${borders({ radius: 12 })};
  ${margins({ m: "16" })};

  width: 588px;
  height: 111px;
`;

export const SkeletonForTable = styled(SkeletonElement)`
  ${borders({ radius: 4 })};

  height: 24px;
  margin: 0 30% 0 16px;
`;

export const SkeletonForPanel = styled(SkeletonElement)`
  ${margins({ m: "0 0 16" })};

  height: 120px;
`;

export const SkeletonForCharts = styled(SkeletonElement)`
  ${margins({ m: "16 0 0" })};

  min-width: 983px;
  height: 60%;
  max-height: calc(100vh - 84px);
`;

export const SkeletonForCard = styled(SkeletonElement)`
  ${margins({ m: "16 0 0" })};

  width: 100%;
  height: 232px;
  max-height: calc(100vh - 84px);
`;

export const TableSimulation = styled.div<{ forWideTable?: boolean }>`
  ${({ forWideTable }) => grid({ gap: 1, rows: `repeat(5, ${forWideTable ? "140px" : "48px"})` })};
`;

export const Row = styled.div<{ grids: string }>`
  ${({ grids }) => gridStyles({ columns: grids })};
  ${borders({ side: "bottom", color: colors.grayscaleInput })};

  &:last-child {
    ${borders({ none: true })};
  }
`;

export const Item = styled.div`
  ${flex({ isColumn: true, totalCenter: true })};

  border-right: 1px solid ${colors.grayscaleInput};

  &:last-child {
    ${borders({ none: true })};
  }
`;
