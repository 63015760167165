import styled from "@emotion/styled/macro";
import { borders, colors, flex, margins, text } from "../../styles";

const { primaryActiveElement, grayscaleBody, grayscaleBeautifulBlack, grayscaleInput } = colors;

export const Container = styled.div``;

export const Header = styled.div`
  ${flex({ gap: 8, vertical: "center" })};
  ${margins({ p: "0 8 0 16" })};
  ${borders({ side: "bottom", color: primaryActiveElement })};
`;

export const Label = styled.p`
  ${text({ weight: 400, size: 15, height: 20, color: grayscaleBody })};
  margin-right: auto;
`;

export const ItemLabel = styled.p`
  ${text({ weight: 400, size: 15, height: 20, color: grayscaleBeautifulBlack })};
  margin-right: auto;
`;

export const List = styled.div``;

export const ListElement = styled.div`
  ${flex({ gap: 8, vertical: "center" })};
  ${margins({ p: "8 12", m: "12 0 0 0" })};
  ${borders({ radius: 12, color: grayscaleInput })};
`;

export const VerticalLine = styled.div`
  height: 40px;
  width: 1px;
  ${borders({ color: grayscaleInput, side: "left" })};
`;
