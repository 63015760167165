import React, { FC } from "react";

export const prefixIcon = "data:image/svg+xml;base64,";

export const IconSvg: FC<PropType> = ({ icon }) => (
  <img alt="picture" style={{ maxWidth: "24px", maxHeight: "24px" }} src={`${prefixIcon}${icon}`} />
);

type PropType = {
  icon: string;
};
