import React from "react";
import { colors } from "../../styles";

export const PlusChar = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 3.5C12.5523 3.5 13 3.94772 13 4.5L13 11H19.5C20.0523 11 20.5 11.4477 20.5 12C20.5 12.5523 20.0523 13 19.5 13H13L13 19.5001C13 20.0524 12.5523 20.5001 12 20.5001C11.4477 20.5001 11 20.0524 11 19.5001L10.999 13H4.5C3.94772 13 3.5 12.5523 3.5 12C3.5 11.4477 3.94772 11 4.5 11H10.999L11 4.5C11 3.94772 11.4477 3.5 12 3.5Z"
      fill={colors.primaryDefault}
    />
  </svg>
);
