import styled from "@emotion/styled/macro";
import { borders, colors, flex, margins, text } from "../../../../styles";

const { primaryLight, primaryDefault, grayscaleInput, transparent } = colors;

export const Container = styled.div`
  ${flex({ isColumn: true, gap: 12 })};
`;

export const AddButton = styled.button<{ enable: boolean }>`
  ${flex({ horizontal: "center", vertical: "center" })};
  background: ${transparent};
  cursor: ${({ enable }) => (enable ? "pointer" : "default")};
  height: 56px;

  border: dashed 2px ${primaryLight};
  border-radius: 12px;

  ${({ enable }) => (enable ? "" : "filter: grayscale(1);")};
`;

export const AddButtonText = styled.p`
  ${text({ weight: 700, size: 13, height: 18, color: primaryDefault })};
`;

export const Card = styled.div`
  ${borders({ color: grayscaleInput, width: 2, radius: 12 })};
`;

export const AddIcon = styled.div`
  ${margins({ p: "8" })};
`;
