import React, { FC, Fragment } from "react";
import { VaccinationComponentsType } from "../../../../api/apiVaccine";
import * as elements from "../InfectionVaccination.styles";
import { getInclinedWord } from "../../../../utils/getInclinedWord";
import { arrDays } from "../../../../constants/dates";

const arrComponent = ["компонента", "компоненты", "компонент"];

export const VaccinationComponent: FC<PropsType> = (props) => {
  const { components } = props;
  const { ComponentCount, Period, SubPeriod } = elements;

  const length = components?.length ?? 0;

  const sortedComponents = components?.sort((a, b) => a.number - b.number);

  return (
    <>
      <ComponentCount border={length > 1}>
        {length} {getInclinedWord(length, arrComponent)}
      </ComponentCount>

      {length > 1 && sortedComponents && (
        <>
          <SubPeriod>Периодичность между компонентами:</SubPeriod>
          {sortedComponents.map(({ interval }, i) => (
            <Fragment key={i}>
              {sortedComponents[i + 1] ? (
                <Period>
                  {i + 1}-я и {i + 2}-я &rArr; {interval} {getInclinedWord(interval, arrDays)}
                </Period>
              ) : (
                <></>
              )}
            </Fragment>
          ))}
        </>
      )}
    </>
  );
};

type PropsType = {
  components?: VaccinationComponentsType;
};
