import React, { FC } from "react";
import { ReasonsText } from "../DeleteReasonsPage.styles";
import { usePopoverHover } from "../../../uiKit/PopoverHover/usePopoverHover";
import { PopoverHover } from "../../../uiKit";

export const Reason: FC<PropsType> = (props) => {
  const { reason } = props;

  const { propsPopoverHover, closePopoverHover, openPopoverHover } = usePopoverHover({
    transformOrigin: { vertical: "top", horizontal: "right" },
    anchorOrigin: { vertical: "top", horizontal: "left" },
  });

  return (
    <>
      <ReasonsText onMouseEnter={openPopoverHover} onMouseLeave={closePopoverHover}>
        {reason ?? "—"}
      </ReasonsText>
      {reason && <PopoverHover {...propsPopoverHover}>{reason}</PopoverHover>}
    </>
  );
};

type PropsType = {
  reason: string;
};
