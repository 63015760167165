import React, { FC, useEffect } from "react";
import { Page } from "../../uiKit";
import { useDeleteReasonsPage } from "./useDeleteReasonsPage";

export const DeleteReasonsPage: FC = () => {
  const methods = useDeleteReasonsPage();
  const { setDateSwitcherType } = methods;

  useEffect(() => {
    setDateSwitcherType("year");
  }, []);

  return <Page methods={methods} />;
};
