import saveAs from "file-saver";
import { useField, useFormikContext } from "formik";
import React, { FC, useState } from "react";
import { EditButton, OkButton, TrashButton } from "../Buttons";
import { Book } from "../Icons";
import { TextInput } from "../Inputs";
import { GeneralPropsType } from "./FilesInput";
import * as elements from "./FilesInput.styles";

export const FilesInputLoaded: FC<GeneralPropsType> = (props) => {
  const { name, filesValue, fileName, fileNameWithoutAccept } = props;
  const { fileSize, fileAccept, element, multiFile, handleRemove } = props;

  const { Container, Title, Description, Text, LoadBarAndText, LoadBar } = elements;

  const [editing, setEditing] = useState<boolean>(false);
  const [fileNameForTextInput, setFileNameForTextInput] = useState<string>();

  const dt = new DataTransfer();

  const { setFieldValue } = useFormikContext();

  const [{ value }] = useField<FileList | undefined | string>(name);

  const isFile = !!value && typeof value !== "string";

  const filesArr = isFile ? Array.from(value) : [];

  const hasFile = filesValue instanceof File && !filesValue.type.includes("image");

  return (
    <Container status="loaded" multiFile={multiFile} as="div">
      <LoadBarAndText status="loaded" multiFile={multiFile}>
        <LoadBar status="loaded">
          <Book />
          {fileAccept}
        </LoadBar>

        <Text>
          <Title
            status="loaded"
            onClick={() => (hasFile ? saveAs(filesValue, fileName) : undefined)}
            needDownload={hasFile}
          >
            {fileNameWithoutAccept}
          </Title>

          <Description status="loaded">{fileSize}</Description>
        </Text>
      </LoadBarAndText>

      {!multiFile && editing ? null : <TrashButton onClick={() => handleRemove(name, element)} />}

      {!multiFile && editing ? (
        <OkButton
          onClick={() => {
            const file = new File([filesArr[0]], `${fileNameForTextInput}${fileAccept}`);

            dt.items.add(file);

            const fileList = dt.files;

            setFieldValue(name, fileList);

            setEditing(false);
            setFileNameForTextInput(undefined);
          }}
        />
      ) : (
        <EditButton
          onClick={() => {
            setEditing(true);
            setFileNameForTextInput(fileNameWithoutAccept);
          }}
        />
      )}

      {!multiFile && editing ? <TextInput name="documentName-0" internal /> : null}
    </Container>
  );
};
