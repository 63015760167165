import { AxiosResponse } from "axios";
import { useAtomValue } from "jotai/utils";
import React, { ReactNode, useState } from "react";
import { statusCreateOrUpdateState, statusDeletionState } from "../../atoms/atoms";
import { Loader } from "../Loader";
import { ConfirmContainer } from "./ConfirmModal.styles";
import { ConfirmModalBeforeRequest } from "./ConfirmModalBeforeRequest";
import { ConfirmModalError } from "./ConfirmModalError";
import { ConfirmModalSuccess } from "./ConfirmModalSuccess";

export const ConfirmModal = <DATA, VOBJ>(props: ConfirmModalPropsType<DATA, VOBJ>) => {
  const { isOpen, onDelete, onSend, onApprove } = props;

  const [status, setStatus] = useState(!!onDelete || !!onSend || !!onApprove ? "" : "success");
  const [error, setError] = useState<string | undefined>();

  const statusDeletion = useAtomValue(statusDeletionState);
  const statusCreateOrUpdate = useAtomValue(statusCreateOrUpdateState);

  return (
    <ConfirmContainer open={isOpen}>
      {status === "success" ? (
        <ConfirmModalSuccess {...props} />
      ) : status === "error" ? (
        <ConfirmModalError error={error} {...props} />
      ) : status === "" && (statusDeletion || statusCreateOrUpdate) ? (
        <ConfirmContainer open={true}>
          <Loader />
        </ConfirmContainer>
      ) : (
        <ConfirmModalBeforeRequest setStatus={setStatus} setError={setError} {...props} />
      )}
    </ConfirmContainer>
  );
};

export type ConfirmModalPropsType<DATA, VOBJ> = {
  questionText: string | ConfirmMessageType;
  successText: string;
  confirmText?: string;
  isOpen: boolean;
  onClose: () => void;
  onDelete?: (() => Promise<AxiosResponse<DATA, VOBJ>[]>) | (() => void);
  onSend?: () => void;
  onApprove?: () => void;
  additionalButton?: ReactNode;
};

export type ConfirmMessageType = { header: string; body?: string };
