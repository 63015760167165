import React, { FC } from "react";
import { useAtom } from "jotai";
import { filtersState } from "../../utils/atoms";
import { Container } from "./OrganizationDepartmentSwitch.styled";
import { useNavigate } from "react-router-dom";
import { INFOGRAPHIC_DISEASES, INFOGRAPHIC_VACCINATION } from "../../../../constants/routes";
import { SwitchLabel } from "../../../../components";
import { UniversalTextButton } from "../../../../uiKit/Buttons";
import { ArrowRight } from "../../../../uiKit/Icons";

export const OrganizationDepartmentSwitch: FC<PropsType> = (props) => {
  const { type: typePage, isDepartment, onClickDepartment } = props;

  const [filters, setFilters] = useAtom(filtersState);
  const navigate = useNavigate();

  const handleChange = (type: "organization" | "department") => {
    setFilters({ ...filters, type: type });
    navigate(typePage === "infection" ? INFOGRAPHIC_DISEASES(type) : INFOGRAPHIC_VACCINATION(type));
  };

  return (
    <Container>
      {!isDepartment ? (
        <SwitchLabel
          label="По подразделениям"
          onChange={(enable) => handleChange(enable ? "department" : "organization")}
          value={filters.type === "department"}
        />
      ) : typePage === "infection" ? (
        <UniversalTextButton
          text="Подробнее"
          type="secondary"
          iconEnd={<ArrowRight />}
          onClick={() => onClickDepartment(isDepartment)}
        />
      ) : null}
    </Container>
  );
};

type PropsType = {
  type: "infection" | "vaccination";
  isDepartment?: string;
  onClickDepartment: (department: string) => void;
};
