import styled from "@emotion/styled/macro";
import { borders, colors, margins } from "../../styles";

const { grayscaleInput, grayscaleOffWhite } = colors;

export const Narrow = styled.div<{ marginTop?: string; marginBottom?: string }>`
  ${borders({ side: "bottom", color: grayscaleInput })};
  ${({ marginTop, marginBottom }) =>
    margins({
      m: `${typeof marginTop === "number" ? marginTop : 0} 0 ${
        typeof marginBottom === "number" ? marginBottom : 0
      } 0`,
    })};

  height: 1px;
`;

export const Wider = styled.div`
  ${borders({ side: "top", color: grayscaleInput })};

  height: 8px;
  background: ${grayscaleOffWhite};
`;

export const Line = styled.div<{ marginTop?: string; marginBottom?: string }>`
  ${borders({ side: "top", color: grayscaleInput })};
  ${({ marginTop, marginBottom }) =>
    margins({
      m: `${typeof marginTop === "number" ? marginTop : 0} 0 ${
        typeof marginBottom === "number" ? marginBottom : 0
      } -24`,
    })};

  width: ${`calc(100% + 48px)`};
  height: 8px;
  background: ${grayscaleOffWhite};
`;
