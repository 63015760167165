import React from "react";

export const Syringe = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_17750_68754)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4076 3.70588C15.9087 3.20481 16.7211 3.20481 17.2222 3.70588L19.0367 5.52043L20.8513 7.33498C21.3523 7.83605 21.3523 8.64845 20.8513 9.14952C20.3502 9.6506 19.5378 9.65059 19.0367 9.14952L18.1294 8.24225L17.2222 9.14952C17.1709 9.2008 17.1163 9.24683 17.0592 9.28761C17.7527 10.7214 17.5046 12.4961 16.3149 13.6859L13.5931 16.4077C12.408 17.5928 10.6424 17.8436 9.21158 17.1601L6.33488 20.0368C5.83381 20.5379 5.02141 20.5379 4.52034 20.0368C4.01926 19.5357 4.01926 18.7233 4.52034 18.2223L7.39703 15.3456C6.71353 13.9148 6.96433 12.1492 8.14943 10.9641L10.8712 8.24225C12.061 7.05251 13.8358 6.8044 15.2695 7.49792C15.3103 7.44079 15.3563 7.38625 15.4076 7.33498L16.3149 6.4277L15.4076 5.52043C14.9065 5.01936 14.9065 4.20696 15.4076 3.70588ZM9.96397 14.5932C10.465 15.0942 11.2774 15.0942 11.7785 14.5932L14.5003 11.8713C15.0014 11.3703 15.0014 10.5579 14.5003 10.0568C13.9993 9.55572 13.1869 9.55572 12.6858 10.0568L9.96397 12.7786C9.4629 13.2797 9.4629 14.0921 9.96397 14.5932Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_17750_68754">
        <rect width="24" height="24" rx="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
