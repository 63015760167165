import { useUpdateAtom } from "jotai/utils";
import React, { FC, MouseEvent, useEffect, useState } from "react";
import { visibleSearchState } from "../../../atoms/atoms";
import { PopoverHover } from "../../PopoverHover";
import { Container, NavigationButton, NavigationLink } from "./HeaderPagesSwitcher.styles";

export const HeaderPagesSwitcher: FC<PropsType> = (props) => {
  const { routers } = props;

  const setVisibleSearch = useUpdateAtom(visibleSearchState);

  useEffect(() => setVisibleSearch(false), []);

  // ------------------------------ < ПОДСКАЗКА К ЗАБЛОКИРОВАННОЙ КНОПКЕ

  const [anchorPopoverHoverElement, setAnchorPopoverHoverElement] = useState<HTMLElement | null>(
    null
  );
  const [popoverHoverText, setPopoverHoverText] = useState<string | null>(null);

  const openPopoverHover = ({ currentTarget }: MouseEvent<HTMLElement>, text: string) => {
    setAnchorPopoverHoverElement(currentTarget);
    setPopoverHoverText(text);
  };

  const closePopoverHover = () => {
    setAnchorPopoverHoverElement(null);
    setPopoverHoverText(null);
  };

  const popoverHoverIsOpen = !!anchorPopoverHoverElement;
  const idPopoverHover = popoverHoverIsOpen ? "popover-hover" : undefined;

  // ------------------------------ ПОДСКАЗКА К ЗАБЛОКИРОВАННОЙ КНОПКЕ >

  return (
    <Container>
      {routers.map((router, i) =>
        "router" in router ? (
          <NavigationLink
            key={i}
            to={router.router}
            className={({ isActive }) => (isActive ? "active" : "")}
            onClick={(evt) => {
              router.onClick && router.onClick();
              setVisibleSearch && setVisibleSearch(false);
              if (router.blocked) evt.preventDefault();
            }}
            blocked={+!!router.blocked}
            aria-owns={popoverHoverIsOpen ? "popover-hover" : undefined}
            aria-haspopup="true"
            onMouseEnter={(evt) =>
              router.blocked && router.blockedText
                ? openPopoverHover(evt, router.blockedText)
                : undefined
            }
            onMouseLeave={closePopoverHover}
          >
            {router.text}
          </NavigationLink>
        ) : (
          <NavigationButton
            key={i}
            isActive={router.typeButton === router.typeTable}
            onClick={() => {
              if (!router.onClick || !router.typeButton) return;
              router.onClick(router.typeButton);
              setVisibleSearch && setVisibleSearch(false);
            }}
          >
            {router.text}
          </NavigationButton>
        )
      )}

      {popoverHoverText ? (
        <PopoverHover
          id={idPopoverHover}
          isOpen={popoverHoverIsOpen}
          element={anchorPopoverHoverElement}
          onClose={closePopoverHover}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: 6, horizontal: "center" }}
        >
          {popoverHoverText}
        </PopoverHover>
      ) : null}
    </Container>
  );
};

type PropsType = {
  routers: RouterType[];
  setVisibleSearch?: (arg: boolean) => void;
};

export type RouterType =
  | {
      text: string | JSX.Element;
      blocked?: boolean;
      blockedText?: string;
      onClick?: (arg: string) => void;
      typeTable?: string | null;
      typeButton?: string;
    }
  | {
      text: string | JSX.Element;
      router: string;
      blocked?: boolean;
      blockedText?: string;
      onClick?: () => void;
      typeTable?: string | null;
      typeButton?: string;
    };
