import { AxiosResponse } from "axios";
import { EntitiesResponseType } from "../api/apiRequest";

// ------------------------------ ДАННЫЕ
export const fetchAll = async <T>(getAll: GetAllType<T>) => (await getAll()).data;
export const fetch = async <T>(getAll: GetAllType<T>) => (await getAll()).data.items;

// ------------------------------ ДАННЫЕ ПО ID

export const fetchById = async <T>(get: FetchGetPropsType<T>) => (await get()).data;

// ------------------------------ ТИПЫ

type GetAllType<T> = () => Promise<AxiosResponse<EntitiesResponseType<T>>>;

type FetchGetPropsType<T> = () => Promise<AxiosResponse<T>>;
