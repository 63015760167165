import { useField } from "formik";
import React, { FC } from "react";
import { PlusMinusButton, TrashButton } from "../../../../uiKit/Buttons";
import { TimesType, TimeType } from "../hooks/useForms";
import { TimeField } from "./TimeField";
import * as elements from "./TimesInput.styles";
import { AppointmentType } from "../../../../api/apiAppointment";
import { PersonIcon } from "../PersonIcon/PersonIcon";

export const TimesInput: FC<PropsType> = (props) => {
  const { name, title, isEdit, appointments } = props;
  const { Container, TimeContainer, Header, Times, Error, Title } = elements;

  const [{ value: values, onBlur }, { error }, { setValue, setTouched }] =
    useField<TimesType>(name);

  const hasError = typeof error === "string" && !!error;
  const errors = error as unknown as ErrorType[] | string;

  const appointmentTimes = new Set(appointments?.map(({ time }) => time.id));

  const handleChange = (date: TimeType, index: number) => {
    setValue([...values.slice(0, index), date, ...values.slice(index + 1)]);
  };

  const handleAdd = () => {
    setValue([...values, { startTime: null, endTime: null }]);
  };
  const handleDelete = (index: number) => {
    if (values.length === 1) setValue([{ startTime: null, endTime: null }]);
    else setValue([...values.slice(0, index), ...values.slice(index + 1)]);
    setTouched(true);
  };

  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        <PlusMinusButton type="plus" disabled={false} onClick={handleAdd} />
      </Header>
      <Times>
        {values.map((time, index) => {
          const errorText = Array.isArray(errors)
            ? errors?.[index]?.startTime || errors?.[index]?.endTime
            : false;

          return (
            <TimeContainer key={index} onBlur={onBlur}>
              <TimeField
                value={time.startTime}
                onChange={(date) => handleChange({ startTime: date, endTime: time.endTime }, index)}
                disabled={isEdit && !!time.id}
              />
              -
              <TimeField
                value={time.endTime}
                onChange={(date) =>
                  handleChange({ startTime: time.startTime, endTime: date }, index)
                }
                disabled={isEdit && !!time.id}
              />
              {time.id && appointmentTimes.has(time.id) && <PersonIcon />}
              <TrashButton
                size={24}
                onClick={() => handleDelete(index)}
                disabled={!!(time.id && appointmentTimes.has(time.id))}
              />
              {(time.startTime || time.endTime) && errorText && <Error>{errorText}</Error>}
            </TimeContainer>
          );
        })}
      </Times>
      {hasError && <Error>{error}</Error>}
    </Container>
  );
};

type PropsType = {
  name: string;
  title: string;
  isEdit: boolean;
  appointments?: AppointmentType[];
};

type ErrorType = { startTime: string; endTime: string } | undefined;
