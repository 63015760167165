import styled from "@emotion/styled/macro";
import { Autocomplete, AutocompleteProps, Chip, ChipTypeMap } from "@mui/material";
import { ElementType } from "react";
import * as styles from "../../styles";

const { borders, colors, flex, grid, margins, overflowOneLine, overflowLines, shadow, text } =
  styles;

const { transparent, white, redBackground, redDefault } = colors;
const { grayscaleInput, grayscaleOffWhite, grayscaleBeautifulBlack, grayscaleLabel } = colors;
const { grayscaleHoverBackground, grayscaleLine } = colors;

export const Container = styled.div`
  position: relative;
`;

export const AutocompleteItem = styled(Autocomplete)<AutocompleteItemPropsType>`
  ${borders({ radius: 16 })};
  ${({ haslabel }) => margins({ p: haslabel ? "8 16" : "16" })};

  width: 100%;
  height: 56px;
  background: ${({ second, color, haserror }) =>
    haserror ? redBackground : second ? transparent : color};
  border: ${({ second }) => (second ? `1px solid ${grayscaleInput}` : "none")};
  cursor: pointer;

  .MuiOutlinedInput-notchedOutline {
    ${borders({ none: true })};
  }

  .MuiOutlinedInput-root {
    ${({ haslabel }) => margins({ m: haslabel ? "16 0 0" : "0", p: "0 0 8" })};
  }

  .MuiButtonBase-root {
    width: 40px;
    height: 40px;
    position: absolute;
    top: ${({ haslabel }) => (haslabel ? "-16px" : "-8px")};
    right: -8px;

    :hover {
      background: ${grayscaleHoverBackground};

      & > svg > path {
        fill: ${grayscaleLabel};
      }
    }
  }

  .MuiAutocomplete-popupIndicator {
    display: ${({ hiddenpopupbutton }) => (hiddenpopupbutton ? "none" : "inline-flex")};
  }

  .MuiAutocomplete-clearIndicator {
    visibility: visible;
    display: inline-flex;
    top: ${({ haslabel }) => (haslabel ? "-16px" : "-8px")};
  }

  .MuiOutlinedInput-root {
    padding-right: 39px !important;
  }

  .MuiChip-root {
    display: none;
  }

  input {
    ${margins({ p: "0", needImportant: true })};
    &::placeholder {
      opacity: 1;
      ${({ inputcolor }) => text({ size: 15, height: 19, weight: 400, color: inputcolor })};
    }

    z-index: 1;
  }

  label {
    ${text({ size: 12, height: 14, weight: 600, color: grayscaleLabel })};
    ${margins({ m: "0" })};

    position: absolute;
    left: 0;
    top: 0;
    transform: none;

    &.Mui-focused {
      ${text({ size: 12, height: 14, weight: 600, color: grayscaleLabel })};
    }
  }
` as <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap["defaultComponent"]
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent> & {
    hiddenpopupbutton?: number;
    haslabel: number;
    haserror: number;
    second: number;
    color: string;
    inputcolor: string;
  }
) => JSX.Element;

export const MenuItemWrapper = styled.div`
  ${grid({ gap: 8, itemsInCenter: true, autoFlowColumn: true })};
  ${overflowOneLine};
  ${text({ size: 15, height: 20, weight: 400 })};
`;

export const MenuCodeWrapper = styled.div`
  ${overflowOneLine};

  width: 54px;
`;

export const PaperProps = (size: string, isCategoryList: boolean) => {
  return {
    sx: {
      width: size === "small" ? 372 : 472,
      maxHeight: 264,
      borderRadius: 4,
      boxShadow: shadow,
      overflowY: "hidden",
      ml: -2,
      padding: 1,

      ul: {
        width: "100%",
        maxHeight: "inherit",
        overflowY: isCategoryList ? "visible" : "auto",
        padding: 0,

        li: {
          height: isCategoryList ? "auto" : 56,
          borderRadius: 2,
          marginBottom: 1,
          px: isCategoryList ? 0 : 2,
          py: 0,

          "&:hover": {
            background: isCategoryList ? white : grayscaleHoverBackground,
          },

          "&:last-child": { marginBottom: 0 },
        },

        "::-webkit-scrollbar": {
          width: 8,
        },

        "::-webkit-scrollbar-thumb": {
          background: grayscaleLine,
          borderRadius: "99em",
        },
      },
    },
  } as const;
};

export const SelectedOptions = styled.div`
  ${flex({ gap: 8, isColumn: true })};
  ${margins({ p: "8 0 0" })};
`;

export const SelectedOptionsChips = styled.div`
  ${flex({ gap: 8 })};
  ${margins({ m: "8 0 0 0" })};

  flex-flow: row wrap;
`;

export const SelectedChipsItem = styled(Chip)`
  ${flex({ totalCenter: true })};
  ${borders({ totalRadius: true })};
  ${margins({ p: "10 10 10 16" })};

  height: 40px;
  color: ${colors.white};
  background: ${colors.grayscaleLabel};

  .MuiChip-label {
    ${text({ size: 13, height: 21, weight: 700 })};
    ${margins({ p: "0" })};
  }

  .MuiSvgIcon-root {
    ${margins({ m: "0 0 0 8" })};
    width: 29px;
    height: 29px;
  }

  &:hover {
    background: ${colors.grayscalePlaceholder};
  }
`;

export const Item = styled.div`
  ${borders({ color: grayscaleInput, radius: 16 })};
  ${margins({ p: "12 20" })};

  background: ${grayscaleOffWhite};
`;

export const ButtonContainer = styled.div`
  ${margins({ p: "0 0 0 8" })};
`;

export const Label = styled.span`
  ${text({ size: 15, height: 20, weight: 400, color: grayscaleBeautifulBlack, align: "start" })};

  align-self: center;
  width: 100%;
`;

export const Description = styled.span`
  ${overflowLines(3)};
  ${text({ size: 15, height: 20, weight: 400, color: grayscaleLabel })};
  ${margins({ p: "10 0 0" })};
`;

export const Header = styled.div`
  ${flex()};
`;

export const Error = styled.p`
  ${text({ size: 12, height: 12, weight: 400, color: redDefault })};

  position: absolute;
  bottom: -6px;
  left: 16px;
  white-space: nowrap;
`;

type AutocompleteItemPropsType = {
  haslabel: number;
  haserror: number;
  second: number;
  color: string;
  hiddenpopupbutton?: boolean;
  inputcolor: string;
};
