import { useAtomValue } from "jotai/utils";
import React from "react";
import { utils, writeFile } from "sheetjs-style";
import * as Yup from "yup";
import { absoluteInitialValues, FiltersType } from "../utils/atoms";
import { filtersState as paramsState } from "../../utils/atoms";
import { StartAndEndDatesInput } from "../../../../uiKit";
import { withoutSpaces } from "../../../../constants/regexs";
import {
  IsCorrectStartEndDate,
  notJustSpaces,
  required,
  thisDateIsToCorrect,
} from "../../../../constants/forValidationSchemes";
import { formatDateForBackend, getPeriodString, now } from "../../../../utils/workingWithDates";
import { employeeServiceApi } from "../../../../api/apiEmployees";
import * as xlsxStyles from "../../../../styles/xlsxStyles";
import { getSickDaysInYear } from "../utils/getSickDays";
import { getFullName } from "../../../../utils/workingWithNames";
import { MergedCellType } from "../../../../uiKit/Filters/ExportFilters";

export const useExport = (
  fields: (jsx: JSX.Element) => JSX.Element,
  totalCount: number,
  departmentName: string
) => {
  // ------------------------------ АТОМЫ

  const paramsPage = useAtomValue(paramsState);

  // ------------------------------ ФОРМА - ПОЛЯ

  const fieldsDownload = fields(
    <>
      <StartAndEndDatesInput
        name={{ start: "startDate", stop: "endDate" }}
        label={{ start: "С", stop: "До" }}
        placeholder={{ start: "ДД.ММ.ГГГГ", stop: "ДД.ММ.ГГГГ" }}
        merge
        outsideLabel="Период"
      />
    </>
  );

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const absoluteInitialDownloadValues = absoluteInitialValues;

  const initialValuesDownload = { ...absoluteInitialDownloadValues };

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchemaDownload = Yup.object().shape({
    status: Yup.string().matches(withoutSpaces, notJustSpaces),
    startDate: Yup.date()
      .typeError(thisDateIsToCorrect)
      .test(IsCorrectStartEndDate)
      .required(required),
    endDate: Yup.date().typeError(thisDateIsToCorrect).required(required),
  });

  // ------------------------------ ФОРМА - СКАЧИВАНИЕ

  const getFilteredData = async (filter: FiltersType) => {
    const { startDate, endDate, status } = filter;
    const { department } = paramsPage;

    const args = {
      mainDepartmentCode: department,
      // infection: { id: infection },
      startIndex: 0,
      size: totalCount,
      startDate: formatDateForBackend(startDate || now),
      endDate: formatDateForBackend(endDate || now),
      status: status ? status : undefined,
    };

    return (await employeeServiceApi().getInfectionData(args)).data.items;
  };

  // ------------------------------ ЭКСПОРТ - СТИЛИ

  const { charA, dataCenterStyle, dataStyle, headerStyle, subHeaderStyle, titleRowStyle } =
    xlsxStyles;

  const onSubmitDownloadFilters = async (filter: FiltersType) => {
    const employees = (await getFilteredData(filter)).filter(
      (employee) => !!employee.disease?.length
    );

    const headerIndexes = [
      "number",
      "employee",
      "position",
      "disabilityPeriod",
      "treatmentPlace",
      "comment",
      "disabilityCode",
      "daysDisabilityNumber",
      "diseasesFrequency",
      "withoutSickLeave",
    ];

    // ------------------------------ заголовки

    const headers = {
      number: "№",
      employee: "Работник",
      position: "Должность",
      disabilityPeriod: "Период нетрудоспособности,\nкол-во дней",
      treatmentPlace: "Место прохождения лечения",
      comment: "Комментарий",
      disabilityCode: "Код по МКБ-10",
      daysDisabilityNumber: "Общее количество дней нетрудоспособности",
      diseasesFrequency: "Частота заболеваний",
      withoutSickLeave: "Без БЛ",
    };

    // ------------------------------ строки с данными

    const rows = [] as { [K in keyof typeof headers]: string }[];
    let orderNumber = 0;

    employees.forEach((employee) => {
      orderNumber += 1;
      if (employee.disease) {
        const diseaseCount = employee.disease.length;
        const daysDisabilityNumber = getSickDaysInYear(employee.disease);

        employee.disease.forEach((disease) => {
          const { code, treatmentPlace, sickLeave } = disease;
          const { start, end, comment } = disease;

          const disabilityPeriod = getPeriodString(start, end, true);

          rows.push({
            number: orderNumber.toString(),
            employee: getFullName({ nameParts: employee }),
            position: employee?.position?.name ?? "",
            disabilityPeriod: disabilityPeriod,

            treatmentPlace: treatmentPlace?.name ?? "",
            comment: comment ?? "",
            disabilityCode: code?.name ?? "",
            daysDisabilityNumber: daysDisabilityNumber.toString(),

            diseasesFrequency: diseaseCount.toString(),
            withoutSickLeave: sickLeave ? "Да" : "",
          });
        });
      }
    });

    // ------------------------------ количество столбцов и строк

    // ------------------------------ формирование книги

    const wb = utils.book_new();

    // ------------------------------ формирование листов

    const { startDate, endDate } = filter;

    const ws = utils.json_to_sheet(
      [
        {},
        {
          number: `Статистика заболеваемости: ${departmentName}`,
        },
        {
          number: `Период: ${getPeriodString(startDate, endDate)}`,
        },
        {},
        headers,
        ...rows,
      ],
      {
        header: [...headerIndexes],
        skipHeader: true,
      }
    );

    utils.book_append_sheet(wb, ws, "Статистика заболеваний");

    // ------------------------------ ширины столбцов

    const widths = [4, 21, 15, 25, 12, 25, 15, 9, 9, 9];
    ws["!cols"] = widths.map((width) => ({ wch: width }));

    // ------------------------------ высоты строк

    ws["!rows"] = [{}, { hpt: 15 * 3 }];

    // ------------------------------ объединения
    // ------------------------------ горизонтально объединённые ячейки с заголовками, подзаголовками и наименованиями подразделений

    const horizontalMergersIndexes = [0, 1, 2, 3] as number[];

    const horizontalMergers = horizontalMergersIndexes.map((rowIndex) => ({
      s: { c: 0, r: rowIndex },
      e: { c: headerIndexes.length - 1, r: rowIndex },
    })) as MergedCellType;

    // ------------------------------ вертикально объединённые ячейки с данными

    let verticalMergersStartIndex = 5;

    const intervals = [] as { s: number; e: number }[];

    for (const { disease } of employees) {
      if (!!disease || (disease && Object.keys(disease).length === 0)) {
        const keys = Object.keys(disease);
        intervals.push({
          s: verticalMergersStartIndex,
          e: verticalMergersStartIndex + keys.length - 1,
        });
        verticalMergersStartIndex += keys.length;
      }
    }

    const verticalMergers = [] as MergedCellType;

    for (const { s, e } of intervals) {
      for (const item of [0, 1, 2, 7, 8]) {
        verticalMergers.push({
          s: { c: item, r: s },
          e: { c: item, r: e },
        });
      }
    }

    // ------------------------------ объединение

    ws["!merges"] = [...horizontalMergers, ...verticalMergers];

    // ------------------------------ заголовок

    ws["A2"].s = headerStyle;
    ws["A3"].s = subHeaderStyle;

    // ------------------------------ строки заголовков таблицы

    for (let i = 0; i < headerIndexes.length; i++) {
      const char = String.fromCharCode(charA + i);
      ws[`${char}5`].s = titleRowStyle;
    }

    // ------------------------------ ячейки с данными

    const mergedColumnsIndexes = [] as number[];

    let mergedColumnsStartIndex = 6;

    for (const { disease } of employees) {
      if (!!disease || (disease && Object.keys(disease).length === 0)) {
        const keys = Object.keys(disease);
        for (let i = 0; i < keys.length; i++) {
          mergedColumnsIndexes.push(mergedColumnsStartIndex++);
        }
      } else {
        mergedColumnsIndexes.push(mergedColumnsStartIndex++);
      }
    }

    // ------------------------------ ячейки с выравниванием по левому краю

    mergedColumnsIndexes.forEach((i) => {
      ["B", "C", "D", "E", "F"].forEach((char) => {
        ws[`${char}${i}`].s = dataStyle;
      });
    });

    // ------------------------------ ячейки с выравниванием по центру

    mergedColumnsIndexes.forEach((i) => {
      ["A", "G", "H", "I", "J"].forEach((char) => {
        ws[`${char}${i}`].s = dataCenterStyle;
      });
    });

    // ------------------------------ скачивание файла

    writeFile(
      wb,
      `Статистика заболеваний ${departmentName} ${getPeriodString(startDate, endDate)}.xlsx`
    );
  };

  return {
    fieldsDownload,
    initialValuesDownload,

    onSubmitDownloadFilters,

    validationSchemaDownload,
    absoluteInitialDownloadValues,
  };
};
