import { useAtom } from "jotai";
import React, { FC, SyntheticEvent } from "react";
import { SelectAutocomplete } from "../../../../uiKit/SelectAutocompleteInput/SelectAutocomplete";
import { OptionType, useInfectionDiseaseOptions } from "../../../../utils/getOptions";
import { filtersState } from "../../utils/atoms";
import { Container } from "./OrganizationInfectionSelect.styled";

export const OrganizationInfectionSelect: FC = () => {
  const infectionOptions = useInfectionDiseaseOptions();

  const [filters, setFilters] = useAtom(filtersState);

  const handleChangeInfection = (_evt: SyntheticEvent<Element, Event>, arg: ArgsType) => {
    if (!arg || Array.isArray(arg) || typeof arg === "string") return;
    setFilters({ ...filters, infection: arg.id });
  };

  return (
    <Container>
      <SelectAutocomplete
        options={infectionOptions}
        onChange={handleChangeInfection}
        value={filters.infection}
        outsideLabel="Заболевание"
      />
    </Container>
  );
};

type ArgsType = string | OptionType | (string | OptionType)[] | null;
