import { useAtom } from "jotai";
import { useUpdateAtom } from "jotai/utils";
import React, { FC } from "react";
import { menuIsExpandState } from "../../../atoms/atoms";
import { Expand, Reduce } from "../../Icons";
import { ExpandButtonContainer } from "../LeftMenu.styles";
import { Tooltip } from "../Tooltip/Tooltip";
import { useTooltip } from "../Tooltip/useTooltip";
import { openSubmenuState } from "../utils/atoms";

export const ExpandButton: FC = () => {
  const popoverHoverMethods = useTooltip();
  const { popoverHoverIsOpen, setPopoverHoverText } = popoverHoverMethods;
  const { openPopoverHover, closePopoverHover } = popoverHoverMethods;

  const setOpenSubMenu = useUpdateAtom(openSubmenuState);

  const [menuIsExpand, setMenuIsExpand] = useAtom(menuIsExpandState);

  const expandMenu = () => {
    setMenuIsExpand((prevState) => !prevState);
    setOpenSubMenu("");

    setPopoverHoverText(menuIsExpand ? "Развернуть меню" : "Свернуть меню");
  };

  const text = menuIsExpand ? "Свернуть меню" : "Развернуть меню";

  return (
    <ExpandButtonContainer
      isExpand={menuIsExpand}
      onClick={expandMenu}
      aria-owns={popoverHoverIsOpen ? "popover-hover" : undefined}
      aria-haspopup="true"
      onMouseEnter={(evt) => openPopoverHover({ evt, type: "expandButton", text })}
      onMouseLeave={closePopoverHover}
    >
      {menuIsExpand ? <Reduce /> : <Expand />}

      <Tooltip type="expandButton" popoverHoverMethods={popoverHoverMethods} />
    </ExpandButtonContainer>
  );
};
