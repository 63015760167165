import React, { FC, MouseEvent } from "react";
import { Cross, ThreeDots } from "../../Icons";
import { Container } from "./TableMenuButton.styles";

export const TableMenuButton: FC<PropsType> = (props) => {
  const { menuModalIsOpen, fromCard = false, onClick } = props;

  return (
    <Container
      type="button"
      menuModalIsOpen={menuModalIsOpen}
      fromCard={fromCard}
      onClick={onClick}
    >
      {menuModalIsOpen ? <Cross /> : <ThreeDots />}
    </Container>
  );
};

type PropsType = {
  menuModalIsOpen: boolean;
  fromCard?: boolean;
  onClick: (evt: MouseEvent<HTMLButtonElement>) => void;
};
