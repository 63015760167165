import { atomWithQuery } from "jotai/query";
import { fetchAll, fetch } from "../../../../utils/fetchData";
import { getPaginationQueryKeys, usePaginationQueryKeys } from "../../../../utils/useQueryKeys";
import { PaginationQueryType } from "../../../../api/types";
import { getEqualsFilter, getQueryFilter } from "../../../../utils/getQueryFilter";
import { loadable } from "jotai/utils";
import { infectionDiseasesApi } from "../../../../api/apiInfectionDiseases";
import { paramsState } from "../../../CalendarPages/CalendarDayPage/utils/atoms";
import { atom } from "jotai";
import { vaccinationSettingsApi } from "../../../../api/apiVaccination";

// ------------------------------ ФОРМА - ОЧИСТКА

export const needCleanupFormAtom = atom(false);

// ------------------------------ ДАННЫЕ - ИНФЕКЦИОННЫЕ ЗАБОЛЕВАНИЯ

const infectionDiseasesAtom = atomWithQuery((get) => {
  const args = getPaginationQueryKeys(get);

  return infectionDiseasesQuery(args);
});

export const infectionDiseasesState = loadable(infectionDiseasesAtom);

const infectionDiseasesQuery = (props: PaginationQueryType) => {
  const { startIndex, query, size } = props;

  const filter = query ? [getQueryFilter(query, ["name"])] : undefined;

  const args = { startIndex, size, filter };

  return {
    queryKey: [startIndex, size, query, "infectionDiseasesAtom"],
    queryFn: () => fetchAll(() => infectionDiseasesApi().getAll(args)),
  };
};

export const useInfectionDiseasesQuery = () => {
  const { size, query, startIndex } = usePaginationQueryKeys();
  return {
    queryKey: [startIndex, size, query, "infectionDiseasesAtom"],
    api: infectionDiseasesApi(),
  };
};

// ------------------------------ ДАННЫЕ

export const vaccinationSettingsState = atomWithQuery(() => {
  return vaccinationSettingsQuery();
});

const vaccinationSettingsQuery = () => {
  return {
    queryKey: ["vaccinationSettingsState"],
    queryFn: () => fetch(() => vaccinationSettingsApi().getAll()),
  };
};

export const useVaccinationSettingsQuery = () => {
  return {
    queryKey: ["vaccinationSettingsState"],
    api: vaccinationSettingsApi(),
  };
};

// ------------------------------ ДАННЫЕ - ЗАБОЛЕВАНИЯ ДЛЯ КАЛЕНДАРЯ

export const activeVaccinationState = atomWithQuery(() => {
  const filter = [getEqualsFilter("true", "active")];

  return {
    queryKey: ["activeVaccinationState"],
    queryFn: () => fetch(() => vaccinationSettingsApi().getAll({ filter })),
  };
});
// ------------------------------ ДАННЫЕ - ПРОСМОТР ДНЯ - НАСТРОЙКИ ПО ЗАБОЛЕВАНИЮ
export const vaccinationSettingsByParamState = atomWithQuery((get) => {
  const params = get(paramsState);
  const { infectionId } = params;

  const filter = [getEqualsFilter(infectionId, "infection.id")];

  return {
    queryKey: [infectionId, "vaccinationSettingsByParamState"],
    queryFn: () => fetch(() => vaccinationSettingsApi().getAll({ filter })),
    enabled: !!infectionId,
  };
});
