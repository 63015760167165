import { eachMonthOfInterval, endOfYear, getMonth, getYear, startOfYear } from "date-fns";
import { useAtomValue } from "jotai/utils";
import React, { ReactNode } from "react";
import { currentDateInterval } from "../../utils/atoms";
import { parseDate } from "../../../../utils/workingWithDates";
import { InfoText } from "../../../../uiKit/ErrorBoundary/ErrorBoundary.styles";
import { monthNames } from "../../../../constants/dates";
import { colors } from "../../../../styles";
import { ChartPageProps } from "../../components/OrganizationChart/OrganizationChart";

export const useCharts = (props: ChartPageProps) => {
  const { filters, selectedOrganizationName, stats = [] } = props;

  // ------------------------------ АТОМЫ

  const dateInterval = useAtomValue(currentDateInterval);

  // ------------------------------ ДАННЫЕ

  const statsForOrganizations = Object.entries(stats)
    .map(([date, morbidity]) => ({
      date: parseDate(date),
      morbidity,
    }))
    .sort((a, b) => a.date.getTime() - b.date.getTime());
  const dataWrapper = (component: ReactNode) =>
    !filters.infection ? (
      <InfoText>Выберите заболевание</InfoText>
    ) : statsForOrganizations.length === 0 ? (
      <InfoText>Нет данных</InfoText>
    ) : (
      component
    );

  // ------------------------------ ДИАГРАММА - ДАННЫЕ

  const getDatasets = () => [
    {
      label: selectedOrganizationName,
      data: getLabels().data.map(({ morbidity }) => morbidity),
    },
  ];

  // ------------------------------ ДИАГРАММА - ПОДПИСИ

  const getLabels = () => {
    let data = [] as StatisticsDataType;
    let labels = [] as (string | string[])[];

    const start = dateInterval[0] as Date;
    const end = dateInterval[1] as Date;

    const inOneYear = startOfYear(start) === startOfYear(end);

    const interval = {
      start: inOneYear ? startOfYear(start) : start,
      end: inOneYear ? endOfYear(start) : end,
    };

    // const numberOfYears = differenceInYears(end, start);
    // const hasRemainingYears = numberOfYears % 9 > 0;

    // const numberOfMonths = differenceInMonths(end, start) + 1;
    // const hasRemainingMonths = numberOfMonths % 9 > 0;

    // const years = eachYearOfInterval(interval);
    const months = eachMonthOfInterval(interval);
    // const weeks = eachWeekOfInterval(interval, { weekStartsOn: 1 });

    // const days = eachDayOfInterval(interval);

    // const filterData = ({ data, start, end }: FilterDataProps) =>
    //   data.filter(({ date }) => date >= start && date <= end);

    const formatData = (intervals: Date[]) =>
      intervals.map((date, i) => {
        const month = getMonth(i === 0 ? interval.start : date);
        const filteredData = statsForOrganizations[month];

        // const filteredData = filterData({
        //   data: statsForOrganizations,
        //   start: i === 0 ? interval.start : date,
        //   end: i === intervals.length - 1 ? interval.end : intervals[i + 1],
        // });

        return {
          date: i === 0 ? interval.start : date,
          morbidity: filteredData?.morbidity ?? 0,
        };
      });
    data = formatData(months);

    labels = months.map((date) => [`${monthNames[getMonth(date)][0]}`, `${getYear(date)}`]);
    // if (numberOfYears > 9 || (numberOfYears === 9 && hasRemainingYears)) {
    //   data = formatData(years);
    //
    //   labels = years.map((date) => `${getYear(date)}`);
    // } else if (numberOfMonths > 9 || (numberOfMonths === 9 && hasRemainingMonths)) {
    //   data = formatData(months);
    //
    //   labels = months.map((date) => [`${monthNames[getMonth(date)][0]}`, `${getYear(date)}`]);
    // } else if (numberOfMonths > 2 && numberOfMonths <= 9) {
    //   data = formatData(weeks);
    //
    //   labels = weeks.map((date, i) => [
    //     `${i === 0 ? formatDate(interval.start) : formatDate(date)}`,
    //     date === weeks[i + 1]
    //       ? ""
    //       : ` - ${i === weeks.length - 1 ? formatDate(interval.end) : formatDate(weeks[i + 1])}`,
    //   ]);
    // } else if (numberOfMonths > 1 && numberOfMonths <= 2) {
    //   data = statsForOrganizations
    //     .filter((_, i) => i % 2 === 0)
    //     .map(({ date, morbidity }) => ({ date, morbidity }));
    //
    //   labels = days
    //     .filter((_, i) => i % 2 === 0)
    //     .map((date, i) => [
    //       `${formatDate(date)}`,
    //       i === Math.ceil(days.length / 2) - 1 ? "" : ` - ${formatDate(plusOneDay(date))}`,
    //     ]);
    // } else if (numberOfMonths <= 1) {
    //   data = statsForOrganizations;
    //
    //   labels = days.map((date) => formatDate(date));
    // }

    return { data, labels };
  };

  // ------------------------------ ДИАГРАММА - ОПЦИИ

  const getOptions = () =>
    ({
      ...getOptionsForExport(),
      interaction: { mode: "nearest" },
      plugins: {
        legend: { display: false },
        tooltip: {
          position: "average",
          backgroundColor: colors.grayscaleBeautifulBlackOpacity,
          bodyColor: colors.white,
          bodyFont: { size: 16, weight: "400" },
          padding: 16,
          cornerRadius: 8,
          displayColors: false,
          callbacks: {
            title: () => "",
            label: ({ formattedValue, dataset }) =>
              `${
                dataset.label ? `${selectedOrganizationName.replaceAll('"', "")}: ` : ""
              }${formattedValue}`,
          },
        },
      },
    } as OptionsType);

  const getOptionsForExport = () =>
    ({
      animation: false,
      scales: {
        x: {
          ticks: {
            maxRotation: 55,
            minRotation: 0,
            font: { family: "Rosatom", size: 12, weight: 600, lineHeight: 1.25 },
            color: colors.grayscaleLabel,
            padding: 8,
          },
          grid: { display: false, drawBorder: false },
        },
        y: {
          ticks: {
            font: { family: "Rosatom", size: 12, weight: 500, lineHeight: 1.25 },
            color: colors.grayscaleLabel,
            stepSize: 5,
          },
          grid: {
            color: colors.grayscaleLine,
            drawTicks: false,
            borderDash: [10, 10],
            drawBorder: false,
          },
          grace: 10,
          beginAtZero: true,
          max: 100,
        },
      },
      barThickness: 20,
      elements: {
        bar: {
          backgroundColor: colors.primaryDefault,
          borderRadius: 100,
        },
      },
      plugins: {
        legend: { display: false },
        tooltip: { enabled: false },
      },
    } as OptionsForExportType);

  return {
    statsForOrganizations,
    dataWrapper,

    getDatasets,
    getLabels,
    getOptions,
    getOptionsForExport,
  };
};

export type StatisticsDataType = {
  date: Date;
  morbidity: number;
}[];

export type OptionsType = {
  plugins: {
    legend: { display: boolean };
    tooltip: {
      callbacks: {
        title: () => string;
        label: (tooltipItem: {
          formattedValue: string;
          dataset: { label?: string };
        }) => string | string[];
      };
    };
  };
};

type OptionsForExportType = {
  plugins: {
    tooltip: { enabled: boolean };
    legend: { display: boolean };
  };
};
