// ------------------------------ ОБЩИЕ

import { endOfToday, today } from "../utils/workingWithDates";

export const required = "Поле должно быть заполнено";
export const emptyMessage = " ";

// ------------------------------ НЕСКОЛЬКО ЗНАЧЕНИЙ

export const multiRequired = "Необходимо выбрать минимум одно значение";

// ------------------------------ БУЛЕВЫ ЗНАЧЕНИЯ

export const requiredAgreementTerms = "Нужно согласиться со всеми условиями размещения";

// ------------------------------ СТРОКИ

export const long = "Слишком длинное значение";
export const notJustSpaces = "Поле должно содержать не только пробелы";
export const uuid = "Должен быть передан id";
export const space = "Пробелы в начале или конце";

export const email = "Введите корректный e-mail";
export const phone = "Введите корректный телефон";

// ------------------------------ ЦИФРЫ

export const numbers = "Допустимы только цифры";
export const minValue = (number: number) => `Минимальное значение - ${number}`;
export const maxValue = (number: number) => `Максимальное значение - ${number}`;

// ------------------------------ ВОЗРАСТ

export const toManyAges = "Кол-во лет больше максимального возраста";
export const toLittleAges = "Кол-во лет меньше минимального возраста";

// ------------------------------ ДАТЫ

export const thisDateIsToCorrect = "Введите корректную дату";
export const thisPeriodIsBusy = "Период не должен пересекается с другим периодом";
export const thisDateInPast = "Дата не должна быть в прошлом";
export const thisDateInFuture = "Дата не должна быть в будущем";

// ------------------------------ ИКОНКИ

export const missingIcon = "Иконка должна быть загружена";
export const falseAcceptIcon = "Допустимый формат - .svg";
export const falseSizeIcon = "Размер иконки не должен быть больше 20Кб";
export const falseWidthIcon = "Ширина иконки не должна быть больше 24px";
export const falseHeightIcon = "Высота иконки не должна быть больше 24px";
export const falseDownloadIcon = "При загрузке иконки произошла ошибка";

// ------------------------------ ФОТОГРАФИИ

export const missingPhoto = "Фотография должна быть загружена";
export const falseAcceptPhoto = (accept: string[]) =>
  `${accept.length === 1 ? "Допустимый формат" : "Допустимые форматы"} - ${accept.join(", ")}`;
export const falseSizePhoto = (maxSize: number) =>
  `Размер фотографии не должен быть больше ${maxSize / (1024 * 1024)}Мб`;
export const falseCountPhotos = (maxCount: number) =>
  `Фотографий не должно быть больше ${maxCount}`;

// ------------------------------ ФАЙЛЫ

export const missingFile = "Файл должен быть загружен";
export const falseAcceptFile = (accept: string[]) =>
  `${accept.length === 1 ? "Допустимый формат" : "Допустимые форматы"} - ${accept.join(", ")}`;
export const requiredSingleFile = "Необходимо загрузить один файл";
export const falseSizeFile = (maxSize: number) =>
  `Размер файла не должен быть больше ${maxSize / (1024 * 1024)}Мб`;
export const falseCountFiles = (maxCount: number) => `Файлов не должно быть больше ${maxCount}`;

// ------------------------------ ДАТЫ

export const IsCorrectStartEndDate = {
  name: "is-after-date",
  message: thisDateIsToCorrect,
  test: async (_: unknown, ctx: { parent: { startDate: Date | null; endDate: Date | null } }) => {
    const { startDate, endDate } = ctx.parent;
    return startDate && endDate ? startDate <= endDate : true;
  },
};

export const IsDateNotPastFutureWithToday = (type: "past" | "future", field = "startDate") => ({
  name: `is-${type}Date`,
  message: type === "past" ? thisDateInPast : thisDateInFuture,
  test: async (_: unknown, ctx: { parent: { [key: string]: Date | undefined } }) => {
    const date = ctx.parent[field];
    return date ? (type === "past" ? date >= today : date <= today) : true;
  },
});
export const IsDateNotPastFuture = (type: "past" | "future", field = "startDate") => ({
  name: `is-${type}Date`,
  message: type === "past" ? thisDateInPast : thisDateInFuture,
  test: async (_: unknown, ctx: { parent: { [key: string]: Date | undefined } }) => {
    const date = ctx.parent[field];
    return date ? (type === "past" ? date > endOfToday : date < endOfToday) : true;
  },
});
