import React, { FC } from "react";
import { CloseButton } from "../Buttons";
import { Container, Title, TitleAndCloseButton, Content } from "./CenteredModal.styles";

export const CenteredModal: FC<PropsType> = (props) => {
  const { children, title, isOpen = false, width, onClose } = props;

  return (
    <Container open={isOpen} onClose={onClose} width={width}>
      <TitleAndCloseButton>
        {typeof title === "string" ? (
          <>
            <Title>{title}</Title>
            <CloseButton onClick={onClose} />
          </>
        ) : (
          title
        )}
      </TitleAndCloseButton>

      <Content>{children}</Content>
    </Container>
  );
};

type PropsType = {
  title?: string | JSX.Element;
  isOpen?: boolean;
  width: string;
  onClose: () => void;
};
