import { AxiosResponse } from "axios";
import { izApi, izServicesApi } from "./api";
import { AppointmentType } from "./apiAppointment";
import { HealthCenterType } from "./apiDictionaries";
import { EmployeeType } from "./apiEmployees";
import { InfectionDiseaseType, InfectionsDiseaseType } from "./apiInfectionDiseases";
import {
  entitiesBase,
  EntitiesResponseType,
  EntityCreateType,
  EntityUpdateType,
} from "./apiRequest";
import { VaccinationComponentType, VaccinationType } from "./apiVaccine";
import { IdType, TimeType } from "./types";

export const vaccinationPlanApi = <E>() => ({
  ...entitiesBase<VaccinationPlanType, E>(izApi, "vs_VaccinationPlan"),
  delete: async (id: string): Promise<AxiosResponse<IdType>> => {
    return izServicesApi.post(`/vaccinationPlanService/remove`, { plan: { id } });
  },
  deleteWithAppointments: async (props: string): Promise<AxiosResponse<IdType>> => {
    return izServicesApi.post(`/vaccinationPlanService/removeWithAppointments`, {
      plan: { id: props },
    });
  },
});
export const vaccinationPlanServiceApi = <E>() => ({
  ...entitiesBase<VaccinationPlanType, E>(izServicesApi, "VaccinationPlanService"),
  getAll: async (
    props: VaccinationPlanServicePropsType
  ): Promise<AxiosResponse<EntitiesResponseType<VaccinationPlanType>>> => {
    const params = {
      params: props,
    };

    const response = await izServicesApi.get(
      `/vaccinationPlanFilterService/getFilteredVaccinationPlan`,
      params
    );
    const items = response.data;
    const totalCount = items.length;

    return { ...response, data: { items, totalCount } };
  },
  create: async ({ data }: EntityCreateType<E>): Promise<AxiosResponse<IdType>> =>
    izServicesApi.post(`/vaccinationPlanService/create`, data),
});

export const vaccinationDayApi = <E>() => ({
  ...entitiesBase<VaccinationDateType, E>(izApi, "vs_VaccinationDate"),
  update: async (props: EntityUpdateType<E>): Promise<AxiosResponse<VaccinationDateType>> => {
    const request = props.data;
    return izServicesApi.post(`/vaccinationDateUpdateService/update`, { request });
  },
  delete: async (id: string): Promise<AxiosResponse<IdType>> => {
    return izServicesApi.post(`/vaccinationDateRemoveService/remove`, {
      date: { id },
    });
  },
  deleteWithAppointments: async (id: string): Promise<AxiosResponse<IdType>> => {
    return izServicesApi.post(`/vaccinationDateRemoveService/removeWithAppointments`, {
      date: { id },
    });
  },
  updateTime: async (request: E): Promise<AxiosResponse<IdType>> => {
    return izServicesApi.post(`/vaccinationDateUpdateTimesService/update`, { request });
  },
  updateLimits: async (request: E): Promise<AxiosResponse<IdType>> => {
    return izServicesApi.post(`/vaccinationDateUpdateLimitService/update`, { request });
  },
});
export const vaccinationFactApi = <E>() =>
  entitiesBase<VaccinationFactType, E>(izApi, "vs_VaccinationFact");

export const vaccinationSettingsApi = <E>() => ({
  ...entitiesBase<OrganizationSettingsType, E>(izApi, "vs_OrganizationSettings"),
});

export const vaccinationNotificationApi = <E>() =>
  entitiesBase<NotificationSettingsType, E>(izApi, "vs_NotificationSettings");

// получаемые значения

export type VaccinationPlanType = IdType & {
  healthCenter: HealthCenterType;
  infection: InfectionDiseaseType;
  organization: string;
  period: VaccinationDatesType;
};

export type VaccinationDateType = IdType & {
  date: string;
  limit: number;
  used: number;
  vaccinationPlan: VaccinationPlanType;
  times: RecordTimesType;

  // вычисляемые данные
  startTrueDate: string;
  startDate: string;
  endDate: string;
};

export type VaccinationDatesType = VaccinationDateType[];

export type RecordTimeType = IdType & {
  startTime: TimeType;
  endTime: TimeType;
};

export type RecordTimesType = RecordTimeType[];

export type VaccinationFactsType = VaccinationFactType[];

export type VaccinationFactType = IdType & {
  employee: EmployeeType;
  appointment: AppointmentType;
  date: string;
  component: VaccinationComponentType;
  componentNumber?: number;
  vaccinated: boolean;
  comment: string;
  abroad: boolean;
  type?: VaccinationType;
};

export type OrganizationSettingsType = IdType & {
  organization: string;
  infection: InfectionDiseaseType;
  access: boolean;
  agreement: boolean;
  active: boolean;
};

export type OrganizationsSettingsType = OrganizationSettingsType[];

export type NotificationSettingsType = IdType & {
  organization: string;
  necessity: InfectionsDiseaseType;
  expired: InfectionsDiseaseType;
};

// передаваемые значения

type VaccinationPlanServicePropsType = {
  startDate: string;
  endDate: string;
  organization: string;
  infectionId: string;
};
