import { TestConfig, ValidationError } from "yup";
import * as messages from "../constants/forValidationSchemes";
import { prefixIcon } from "../uiKit/Icons/IconSvg/IconSvg";
import { base64ToFile } from "./convertBase64ToFile";

const { falseAcceptIcon, falseDownloadIcon, falseHeightIcon, falseSizeIcon } = messages;
const { falseWidthIcon, missingIcon } = messages;

export const validateIcon = async (data: File | string): Promise<string | undefined> => {
  if (!data) {
    return missingIcon;
  }

  const icon = typeof data === "string" ? base64ToFile(`${prefixIcon}${data}`, "icon.svg") : data;

  if (!!icon && !icon?.name.includes(".svg")) {
    return falseAcceptIcon;
  }

  if (!!icon && icon.size > 20480) {
    return falseSizeIcon;
  }

  const img = new Image();
  img.src = URL.createObjectURL(icon);

  return new Promise<string | undefined>((resolve) => {
    img.onload = () => {
      const width = img.width;
      const height = img.height;

      if (!!icon && width > 24) {
        resolve(falseWidthIcon);
      } else if (!!icon && height > 24) {
        resolve(falseHeightIcon);
      } else {
        resolve(undefined);
      }
    };

    img.onerror = () => {
      resolve(falseDownloadIcon);
    };
  });
};

export const validateIconObj: TestConfig<File, unknown> = {
  name: "validate-icon",
  message: (msg) => msg,
  test: async (value, { path }) => {
    const result = await validateIcon(value);
    return result ? new ValidationError(result, value, path) : true;
  },
};
