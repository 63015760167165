import { useAtomValue } from "jotai/utils";
import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { menuIsExpandState } from "../../../atoms/atoms";
import { SectionType } from "../../../constants/sections";
import { Tooltip } from "../Tooltip/Tooltip";
import { useTooltip } from "../Tooltip/useTooltip";
import { Button } from "./SectionButtons.styles";

export const SubSectionsButton: FC<PropsType> = (props) => {
  const { subSection } = props;
  const { additionalRoutes, rout, text, name } = subSection;

  const popoverHoverMethods = useTooltip();
  const { openPopoverHover, closePopoverHover } = popoverHoverMethods;

  const menuIsExpand = useAtomValue(menuIsExpandState);

  const navigate = useNavigate();
  const location = useLocation();

  const mockCount = 24; // моковые данные, удалить после получения данных по количеству с бека

  const active =
    location.pathname.includes(rout) ||
    location.search.includes(rout) ||
    additionalRoutes?.some((addRoute) => location.pathname.includes(addRoute));

  return (
    <>
      <Button
        isExpand={menuIsExpand}
        active={!!active}
        onClick={() => navigate(rout)}
        onMouseEnter={(evt) =>
          openPopoverHover({
            evt,
            type: "subSectionButton",
            text: (evt.target as HTMLElement).innerText,
          })
        }
        onMouseLeave={closePopoverHover}
      >
        <div className="withoutIcon"></div>{" "}
        {menuIsExpand && <p onClick={() => navigate(rout)}>{text}</p>}
        {name === "inbox-applications" && mockCount > 0 && <div className="count">{mockCount}</div>}
      </Button>

      <Tooltip type="subSectionButton" popoverHoverMethods={popoverHoverMethods} />
    </>
  );
};

type PropsType = {
  subSection: SectionType;
};
