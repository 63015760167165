import { HeaderNavigation } from "../../../uiKit";
import React from "react";
import { currentDateInterval, deletionReasonsState, useDeletionReasonsQuery } from "../utils/atoms";
import { useAtomValue, useUpdateAtom } from "jotai/utils";
import { currentUserAtom } from "../../../atoms/atomCurrentUser";
import { dateSwitcherTypeState } from "../../../atoms/atoms";
import { useLoadableData } from "../../../utils/useLoadableData";

export const useData = () => {
  // ------------------------------ АТОМЫ

  const { roleModel } = useAtomValue(currentUserAtom);
  const setDateSwitcherType = useUpdateAtom(dateSwitcherTypeState);
  const { data, loadableData, totalCount } = useLoadableData(deletionReasonsState, []);

  // ------------------------------ ХУКИ

  const { queryKey, api } = useDeletionReasonsQuery();

  // ------------------------------ КОНСТАНТЫ

  const titlePage = "Причины удаления сотрудника с вакцинации";
  const { canViewDeleteReasons: canView } = roleModel;

  // ------------------------------ ШАПКА - НАВИГАЦИЯ

  const navigation = (
    <HeaderNavigation
      title={titlePage}
      needDateSwitcher={{
        typeInterval: "twoDate",
        dateIntervalAtom: currentDateInterval,
        needPeriodSwitcher: true,
      }}
    />
  );

  return {
    data,
    loadableData,

    navigation,

    canView,

    api,
    queryKey,

    setDateSwitcherType,
    totalCount,
  };
};
