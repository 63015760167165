import { VaccinationFactType } from "../../../../api/apiVaccination";
import React, { useState } from "react";
import { FieldsBlock } from "../../../../uiKit/Form/Form.styles";
import { TextInput } from "../../../../uiKit";
import * as Yup from "yup";
import { long, notJustSpaces, required } from "../../../../constants/forValidationSchemes";
import { withoutSpaces } from "../../../../constants/regexs";

export const useFactDeletionForm = () => {
  // ------------------------------ КОНСТАНТЫ

  const [modalData, setModalData] = useState<VaccinationFactType | undefined>(undefined);

  // ------------------------------ КОНСТАНТЫ

  const addFormTitle = "Причина удаления факта вакцинации";
  const editFormTitle = "Причина удаления факта вакцинации";
  const formName = "vaccinationFactDeletionForm";

  // ------------------------------ ФОРМА - ПОЛЯ

  const fields = (
    <FieldsBlock>
      <TextInput name="comment" outsideLabel="Укажите причину*" multiline />
    </FieldsBlock>
  );

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesAddForm = {
    comment: "",
  };

  const initialValuesEditForm = () => ({
    comment: "",
  });

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchema = Yup.object().shape({
    comment: Yup.string().matches(withoutSpaces, notJustSpaces).required(required).max(255, long),
  });

  // ------------------------------ ФОРМА - ОТПРАВКА

  const valuesObject = ({ comment }: InitialValuesFactFormType) => ({
    comment,
    vaccinated: false,
  });

  return {
    addFormTitle,
    editFormTitle,
    formName,

    fields,

    initialValuesAddForm,
    initialValuesEditForm,

    validationSchema,
    valuesObject,

    modalData,
    setModalData,
  };
};

export type InitialValuesFactFormType = {
  comment: string;
};
