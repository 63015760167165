import React, { FC, ReactNode, useEffect } from "react";
import { Container } from "./Header.styles";
import { visibleSearchState } from "../../atoms/atoms";
import { useAtom } from "jotai";
import { SearchInput } from "../SearchInput";

export const Header: FC<PropsType> = (props) => {
  const { navigation, pagesSwitcher } = props;

  const [visibleSearch, setVisibleSearch] = useAtom(visibleSearchState);

  useEffect(() => setVisibleSearch(false), []);

  return (
    <Container>
      {navigation}
      {visibleSearch && <SearchInput />}
      {!!pagesSwitcher && pagesSwitcher}
    </Container>
  );
};

type PropsType = {
  navigation: ReactNode;
  pagesSwitcher?: JSX.Element | null;
};
