import React from "react";
import { colors } from "../../styles";

export const Comment = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.75 3.5C16.8586 3.5 21 7.08172 21 11.5C21 15.9183 16.8586 19.5 11.75 19.5C10.8762 19.5 10.0306 19.3952 9.22927 19.1994C8.17979 20.1338 6.42785 20.9088 3.97509 21.5236C3.86927 21.5501 3.75732 21.5352 3.66207 21.4821C3.45545 21.3667 3.38146 21.1057 3.4968 20.8991L3.67509 20.5749C4.46277 19.1217 4.93454 17.9465 5.09039 17.0493C3.48596 15.6125 2.5 13.6558 2.5 11.5C2.5 7.08172 6.64137 3.5 11.75 3.5Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
