import styled from "@emotion/styled/macro";
import { Pagination, Select } from "@mui/material";
import { borders, colors, flex, margins, overflowOneLine, shadow, text } from "../../styles";

export const Overlay = styled.div<{ isWidthLessThanMinimum: boolean; menuIsExpand: boolean }>`
  width: calc(100% - ${({ menuIsExpand }) => (menuIsExpand ? "332px" : "104px")});
  position: absolute;
  right: 16px;
  bottom: ${({ isWidthLessThanMinimum }) => (isWidthLessThanMinimum ? "16px" : "0")};
`;

export const Blur = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(${colors.transparent}, ${colors.grayscaleLabelOpacityA0});
  -webkit-mask: linear-gradient(${colors.transparent}, ${colors.black});
  backdrop-filter: blur(2px);
`;

export const Container = styled.div`
  ${flex({ gap: 32, horizontal: "center" })};
  ${margins({ p: "24 0" })};
`;

export const PaginationItem = styled(Pagination)`
  ${borders({ color: colors.grayscaleInput, radius: 16 })};
  ${margins({ p: "8 16" })};

  background: ${colors.white};
  color: ${colors.grayscaleBeautifulBlack};
  z-index: 1;

  .MuiButtonBase-root:hover {
    background: ${colors.grayscaleInput};
  }

  .MuiButtonBase-root.Mui-selected {
    background: ${colors.grayscaleLine};
  }

  .MuiPaginationItem-previousNext {
    & > svg > path {
      fill: ${colors.grayscaleLabel};
    }

    &.Mui-disabled {
      & > svg > path {
        fill: ${colors.grayscaleIcons};
      }
    }

    :hover {
      & > svg > path {
        fill: ${colors.grayscaleBeautifulBlack};
      }
    }
  }
` as typeof Pagination;

export const QuantitySelection = styled.div`
  ${borders({ radius: 16 })};
  ${margins({ p: "8 16" })};

  background: ${colors.white};
  border: 1px solid ${colors.grayscaleInput};
  color: ${colors.grayscaleBeautifulBlack};
  z-index: 1;
`;

export const Input = styled(Select)`
  ${borders({ radius: 16 })};
  ${margins({ p: "8 0 8 16" })};

  width: 100px;
  height: 40px;
  background: ${colors.transparent};
  cursor: pointer;

  .MuiSelect-select {
    ${margins({ m: "0", p: "0", needImportant: true })};

    z-index: 1;
  }

  .MuiOutlinedInput-notchedOutline {
    ${borders({ none: true })};
    ${margins({ p: "0" })};
  }
`;

export const MenuItemWrapper = styled.div`
  ${overflowOneLine};
  ${text({ size: 16, height: 24, weight: 400, color: colors.grayscaleAsh })};
`;

export const MenuProps = {
  sx: {
    height: "100%",

    ".MuiPaper-root": {
      borderRadius: 4,
      boxShadow: shadow,
      overflowY: "scroll",
      padding: "8px",

      ul: {
        width: "100%",
        padding: 0,

        li: {
          height: 56,
          borderRadius: 2,
          marginBottom: 1,
          px: 2,
          py: 0,

          "&:hover": { background: colors.grayscaleHoverBackground },
          "&:last-child": { marginBottom: 0 },
        },
      },

      "&::-webkit-scrollbar": {
        width: 8,
      },

      "&::-webkit-scrollbar-thumb": {
        background: colors.grayscaleLine,
        borderRadius: "99em",
      },
    },
    ".MuiPopover-paper": { width: 130 },
  },
  anchorOrigin: {
    vertical: 0,
    horizontal: -30,
  },
  transformOrigin: {
    vertical: 300,
    horizontal: 0,
  },
};
