import styled from "@emotion/styled/macro";
import { StatusType } from "../../api/types";
import { borders, button, colors, flex, grid, margins, overflowOneLine, text } from "../../styles";

export const Container = styled.div<{ status: StatusType; multiFile: boolean }>`
  ${({ multiFile, status }) =>
    multiFile && status === "empty"
      ? flex({ totalCenter: true })
      : flex({ horizontal: "evenly", vertical: "center" })};
  ${borders({ radius: 16 })};
  ${margins({ m: "0 0 16 0", p: "16" })};

  width: 100%;
  height: ${({ multiFile }) => (multiFile ? "157px" : "72px")};
  position: relative;
  background: ${colors.transparent};
  border: ${({ status }) =>
    status === "empty"
      ? `2px dashed ${colors.primaryActiveElement}`
      : `2px solid ${colors.grayscaleInput}`};

  outline: none;
  cursor: ${({ status }) => (status === "empty" ? "pointer" : "default")};

  :hover {
    border: 2px
      ${({ status }) =>
        status === "empty" ? `dashed ${colors.primaryDefault}` : `solid ${colors.grayscaleInput}`};
  }
`;

export const LoadBarAndText = styled.div<{ status: StatusType; multiFile: boolean }>`
  ${({ multiFile, status }) =>
    multiFile && status === "empty"
      ? flex({ isColumn: true, horizontal: "center" })
      : flex({ vertical: "center" })};
  width: ${({ multiFile, status }) =>
    multiFile && status === "empty" ? "100%" : "calc(100% - 52px)"};
  overflow: hidden;
`;

export const LoadBar = styled.div<{ status: StatusType }>`
  ${flex({ isColumn: true, totalCenter: true })};
  ${borders({ totalRadius: true })};
  ${text({ size: 10, height: 13, weight: 900 })};
  ${margins({ m: "0 12 0 0" })};

  width: 40px;
  height: 40px;
  border: ${({ status }) =>
    status === "loaded" || status === "empty"
      ? "none"
      : `3px solid ${
          status === "error"
            ? colors.redDefault
            : status === "success"
            ? colors.greenDefault
            : colors.grayscaleBackground
        }  `};
`;

export const Progress = styled.div`
  ${margins({ m: "0 12 0 0" })};

  width: 40px;
  height: 40px;
  position: relative;

  .MuiCircularProgress-root {
    color: ${colors.primaryDefault};
  }
`;

export const Percent = styled.div`
  ${flex({ totalCenter: true })};
  ${borders({ totalRadius: true })};
  ${text({ size: 10, height: 10, weight: 900, color: colors.grayscaleBeautifulBlack })};

  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  border: 3px solid ${colors.grayscaleBackground};
  z-index: -1;
`;

export const DocumentIconAndName = styled.div`
  ${flex({ gap: 8, vertical: "center" })};
`;

export const Text = styled.div`
  ${flex({ isColumn: true, horizontal: "start" })};
  ${overflowOneLine};
`;

export const Title = styled.p<{ status: StatusType; needDownload?: boolean }>`
  ${({ status }) => {
    const notFile = status === "progress" || status === "error" || status === "success";
    const color = notFile ? colors.grayscaleLabel : colors.primaryDefault;

    return text({ size: 15, height: 20, weight: 700, color: color });
  }};

  :hover {
    ${({ needDownload }) => needDownload && "cursor: pointer"};
  }
`;

export const Description = styled.p<{ status: StatusType }>`
  ${({ status }) => {
    const color = status === "error" ? colors.redDefault : colors.grayscaleLabel;

    return text({ size: 13, height: 23, weight: 400, color: color });
  }};
`;

export const Icon = styled.div`
  ${flex({ totalCenter: true })};
  ${margins({ m: "0 0 0 12" })};

  width: 40px;
  height: 40px;
`;

export const Button = styled.button`
  ${flex({ totalCenter: true })};
  ${button};
  ${margins({ m: "0 0 0 12" })};

  width: 40px;
  height: 40px;
`;

export const Input = styled.input`
  display: none;
  position: absolute;
  top: 24px;
`;

export const FileBlock = styled.div`
  ${margins({ m: "0 0 40 0" })};

  position: relative;
`;

export const FileCard = styled.div`
  ${flex({ horizontal: "evenly", vertical: "center" })};
  ${borders({ radius: 16 })};
  ${margins({ m: "0 0 16 0", p: "16" })};

  width: 100%;
  height: 72px;
  background: ${colors.transparent};
  border: 2px solid ${colors.grayscaleInput};
  outline: none;
`;

export const FileCardButtons = styled.div`
  ${flex()};
`;

export const Photos = styled.div`
  ${grid({ gap: 8, columns: "repeat(6, 1fr)" })};
`;

export const Photo = styled.div<{ background?: string }>`
  ${borders({ radius: 16 })};

  height: 117px;
  position: relative;
  background: ${({ background }) => (background ? `url(${background})` : colors.grayscaleInput)};
  background-size: cover;
`;

export const Buttons = styled.div`
  ${flex({ gap: 8, horizontal: "center", vertical: "center" })};

  width: 100%;
  height: 40px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, ${colors.black} 100%);
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
`;

export const CheckButton = styled.div`
  & > svg > path {
    fill: ${colors.white};
  }
`;

export const RemoveButton = styled.div`
  & > svg > path {
    fill: ${colors.white};
  }
`;
