import { createFilterOptions } from "@mui/material";
import { useField } from "formik";
import React, { FC, MouseEvent, ReactNode, useState } from "react";
import { colors } from "../../styles";
import { OptionsType } from "../../utils/getOptions";
import { FieldContainer } from "../Form/Form.styles";
import { LabelField, LabelType } from "../Form/LabelField";
import { CrossInCircle, TriangleDown } from "../Icons";
import { PopoverHover } from "../PopoverHover";
import * as elements from "./SelectAutocompleteMultilineInput.styles";
import { SelectedItem } from "./SelectedItem";
import { SelectedItemChips } from "./SelectedItemChips";
import { useSelectAutocompleteMultilineInput } from "./useSelectAutocompleteMultilineInput";

export const SelectAutocompleteMultilineInput: FC<PropsType> = (props) => {
  const { name, size = "", disabled, category, clearable } = props;
  const { result = "list", filter = false, color = colors.grayscaleInput } = props;
  const { outsideLabel, insideLabel } = props;

  const inputMethods = useSelectAutocompleteMultilineInput(props);
  const { autocompleteValue, renderInput, renderOption, markOptions, handleChange } = inputMethods;

  const { Container, AutocompleteItem, SelectedOptions, PaperProps, Error } = elements;
  const { SelectedOptionsChips } = elements;

  const [{ value: values, onBlur }, { touched, error }] = useField<string[]>(name);

  const hasError = touched && !!error;

  const valuesQuantity = values.length;

  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);

  return (
    <FieldContainer>
      <LabelField outsideLabel={outsideLabel} />

      <Container>
        <AutocompleteItem
          value={autocompleteValue}
          options={markOptions}
          filterOptions={createFilterOptions({ limit: 200 })}
          openText=""
          closeText=""
          groupBy={category ? (markOption) => markOption.category : undefined}
          getOptionLabel={(option) => option?.option.name as string}
          onChange={handleChange}
          onBlur={onBlur}
          renderInput={renderInput}
          renderOption={renderOption}
          popupIcon={<TriangleDown />}
          clearIcon={<CrossInCircle color={colors.grayscaleIcons} />}
          disableCloseOnSelect
          componentsProps={{ paper: PaperProps(size, !!category) }}
          noOptionsText="Нет совпадений"
          isOptionEqualToValue={(option, value) => option?.option.id === value?.option.id}
          haslabel={+!!insideLabel}
          haserror={+hasError}
          second={+filter}
          disabled={disabled}
          color={color}
          disableClearable={!clearable}
          hiddenpopupbutton={+(!!clearable && !!valuesQuantity)}
          inputcolor={valuesQuantity ? colors.grayscaleAsh : colors.grayscalePlaceholder}
          multiple
          onMouseEnter={(evt) =>
            valuesQuantity && setAnchorEl((evt as MouseEvent<HTMLInputElement>).currentTarget)
          }
          onMouseLeave={() => setAnchorEl(null)}
        />

        {hasError && <Error>{error}</Error>}

        {result === "list" ? (
          <SelectedOptions>
            {values.map((value) => (
              <SelectedItem key={value} id={value} inputMethods={inputMethods} />
            ))}
          </SelectedOptions>
        ) : result === "chips" ? (
          <SelectedOptionsChips>
            {values.map((value) => (
              <SelectedItemChips key={value} id={value} inputMethods={inputMethods} />
            ))}
          </SelectedOptionsChips>
        ) : null}

        {result === "text" && (
          <PopoverHover
            id={`popover-hover-${name}`}
            isOpen={!!anchorEl}
            element={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: 6, horizontal: "center" }}
          >
            {values
              .map((id) => markOptions.find((el) => el.option.id === id)?.option.name)
              .join(", ")}
          </PopoverHover>
        )}
      </Container>
    </FieldContainer>
  );
};

export type PropsType = LabelType & {
  name: string;
  options: OptionsType | OptionsType[];
  category?: string[];
  placeholder: string;
  size?: string;
  disabled?: boolean;
  filter?: boolean;
  result?: "text" | "list" | "chips";
  color?: string;
  iconStart?: ReactNode;
  clearable?: boolean;
};
