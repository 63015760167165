import React, { FC, ReactNode } from "react";
import { Action, Container, Content, Description, Title } from "./ActionCard.styles";
import { usePopoverHover } from "../../../../uiKit/PopoverHover/usePopoverHover";
import { PopoverHover } from "../../../../uiKit";

export const ActionCard: FC<PropsType> = (props) => {
  const { title, type, description, children, end, hoverText } = props;

  const { propsPopoverHover, openPopoverHover, closePopoverHover } = usePopoverHover({
    transformOrigin: { vertical: "center", horizontal: "right" },
    anchorOrigin: { vertical: "center", horizontal: "left" },
  });

  return (
    <Container>
      <Content>
        <Title>{title}</Title>
        {description && (
          <Description
            type={type}
            onMouseEnter={hoverText ? openPopoverHover : undefined}
            onMouseLeave={hoverText ? closePopoverHover : undefined}
          >
            {description}
          </Description>
        )}
      </Content>
      {children && <Action>{children}</Action>}
      {end}

      <PopoverHover {...propsPopoverHover}>{hoverText}</PopoverHover>
    </Container>
  );
};

type PropsType = {
  title: string;
  description?: string;
  hoverText?: ReactNode;
  end?: ReactNode;
  type: "default" | "error" | "access";
};
