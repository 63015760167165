import { useData } from "./hooks/useData";
import { useForms } from "./hooks/useForms";
import { useTable } from "./hooks/useTable";

export const useInfectionDiseasePage = () => {
  const data = useData();
  const { canEdit } = data;
  const forms = useForms();
  const table = useTable(canEdit);

  return {
    ...data,
    ...table,
    ...forms,
  };
};
