import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { borders, colors, margins, text } from "../../../styles";

export const TextInputContainer = styled.div<ContainerPropsType>`
  ${borders({ none: true, radius: 16 })};
  position: ${({ internal }) => (internal ? "absolute" : "relative")};
  ${({ internal }) => (internal ? "top: 6px; left: 58px;" : "")};

  background: ${({ hasError }) => (hasError ? colors.redBackground : colors.grayscaleInput)};

  ${({ postfix, value }) => {
    const div = document.createElement("div");
    div.style.width = "fit-content";
    div.style.fontSize = "16px";
    div.style.lineHeight = "24px";
    div.style.fontWeight = "400";
    div.innerHTML = String(value);
    document.body.appendChild(div);
    const width = div.clientWidth;
    document.body.removeChild(div);

    return !!postfix && value !== "" && String(value) !== "0"
      ? `
      ::before {
        content: "${postfix ?? ""}";
        position: absolute;
        top: 25px;
        left:  calc(16px + 4px + ${width}px);
      }`
      : "";
  }}
`;

const inputStyle = ({ hasLabel, hasPrepend, hasError }: InputPropsType) => css`
  ${borders({ none: true, radius: 16 })};
  ${text({ size: 16, height: 24, weight: 400, color: colors.grayscaleAsh })};
  ${margins({
    p: hasLabel ? "0 16 0" : hasPrepend ? "100 16 8" : "16",
    m: hasLabel ? "20 0 8" : "0",
  })};

  background: ${hasError ? colors.redBackground : colors.grayscaleInput};
  outline: none;

  ::placeholder {
    color: ${colors.grayscalePlaceholder};
  }

  :disabled {
    cursor: no-drop;
    -webkit-text-fill-color: ${colors.grayscaleInputDisabled};
  }
`;

export const Input = styled.input<InputPropsType>`
  ${inputStyle};

  width: ${({ internal }) => (internal ? "350px;" : "100%")};
  height: ${({ hasLabel }) => (hasLabel ? "34px" : "56px")};
  cursor: ${({ disabled }) => (disabled ? "no-drop" : "auto")};

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    ${margins({ m: "0" })};

    -webkit-appearance: none;
  }

  [type="number"] {
    -moz-appearance: textfield;
  }
`;

export const MultilineInput = styled.textarea<InputPropsType>`
  ${inputStyle};

  width: 100%;
  height: ${({ hasPrepend }) => (hasPrepend ? "220px" : "150px")};

  overflow-y: scroll;
  resize: none;
  vertical-align: top;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: ${colors.transparent};
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.grayscaleLine};
    ${borders({ totalRadius: true })};
  }
`;

export const PrependContainer = styled.div`
  ${margins({ p: "0 0 0 16" })};

  height: 56px;
  position: absolute;
  left: 16px;
  top: 22px;
  border-left: 1px solid black;
`;

export const Error = styled.p`
  ${text({ size: 12, height: 12, weight: 400, color: colors.redDefault })};

  position: absolute;
  bottom: -14px;
  left: 16px;
  white-space: nowrap;
`;

type ContainerPropsType = {
  internal: boolean;
  postfix?: string;
  value: string | number;
  hasError: boolean;
};

type InputPropsType = {
  hasLabel: boolean;
  hasError: boolean;
  internal: boolean;
  hasPrepend?: boolean;
};
