import { AxiosResponse } from "axios";
import { hrApi, mrApi, psApi } from "./api";
import { IdType } from "./types";
import { entitiesBase } from "./apiRequest";
import { LittleOtherType } from "./apiDictionaries";

// ------------------------------ СПИСОК ПОДРАЗДЕЛЕНИЙ

export const departmentsApi = {
  get: async (props?: DepartmentsFiltersType): Promise<AxiosResponse<DepartmentsType>> => {
    return psApi.get(`/departments/`, {
      params: props,
    });
  },
};

// ------------------------------ СПИСОК СТРАН

export const countriesApi = <VOBJ>() =>
  entitiesBase<LittleOtherType, VOBJ>(hrApi, "resort_Country");

// ------------------------------ СПИСОК ОРГАНИЗАЦИЙ

export const organizationsApi = {
  get: async (): Promise<AxiosResponse<OrganizationsType>> => {
    return mrApi.get(`/organizations`);
  },
};

// ------------------------------ ТИПЫ - СПИСОК ПОДРАЗДЕЛЕНИЙ

// передаваемые значения

export type DepartmentsFiltersType = {
  organizationCode?: string;
  isMainDepartment?: boolean;
  mainDepartmentCode?: string;
};

// получаемые значения

export type DepartmentsType = DepartmentType[];

export type DepartmentType = IdType & {
  code: string;
  mainDepartment: string;
  mainDepartmentName: string;
  name: string;
  shortName: string;
  type: string;
  organization: OrganizationType;
  parent: string;
  location: LocationType;
};

type LocationType = {
  country: string;
  region: string;
  city: string;
  street: string;
  house: string;
  flat: string;
  name: string;
};

type OrganizationType = {
  code: string;
  name: string;
};

// ------------------------------ ТИПЫ - СПИСОК ОРГАНИЗАЦИЙ

// получаемые значения

export type OrganizationsType = {
  items: OrganizationType[];
  totalCount: number;
};
