import React, { FC } from "react";
import { Bars, History, MorbidityCard } from "../../InfographicPage.styled";
import { DiseasesType } from "../../../../api/apiDisease";
import { useChartsBar } from "../../InfectionPage/hooks/useChartsBar";
import { monthNumbers } from "../../../../constants/dates";
import EmployeeMorbidityBarMonth from "./EmployeeMorbidityBarMonth";
import { getDaysInMonth, setMonth } from "date-fns";
import { useAtomValue } from "jotai/utils";
import { currentDateInterval } from "../../utils/atoms";
import { now } from "../../../../utils/workingWithDates";

const EmployeeMorbidityCard: FC<PropsType> = (props) => {
  const { diseases } = props;

  const { getDiseasesInfo, sickDaysInYear, getSickDaysInMonth } = useChartsBar(diseases);

  const interval = useAtomValue(currentDateInterval);
  const date = interval[0] ?? now;

  return (
    <MorbidityCard>
      <History>
        <p>История</p>
        <p>{sickDaysInYear}</p>
        <p>Дней болезни</p>
      </History>

      <Bars>
        {monthNumbers.map((monthNumber) => {
          const sickDays = getSickDaysInMonth(monthNumber);
          const diseasesInfo = getDiseasesInfo(monthNumber);
          const days = getDaysInMonth(setMonth(date, monthNumber - 1));

          return (
            <EmployeeMorbidityBarMonth
              key={monthNumber}
              sickDays={sickDays ?? 0}
              days={days}
              monthNumber={monthNumber}
              diseasesInfo={diseasesInfo}
            />
          );
        })}
      </Bars>
    </MorbidityCard>
  );
};

export default EmployeeMorbidityCard;

type PropsType = {
  diseases: DiseasesType | undefined;
};
