import { Formik, FormikValues } from "formik";
import { useUpdateAtom } from "jotai/utils";
import React, { useState } from "react";
import { addFormState } from "../../atoms/atoms";
import { convertFileToBase64 } from "../../utils/convertFileToBase64";
import { getErrorMessage } from "../../utils/getErrorMessage";
import { ApiType, QueryKeysType, QueryKeyType, useQuery } from "../../utils/useQuery";
import { validateChanges } from "../../utils/validateChanges";
import { ErrorText } from "../ErrorBoundary/ErrorBoundary.styles";
import { RightModal } from "../RightModal";
import { RightModalActions } from "../RightModal/RightModalActions";
import { RightModalBody } from "../RightModal/RightModalBody";
import { FormContainer } from "./Form.styles";
import { FormButtons } from "./FormButtons";
import { ObjectSchema } from "yup";
import { Assign, ObjectShape } from "yup/lib/object";
import { SetStateAction } from "jotai";

export const Form = <DATUM, FLDS extends FormikValues, VOBJ>(
  props: FormPropsType<DATUM, FLDS, VOBJ>
) => {
  const {
    methods,
    id,
    isEdit = false,
    withoutModal = false,
    withoutChanges = false,
    onClose,
  } = props;

  const { formName, editFormTitle, addFormTitle, validationSchema, queryKeyForOneEntity } = methods;
  const { initialValuesAddForm, initialValuesEditForm, fields, modalData, setModalData } = methods;
  const { api, queryKey, onSubmit, valuesObject, formButtons } = methods;

  const setAddFormIsOpen = useUpdateAtom(addFormState);

  const [submitError, setSubmitError] = useState("");
  const create = useQuery<VOBJ>().useCreate({
    api: api as ApiType<VOBJ>,
    queryKey: queryKey as QueryKeyType,
    queryKeyForOneEntity,
  });

  const update = useQuery<VOBJ>().useUpdate({
    api: api as ApiType<VOBJ>,
    queryKey: queryKey as QueryKeyType,
    queryKeyForOneEntity,
  });

  const error = submitError
    ? submitError
    : modalData && isEdit
    ? getErrorMessage(update.error)
    : getErrorMessage(create.error);
  const isLoading = modalData && isEdit ? update.isLoading : create.isLoading;

  const handleClose = onClose
    ? () => onClose()
    : modalData && setModalData && isEdit
    ? () => setModalData(undefined)
    : () => setAddFormIsOpen(false);

  if (
    !api ||
    !queryKey ||
    !fields ||
    !initialValuesEditForm ||
    !initialValuesAddForm ||
    !editFormTitle ||
    !addFormTitle ||
    !formName
  )
    return null;

  const initialValuesObject =
    modalData && isEdit ? initialValuesEditForm(modalData) : initialValuesAddForm;

  const editFormName = formName + "EditForm";
  const addFormName = formName + "AddForm";

  const form = (
    <Formik
      initialValues={initialValuesObject}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        if (!validateChanges({ initialValues: initialValuesObject, values }) && !withoutChanges)
          return;

        setSubmitError("");
        if (values.icon?.type === "image/svg+xml") {
          const icon = await convertFileToBase64(values.icon);
          values = { ...values, icon: icon };
        }

        try {
          onSubmit
            ? await onSubmit(values, isEdit, id)
            : valuesObject
            ? !modalData || !isEdit
              ? await create.mutateAsync({ data: valuesObject(values) })
              : id
              ? await update.mutateAsync({ data: valuesObject(values, true), id })
              : undefined
            : undefined;

          handleClose();
        } catch (err) {
          if (onSubmit) setSubmitError(getErrorMessage(err));

          console.error(err);
        }
      }}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {({ handleSubmit, isValid, dirty }) => (
        <>
          <RightModalBody>
            <form id={modalData && isEdit ? editFormName : addFormName} onSubmit={handleSubmit}>
              <FormContainer>
                {fields ? (typeof fields !== "function" ? fields : fields(isEdit)) : null}
              </FormContainer>
            </form>
          </RightModalBody>

          {!!error && <ErrorText>{error}</ErrorText>}

          <RightModalActions>
            {formButtons ? (
              formButtons
            ) : (
              <FormButtons
                formName={modalData && isEdit ? editFormName : addFormName}
                onClose={handleClose}
                saveOrAdd={modalData && isEdit ? "save" : "add"}
                disabled={isLoading || (!withoutChanges && (!isValid || !dirty))}
              />
            )}
          </RightModalActions>
        </>
      )}
    </Formik>
  );

  return withoutModal ? (
    form
  ) : (
    <RightModal
      title={modalData && isEdit ? editFormTitle : addFormTitle}
      isOpen
      onClose={handleClose}
      needBlur
    >
      {form}
    </RightModal>
  );
};

export type FormPropsType<DATUM, FLDS, VOBJ> = {
  methods: FormMethodsType<DATUM, FLDS, VOBJ>;
  id?: string;
  isEdit?: boolean;
  withoutModal?: boolean;
  withoutChanges?: boolean;
  onClose?: () => void;
};

export type FormMethodsType<DATUM, FLDS, VOBJ> = {
  queryKey: QueryKeyType;
  api: ApiType<VOBJ>;
  queryKeyForOneEntity?: QueryKeysType;

  addFormTitle?: string;
  formName?: string;
  editFormTitle?: string;

  fields?: ((isEdit: boolean) => JSX.Element) | JSX.Element;
  initialValuesAddForm?: FLDS;
  initialValuesEditForm?: (arg: DATUM) => FLDS;
  validationSchema?: ObjectSchema<Assign<ObjectShape, object>>;
  valuesObject?: (values: FLDS, isEdit?: boolean) => VOBJ;
  modalData?: DATUM;
  setModalData?: (datum: SetStateAction<DATUM | undefined>) => void;
  onSubmit?: (values: FLDS, isEdit?: boolean, id?: string) => Promise<void>;
  formButtons?: JSX.Element;
};
