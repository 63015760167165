import React, { FC } from "react";
import { Page, Table } from "../../../uiKit";
import { PageContainer } from "../UserProfile.styles";
import { Panel } from "../components/Panel/Panel";
import { PageSwitcher } from "../components/PageSwitcher/PageSwitcher";
import { useInfections } from "./useInfections";

export const Infections: FC = () => {
  const methods = useInfections();
  const { header } = methods;

  return (
    <Page methods={methods} needScroll>
      <Panel />
      <PageContainer>
        <PageSwitcher />
        {header}
        <Table methods={methods} needScroll />
      </PageContainer>
    </Page>
  );
};
