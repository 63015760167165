import { useField, useFormikContext } from "formik";
import React, { FC, ReactNode, SyntheticEvent } from "react";
import { OptionsType, OptionType } from "../../utils/getOptions";
import { LabelType } from "../Form/LabelField";
import { SelectAutocomplete } from "./SelectAutocomplete";
import * as elements from "./SelectAutocompleteInput.styles";

export const SelectAutocompleteInput: FC<SelectAutocompleteInputPropsType> = (props) => {
  const { name } = props;

  const { Container, Error } = elements;

  const [{ value, onBlur }, { touched, error }] = useField<string>(name);
  const { setFieldValue } = useFormikContext();

  const hasError = touched && !!error;

  const handleChange = (
    _evt: SyntheticEvent<Element, Event>,
    arg: string | OptionType | (string | OptionType)[] | null
  ) => {
    if (!arg || typeof arg === "string" || Array.isArray(arg)) return;

    const value = arg.id;

    setFieldValue(name, value);
  };

  return (
    <Container>
      <SelectAutocomplete
        {...props}
        onChange={handleChange}
        onBlur={onBlur}
        value={value}
        hasError={hasError}
      />
      {hasError && <Error>{error}</Error>}
    </Container>
  );
};

export type SelectAutocompleteInputPropsType = LabelType & {
  name: string;
  options: OptionsType;
  placeholder?: string;
  size?: "small";
  disabled?: boolean;
  filter?: boolean;
  iconStart?: ReactNode;
  color?: string;
  limit?: number;
};
