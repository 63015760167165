import { QueryKeyType, useQuery } from "../../../../../utils/useQuery";
import { vaccinationDayApi, vaccinationPlanApi } from "../../../../../api/apiVaccination";
import { useEffect, useState } from "react";
import { modalDataDayState } from "../../utils/atoms";
import { useAtom } from "jotai";
import { AxiosError } from "axios";
import {
  ContinueCloseType,
  multiModalStep,
} from "../../../../../components/MultiStepConfirmModal/useMultiModal";
import { timeTrimSeconds } from "../../../../../utils/workingWithDates";

export const useDeleteDay = (queryKey: QueryKeyType) => {
  // ------------------------------ ДАННЫЕ ДЛЯ УДАЛЕНИЯ

  const [deletionData, setDeletionData] = useAtom(modalDataDayState);
  const { dayId, planId, startDate, endDate } = deletionData || {};
  const { infectionName, centerName, date } = deletionData || {};

  // ------------------------------ СТЕЙТЫ

  const [confirmModalText, setConfirmModalText] = useState("");
  const [confirmModalStage, setConfirmModalStage] = useState<ConfirmStageType>("first");

  // ------------------------------ КОНСТАНТЫ

  const shortStartDate = timeTrimSeconds(startDate);
  const shortEndDate = timeTrimSeconds(endDate);
  const shortDate = date?.substring(0, 5);
  const isOpen = !!deletionData;
  const isOneDayPeriod = startDate === endDate;
  const additionalButtonText = `Удалить весь период: ${shortStartDate} - ${shortEndDate}`;
  const needAdditional = !isOneDayPeriod && confirmModalStage === "first";

  // ------------------------------ ЗАПРОСЫ

  // ------------- план
  const deletionPlan = useQuery().useDelete({ api: vaccinationPlanApi(), queryKey });
  const deletionPlanWithRecords = useQuery().useCustomRequest({
    api: vaccinationPlanApi(),
    queryKey,
    key: "deleteWithAppointments",
  });

  // ------------- дни

  const deletionDay = useQuery().useDelete({ api: vaccinationDayApi(), queryKey });
  const deletionDayWithRecords = useQuery().useCustomRequest({
    api: vaccinationDayApi(),
    queryKey,
    key: "deleteWithAppointments",
  });

  useEffect(() => {
    if (deletionData) {
      setConfirmModalText(
        `Вы действительно хотите удалить вакцинацию против заболевания «${infectionName}» в ${centerName} на ${shortDate}?`
      );
      setConfirmModalStage("first");
    }
  }, [deletionData]);

  const closeConfirmModal = () => {
    setDeletionData(undefined);
  };

  // ------------------------------ УДАЛЕНИЕ ДНЯ

  const completeDeleteDay = (): ContinueCloseType => "close";
  const errorDeleteDay = (error: AxiosError): ContinueCloseType => {
    if (error.response?.status === 422) {
      setConfirmModalText(
        `На ${shortDate} ранее была произведена запись сотрудников на вакцинацию. Удаляя день из периода, вы так же удаляете всю запись на этот день.`
      );
      setConfirmModalStage("additionalDay");
      return "continue";
    } else return "close";
  };

  const handleDeleteDay = async () => {
    if (confirmModalStage === "first") {
      return multiModalStep({
        requests: deletionDay?.mutateAsync([dayId ?? ""]),
        onError: errorDeleteDay,
        onComplete: completeDeleteDay,
      });
    } else
      return multiModalStep({
        request: deletionDayWithRecords?.mutateAsync(dayId ?? ""),
        onError: completeDeleteDay,
        onComplete: completeDeleteDay,
      });
  };

  // ------------------------------ УДАЛЕНИЕ ПЕРИОДА

  const completePlan = (): ContinueCloseType => "close";

  const errorDeletePlan = (error: AxiosError): ContinueCloseType => {
    if (error.response?.status === 422) {
      setConfirmModalText(
        `На период ${shortStartDate} - ${shortEndDate} ранее была произведена запись сотрудников на вакцинацию. Удаляя период из календаря, вы так же удаляете всю запись.`
      );
      setConfirmModalStage("additionalPlan");
      return "continue";
      // } else return "close";
    } else {
      setConfirmModalText(
        `На ${shortDate} ранее была произведена запись сотрудников на вакцинацию. Удаляя день из периода, вы так же удаляете всю запись на этот день.`
      );
      return "continue";
    }
  };

  const handleDeletePlan = async () => {
    if (confirmModalStage === "firstPlan") {
      return multiModalStep({
        requests: deletionPlan?.mutateAsync([planId ?? ""]),
        onError: errorDeletePlan,
        onComplete: completePlan,
      });
    } else
      return multiModalStep({
        request: deletionPlanWithRecords?.mutateAsync(planId ?? ""),
        onError: errorDeletePlan,
        onComplete: completePlan,
      });
  };

  const openSecondPlanConfirm = () => {
    setConfirmModalText(
      `Вы действительно хотите удалить период ${shortStartDate} - ${shortEndDate} из календаря?`
    );
    setConfirmModalStage("firstPlan");
  };

  return {
    confirmModalText,
    isOpen,
    closeConfirmModal,
    additionalButtonText,

    confirmModalStage,
    handleDeletePlan,
    handleDeleteDay,
    openSecondPlanConfirm,

    needAdditional,
  };
};

type ConfirmStageType = "first" | "additionalDay" | "firstPlan" | "additionalPlan";
