import * as dateFns from "date-fns";
import { isValid } from "date-fns";
import { DateSwitcherTypeType } from "../atoms/atoms";
import { arrDays, arrMonths, arrYears } from "../constants/dates";
import { getInclinedWord } from "./getInclinedWord";
import { dateTypeOptions } from "../constants/options";

const { differenceInYears, differenceInMonths, differenceInDays } = dateFns;
const { endOfDay, format, parse, startOfDay } = dateFns;
const { addDays, addMonths, addQuarters, addYears } = dateFns;
const { subDays, subMonths, subQuarters, subYears } = dateFns;
const { startOfISOWeek, startOfMonth, startOfQuarter, startOfYear } = dateFns;
const { endOfISOWeek, endOfMonth, endOfQuarter, endOfYear } = dateFns;

// ------------------------------ НАЧАЛО И КОНЕЦ ТЕКУЩЕГО ДНЯ

export const now = new Date();
export const today = startOfDay(now);
export const endOfToday = endOfDay(now);

// ------------------------------ ПРЕОБРАЗОВАНИЕ ОБЪЕКТА ДАТЫ В СТРОКУ ДАТЫ

export const formatDate = (date: Date) => format(date, "dd.MM.yyyy");

// ------------------------------ ПРЕОБРАЗОВАНИЕ СТРОКИ ДАТЫ В ОБЪЕКТ ДАТЫ
export const parseDate = (dateString: string) => {
  const date = dateString.includes("T") ? dateString.split("T")[0] : dateString;
  const formattedDate = parse(date, date.includes("-") ? "yyyy-MM-dd" : "dd.MM.yyyy", today);

  return date ? formattedDate : today;
};

// ------------------------------ ПРЕОБРАЗОВАНИЕ СТРОКИ ДАТЫ В ФОРМАТИРОВАННУЮ СТРОКУ ДАТЫ

export const formatParseDate = (dateString: string) => formatDate(parseDate(dateString));

// ------------------------------ ПРЕОБРАЗОВАНИЕ СТРОКИ ДАТЫ В КОРОТКУЮ ФОРМАТИРОВАННУЮ СТРОКУ ДАТЫ

export const formatShortParseDate = (dateString: string) => format(parseDate(dateString), "dd.MM");

// ------------------------------ ПРЕОБРАЗОВАНИЕ ОБЪЕКТА ДАТЫ В ФОРМАТИРОВАННУЮ ДЛЯ БЭКЕНДА СТРОКУ ДАТЫ

export const formatDateForBackend = (date: Date) => format(date, "yyyy-MM-dd");

// ------------------------------ ПРЕОБРАЗОВАНИЕ В ДЕНЬ НЕДЕЛИ

export const formatParseDayOfWeek = (dateString: string) => format(parseDate(dateString), "EEE");

// ------------------------------ ПРЕОБРАЗОВАНИЕ СТРОКИ ВРЕМЕНИ В ОБЪЕКТ ДАТЫ
export const parseTime = (timeString: string) => {
  const withoutSecond = `${timeString.split(":")[0]}:${timeString.split(":")[1]}`;
  const formatedTime = parse(withoutSecond, "HH:mm", new Date());

  return isValid(formatedTime) ? formatedTime : null;
};

// ------------------------------ ПРЕОБРАЗОВАНИЕ ОБЬЕКТА ДАТЫ В СТРОКУ ВРЕМЕНИ
export const formatTime = (timeDate: Date | null) => {
  return timeDate ? format(timeDate, "HH:mm") : "";
};

// ------------------------------ ПРЕОБРАЗОВАНИЕ СТРОКИ ВРЕМЕНИ БЭКА
export const timeTrimSeconds = (timeString?: string) => {
  return timeString ? timeString.substring(0, 5) : "";
};

// ------------------------------ ПРЕОБРАЗОВАНИЕ СТРОКИ ВРЕМЕНИ БЭКА
export const timeString = (start?: string, end?: string) => {
  return `${timeTrimSeconds(start)} - ${timeTrimSeconds(end)}`;
};

// ------------------------------ ДОБАВЛЕНИЕ УДАЛЕНИЕ ЕДИНИЦЫ ПЕРИОДА

export const plusOneDay = (date: Date) => addDays(date, 1);
export const minusOneDay = (date: Date) => subDays(date, 1);

export const plusOneMonth = (date: Date) => addMonths(date, 1);
export const minusOneMonth = (date: Date) => subMonths(date, 1);

export const plusOneQuarter = (date: Date) => addQuarters(date, 1);
export const minusOneQuarter = (date: Date) => subQuarters(date, 1);

export const plusOneYear = (date: Date) => addYears(date, 1);
export const minusOneYear = (date: Date) => subYears(date, 1);

// ------------------------------ ВЫЧИСЛЕНИЕ РАЗНИЦЫ В МЕЖДУ СЕЙЧАС И ОПРЕДЕЛЁННОЙ ДАТОЙ

export const differenceDate = (dateString: string) => {
  const yearsQuantity = differenceInYears(today, parseDate(dateString));
  const monthsQuantity = differenceInMonths(today, parseDate(dateString));

  return yearsQuantity === 0
    ? `${monthsQuantity} ${getInclinedWord(monthsQuantity, arrMonths)}`
    : `${yearsQuantity} ${getInclinedWord(yearsQuantity, arrYears)}`;
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * ВЫЧИСЛЕНИЕ РАЗНИЦЫ МЕЖДУ СЕЙЧАС И ОПРЕДЕЛЁННОЙ ДАТОЙ
 * например, возраста или стажа
 * в годах или месяцах
 *
 *
 * @param dateStr - дата, разница с которой вычисляется
 * @param period - "year" | "month" | "days"
 *
 */

export const differenceWithToday = (
  dateStr: string,
  period: "year" | "month" | "days" = "year"
) => {
  const date = parseDate(dateStr);

  const yearsQuantity = differenceInYears(today, date);
  const monthsQuantity = differenceInMonths(today, date);
  const daysQuantity = differenceInDays(today, date);

  return period === "year" ? yearsQuantity : period === "month" ? monthsQuantity : daysQuantity;
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * ПОЛУЧЕНИЕ СТРОКИ ПЕРИОДА
 * должна быть передана хотя бы одна дата
 *
 *
 * @param since - начало периода
 * @param until - конец периода
 * @param withText - указание на то, что период требуется в формате "с/по", а не через дефис - если не передан, то формат через дефис
 *
 */

export const getPeriodString = (
  since?: Date | string | null,
  until?: Date | string | null,
  withText?: boolean
) => {
  const sinceDate =
    !!since && (typeof since === "string" ? formatParseDate(since) : formatDate(since));
  const untilDate =
    !!until && (typeof until === "string" ? formatParseDate(until) : formatDate(until));

  const sinceString = since ? `${withText ? "c " : ""}${sinceDate}` : "";
  const untilString = until ? `${withText ? " по " : " — "}${untilDate}` : "";

  return `${sinceString}${untilString}`;
};

// ------------------------------ МЕТОДЫ ПО ПЕРИОДАМ

export const startAndEndMethods = {
  start: {
    year: startOfYear,
    quarter: startOfQuarter,
    month: startOfMonth,
    period: startOfISOWeek,
  },
  end: {
    year: endOfYear,
    quarter: endOfQuarter,
    month: endOfMonth,
    period: endOfISOWeek,
  },
} as Record<"start" | "end", Record<DateSwitcherTypeType, (date: Date | number) => Date>>;

export const plusAndMinusOneDayMethods = {
  plus: {
    year: plusOneYear,
    quarter: plusOneQuarter,
    month: plusOneMonth,
    day: plusOneDay,
  },
  minus: {
    year: minusOneYear,
    quarter: minusOneQuarter,
    month: minusOneMonth,
    day: minusOneDay,
  },
} as Record<"plus" | "minus", Record<DateSwitcherTypeType, (date: Date) => Date>>;

// ------------------------------ ПОЛУЧЕНИЕ ТИПА ИНТЕРВАЛА

const unitMultiplies = {
  [dateTypeOptions[1].id]: 30,
  [dateTypeOptions[2].id]: 365,
  [dateTypeOptions[0].id]: 1,
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * ПОЛУЧЕНИЕ ЕДИНИЦЫ ИЗМЕРЕНИЯ ИНТЕРВАЛА
 * ('year')
 *
 * @param interval - кол-во дней
 *
 */
export const getIntervalUnit = (interval: number) =>
  Object.keys(unitMultiplies).find((key) => interval % unitMultiplies[key] === 0) ??
  dateTypeOptions[0].id;

/**
 *
 * ------------------------------------------------------------------------------------------
 * ПРЕОБРАЗОВАНИЕ ИНТЕРВАЛА В ЕДИНИЦУ ИЗМЕРЕНИЯ
 *
 * @param interval - кол-во дней
 *
 * @return count кол-во лет/месяцев/недель в зависимости от дней
 *
 */
export const intervalToNumber = (interval: number) =>
  interval / unitMultiplies[getIntervalUnit(interval)];

/**
 *
 * ------------------------------------------------------------------------------------------
 * ПРЕОБРАЗОВАНИЕ В ДНИ ИНТЕРВАЛА ПО ТИПУ
 *
 * @param interval - кол-во лет/месяцев/недель
 * @param units - единица измерения
 *
 * @return count кол-во дней
 *
 */

export const getIntervalByUnit = (interval: number, units: string) =>
  interval * unitMultiplies[units] ?? 1;

/**
 *
 * ------------------------------------------------------------------------------------------
 * ПРЕОБРАЗОВАНИЕ В СТРОКУ ДНЕЙ ИНТЕРВАЛА
 *
 * @param interval - кол-во лет/месяцев/недель
 *
 * @return string форматированная строка
 *
 */

export const getFormattedInterval = (interval: number) => {
  const unit = getIntervalUnit(interval);
  const date = intervalToNumber(interval);

  return `${date} ${getInclinedWord(date, inclinesInterval[unit])}`;
};

export const inclinesInterval = {
  days: arrDays,
  month: arrMonths,
  year: arrYears,
} as { [key: string]: string[] };
