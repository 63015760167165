import { atomWithQuery } from "jotai/query";
import { fetch } from "../../../../utils/fetchData";
import { atom } from "jotai";
import { loadable, useAtomValue } from "jotai/utils";
import { currentUserAtom } from "../../../../atoms/atomCurrentUser";
import { getEqualsFilter } from "../../../../utils/getQueryFilter";
import {
  NotificationSettingsType,
  vaccinationNotificationApi,
} from "../../../../api/apiVaccination";

export const modalDataState = atom<undefined | NotificationSettingsType>(undefined);

// ------------------------------ ДАННЫЕ

export const notificationSettingsAtom = atomWithQuery((get) => {
  const organization = get(currentUserAtom).res.organization;

  return notificationSettingsQuery(organization);
});

export const notificationSettingsState = loadable(notificationSettingsAtom);

const notificationSettingsQuery = (organization: string) => {
  const filter = [getEqualsFilter(organization, "organization")];

  return {
    queryKey: [organization, "notificationSettingsState"],
    queryFn: () => fetch(() => vaccinationNotificationApi().getAll({ filter })),
  };
};

export const useNotificationSettingsQuery = () => {
  const organization = useAtomValue(currentUserAtom).res.organization;
  return {
    queryKey: [organization, "notificationSettingsState"],
    api: vaccinationNotificationApi(),
  };
};
