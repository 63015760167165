import React from "react";
import { ObjectSchema } from "yup";
import { Assign, ObjectShape } from "yup/lib/object";
import { useAtom } from "jotai";
import { exportFormState } from "../../atoms/atoms";
import { RightModal } from "../RightModal";
import { Formik } from "formik";
import { RightModalBody } from "../RightModal/RightModalBody";
import { FormContainer } from "../Form/Form.styles";
import { RightModalActions } from "../RightModal/RightModalActions";
import { FormButtons } from "../Form";

export const ExportFilters = <EFLTRS,>(props: PropsType<EFLTRS>) => {
  const { children, methods } = props;

  const {
    downloadFormName = "downloadFiltersForm",
    downloadFormTitle = "Экспортировать",
    fieldsDownload,
    onSubmitDownloadFilters,
  } = methods;
  const { absoluteInitialDownloadValues, initialValuesDownload, validationSchemaDownload } =
    methods;

  const [downloadFormIsOpen, setDownloadFormIsOpen] = useAtom(exportFormState);

  const handleClose = () => setDownloadFormIsOpen(false);

  if (!fieldsDownload || !onSubmitDownloadFilters || !initialValuesDownload) return null;

  return (
    <RightModal
      title={downloadFormTitle}
      isOpen={downloadFormIsOpen}
      onClose={handleClose}
      size="small"
    >
      <Formik
        initialValues={initialValuesDownload ?? {}}
        validationSchema={validationSchemaDownload}
        onSubmit={async (values) => {
          await onSubmitDownloadFilters(values as EFLTRS);

          handleClose();
        }}
      >
        {({ handleSubmit, setValues }) => (
          <>
            {children ? (
              children
            ) : (
              <RightModalBody>
                <form id={downloadFormName} onSubmit={handleSubmit}>
                  <FormContainer>{fieldsDownload}</FormContainer>
                </form>
              </RightModalBody>
            )}

            <RightModalActions>
              <FormButtons
                formName={downloadFormName}
                onClose={handleClose}
                saveOrAdd="download"
                onClear={() => setValues(absoluteInitialDownloadValues ?? {})}
              />
            </RightModalActions>
          </>
        )}
      </Formik>
    </RightModal>
  );
};

type PropsType<EFLTRS> = {
  methods: ExportFiltersMethodsType<EFLTRS>;
  children?: JSX.Element;
};

export type ExportFiltersMethodsType<EFLTRS> = {
  fieldsDownload?: JSX.Element;
  onSubmitDownloadFilters?: (arg: EFLTRS) => Promise<void>;
  absoluteInitialDownloadValues?: EFLTRS;
  initialValuesDownload?: EFLTRS;
  validationSchemaDownload?: ObjectSchema<Assign<ObjectShape, object>>;

  downloadFormName?: string;
  downloadFormTitle?: string;
};

export type MergedCellType = {
  s: { c: number; r: number };
  e: { c: number; r: number };
}[];
