import React from "react";
import * as Yup from "yup";
import { DiseaseClassType } from "../../../../api/apiDictionaries";
import { long, notJustSpaces, required } from "../../../../constants/forValidationSchemes";
import { withoutSpaces } from "../../../../constants/regexs";
import { TextInput } from "../../../../uiKit";
import { FieldsBlock } from "../../../../uiKit/Form/Form.styles";

export const useForms = () => {
  // ------------------------------ КОНСТАНТЫ

  const addFormTitle = "Добавить код МКБ-10";
  const editFormTitle = "Изменение кода МКБ-10";

  const formName = "diseasesClass";

  // ------------------------------ ФОРМА - ПОЛЯ

  const fields = (
    <FieldsBlock>
      <TextInput name="code" outsideLabel="Код МКБ-10*" placeholder="Введите код" />
      <TextInput name="name" outsideLabel="Наименование *" placeholder="Введите наименование" />
      <TextInput name="description" outsideLabel="Описание" placeholder="—" multiline />
    </FieldsBlock>
  );

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesAddForm = {
    code: "",
    name: "",
    description: "",
  };

  const initialValuesEditForm = ({ name, description, code }: DiseaseClassType) => ({
    code,
    name,
    description,
  });

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchema = Yup.object().shape({
    code: Yup.string().matches(withoutSpaces, notJustSpaces).max(255, long).required(required),
    name: Yup.string().matches(withoutSpaces, notJustSpaces).max(255, long).required(required),
    description: Yup.string().matches(withoutSpaces, notJustSpaces).max(1000, long),
  });

  // ------------------------------ ФОРМА - ЗНАЧЕНИЯ

  const valuesObject = ({ name, description, code }: InitialValuesType) => ({
    code,
    name,
    description,
  });

  return {
    addFormTitle,
    editFormTitle,
    formName,

    fields,

    initialValuesAddForm,
    initialValuesEditForm,

    validationSchema,
    valuesObject,
  };
};

type InitialValuesType = Omit<DiseaseClassType, "id">;
