import React, { FC } from "react";
import { MarginsType } from "../../styles/marginsStyles";
import { Icon } from "./ProfileIcon.styles";

export const ProfileIcon: FC<PropsType> = (props) => {
  const { initials, userIcon, width, height, margin } = props;

  return (
    <Icon width={width} height={height} margin={margin} backgroundImg={userIcon}>
      <p>{initials}</p>
    </Icon>
  );
};

type PropsType = {
  initials: string;
  userIcon?: string;
  width?: string;
  height?: string;
  margin?: MarginsType;
};
