import React, { FC } from "react";
import { PopoverModal } from "../../uiKit";
import { Info } from "../../uiKit/Icons";
import { usePopoverHover } from "../../uiKit/PopoverHover/usePopoverHover";
import { Container, InfoContainer, InfoIcon, InfoText, Title } from "./TitleWithInfo.styles";

export const TitleWithInfo: FC<PropsType> = (props) => {
  const { title, info } = props;

  const { propsPopoverHover, openPopoverHover } = usePopoverHover({});

  return (
    <Container>
      <Title>{title}</Title>
      {info && (
        <InfoIcon onClick={openPopoverHover}>
          <Info />
        </InfoIcon>
      )}

      <PopoverModal {...propsPopoverHover} id="info">
        <InfoContainer>
          <InfoText>{info}</InfoText>
        </InfoContainer>
      </PopoverModal>
    </Container>
  );
};

type PropsType = {
  title: string;
  info?: string;
};
