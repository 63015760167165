import styled from "@emotion/styled/macro";
import { colors, flex, margins, text } from "../../styles";

export const Container = styled.div`
  ${flex({ isColumn: true, horizontal: "center", vertical: "center" })};

  width: 100vw;
  height: 100vh;
`;

export const WarningText = styled.p`
  ${text({ size: 28, height: 30, weight: 500, color: colors.warningDark, uppercase: true })};
`;

export const Description = styled.p`
  ${text({ size: 18, height: 20, weight: 400 })};
  ${margins({ m: "40 0 0 0" })};
`;

export const Mail = styled.a`
  ${text({ size: 18, height: 20, weight: 400 })};
  ${margins({ m: "40 0 0 0" })};
`;
