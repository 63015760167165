import React from "react";
import { colors } from "../../styles";

export const CrossFilled = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 10C0.75 4.75329 5.00329 0.5 10.25 0.5C15.4967 0.5 19.75 4.75329 19.75 10C19.75 15.2467 15.4967 19.5 10.25 19.5C5.00329 19.5 0.75 15.2467 0.75 10ZM6.71492 13.5355C6.3244 13.145 6.3244 12.5118 6.71492 12.1213L8.8362 10.0001L6.71477 7.87867C6.32424 7.48815 6.32424 6.85499 6.71477 6.46446C7.10529 6.07394 7.73846 6.07394 8.12898 6.46446L10.2504 8.5859L12.3718 6.46447C12.7623 6.07395 13.3955 6.07395 13.786 6.46447C14.1765 6.85499 14.1765 7.48816 13.786 7.87868L11.6646 10.0001L13.7858 12.1213C14.1764 12.5118 14.1764 13.145 13.7858 13.5355C13.3953 13.9261 12.7621 13.9261 12.3716 13.5355L10.2504 11.4143L8.12913 13.5355C7.73861 13.9261 7.10544 13.9261 6.71492 13.5355Z"
      fill={colors.redDefault}
    />
  </svg>
);
