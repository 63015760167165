import styled from "@emotion/styled/macro";
import React, { FC, MouseEvent } from "react";
import * as icons from ".";
import { borders, colors, flex, grid, margins, text } from "../../styles";

export const Icons: FC = () => (
  <Container>
    <h3>При нажатии ЛКМ по тегу необходимой компоненты, он будет скопирован в буфер обмена</h3>

    <IconsContainer>
      <IconsBlock icon={<icons.Logo />} name="<Logo />" />
      <>
        <div></div>
        <div></div>
      </>

      <IconsBlock icon={<icons.BigCheck />} name="<BigCheck />" />
      <IconsBlock icon={<icons.BigCrossInCircle />} name="<BigCrossInCircle />" />
      <IconsBlock icon={<icons.BigPlus />} name="<BigPlus />" />

      <IconsBlock icon={<icons.BigWarning />} name="<BigWarning />" />
      <>
        <div></div>
        <div></div>
      </>
      <>
        <div></div>
        <div></div>
      </>

      <IconsBlock icon={<icons.ArrowLeft />} name="<ArrowLeft />" />
      <IconsBlock icon={<icons.ArrowRight />} name="<ArrowRight />" />
      <IconsBlock icon={<icons.ArrowRound />} name="<ArrowRound />" />

      <IconsBlock icon={<icons.TriangleDown />} name="<TriangleDown />" />
      <IconsBlock icon={<icons.TriangleRight />} name="<TriangleRight />" />
      <IconsBlock icon={<icons.TriangleUp />} name="<TriangleUp />" />

      <IconsBlock icon={<icons.Cross />} name="<Cross />" />
      <IconsBlock icon={<icons.CrossInCircle />} name="<CrossInCircle />" />
      <IconsBlock icon={<icons.CrossOnCircle />} name="<CrossOnCircle />" />

      <IconsBlock icon={<icons.CrossFilled />} name={"CrossFilled"} />
      <>
        <div></div>
        <div></div>
      </>
      <>
        <div></div>
        <div></div>
      </>

      <IconsBlock icon={<icons.Book />} name="<Book />" />
      <IconsBlock icon={<icons.Folder />} name="<Folder />" />
      <IconsBlock icon={<icons.Loupe />} name="<Loupe />" />

      <IconsBlock icon={<icons.Calendar />} name="<Calendar />" />
      <IconsBlock icon={<icons.CalendarFilled />} name="<CalendarFilled />" />
      <IconsBlock icon={<icons.Statistics />} name="<Statistics />" />

      <IconsBlock icon={<icons.BuildingAndTree />} name="<BuildingAndTree />" />
      <IconsBlock icon={<icons.BuildAndTreeFilled />} name="<BuildAndTreeFilled />" />
      <IconsBlock icon={<icons.ListPlus />} name="<ListPlus />" />

      <IconsBlock icon={<icons.Reduce />} name="<Reduce />" />
      <IconsBlock icon={<icons.Expand />} name="<Expand />" />
      <IconsBlock icon={<icons.Gear />} name="<Gear />" />

      <IconsBlock icon={<icons.Bell />} name="<Bell />" />

      <IconsBlock icon={<icons.PaperClip />} name="<PaperClip />" />
      <IconsBlock icon={<icons.Ok />} name="<Ok />" />

      <IconsBlock icon={<icons.Trash />} name="<Trash />" />
      <IconsBlock icon={<icons.ShadedTrash />} name="<ShadedTrash />" />
      <IconsBlock icon={<icons.Sieve />} name="<Sieve />" />

      <IconsBlock icon={<icons.Geo />} name="<Geo />" />
      <IconsBlock icon={<icons.Group />} name="<Group />" />
      <>
        <div></div>
        <div></div>
      </>

      <IconsBlock icon={<icons.CloudWithArrow />} name="<CloudWithArrow />" />
      <IconsBlock icon={<icons.Export />} name="<Export />" />
      <IconsBlock icon={<icons.Download />} name="<Download />" />

      <IconsBlock icon={<icons.Square />} name="<Square />" />
      <IconsBlock icon={<icons.SquareWithCheck />} name="<SquareWithCheck />" />
      <IconsBlock icon={<icons.SquareFilledWithCheck />} name="<SquareFilledWithCheck />" />

      <IconsBlock icon={<icons.Radio checked />} name="<Radio checked />" />
      <IconsBlock icon={<icons.Radio checked={false} />} name="<Radio checked={false}/>" />
      <IconsBlock icon={<icons.Triangle />} name="<Triangle />" />

      <IconsBlock icon={<icons.Star active={true} />} name="<Star active={true} />" />
      <IconsBlock icon={<icons.Star active={false} />} name="<Star active={false} />" />
      <>
        <div></div>
        <div></div>
      </>

      <IconsBlock icon={<icons.Check />} name="<Check />" />
      <IconsBlock icon={<icons.CheckCircleOutline />} name="<CheckCircleOutline />" />
      <>
        <div></div>
        <div></div>
      </>

      <IconsBlock icon={<icons.ExclamationMark />} name="<ExclamationMark />" />
      <IconsBlock icon={<icons.Info />} name="<Info />" />
      <IconsBlock icon={<icons.InfoPaper />} name="<InfoPaper />" />

      <>
        <div></div>
        <div></div>
      </>
      <IconsBlock icon={<icons.Comment />} name="<Comment />" />
      <IconsBlock icon={<icons.List />} name="<List />" />
      <>
        <div></div>
        <div></div>
      </>

      <IconsBlock icon={<icons.Plus />} name="<Plus />" />
      <IconsBlock icon={<icons.Minus />} name="<Minus />" />
      <IconsBlock icon={<icons.PlusChar />} name="<PlusChar />" />

      <IconsBlock icon={<icons.ThreeDots />} name="<ThreeDots />" />
      <IconsBlock icon={<icons.NineDots />} name="<NineDots />" />
      <>
        <div></div>
        <div></div>
      </>

      <IconsBlock icon={<icons.Order />} name="<Order />" />
      <IconsBlock icon={<icons.EmptyBox />} name="<EmptyBox />" />
      <>
        <div></div>
        <div></div>
      </>

      <IconsBlock icon={<icons.CheckForSwitch />} name="<CheckForSwitch />" />
      <IconsBlock icon={<icons.Pencil />} name="<Pencil />" />
      <IconsBlock icon={<icons.TimeEdit />} name="<TimeEdit />" />

      <IconsBlock icon={<icons.PersonOutline />} name="<PersonOutline />" />
      <IconsBlock icon={<icons.Person />} name="<Person />" />
      <IconsBlock icon={<icons.PersonEdit />} name="<PersonEdit />" />

      <IconsBlock icon={<icons.Syringe />} name="<Syringe />" />
      <IconsBlock icon={<icons.MedPlus />} name="<MedPlus />" />

      <>
        <div></div>
        <div></div>
      </>
    </IconsContainer>
  </Container>
);

const IconsBlock: FC<{ icon: JSX.Element; name: string }> = ({ icon, name }) => (
  <>
    <div>{icon}</div>
    <div>
      <p onClick={copyText}>{name}</p>
    </div>
  </>
);

const copyText = async (evt: MouseEvent<HTMLParagraphElement>) =>
  await navigator.clipboard.writeText(evt.currentTarget.innerText);

const Container = styled.div`
  ${flex({ isColumn: true, horizontal: "center" })};

  height: 100vh;
  overflow: auto;

  h3 {
    ${text({ size: 24, height: 24, weight: 400 })};
    ${margins({ m: "40 0" })};
  }
`;

const IconsContainer = styled.div`
  ${grid({ gap: 16, columns: "repeat(3, 50px 250px)", autoRows: 40 })};
  ${borders({ color: colors.grayscaleInput, radius: 16 })};
  ${margins({ m: "16", p: "16" })};

  width: fit-content;
  height: fit-content;

  div {
    ${flex({ totalCenter: true })};

    :nth-of-type(2n) {
      ${flex({ horizontal: "start" })};
    }

    p {
      ${text({ size: 20, height: 24, weight: 400 })};

      cursor: pointer;
    }
  }
`;
