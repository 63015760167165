import styled from "@emotion/styled/macro";
import * as styles from "../../../styles";

const { colors, margins, button, buttonOnlyIcon, buttonSecondaryDefault, buttonTextDefault } =
  styles;

export const Container = styled.button<{ visibleSearch: boolean }>`
  ${button};
  ${({ visibleSearch }) => (visibleSearch ? buttonSecondaryDefault : buttonTextDefault)};
  ${buttonOnlyIcon};

  ${margins({ m: "0" })};

  svg > path {
    fill: ${colors.grayscaleBeautifulBlack};
  }
`;
