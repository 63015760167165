import { useAtom } from "jotai";
import React from "react";
import * as Yup from "yup";
import { SelectAutocompleteInput, SelectInput } from "../../../../uiKit";
import { FilterBody } from "../../../../uiKit/Filters/Filters.styles";
import { useMainDepartmentOptions } from "../../../../utils/getOptions";
import { absoluteInitialValues, filtersState } from "../utils/atoms";
import { yesAndNoOptions } from "../../../../constants/options";

export const useFilters = (canViewAdditionalFilter: boolean) => {
  // ------------------------------ АТОМЫ

  const [filters, setFilters] = useAtom(filtersState);

  // ------------------------------ ХУКИ

  const mainDepartmentOptions = useMainDepartmentOptions();

  // ------------------------------ КОНСТАНТЫ

  const filtersFormName = "filterForm";
  const filtersFormTitle = "Фильтры";

  const fieldsFiltersBase = (additional?: JSX.Element) => (
    <FilterBody>
      {canViewAdditionalFilter && (
        <SelectAutocompleteInput
          name="departmentCode"
          options={mainDepartmentOptions}
          outsideLabel="Подразделение"
          placeholder="Все"
          size="small"
        />
      )}
      <SelectInput
        name="access"
        options={yesAndNoOptions}
        outsideLabel="Допуск"
        size="small"
        placeholder="Все"
      />
      <SelectInput
        name="agreement"
        options={yesAndNoOptions}
        outsideLabel="Согласие"
        size="small"
        placeholder="Все"
      />
      <SelectInput
        name="exemption"
        options={yesAndNoOptions}
        outsideLabel="Медотвод"
        size="small"
        placeholder="Все"
      />
      <SelectInput
        name="hasAppointment"
        options={yesAndNoOptions}
        outsideLabel="Запись"
        size="small"
        placeholder="Все"
      />
      <SelectInput
        name="plannedExpired"
        options={yesAndNoOptions}
        outsideLabel="Просрочена дата планируемой вакцинации"
        size="small"
        placeholder="Все"
      />
      {additional}
    </FilterBody>
  );

  const fieldsFilters = fieldsFiltersBase();
  const fieldsFiltersWithAdditional = (jsx: JSX.Element) => fieldsFiltersBase(jsx);

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesFilters = filters;

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchemaFilters = Yup.object().shape({
    departmentCode: Yup.string(),
    hasAppointment: Yup.string(),
    access: Yup.string(),
    agreement: Yup.string(),
    plannedExpired: Yup.string(),
    exemption: Yup.string(),
    startDate: Yup.string(),
    endDate: Yup.string(),
  });

  return {
    filtersFormName,
    filtersFormTitle,

    fieldsFilters,
    fieldsFiltersWithAdditional,

    filters,
    setFilters,
    absoluteInitialValues,

    initialValuesFilters,
    validationSchemaFilters,
  };
};
