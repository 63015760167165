import { useUserProfilePage } from "../../useUserProfilePage";
import { useInfectionDiseaseOptions } from "../../../../utils/getOptions";
import { useAtom } from "jotai";
import {
  appointmentsByInfectionState,
  currentInfectionState,
  employeeVaccinationHistoryState,
  modalDataFactState,
  modalDataVaccinationRecordState,
  selectedInfectionsState,
  useEmployeeVaccinationHistoryKeys,
  vaccineInfectionProfileState,
} from "../../utils/atoms";
import { VaccinationFactType } from "../../../../api/apiVaccination";
import { useAtomValue } from "jotai/utils";
import { formatDate, parseDate } from "../../../../utils/workingWithDates";
import { addDays } from "date-fns";

export const useData = () => {
  // ------------------------------ ОБЩИЕ ДАННЫЕ

  const methods = useUserProfilePage();

  // ------------------------------ АТОМЫ

  const [modalData, setModalData] = useAtom(modalDataFactState);
  const infectionComponents = useAtomValue(vaccineInfectionProfileState);
  const historyVaccinated = useAtomValue(employeeVaccinationHistoryState);
  const appointments = useAtomValue(appointmentsByInfectionState);

  const [modalDataView, setModalDataView] = useAtom(modalDataVaccinationRecordState);
  const [selectedInfections, setSelectedInfection] = useAtom(selectedInfectionsState);
  const [currentInfection, setCurrentInfection] = useAtom(currentInfectionState);

  // ------------------------------ ХУКИ

  const infectionOptions = useInfectionDiseaseOptions();
  const { api, queryKey: additionalKey } = useEmployeeVaccinationHistoryKeys();
  const queryKeyForOneEntity = [additionalKey];

  // ------------------------------ КОНСТАНТЫ

  const planetDate = () => {
    if (!historyVaccinated?.length) return undefined;
    const lastFact = historyVaccinated[0];

    const days = lastFact.component.interval
      ? lastFact.component.interval
      : lastFact.component.vaccine.interval;

    const date = addDays(parseDate(lastFact.date), days);

    return formatDate(date);
  };

  const getTypeRecord = (vaccinationFact?: VaccinationFactType) => {
    return infectionComponents.vaccineComponents.some(
      ({ id }) => id === vaccinationFact?.component.id
    )
      ? "vaccine"
      : "revaccine";
  };

  return {
    ...methods,

    modalData,
    setModalData,

    api,
    queryKeyForOneEntity,

    historyVaccinated,
    appointments,
    planetDate,

    infectionOptions,

    selectedInfections,
    setSelectedInfection,

    setCurrentInfection,
    currentInfection,

    modalDataView,
    setModalDataView,
    getTypeRecord,

    infectionComponents,
  };
};
