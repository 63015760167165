import React, { FC } from "react";
import { Info } from "./InfoText.styles";

export const InfoText: FC<PropsType> = (props) => {
  const { text } = props;

  return <Info>{text}</Info>;
};

type PropsType = {
  text: string;
};
