import React, { FC, useEffect } from "react";
import { VaccinationDateType } from "../../../../api/apiVaccination";
import * as elements from "./SummaryDayInfo.styles";
import { Times } from "./SummaryDayInfo.styles";
import { PersonOutline } from "../../../../uiKit/Icons";
import { UsedCount } from "../../CalendarPage/CalendarView/CalendarView.styles";
import { useAtom } from "jotai";
import { selectedTimeState } from "../utils/atoms";
import { timeTrimSeconds } from "../../../../utils/workingWithDates";

export const SummaryDayInfo: FC<PropsType> = (props) => {
  const { day } = props;
  const { limit, used, times } = day ?? {};

  const { Container, DayBlock, SubTitle, Time } = elements;

  const [selectedTime, setSelectedTime] = useAtom(selectedTimeState);

  useEffect(() => {
    if (times && (!selectedTime || !times.some(({ id }) => id === selectedTime?.id)))
      setSelectedTime(times[0]);
  }, [times]);

  return (
    <Container>
      <DayBlock>
        <SubTitle>Слоты на день</SubTitle>

        <UsedCount>
          <PersonOutline />
          {used ?? 0}/{limit ?? 0}
        </UsedCount>
      </DayBlock>
      <DayBlock full>
        <SubTitle>Выберите время для записи</SubTitle>
        <Times>
          {times?.map(({ startTime, endTime, id }, i) => (
            <Time
              key={i}
              selected={selectedTime?.id === id}
              onClick={() => setSelectedTime({ startTime, endTime, id })}
            >
              {timeTrimSeconds(startTime)} - {timeTrimSeconds(endTime)}
            </Time>
          ))}
        </Times>
      </DayBlock>
    </Container>
  );
};

type PropsType = {
  day?: VaccinationDateType;
};
