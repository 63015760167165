import { useFormikContext } from "formik";
import { useUpdateAtom } from "jotai/utils";
import { RefObject, useState } from "react";
import { statusFileInputState } from "../../atoms/atoms";
import { falseAcceptFile, falseSizeFile } from "../../constants/forValidationSchemes";

export const useFilesInput = ({ accept, maxSize }: UseFileInputOptionsType) => {
  const setStatus = useUpdateAtom(statusFileInputState);

  const [error, setError] = useState("");
  const [progress, setProgress] = useState(0);
  const [timerSuccess, setTimerSuccess] = useState<ReturnType<typeof setTimeout>>();
  const [timerIdLoaded, setTimerIdLoaded] = useState<ReturnType<typeof setTimeout>>();
  const [timerProgress, setTimerProgress] = useState<ReturnType<typeof setInterval>>();

  const { setFieldValue, values } =
    useFormikContext<Record<string, FileList | undefined | string>>();

  values["files"];

  const validFileType = (file: File) => {
    if (!accept || !file) {
      setError("");
      return true;
    }

    if (!accept.some((item) => item === file?.name.slice(file?.name.lastIndexOf(".")))) {
      setError(falseAcceptFile(accept));
      return false;
    }

    setError("");
    return true;
  };

  const validFileSize = (file: File) => {
    if (!maxSize || !file || file.size <= maxSize) {
      setError("");
      return true;
    }

    setError(falseSizeFile(maxSize));
    return false;
  };

  const addFile = (name: string, files: File | FileList | undefined) => {
    if (!files) return;

    setFieldValue(name, files);

    const fileTypeNotValid =
      files instanceof File
        ? !validFileType(files)
        : !!Array.from(files).find((file) => !validFileType(file));
    const fileSizeNotValid =
      files instanceof File
        ? !validFileSize(files)
        : !!Array.from(files).find((file) => !validFileSize(file));

    if (fileTypeNotValid || fileSizeNotValid) {
      setStatus("error");
      return;
    }

    setStatus("progress");

    const intervalTimer = setInterval(() => {
      setProgress((prevState) => (prevState >= 100 ? 0 : prevState + 20));
    }, 300);

    setTimerProgress(intervalTimer);

    setTimerSuccess(setTimeout(() => setStatus("success"), 1500));
    setTimerIdLoaded(setTimeout(() => setStatus("loaded"), 2500));

    setTimeout(() => {
      setProgress(0);
      clearInterval(intervalTimer as ReturnType<typeof setInterval>);
    }, 1500);
  };

  const handleRemove = (name: string, ref: RefObject<HTMLInputElement>) => {
    setProgress(0);

    clearTimeout(timerSuccess as ReturnType<typeof setTimeout>);
    clearTimeout(timerIdLoaded as ReturnType<typeof setTimeout>);
    clearInterval(timerProgress as ReturnType<typeof setInterval>);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const input = ref.current!;
    input.value = "";
    setFieldValue(name, undefined);
    setStatus("empty");
  };

  return {
    setStatus,
    error,
    progress,
    timerSuccess,
    timerIdLoaded,
    timerProgress,
    validFileType,
    validFileSize,
    addFile,
    handleRemove,
  };
};

type UseFileInputOptionsType = {
  accept?: string[];
  maxSize?: number;
};
