import React from "react";
import * as Yup from "yup";
import { HealthCenterType } from "../../../../api/apiDictionaries";
import { long, notJustSpaces, required } from "../../../../constants/forValidationSchemes";
import { yesAndNoOptions } from "../../../../constants/options";
import { withoutSpaces } from "../../../../constants/regexs";
import { SelectInput, TextInput } from "../../../../uiKit";
import { FieldsBlock } from "../../../../uiKit/Form/Form.styles";

export const useForms = () => {
  // ------------------------------ КОНСТАНТЫ

  const addFormTitle = "Добавить медицинскую организацию";
  const editFormTitle = "Изменение медицинской организации";

  const formName = "healthCenter";

  // ------------------------------ ФОРМА - ПОЛЯ

  const fields = (
    <FieldsBlock>
      <TextInput name="name" outsideLabel="Наименование *" placeholder="Введите наименование" />
      <TextInput
        name="shortName"
        outsideLabel="Краткое наименование"
        placeholder="Введите краткое наименование"
      />
      <SelectInput name="fmba" outsideLabel="ФМБА *" options={yesAndNoOptions} />
    </FieldsBlock>
  );

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesAddForm = {
    name: "",
    shortName: "",
    fmba: false,
  };

  const initialValuesEditForm = ({ name, shortName, fmba }: HealthCenterType) => ({
    name,
    shortName,
    fmba,
  });

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchema = Yup.object().shape({
    name: Yup.string().matches(withoutSpaces, notJustSpaces).max(255, long).required(required),
    shortName: Yup.string().matches(withoutSpaces, notJustSpaces).max(255, long),
    fmba: Yup.string().matches(withoutSpaces, notJustSpaces).required(required),
  });

  // ------------------------------ ФОРМА - ОТПРАВКА

  type InitialValuesType = Omit<HealthCenterType, "id">;

  const valuesObject = ({ name, shortName, fmba }: InitialValuesType) => ({
    name,
    shortName,
    fmba,
  });

  return {
    addFormTitle,
    editFormTitle,
    formName,

    fields,

    initialValuesAddForm,
    initialValuesEditForm,

    validationSchema,

    valuesObject,
  };
};
