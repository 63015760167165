import { useAtomValue, useUpdateAtom } from "jotai/utils";
import React, { Suspense, useEffect } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { currentUserState, RoleModelType } from "./atoms/atomCurrentUser";
import { organizationState } from "./atoms/atomsOrg";
import * as routes from "./constants/routes";
import * as pages from "./pages";
import { WarningPage } from "./pages/WarningPage";
import "./styles/index.css";
import { Loader } from "./uiKit";
import { Buttons } from "./uiKit/Buttons/Buttons";
import { Icons } from "./uiKit/Icons/Icons";
import { getErrorMessage } from "./utils/getErrorMessage";

export const AppRoutes = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const clearPathname = pathname.substring(0, pathname.indexOf("&"));

  // ------------------------------ < ПРОВЕРКА РОЛЕЙ

  const currentUser = useAtomValue(currentUserState);
  const setOrganization = useUpdateAtom(organizationState);

  const user = currentUser.state === "hasData" && currentUser.data.res;
  const roleModel = currentUser.state === "hasData" ? currentUser.data.roleModel : undefined;
  const error = currentUser.state === "hasError" && currentUser.error;
  const loading = currentUser.state === "loading";

  // ------------------------------ ПРОВЕРКА РОЛЕЙ >

  const hash = window.location.hash;

  useEffect(() => {
    if (!hash) {
      setTimeout(() => navigate("/"), 10);
    } else if (clearPathname) setTimeout(() => navigate(clearPathname), 10);
  }, [hash, clearPathname]);

  useEffect(() => {
    setOrganization(user ? user.organization : "");
  }, [currentUser]);

  !!error &&
    getErrorMessage(error)?.includes("The user is missing the main_department attribute") && (
      <WarningPage code="not_main_department" />
    );

  if (!user || !roleModel) return loading ? <Loader /> : <WarningPage code="not_auth" />;

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {routesAndElements(roleModel).map(({ path, element }, i) => (
          <Route key={i} path={path} element={element} />
        ))}

        {/* ------------------------------ СТИЛИ ------------------------------ */}
        {/* todo: временный раздел - убрать, когда будет готова библиотека */}

        <Route path="/styles/icons" element={<Icons />} />
        <Route path="/styles/buttons" element={<Buttons />} />
      </Routes>
    </Suspense>
  );
};

export const routesAndElements = (roleModel: RoleModelType) => {
  const { oot, osr, superUser, center, department } = roleModel;

  return [
    {
      path: "*",
      element: <WarningPage code="page_not_found" />,
    },
    {
      path: "/",
      element: (
        <Navigate
          to={
            department || oot || osr || superUser || center
              ? routes.CALENDAR
              : routes.DICTIONARY_HEALTH_CENTER
          }
        />
      ),
    },

    // ------------------------------ СПРАВОЧНИКИ ------------------------------

    {
      path: routes.DICTIONARY,
      element: <Navigate to={routes.DICTIONARY_HEALTH_CENTER} />,
    },

    {
      path: routes.DICTIONARY_NATIONALITY,
      element: <pages.NationalityDictionaryPage />,
    },
    {
      path: routes.DICTIONARY_HEALTH_CENTER,
      element: <pages.HealthCenterDictionaryPage />,
    },
    {
      path: routes.DICTIONARY_PERSONAL_CATEGORY,
      element: <pages.PersonnelCategoriesDictionaryPage />,
    },
    {
      path: routes.DICTIONARY_DISEASE_SEVERITIES,
      element: <pages.DiseaseSeverityDictionaryPage />,
    },
    {
      path: routes.DICTIONARY_TREATMENTS_PLACE,
      element: <pages.TreatmentPlaceDictionaryPage />,
    },
    {
      path: routes.DICTIONARY_DISEASES_CLASS,
      element: <pages.DiseasesClassDictionaryPage />,
    },
    {
      path: routes.DICTIONARY_INFECTION_DISEASES,
      element: <pages.InfectionDiseaseDictionaryPage />,
    },
    {
      path: routes.DICTIONARY_INFECTION_VACCINATIONS,
      element: <pages.InfectionVaccinationDictionaryPage />,
    },

    // ------------------------------ АДМИНИСТРИРОВАНИЕ ------------------------------

    {
      path: routes.ADMINISTRATION,
      element: (
        <Navigate to={department ? routes.VACCINATION_NOTIFICATION : routes.VACCINATION_SETTINGS} />
      ),
    },

    {
      path: routes.VACCINATION_SETTINGS,
      element: <pages.VaccinationSettingsPage />,
    },
    {
      path: routes.VACCINATION_NOTIFICATION,
      element: <pages.VaccinationNotificationPage />,
    },

    // ------------------------------ КАЛЕНДАРЬ ------------------------------

    {
      path: routes.CALENDAR,
      element: <pages.CalendarPage />,
    },
    {
      path: routes.CALENDAR_DAY(undefined, undefined, undefined),
      element: <pages.CalendarDayPage />,
    },

    // ------------------------------ ЛИЧНАЯ КАРТОЧКА РАБОТНИКА ------------------------------

    {
      path: routes.USER_INFECTION_DISEASES(),
      element: <pages.UserProfilePage type="infections" />,
    },
    {
      path: routes.USER_VACCINATION_RECORDS(),
      element: <pages.UserProfilePage type="vaccination-record" />,
    },
    {
      path: routes.USER_VACCINATIONS(),
      element: <pages.UserProfilePage type="vaccinations" />,
    },

    // ------------------------------ ПРИЧИНЫ УДАЛЕНИЯ ------------------------------

    {
      path: routes.DELETION_REASONS,
      element: <pages.DeleteReasonsPage />,
    },

    // ------------------------------ ДАННЫЕ О ВАКЦИНАЦИИ ------------------------------

    {
      path: routes.VACCINATION_STATISTIC,
      element: <pages.VaccinationStatisticPage />,
    },

    // ------------------------------ ИНФОГРАФИКА ------------------------------
    {
      path: routes.INFOGRAPHIC,
      element: <Navigate to={routes.INFOGRAPHIC_DISEASES("organization")} />,
    },

    {
      path: routes.INFOGRAPHIC_VACCINATION("organization"),
      element: <pages.InfographicPage page="vaccination" type="organization" />,
    },
    {
      path: routes.INFOGRAPHIC_VACCINATION("department"),
      element: <pages.InfographicPage page="vaccination" type="department" />,
    },
    {
      path: routes.INFOGRAPHIC_VACCINATION("users"),
      element: <pages.InfographicPage page="vaccination" type="users" />,
    },

    {
      path: routes.INFOGRAPHIC_DISEASES("organization"),
      element: <pages.InfographicPage page="infection" type="organization" />,
    },
    {
      path: routes.INFOGRAPHIC_DISEASES("department"),
      element: <pages.InfographicPage page="infection" type="department" />,
    },
    {
      path: routes.INFOGRAPHIC_DISEASES("users"),
      element: <pages.InfographicPage page="infection" type="users" />,
    },
  ];
};
