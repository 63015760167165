import React, { FC, useState } from "react";
import { UniversalTextButton } from "../../../../uiKit/Buttons";
import { useAtom } from "jotai";
import { selectedRowsState } from "../../../../atoms/atoms";
import { EmployeeModuleType } from "../../../../api/apiEmployees";
import { Form } from "../../../../uiKit";
import {
  InitialValuesFactFormType,
  useVaccinationFactForms,
} from "../hooks/useVaccinationFactForms";
import { VaccinationDateType, vaccinationFactApi } from "../../../../api/apiVaccination";
import { QueryKeyType, useQuery } from "../../../../utils/useQuery";
import { formatDateForBackend, now } from "../../../../utils/workingWithDates";
import { IdType } from "../../../../api/types";

export const MultiplyFactButton: FC<PropsType> = (props) => {
  const { data, day, queryKey } = props;

  const formTitle = "Множественное добавление факта вакцинации против заболевания";
  const vaccinationFactMethods = useVaccinationFactForms(day, queryKey, formTitle);

  const [selectedRow, setSelectedRow] = useAtom(selectedRowsState);
  const [isOpenForm, setIsOpenForm] = useState(false);

  const create = useQuery().useCreate({ api: vaccinationFactApi(), queryKey });

  const enable = !!selectedRow.length;

  const onSubmit = async ({
    component,
    comment,
    abroad,
    notVaccinated,
  }: InitialValuesFactFormType) => {
    const createArray: SendFactType[] = [];

    selectedRow.forEach((rowId) => {
      const selectedRowData = data.find(({ id }) => id === rowId);

      if (selectedRowData)
        createArray.push({
          employee: { id: selectedRowData?.employee.id },
          appointment: { id: selectedRowData?.appointment?.id ?? "" },
          date: formatDateForBackend(now),
          component: { id: component },
          vaccinated: !notVaccinated,
          comment,
          abroad,
        });
    });

    await create.mutateAsync({ data: createArray });

    setSelectedRow([]);
  };

  return (
    <>
      <UniversalTextButton
        text="Добавить факт вакцинации"
        enabled={enable}
        onClick={() => setIsOpenForm(true)}
      />
      {isOpenForm && (
        <Form
          methods={{ ...vaccinationFactMethods, onSubmit }}
          onClose={() => setIsOpenForm(false)}
        />
      )}
    </>
  );
};

type PropsType = {
  data: EmployeeModuleType[];
  day: VaccinationDateType | undefined;
  queryKey: QueryKeyType;
};

type SendFactType = {
  employee: IdType;
  appointment: IdType;
  date: string;
  component: IdType;
  vaccinated: boolean;
  comment: string;
  abroad: boolean;
};
