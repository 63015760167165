import React, { FC } from "react";
import { ArrowRound, ExclamationMark } from "../Icons";
import { GeneralPropsType } from "./FilesInput";
import * as elements from "./FilesInput.styles";

export const FilesInputError: FC<GeneralPropsType> = (props) => {
  const { name, fileName, element, error, multiFile, handleRemove } = props;

  const { Container, Title, Description, Text, Button, LoadBarAndText, LoadBar } = elements;

  return (
    <Container status="error" multiFile={multiFile} as="div">
      <LoadBarAndText status="error" multiFile={multiFile}>
        <LoadBar status="error">
          <ExclamationMark />
        </LoadBar>
        <Text>
          <Title status="error">{fileName}</Title>
          <Description status="error">{error}</Description>
        </Text>
      </LoadBarAndText>

      <Button onClick={() => handleRemove(name, element)}>
        <ArrowRound />
      </Button>
    </Container>
  );
};
