import React, { FC, MouseEvent } from "react";
import { ProfileMenuButton, ProfileMenuButtons, ProfileMenuLink } from "./Profile.styles";
import { USER_VACCINATION_RECORDS } from "../../../constants/routes";

export const ProfileMenu: FC<PropsType> = (props) => {
  const { baseUrl, onClose, canView, id } = props;

  const logoutRoute = `http://${baseUrl}/c/portal/logout`;

  return (
    <>
      <ProfileMenuButtons>
        {canView && (
          <ProfileMenuLink to={USER_VACCINATION_RECORDS(id)} onClick={onClose}>
            <p>Профиль</p>
          </ProfileMenuLink>
        )}
        <ProfileMenuButton href={logoutRoute} onClick={onClose}>
          <p>Выйти</p>
        </ProfileMenuButton>
      </ProfileMenuButtons>
    </>
  );
};

type PropsType = {
  onClose: (evt: MouseEvent<HTMLElement>) => void;
  baseUrl?: string;
  canView?: boolean;
  id: string;
};
