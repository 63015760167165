import { DatePicker, DateRangePicker } from "@mui/lab";
import { DatePickerView } from "@mui/lab/DatePicker/shared";
import React, { FC, useState } from "react";
import { formatDate, today } from "../../utils/workingWithDates";
import { DateSwitcherType } from "../Header/HeaderNavigation/HeaderNavigation";
import * as elements from "./DateSwitcher.styles";
import { useDateSwitcher } from "./useDateSwitcher";

export const DaySwitcher: FC<PropsType> = (props) => {
  const { typeInterval, dateAtom, dateIntervalAtom, router, color, min, max } = props;

  const methods = useDateSwitcher({ dateAtom, dateIntervalAtom, router });
  const { date, setDate, dateInterval, setDateInterval } = methods;
  const { startDate, endDate, minDate, text, navigateDate, arrow } = methods;

  const [open, setOpen] = useState(false);

  const { DateIntervalWrapper, DateSwitcher, DateSwitcherText, PaperProps, PopperProps } = elements;

  const selectedOneDay =
    typeInterval === "twoDate" && formatDate(startDate) === formatDate(endDate);

  const commonArguments = {
    calendars: typeInterval === "twoDate" ? (color ? (2 as const) : (1 as const)) : undefined,
    views: typeInterval === "oneDate" ? (["day"] as DatePickerView[]) : undefined,
    mask: "__.__.____",
    inputFormat: "dd.MM.yyyy",
    open,
    minDate: min ?? minDate,
    maxDate: max ?? undefined,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
  };

  const dateIntervalPicker =
    dateIntervalAtom && dateInterval ? (
      <DateRangePicker
        {...commonArguments}
        value={dateInterval}
        shouldDisableDate={(date: Date) => date < (min ?? minDate)}
        onChange={(newValue) => {
          setDateInterval && setDateInterval(newValue);
          navigateDate(newValue);
        }}
        renderInput={(startProps, endProps) =>
          color ? (
            <DateIntervalWrapper color={color}>
              <DateSwitcher color={color} onClick={() => setOpen(true)}>
                <DateSwitcherText color={color}>
                  {text({ startProps, endProps }).day}
                </DateSwitcherText>
              </DateSwitcher>
            </DateIntervalWrapper>
          ) : (
            <>
              <DateSwitcher onClick={() => setOpen(true)}>
                <DateSwitcherText>{text({ startProps, endProps }).day}</DateSwitcherText>
              </DateSwitcher>
            </>
          )
        }
        PopperProps={PopperProps}
      />
    ) : null;

  const datePicker =
    dateAtom && date ? (
      <DatePicker
        {...commonArguments}
        value={date}
        onChange={(newDate) => {
          setDate(newDate ?? today);
          navigateDate([newDate ? newDate : today, undefined]);
        }}
        renderInput={(params) => (
          <DateSwitcher onClick={() => setOpen(true)} ref={params.inputRef}>
            <DateSwitcherText>{params.inputProps?.value}</DateSwitcherText>
          </DateSwitcher>
        )}
        PaperProps={PaperProps}
      />
    ) : null;

  return (
    <>
      {(typeInterval === "twoDate" && selectedOneDay) || typeInterval === "oneDate"
        ? arrow("left").day
        : null}

      {typeInterval === "twoDate"
        ? color
          ? dateIntervalPicker
          : dateIntervalPicker
        : typeInterval === "oneDate"
        ? datePicker
        : null}

      {(typeInterval === "twoDate" && selectedOneDay) || typeInterval === "oneDate"
        ? arrow("right").day
        : null}
    </>
  );
};

type PropsType = DateSwitcherType;
