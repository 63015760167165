import React from "react";
import * as Yup from "yup";
import { MediumOtherType } from "../api/apiDictionaries";
import { long, notJustSpaces, required } from "../constants/forValidationSchemes";
import { withoutSpaces } from "../constants/regexs";
import { TextInput } from "../uiKit";
import { FieldsBlock } from "../uiKit/Form/Form.styles";

/**
 *
 * ------------------------------------------------------------------------------------------
 * СТАНДАРТНЫЕ МЕТОДЫ ТАБЛИЦЫ ДЛЯ ВИДА "№" "Наименование" "Описание"
 *
 * @param canEdit - возможность редактирования
 */
export const useNameDescriptionDictionaryTable = (canEdit: boolean) => {
  // ------------------------------ ТАБЛИЦА - ЗАГОЛОВКИ

  const titlesInit = [
    { id: 1, title: "№", name: "number" },
    { id: 2, title: "Наименование", name: "name" },
    { id: 3, title: "Описание", name: "description" },
  ];

  // ------------------------------ ТАБЛИЦА - ШИРИНЫ ТАБЛИЦ

  const gridsInit = [
    ...(canEdit ? [{ titleId: null, size: "56px" }] : []),
    { titleId: 1, size: "76px" },
    { titleId: 2, size: "1fr" },
    { titleId: 3, size: "1fr" },
    ...(canEdit ? [{ titleId: null, size: "56px" }] : []),
  ];

  // ------------------------------ ТАБЛИЦА - ДАННЫЕ ДЛЯ СТРОКИ

  const object = ({ name, description }: MediumOtherType, number?: number): ObjectType => ({
    ...(number ? { number } : {}),
    name,
    description: description || "—",
  });

  return {
    titlesInit,
    gridsInit,
    object,
  };
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * СТАНДАРТНЫЕ МЕТОДЫ ФОРМЫ ДЛЯ ВИДА "Наименование" "Описание"
 *
 */
export const useNameDescriptionDictionaryForms = () => {
  // ------------------------------ ФОРМА - ПОЛЯ

  const fields = (
    <FieldsBlock>
      <TextInput name="name" outsideLabel="Наименование *" placeholder="Введите наименование" />
      <TextInput name="description" outsideLabel="Описание" placeholder="—" multiline />
    </FieldsBlock>
  );

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesAddForm = {
    name: "",
    description: "",
  };

  const initialValuesEditForm = ({ name, description }: MediumOtherType) => ({ name, description });

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchema = Yup.object().shape({
    name: Yup.string().matches(withoutSpaces, notJustSpaces).max(255, long).required(required),
    description: Yup.string().max(1000, long).matches(withoutSpaces, notJustSpaces),
  });

  // ------------------------------ ФОРМА - ЗНАЧЕНИЯ

  const valuesObject = ({ name, description }: InitialValuesType) => ({
    name,
    description,
  });

  return {
    fields,

    initialValuesAddForm,
    initialValuesEditForm,

    validationSchema,

    valuesObject,
  };
};

type InitialValuesType = Omit<MediumOtherType, "id">;
type ObjectType = InitialValuesType & { number?: number };
