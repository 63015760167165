import React from "react";
import * as Yup from "yup";
import { InfectionDiseaseType } from "../../../../api/apiInfectionDiseases";
import {
  long,
  multiRequired,
  notJustSpaces,
  required,
  toManyAges,
} from "../../../../constants/forValidationSchemes";
import { withoutSpaces } from "../../../../constants/regexs";
import { SelectAutocompleteMultilineInput, TextInput } from "../../../../uiKit";
import { FieldsBlock } from "../../../../uiKit/Form/Form.styles";
import { useDiseaseClassOptions } from "../../../../utils/getOptions";
import { AgesFields } from "../AgesFields/AgesFields";

export const useForms = () => {
  // ------------------------------ ХУКИ

  const diseasesOptions = useDiseaseClassOptions();

  // ------------------------------ КОНСТАНТЫ

  const addFormTitle = "Добавить инфекционное заболевание";
  const editFormTitle = "Изменение инфекционного заболевания";

  const formName = "infectionDisease";

  // ------------------------------ ФОРМА - ПОЛЯ

  const fields = (
    <FieldsBlock>
      <TextInput
        name="name"
        placeholder="Введите наименование"
        outsideLabel="Наименование заболевания*"
      />
      <SelectAutocompleteMultilineInput
        name="icd"
        options={diseasesOptions}
        placeholder="Выберите из доступных"
        result="chips"
        outsideLabel="Код МКБ-10*"
      />
      <TextInput name="description" placeholder="—" multiline outsideLabel="Описание" />

      <AgesFields nameStart="startAge" nameEnd="endAge" outsideLabel="Возраст*" />
    </FieldsBlock>
  );

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesAddForm = {
    name: "",
    description: "",
    icd: [],
    startAge: 0,
    endAge: 0,
  };

  const initialValuesEditForm = (infection: InfectionDiseaseType) => {
    const { name, description, icd, endAge, startAge } = infection;
    return {
      name,
      description,
      icd: icd?.map(({ id }) => id),
      endAge,
      startAge,
    };
  };

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchema = Yup.object().shape({
    name: Yup.string().matches(withoutSpaces, notJustSpaces).max(255, long).required(required),
    description: Yup.string().matches(withoutSpaces, notJustSpaces).max(1000, long),
    icd: Yup.array().required(multiRequired),
    endAge: Yup.number().required(required),
    startAge: Yup.number()
      .required(required)
      .test(
        "start-age",
        toManyAges,
        async (
          _,
          ctx: { parent: { startAge: number | undefined; endAge: number | undefined } }
        ) => {
          const { startAge, endAge } = ctx.parent;
          return startAge !== undefined && endAge !== undefined ? endAge >= startAge : true;
        }
      ),
  });

  // ------------------------------ ФОРМА - ЗНАЧЕНИЯ

  type InitialValuesType = {
    name: string;
    description: string;
    icd: string[];
    endAge?: number;
    startAge?: number;
  };

  const valuesObject = (props: InitialValuesType) => {
    const { startAge, endAge, icd, name, description } = props;
    return {
      name,
      description,
      icd: icd.map((id) => ({ id })),
      startAge: startAge,
      endAge: endAge,
    };
  };

  return {
    addFormTitle,
    editFormTitle,
    formName,

    fields,

    initialValuesAddForm,
    initialValuesEditForm,

    validationSchema,

    valuesObject,
  };
};
