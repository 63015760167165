import React from "react";
import { colors } from "../../styles";

export const BuildingAndTree = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.85 15.3487C16.85 16.7127 17.7743 17.8595 19.031 18.1976V21.2497H15.05V6.03172C15.05 3.60172 13.73 2.26172 11.32 2.26172H5.09C2.67 2.26172 1.34 3.60172 1.34 6.03172V21.2497H1C0.59 21.2497 0.25 21.5897 0.25 21.9997C0.25 22.4097 0.59 22.7497 1 22.7497H2.03564C2.05361 22.751 2.07173 22.7517 2.09 22.7517C2.10827 22.7517 2.12639 22.751 2.14436 22.7497H8.25H14.2456C14.2636 22.751 14.2817 22.7517 14.3 22.7517C14.3183 22.7517 14.3364 22.751 14.3544 22.7497H19.66C19.6994 22.7563 19.7399 22.7597 19.781 22.7597C19.8231 22.7597 19.8644 22.7563 19.9045 22.7497H23C23.41 22.7497 23.75 22.4097 23.75 21.9997C23.75 21.5897 23.41 21.2497 23 21.2497H20.531V18.2076C21.8071 17.8826 22.75 16.7264 22.75 15.3487V13.0787C22.75 12.2963 22.4392 11.546 21.886 10.9928C21.3327 10.4395 20.5824 10.1287 19.8 10.1287C19.0176 10.1287 18.2673 10.4395 17.714 10.9928C17.1608 11.546 16.85 12.2963 16.85 13.0787V15.3487ZM7.5 21.2497H2.84V6.03172C2.84 4.44172 3.52 3.76172 5.09 3.76172H11.32C12.88 3.76172 13.55 4.44172 13.55 6.03172V21.2497H9V18.2497C9 17.8397 8.66 17.4997 8.25 17.4997C7.84 17.4997 7.5 17.8397 7.5 18.2497V21.2497ZM19.8 11.6387C19 11.6387 18.35 12.2887 18.35 13.0887V15.3587C18.3639 15.734 18.5228 16.0892 18.7932 16.3497C19.0636 16.6103 19.4245 16.7559 19.8 16.7559C20.1755 16.7559 20.5364 16.6103 20.8068 16.3497C21.0772 16.0892 21.2361 15.734 21.25 15.3587V13.0887C21.25 12.2887 20.6 11.6387 19.8 11.6387ZM5.8 8.99972H10.75C11.16 8.99972 11.5 8.65972 11.5 8.24972C11.5 7.83972 11.16 7.49972 10.75 7.49972H5.8C5.39 7.49972 5.05 7.83972 5.05 8.24972C5.05 8.65972 5.39 8.99972 5.8 8.99972ZM5.8 12.7497H10.75C11.16 12.7497 11.5 12.4097 11.5 11.9997C11.5 11.5897 11.16 11.2497 10.75 11.2497H5.8C5.39 11.2497 5.05 11.5897 5.05 11.9997C5.05 12.4097 5.39 12.7497 5.8 12.7497Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
