import React, { FC, useEffect, useRef } from "react";
import { DiagramContainer } from "../../InfographicPage.styled";
import { useDiagram } from "../../ChartsPage/hooks/useDiagram";
import { colors } from "../../../../styles";
import Chart from "chart.js/auto";

export const DepartmentDiagram: FC<PropsType> = (props) => {
  const { id, healthIndex } = props;
  const { getDatasets, getPlugins, getOptions } = props;

  const ref = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvasElement = ref.current;
    if (!canvasElement) return;
    const context = canvasElement.getContext("2d");

    const gradient = context?.createRadialGradient(4, 4, 200, 4, 4, 0);
    gradient?.addColorStop(0, colors.blueLight);
    gradient?.addColorStop(1, colors.primaryDefault);

    const datasets = getDatasets(id, healthIndex, gradient);
    const plugins = getPlugins(healthIndex);
    const options = getOptions(healthIndex);

    const myDiagram = new Chart(canvasElement, {
      type: "doughnut",
      data: { labels: [], datasets },
      plugins: plugins,
      options: options,
    });

    return () => myDiagram.destroy();
  }, [healthIndex]);

  return (
    <DiagramContainer>
      <canvas ref={ref} />
    </DiagramContainer>
  );
};

type PropsType = ReturnType<typeof useDiagram> & { id: string; healthIndex: number };
