import React, { FC } from "react";
import { AppContainer } from "./App.styles";
import { Loader, LeftMenu } from "./uiKit";
import { useCachingKeycloak } from "./utils/useCachingKeycloak";

export const App: FC = ({ children }) => {
  const { authenticated } = useCachingKeycloak();

  return authenticated ? (
    <AppContainer className="App">
      <LeftMenu />
      {children}
    </AppContainer>
  ) : (
    <Loader />
  );
};
