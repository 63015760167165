import { MenuItem } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React, { FC, useState } from "react";
import { ColorType } from "../../api/types";
import { colors } from "../../styles";
import { OptionsType } from "../../utils/getOptions";
import { OpenListButton } from "../Buttons";
import { FieldContainer } from "../Form/Form.styles";
import { LabelField, LabelType } from "../Form/LabelField";
import * as elements from "./SelectInput.styles";

export const SelectInput: FC<SelectInputPropsType> = (props) => {
  const { name, placeholder = "Выберите из списка", outsideLabel, insideLabel } = props;
  const { defaultValue, options, onChange } = props;
  const { disabled = false, size = "", color = colors.grayscaleInput } = props;

  const { Container, Item, Placeholder, MenuItemWrapper, MenuProps, Error } = elements;

  const { handleChange, handleBlur } = useFormikContext();

  const [{ value }, { error, touched }] = useField<string>(name);

  const [isOpen, setIsOpen] = useState(false);
  const [wasInFocus, setWasInFocus] = useState(false);

  const filteredOptions = () => options.filter(({ id }) => id !== "");

  const renderValue =
    value !== "" || value === null
      ? undefined
      : () => <Placeholder haslabel={+!!insideLabel}>{placeholder}</Placeholder>;

  const handleClick = () => (disabled ? undefined : setIsOpen(!isOpen));

  const icon = () => (
    <OpenListButton isOpen={isOpen} onClick={() => undefined} disabled={disabled} />
  );

  const hasError = touched && !!error && wasInFocus;

  return (
    <FieldContainer>
      <LabelField outsideLabel={outsideLabel} />

      <Container disabled={disabled} onClick={handleClick}>
        <LabelField insideLabel={insideLabel} />

        <Item
          name={name}
          value={value}
          open={isOpen}
          displayEmpty
          label={insideLabel}
          labelId={insideLabel ? `${name}-label` : ""}
          haslabel={+!!insideLabel}
          renderValue={renderValue}
          IconComponent={icon}
          MenuProps={MenuProps(size)}
          onChange={(evt) => {
            onChange && onChange(evt.target.value as string);
            handleChange(evt);
            setWasInFocus(false);
          }}
          onBlur={(evt) => {
            handleBlur(evt);
            setWasInFocus(!!error);
          }}
          onClick={() => undefined}
          haserror={+hasError}
          defaultValue={defaultValue ? defaultValue : ""}
          autoComplete="off"
          disabled={disabled}
          backgroundcolor={color}
        >
          {filteredOptions().map(({ id, name }, i) => (
            <MenuItem key={i} value={id}>
              <MenuItemWrapper>{name}</MenuItemWrapper>
            </MenuItem>
          ))}
        </Item>

        {error && wasInFocus ? <Error>{error}</Error> : null}
      </Container>
    </FieldContainer>
  );
};

export type SelectInputPropsType = LabelType & {
  name: string;
  options: OptionsType;
  placeholder?: string;
  defaultValue?: string | null;
  disabled?: boolean;
  size?: "small" | "default" | number;
  color?: ColorType;
  onChange?: (evt: string) => void;
};
