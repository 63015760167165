import { css } from "@emotion/react";

export const text = (props: TextPropsType) => {
  const { size, height, weight, color, align, uppercase, lowercase, line } = props;

  return css`
    ${size ? `font-size: ${size}px` : undefined};

    ${height ? `line-height: ${height}px` : undefined};

    ${weight ? `font-weight: ${weight}` : undefined};

    ${color ? `color: ${color}` : undefined};

    ${align ? `text-align: ${align}` : undefined};

    text-transform: ${uppercase ? "uppercase" : lowercase ? "lowercase" : "none"};

    ${line
      ? `text-decoration: ${
          line === "center" ? "line-through" : line === "bottom" ? "underline" : "none"
        }`
      : undefined};
  `;
};

export type TextPropsType = {
  size: number;
  height: number;
  weight: number;
  color?: string;
  align?: "center" | "start" | "justify";
  uppercase?: boolean;
  lowercase?: boolean;
  line?: "center" | "bottom";
};
