import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { infectionDiseasesApi, InfectionDiseaseType } from "../../../../api/apiInfectionDiseases";
import { PaginationQueryType } from "../../../../api/types";
import { fetch, fetchAll } from "../../../../utils/fetchData";
import { getQueryFilter } from "../../../../utils/getQueryFilter";
import { getPaginationQueryKeys, usePaginationQueryKeys } from "../../../../utils/useQueryKeys";

export const modalDataState = atom<InfectionDiseaseType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

export const infectionsDiseasesAtom = atomWithQuery((get) => {
  const args = getPaginationQueryKeys(get);

  return infectionsDiseasesQuery(args);
});

export const infectionsDiseasesState = loadable(infectionsDiseasesAtom);

export const infectionsDiseasesQuery = (props: PaginationQueryType) => {
  const { size, query, startIndex } = props;
  const filter = query ? [getQueryFilter(query, ["name"])] : undefined;

  const args = { filter, size, startIndex };

  return {
    queryKey: [startIndex, size, query, "infectionsDiseasesState"],
    queryFn: () => fetchAll(() => infectionDiseasesApi().getAll(args)),
  };
};

export const useInfectionDiseasesQuery = () => {
  const { size, query, startIndex } = usePaginationQueryKeys();

  return {
    queryKey: [startIndex, size, query, "infectionsDiseasesState"],
    api: infectionDiseasesApi(),
  };
};

// ------------------------------ ДАННЫЕ - ВСЕ

export const infectionsDiseasesAllState = atomWithQuery(() => ({
  queryKey: ["infectionsDiseasesAllState"],
  queryFn: () => fetch(() => infectionDiseasesApi().getAll()),
}));
