import { useData } from "./hooks/useData";
import { useTable } from "./hooks/useTable";
import { useForms } from "./hooks/useForms";

export const useDiseasesClassPage = () => {
  const data = useData();
  const table = useTable();
  const forms = useForms();

  return {
    ...data,
    ...table,
    ...forms,
  };
};
