import { useAtom } from "jotai";
import { useAtomValue } from "jotai/utils";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { appointmentApi, AppointmentType } from "../../../../api/apiAppointment";
import { VaccinationDateType } from "../../../../api/apiVaccination";
import { addFormState } from "../../../../atoms/atoms";
import { long, notJustSpaces, required } from "../../../../constants/forValidationSchemes";
import { withoutSpaces } from "../../../../constants/regexs";
import { CheckInput, SelectInput, TextInput } from "../../../../uiKit";
import { FieldsBlock } from "../../../../uiKit/Form/Form.styles";
import { QueryKeysType, QueryKeyType, useQuery } from "../../../../utils/useQuery";
import {
  differenceWithToday,
  formatDateForBackend,
  now,
  parseDate,
  timeString,
} from "../../../../utils/workingWithDates";
import {
  currentDateDay,
  currentFormRowState,
  currentTypeFormState,
  modalDataAppointmentState,
  selectedTimeState,
} from "../utils/atoms";
import { ConfirmMessageType } from "../../../../uiKit/ConfirmModal/ConfirmModal";
import { differenceInDays } from "date-fns";

export const useAppointmentForms = (
  day: VaccinationDateType | undefined,
  queryKey: QueryKeyType,
  queryKeyForOneEntity: QueryKeysType
) => {
  const { vaccinationPlan, times = [] } = day || {};
  const { infection, id: planId = "" } = vaccinationPlan || {};

  // ------------------------------ АТОМЫ

  const currentUserRow = useAtomValue(currentFormRowState);
  const selectedTime = useAtomValue(selectedTimeState);
  const date = useAtomValue(currentDateDay);
  const [modalData, setModalData] = useAtom(modalDataAppointmentState);
  const [addFormIsOpen, setAddFormIsOpen] = useAtom(addFormState);
  const currentForm = useAtomValue(currentTypeFormState);

  const [formPassed, setFormPassed] = useState<PassedFormType>();
  const [confirmText, setConfirmText] = useState<ConfirmMessageType>();

  // ------------------------------ КОНСТАНТЫ

  const idAppointment = modalData?.id;
  const deletionFormOpen = currentForm === "appointment-deletion";
  const api = appointmentApi();
  const deleteAppointment = useQuery().useCustomRequest({
    api,
    queryKey,
    queryKeyForOneEntity,
    key: "deleteAppointment",
  });

  const isOpenAppointmentForm =
    (currentForm === "appointment" || deletionFormOpen) &&
    ((addFormIsOpen && formPassed === "complete") || !!modalData);
  const isEditAppointmentForm = !!modalData;

  const addFormTitle = `Запись на вакцинацию против заболевания «${infection?.name ?? "—"}»`;
  const editFormTitle = deletionFormOpen
    ? "Причина удаления записи"
    : `Редактирование записи на вакцинацию против заболевания «${infection?.name ?? "—"}»`;

  const formName = "appointment";

  // ------------------------------ ПРОВЕРКИ ПЕРЕД ОТКРЫТИЕМ

  useEffect(() => {
    if (currentForm === "appointment" && addFormIsOpen)
      if (!formPassed || formPassed === "complete")
        if (!agePassed()) {
          setConfirmText({
            header:
              "Данному сотруднику не рекомендуется ре\\вакцинация согласно возрастным ограничениям. Хотите продолжить запись?",
            body: "(Информация возрастной категории из национального календаря прививок Минздрав РФ)",
          });
          setFormPassed("age");
        } else handleConfirmAge();
  }, [currentForm, addFormIsOpen]);

  const agePassed = () => {
    const birthDate = currentUserRow?.employee.birthDate;
    if (!birthDate || !infection) return true;

    const age = differenceWithToday(birthDate, "year");
    return age > infection.startAge && age < infection.endAge;
  };

  const handleConfirmAge = () => {
    if (!periodicityPassed()) {
      setFormPassed("periodicity");
      setConfirmText({
        header: "У сотрудника срок ре\\вакцинации еще не подошел. Хотите продолжить запись?",
      });
    } else handleConfirmPeriodicity();
  };

  const handleConfirmPeriodicity = () => {
    setFormPassed("complete");
    setConfirmText(undefined);
  };

  const periodicityPassed = () => {
    const planedDate = currentUserRow?.planned;
    const pastDate = currentUserRow?.previous;

    if (!planedDate || !pastDate) return true;

    const differentPast = differenceInDays(parseDate(planedDate), parseDate(pastDate)) / 4;
    const differentWithCurrent = differenceInDays(parseDate(planedDate), date ?? now);

    return differentPast >= differentWithCurrent && differentPast <= 180;
  };

  const handleClose = () => {
    setFormPassed(undefined);
    setAddFormIsOpen(false);
    setModalData(undefined);
    setConfirmText(undefined);
  };

  // ------------------------------ ОПЦИИ

  const timeOptions = times.map(({ id, startTime, endTime }) => ({
    id,
    name: timeString(startTime, endTime),
  }));

  // ------------------------------ ФОРМА - ПОЛЯ

  const fields = (
    <FieldsBlock>
      {deletionFormOpen ? (
        <TextInput
          name="removeReason"
          placeholder="Начните вводить текст..."
          multiline
          outsideLabel="Укажите причину"
        />
      ) : (
        <>
          <SelectInput name="time" options={timeOptions} outsideLabel="Время вакцинации*" />
          <CheckInput name="abroad" label="В связи с поездкой за рубеж" />
        </>
      )}
    </FieldsBlock>
  );

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesAddForm = {
    time: selectedTime?.id ?? "",
    abroad: false,
    removeReason: "",
    date: "",
  };

  const initialValuesEditForm = ({ time, abroad, removeReason, date }: AppointmentType) => ({
    time:
      times.find(
        ({ startTime: start, endTime: end }) => start === time.startTime && end === time.endTime
      )?.id ?? "",
    abroad,
    removeReason,
    date,
  });

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchema = Yup.object().shape({
    time: deletionFormOpen ? Yup.string() : Yup.string().required(required),
    abroad: Yup.boolean(),
    removeReason: deletionFormOpen
      ? Yup.string().matches(withoutSpaces, notJustSpaces).required(required).max(255, long)
      : Yup.string(),
  });

  // ------------------------------ ФОРМА - ОТПРАВКА

  type InitialValuesType = { time: string; abroad: boolean; removeReason: string; date: string };

  const valuesObject = (
    { time, abroad, date: dateAppointment }: InitialValuesType,
    isEdit?: boolean
  ) => ({
    dto: {
      ...(isEdit ? { appointmentId: modalData?.id } : {}),
      employee: { id: currentUserRow?.employee.id },
      date: isEdit ? dateAppointment : formatDateForBackend(date ?? now),
      time: { id: time },
      vaccinationPlan: { id: planId },
      abroad,
      ignoreAge: true,
    },
  });

  const onSubmit = deletionFormOpen
    ? async ({ removeReason }: InitialValuesType) => {
        const { id } = modalData || {};
        if (id)
          await deleteAppointment.mutateAsync({
            data: { removeReason, removed: true },
            id,
          });
      }
    : undefined;

  return {
    idAppointment,

    queryKey,
    queryKeyForOneEntity,
    api,

    addFormTitle,
    editFormTitle,
    formName,
    isOpenAppointmentForm,
    isEditAppointmentForm,

    fields,
    initialValuesAddForm,
    initialValuesEditForm,
    valuesObject,
    onSubmit,

    modalData,
    setModalData,

    validationSchema,

    handleConfirmAge,
    handleConfirmPeriodicity,
    handleClose,
    confirmText,
    formPassed,
  };
};

type PassedFormType = "age" | "periodicity" | "complete";
