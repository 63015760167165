import { DateRange } from "@mui/lab";
import { MuiTextFieldProps } from "@mui/lab/internal/pickers/PureDateInput";
import { TextFieldProps } from "@mui/material";
import { getQuarter, getYear, setYear } from "date-fns";
import { useAtom } from "jotai";
import { useUpdateAtom } from "jotai/utils";
import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as atoms from "../../atoms/atoms";
import { DateSwitcherTypeType } from "../../atoms/atoms";
import { useOptionalAtom } from "../../utils/useOptionalAtom";
import * as dateMethods from "../../utils/workingWithDates";
import { LeftAndRightArrowsButtons } from "../Buttons";
import { DateAtomType, DateIntervalAtomType } from "../Header/HeaderNavigation/HeaderNavigation";

const { dateSwitcherTypeState, periodSwitcherTypeState } = atoms;

const { plusAndMinusOneDayMethods, startAndEndMethods } = dateMethods;
const { formatDate, getPeriodString, today } = dateMethods;

export const useDateSwitcher = (props: PropsType) => {
  const { dateAtom, dateIntervalAtom, router } = props;

  const setDateSwitcherType = useUpdateAtom(dateSwitcherTypeState);
  const [periodSwitcherType, setPeriodSwitcherType] = useAtom(periodSwitcherTypeState);

  const [date, setDate] = useOptionalAtom(dateAtom);
  const [dateInterval, setDateInterval] = useOptionalAtom(dateIntervalAtom);

  const startDate = date ?? (dateInterval?.[0] ? dateInterval[0] : today);
  const endDate = dateInterval?.[1] ? dateInterval[1] : today;

  const minDate = startAndEndMethods.start["year"](setYear(today, getYear(today) - 5));
  const maxDate = startAndEndMethods.end["year"](today);

  const navigate = useNavigate();
  const { date: dateParams } = useParams();

  const text = (props: TextPropsType) => {
    const { startProps, endProps, inputProps } = props;

    return {
      day:
        startProps?.inputProps?.value === endProps?.inputProps?.value
          ? startProps?.inputProps?.value
          : getPeriodString(startProps?.inputProps?.value, endProps?.inputProps?.value),
      month:
        inputProps?.inputProps?.value.split(" ")[1] === String(getYear(today))
          ? inputProps?.inputProps?.value.split(" ")[0]
          : inputProps?.inputProps?.value,
      quarter:
        startDate &&
        (getYear(startDate) !== getYear(today)
          ? // eslint-disable-next-line react/jsx-key
            [
              <Fragment key="0">{getQuarter(startDate)} кв.</Fragment>,
              <br key="1" />,
              <Fragment key="2">{getYear(startDate)} года</Fragment>,
            ]
          : `${getQuarter(startDate)} квартал`),
      year: inputProps?.inputProps?.value,
    };
  };

  const navigateDate = (dateStartAndEndDates: DateRange<Date | undefined>) => {
    const [dateStart, endDate] = dateStartAndEndDates;

    if (dateParams && dateStart && router) {
      endDate
        ? formatDate(dateStart) === formatDate(endDate)
          ? navigate(`${router(`${formatDate(dateStart)}`)}`)
          : navigate(`${router(`${formatDate(dateStart)}-${formatDate(endDate)}`)}`)
        : navigate(`${router(`${formatDate(dateStart)}`)}`);
    }
  };

  const handleClickPeriod = (period: DateSwitcherTypeType) => {
    if (periodSwitcherType === period) return;

    setDateSwitcherType(period);
    setPeriodSwitcherType(period);

    const currentDate =
      !!startDate && getYear(startDate) !== getYear(today)
        ? startAndEndMethods.start["year"](startDate)
        : today;

    setDateInterval([
      startAndEndMethods.start[period](currentDate),
      startAndEndMethods.end[period](currentDate),
    ]);
  };

  const handleClickArrow = (
    direction: "left" | "right",
    dateSwitcherType: DateSwitcherTypeType
  ) => {
    if (direction === "left" ? disableBack?.day : disableNext?.day) return;

    if (date && setDate) {
      if (direction === "left") {
        setDate(plusAndMinusOneDayMethods.minus["day"](date));
        navigateDate([plusAndMinusOneDayMethods.minus["day"](date), undefined]);
      } else {
        setDate(plusAndMinusOneDayMethods.plus["day"](date));
        navigateDate([plusAndMinusOneDayMethods.plus["day"](date), undefined]);
      }
    } else if (startDate && dateIntervalAtom) {
      {
        const currentDate = (
          direction === "left"
            ? plusAndMinusOneDayMethods.minus[dateSwitcherType]
            : plusAndMinusOneDayMethods.plus[dateSwitcherType]
        )(startDate);

        if (dateSwitcherType !== "day" && dateSwitcherType !== "period") {
          if (
            direction === "left" ? disableBack?.[dateSwitcherType] : disableNext?.[dateSwitcherType]
          )
            return;
        }

        dateSwitcherType === "day"
          ? setDateInterval([currentDate, currentDate])
          : setDateInterval([
              startAndEndMethods.start[dateSwitcherType](currentDate),
              startAndEndMethods.end[dateSwitcherType](currentDate),
            ]);

        dateSwitcherType === "day" && navigateDate([currentDate, currentDate]);
      }
    }
  };

  const arrow = (direction: "left" | "right") => ({
    day: (
      <LeftAndRightArrowsButtons
        onClick={() => handleClickArrow(direction, "day" as DateSwitcherTypeType)}
        disabled={direction === "left" ? disableBack?.day : disableNext?.day}
        orientation={direction}
      />
    ),
    month: (
      <LeftAndRightArrowsButtons
        onClick={() => handleClickArrow(direction, "month" as DateSwitcherTypeType)}
        disabled={direction === "left" ? disableBack?.month : disableNext?.month}
        orientation={direction}
      />
    ),
    quarter: (
      <LeftAndRightArrowsButtons
        onClick={() => handleClickArrow(direction, "quarter" as DateSwitcherTypeType)}
        disabled={direction === "left" ? disableBack?.quarter : disableNext?.quarter}
        orientation={direction}
      />
    ),
    year: (
      <LeftAndRightArrowsButtons
        onClick={() => handleClickArrow(direction, "year" as DateSwitcherTypeType)}
        disabled={direction === "left" ? disableBack?.year : disableNext?.year}
        orientation={direction}
      />
    ),
  });

  const disableBack = date &&
    minDate && {
      day: date < minDate,
      month: plusAndMinusOneDayMethods.minus["month"](date) < minDate,
      quarter: plusAndMinusOneDayMethods.minus["quarter"](date) < minDate,
      year: plusAndMinusOneDayMethods.minus["year"](date) < minDate,
    };

  const disableNext = date &&
    maxDate && {
      day: date > maxDate,
      month: plusAndMinusOneDayMethods.plus["month"](date) > maxDate,
      quarter: plusAndMinusOneDayMethods.plus["quarter"](date) > maxDate,
      year: plusAndMinusOneDayMethods.plus["year"](date) > maxDate,
    };

  return {
    date,
    setDate,
    dateInterval,
    setDateInterval,

    startDate,
    endDate,
    maxDate,
    minDate,

    text,

    navigateDate,

    arrow,

    handleClickPeriod,
  };
};

type PropsType = {
  dateAtom?: DateAtomType;
  dateIntervalAtom?: DateIntervalAtomType;
  router?: (date: string | undefined) => string;
};

type TextPropsType = {
  startProps?: MuiTextFieldProps;
  endProps?: MuiTextFieldProps;
  inputProps?: TextFieldProps;
};
