import styled from "@emotion/styled/macro";
import { borders, colors, grid, margins } from "../../styles";

export const PageContainer = styled.div`
  ${grid({ rows: "min-content 1fr" })};

  height: 100vh;
  background: ${colors.white};
`;

export const Main = styled.main<{ needPagination?: boolean; needScroll: boolean }>`
  ${borders({ side: "top", color: colors.grayscaleInput })};
  ${({ needPagination }) => margins({ m: "-1 0 0", p: needPagination ? "16 16 0" : "16" })};

  width: 100%;
  background: ${colors.gray};
  overflow: ${({ needScroll }) => (needScroll ? "auto" : "hidden")};

  &::-webkit-scrollbar {
    background: ${colors.white};
    height: 16px;
  }

  &::-webkit-scrollbar-thumb {
    ${borders({ totalRadius: true })};

    background: ${colors.grayscaleLabelOpacity77};
  }

  &::-webkit-scrollbar:vertical {
    display: none;
  }
`;
