import { atom, useAtom, WritableAtom } from "jotai";
import { SetAtom } from "jotai/core/atom";

const nullAtom = atom(null);

declare type ResolveType<T> = T extends Promise<infer V> ? V : T;

export const useOptionalAtom = <Value, Update>(
  atom: WritableAtom<Value | null, Update, void> | undefined
): [ResolveType<Value> | null, SetAtom<Update | null, void>] => {
  const result = useAtom(atom ?? nullAtom);

  return atom
    ? result
    : [
        null,
        () => {
          throw new Error("В хук useOptionalAtom передан несуществующий атом");
        },
      ];
};
