import { useAtomValue } from "jotai/utils";
import React, { FC } from "react";
import { menuIsExpandState } from "../../../atoms/atoms";
import { PopoverModal } from "../../PopoverModal";
import { ProfileIcon } from "../../ProfileIcon";
import { Card, Container, Name, Roles } from "./Profile.styles";
import { ProfileMenu } from "./ProfileMenu";
import { useProfile } from "./useProfile";

export const Profile: FC<PropsType> = (props) => {
  const { baseUrl } = props;

  const menuIsExpand = useAtomValue(menuIsExpandState);

  const methods = useProfile();
  const { fullName, initials, userIcon, role, canView, id } = methods;
  const { profileMenu, openProfileMenu, closeProfileMenu, profileMenuIsOpen } = methods;

  return (
    <>
      {menuIsExpand ? (
        <Container onClick={openProfileMenu}>
          <Card>
            <ProfileIcon initials={initials} userIcon={userIcon} margin="0 16 0 0" />

            {!!fullName && (
              <div style={{ overflow: "hidden" }}>
                <Name>{fullName}</Name>

                <Roles>{role}</Roles>
              </div>
            )}
          </Card>
        </Container>
      ) : (
        <Container onClick={openProfileMenu} onlyIcon>
          <ProfileIcon initials={initials} userIcon={userIcon} />
        </Container>
      )}

      <PopoverModal
        id="profileMenu"
        isOpen={profileMenuIsOpen}
        element={profileMenu}
        onClose={closeProfileMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <ProfileMenu onClose={closeProfileMenu} baseUrl={baseUrl} canView={canView} id={id} />
      </PopoverModal>
    </>
  );
};

type PropsType = {
  baseUrl?: string;
};
