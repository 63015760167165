import React, { FC } from "react";
import { ArrowLeft } from "../../Icons";
import { TitleAndButton, TitleLink } from "./NavigateButton.styles";
import { To } from "react-router-dom";

export const NavigateButton: FC<PropsType> = (props) => {
  const { link, title, from, subTitle } = props;

  return (
    <TitleAndButton>
      <TitleLink to={link as To} {...(from && { from })}>
        <div>
          <ArrowLeft />
        </div>
        <p>{title}</p>
      </TitleLink>
      {subTitle && <p>{subTitle}</p>}
    </TitleAndButton>
  );
};

type PropsType = {
  link: string | -1;
  title: string;
  subTitle?: string;
  from?: string;
};
