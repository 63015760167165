import { css } from "@emotion/react";

export const margins = (props: { m?: MarginsType; p?: MarginsType; needImportant?: boolean }) => {
  const { m, p, needImportant = false } = props;

  const marginArr = m?.split(" ");
  const paddingArr = p?.split(" ");

  const marginStr = marginArr
    ? marginArr
        .map((margin) => (margin ? (margin === "auto" ? `${margin}` : `${margin}px`) : 0))
        .join(" ")
    : `${m}px`;

  const paddingStr = paddingArr
    ? paddingArr
        .map((padding) => (padding ? (padding === "auto" ? `${padding}` : `${padding}px`) : 0))
        .join(" ")
    : `${p}px`;

  return css`
    ${m ? `margin: ${marginStr}` : undefined};
    ${p ? `padding: ${paddingStr} ${needImportant ? "!important" : ""}` : undefined};
  `;
};

export type MarginsType =
  | `${MarginType}`
  | `${MarginType} ${MarginType}`
  | `${MarginType} ${MarginType} ${MarginType}`
  | `${MarginType} ${MarginType} ${MarginType} ${MarginType}`;

type MarginType = number | "auto";
