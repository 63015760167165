import React, { FC, MouseEvent } from "react";
import { Pencil } from "../../Icons";
import { IconButton } from "../IconButton/IconButton";

export const EditButton: FC<PropsType> = (props) => {
  const { onClick, size = 40 } = props;

  return (
    <IconButton onClick={onClick} size={size}>
      <Pencil />
    </IconButton>
  );
};

type PropsType = {
  onClick: (arg: MouseEvent<HTMLButtonElement>) => void;
  size?: number;
};
