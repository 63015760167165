import React, { useState } from "react";
import { HeaderNavigationLeftButtons } from "../../../../uiKit/Header/HeaderNavigation/HeaderNavigationLeftButtons";
import { Buttons } from "../../../../uiKit/Header/HeaderNavigation/HeaderNavigation.styles";
import {
  employeeDiseasesState,
  modalDataInfectionState,
  useEmployeeDiseasesKeys,
} from "../../utils/atoms";
import { SearchButton } from "../../../../uiKit/Buttons";
import { getQuestionText } from "../../../../utils/getQuestionText";
import { SearchInput } from "../../../../uiKit";
import { useAtomValue } from "jotai/utils";
import { currentUserAtom } from "../../../../atoms/atomCurrentUser";
import { NavigationInfection } from "../../UserProfile.styles";
import { searchQueryState } from "../../../../atoms/atoms";
import { useUserProfilePage } from "../../useUserProfilePage";
import { useAtom } from "jotai";
import { getFileName } from "../../../../utils/workingWithFiles";
import { diseaseApi } from "../../../../api/apiDisease";

export const useData = () => {
  // ------------------------------ ОБЩИЕ ДАННЫЕ

  const methods = useUserProfilePage();

  // ------------------------------ ХУКИ

  const { roleModel } = useAtomValue(currentUserAtom);
  const [visibleSearch, setVisibleSearch] = useState(false);
  const [modalData, setModalData] = useAtom(modalDataInfectionState);

  const query = useAtomValue(searchQueryState);

  let data = useAtomValue(employeeDiseasesState);
  const { queryKey: additionalKeys } = useEmployeeDiseasesKeys();

  // ------------------------------ КОНСТАНТЫ

  const queryKeyForOneEntity = [additionalKeys];
  const { employeeProfile, queryKey } = methods;
  const status = employeeProfile?.status;
  const api = diseaseApi();

  const {
    canViewProfile: canView,
    canEditProfile: canEdit,
    canAddDiseasesProfile: canAdd,
  } = roleModel;

  const questionWords = [
    "инфекционное заболевание",
    "инфекционных заболевания",
    "инфекционных заболеваний",
  ];

  const questionText = getQuestionText(questionWords);

  // ------------------------------ ШАПКА - НАВИГАЦИЯ

  const header = (
    <NavigationInfection>
      <Buttons>
        <HeaderNavigationLeftButtons
          needAddButton={canAdd}
          needDeleteButton={canAdd}
          api={api}
          queryKey={queryKey}
          questionText={questionText}
          queryKeyForOneEntity={queryKeyForOneEntity}
          title=""
        />

        <SearchButton
          onClick={() => setVisibleSearch(!visibleSearch)}
          visibleSearch={visibleSearch}
        />
      </Buttons>
      {visibleSearch && <SearchInput />}
    </NavigationInfection>
  );

  // ------------------------------ ДАННЫЕ - ФИЛЬТРАЦИЯ

  if (query) {
    data = data.filter(({ infection, document, healthCenter, comment }) => {
      const datumForSearch = {
        infection: infection.name,
        document: document ? getFileName(document) : "",
        healthCenter: healthCenter?.name,
        comment,
      };

      return Object.values(datumForSearch).join().toLowerCase().includes(query.toLowerCase());
    });
  }

  return {
    ...methods,

    status,
    modalData,
    setModalData,
    data,

    canView,
    canEdit,
    canAdd,

    header,
    questionText,

    queryKey,
    api,
    queryKeyForOneEntity,
  };
};
