import styled from "@emotion/styled/macro";
import { borders, colors, flex, grid, margins, shadow, text } from "../../styles";

export const HeaderPage = styled.header`
  ${grid({ gap: 16, columns: "1fr 1fr" })};
`;

export const BodyPage = styled.div<{ forExport?: boolean }>`
  ${flex({ gap: 24, isColumn: true })};
  ${margins({ p: "24", m: "24 0 0 0" })};
  ${borders({ radius: 24 })};

  ${({ forExport }) =>
    forExport
      ? `width: 297mm;
         min-height: 220mm;
         position: absolute;
         left: -10000px;`
      : ""}
  background: ${colors.white};
`;

export const Title = styled.p<{ forExport?: boolean }>`
  ${({ forExport }) => (forExport ? margins({ p: "0 0 0 40" }) : "")};
  ${text({
    size: 20,
    weight: 800,
    height: 26,
    color: colors.grayscaleBeautifulBlack,
  })};
  place-self: center;

  &:not(:last-of-type):after {
    ${text({ weight: 400, size: 14, height: 22, color: colors.grayscaleIcons })};
    ${margins({ m: "0 9 0 9" })};
    content: "/";
  }
`;

export const HeaderExport = styled.div`
  ${flex({ vertical: "center" })};
  ${margins({ m: "0 0 16", p: "16 40" })};
  width: 100%;
  height: 76px;
  background: ${colors.white};
`;

export const HeaderBody = styled.div`
  ${flex()};
  min-height: 48px;
`;

export const ChartContainer = styled.div<{ mainHeight: number }>`
  ${borders({ radius: 16 })};
  ${margins({ p: "16 16 8 16" })};

  min-width: calc(1024px - 73px - (16px * 2));
  // (73px) - ширина боковой панели
  // (16px * 2) - 2 паддинга компоненты Main
  width: 100%;
  height: fit-content;
  max-height: calc(100vh - 132px - 76px - (16px * 2));
  // (132px) - высота Heder
  // (76px) - высота панели организаций
  // (16px * 2) - 2 паддинга компоненты Main
  background: ${colors.white};

  canvas {
    max-height: ${({ mainHeight }) => `${mainHeight}px`} !important;
  }
`;

export const DiagramContainer = styled.div`
  ${margins({ m: "0 18 0 0" })}
  ${borders({ totalRadius: true })};
  width: 200px;
  height: 200px;
  background: radial-gradient(
    ${colors.transparent},
    ${colors.transparent} 48%,
    ${colors.grayscaleInput} 48%,
    ${colors.grayscaleInput} 100%
  );
`;

export const CardsContainer = styled.div<{ forExport?: boolean }>`
  ${grid({ columns: "repeat(auto-fit, minmax(400px, 1fr))", gap: 16 })};
  ${({ forExport }) => margins({ m: forExport ? "0 40" : "0" })};
  ${margins({ p: "0 0 80 0" })};
`;

export const TextMonth = styled.p`
  ${text({ size: 13, height: 17, weight: 400, color: colors.grayscalePlaceholder })};
`;
export const Month = styled.div`
  ${flex({ isColumn: true, gap: 2 })};
  ${margins({ p: "2" })};
  ${borders({ radius: 8 })};
  width: 33px;
  height: 69px;

  background: ${colors.grayscaleInput};
`;

const Day = styled.div<{ height?: number }>`
  height: ${({ height }) => (height ? `${height}%` : 0)};
  ${borders({ radius: 8 })};
`;

export const HealthDays = styled(Day)`
  background: ${colors.primaryDefault};
`;

export const SickDays = styled(Day)`
  background: ${colors.redDefault};
`;

export const MonthBar = styled.div`
  ${flex({ isColumn: true, horizontal: "center" })};
`;

export const PopoverDataContainer = styled.div`
  ${flex({ isColumn: true, gap: 8 })};
`;

export const MorbidityCard = styled.div`
  ${grid({ gap: 16, columns: "100px 1fr" })};
  ${margins({ p: "12" })};
  ${borders({ radius: 12 })};

  max-width: 700px;
  box-shadow: ${shadow};
`;

export const History = styled.div`
  ${flex({ isColumn: true, gap: 4 })};
  & > p:nth-of-type(1) {
    ${text({ size: 13, height: 18, weight: 700, color: colors.grayscaleLabel })};
  }
  & > p:nth-of-type(2) {
    ${text({ size: 32, height: 42, weight: 400, color: colors.black })};
    margin: 8px 0 -6px 0;
  }
  & > p:nth-of-type(3) {
    ${text({ size: 13, height: 17, weight: 400, color: colors.grayscalePlaceholder })};
  }
`;

export const Bars = styled.div`
  ${grid({ columns: "repeat(12, 1fr)", gap: 4 })};
  ${margins({ p: "0 4" })};
`;
