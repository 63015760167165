import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";
import { borders, colors, flex, margins, text } from "../../../styles";

export const TitleAndButton = styled.div`
  ${flex({ isColumn: true })};

  & > p {
    padding-left: 28px;
    ${text({ size: 15, weight: 400, height: 20, color: colors.grayscaleBeautifulBlack })}
  }
`;

export const TitleLink = styled(Link)`
  ${flex({ vertical: "center" })};

  color: ${colors.grayscaleBeautifulBlack};

  div {
    ${flex({ totalCenter: true })};
    ${borders({ totalRadius: true })};

    width: 24px;
    height: 24px;
  }

  :hover {
    ${borders({ radius: 12 })};
    ${margins({ p: "0 8 0 0" })};

    background: ${colors.grayscaleHoverBackground};
    color: ${colors.grayscaleBeautifulBlack};

    div {
      background: ${colors.grayscaleHoverBackground};
    }
  }

  p {
    ${margins({ m: "0 0 0 4" })};
  }

  svg > path {
    fill: ${colors.grayscaleBeautifulBlack};
  }
`;
