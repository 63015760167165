import { useAtomValue } from "jotai/utils";
import { currentDateInterval } from "../../utils/atoms";
import { getDay, getDaysInMonth } from "date-fns";
import { formatDateForBackend, minusOneMonth, now } from "../../../../../utils/workingWithDates";

export const useCalendarView = () => {
  // ------------------------------ АТОМЫ

  const interval = useAtomValue(currentDateInterval);

  // ------------------------------ КОНСТАНТЫ

  const startDate = interval[0] ?? now;

  // ------------------------------ КОЛИЧЕСТВО ДНЕЙ В ВЫБРАННОМ МЕСЯЦЕ

  const monthDaysQuantity = getDaysInMonth(startDate);

  // ------------------------------ КОЛИЧЕСТВО ДНЕЙ В МЕСЯЦЕ, ПРЕДЫДУЩЕМ ВЫБРАННОМУ

  const beforeMonthDaysQuantity = getDaysInMonth(minusOneMonth(startDate));

  // ------------------------------ КОЛИЧЕСТВО ДНЕЙ В ПЕРВОЙ НЕДЕЛЕ ВЫБРАННОГО МЕСЯЦА ДО ПЕРВОГО ЧИСЛА

  const daysBeforeQuantity = getDay(startDate) === 0 ? 6 : getDay(startDate) - 1;

  // ------------------------------ ДНИ ДО ВЫБРАННОГО МЕСЯЦА

  const daysBefore = [];
  for (let i = daysBeforeQuantity - 1; i >= 0; i--) {
    daysBefore.push(beforeMonthDaysQuantity - i);
  }

  // ------------------------------ ДНИ ВЫБРАННОГО МЕСЯЦА

  const daysCurrent = [];
  for (let i = 1; i <= monthDaysQuantity; i++) {
    daysCurrent.push(i);
  }

  // ------------------------------ ДНИ ПОСЛЕ ВЫБРАННОГО МЕСЯЦА

  const daysAfter = [];
  for (let i = 1; i <= 42 - monthDaysQuantity - daysBeforeQuantity; i++) {
    daysAfter.push(i);
  }

  // ------------------------------ ТЕКУЩИЙ ЛИ ДЕНЬ

  const isCurrentDay = (day: number) =>
    startDate.getMonth() === now.getMonth() && day === now.getDate();

  // ------------------------------ ПОЛНАЯ ДАТА ДЛЯ ДНЯ

  const getFullDate = (day: number) => {
    const date = new Date(startDate.valueOf());
    date.setDate(day);
    return `${formatDateForBackend(date)}`;
  };

  return {
    daysAfter,
    daysBefore,
    daysCurrent,

    isCurrentDay,
    getFullDate,
  };
};
