import React from "react";
import { useAtomValue } from "jotai/utils";
import { currentUserAtom } from "../../../../atoms/atomCurrentUser";
import { HeaderNavigation } from "../../../../uiKit";
import { ADMINISTRATION } from "../../../../constants/routes";
import {
  infectionDiseasesState,
  useInfectionDiseasesQuery,
  useVaccinationSettingsQuery,
  vaccinationSettingsState,
} from "../utils/atoms";
import { SettingType } from "./useForms";
import { ApplyButton } from "../ApplyButton/ApplyButton";
import { useLoadableData } from "../../../../utils/useLoadableData";
import { OrganizationSettingsType } from "../../../../api/apiVaccination";

export const useData = () => {
  // ------------------------------ АТОМЫ

  const { roleModel, res } = useAtomValue(currentUserAtom);

  const {
    totalCount,
    loadableData,
    data: infections,
  } = useLoadableData(infectionDiseasesState, []);
  const settings = useAtomValue(vaccinationSettingsState);

  // ------------------------------ ХУКИ

  const { queryKey, api } = useVaccinationSettingsQuery();
  const { queryKey: keyInfection } = useInfectionDiseasesQuery();

  const { canViewVaccinationSettings: canView, canEditVaccinationSettings: canEdit } = roleModel;

  // ------------------------------ КОНСТАНТЫ

  const titlePage = "Настройка разрешений";
  const queryKeys = [keyInfection];
  const organization = res.organization;

  const data = infections.map((infection) => {
    const {
      access = false,
      agreement = false,
      active = false,
      id,
    } = settings.find(
      ({ infection: settingsInfection }) => settingsInfection.id === infection.id
    ) ?? {};

    return {
      id: id ?? infection.id,
      infection: infection,
      access,
      agreement,
      active,
    } as OrganizationSettingsType;
  });

  // ------------------------------ РЕДАКТИРОВАНИЕ СТРАНИЦЫ

  const hasDifferent = (settings: SettingType) =>
    !Object.keys(settings).every((keySettings) => {
      const key = keySettings as keyof SettingType;
      const idSettings = key.split("_")[0];
      const field = key.split("_")[1] as keyof OrganizationSettingsType;

      const organizationSettings = data.find(({ id }) => id === idSettings);

      if (organizationSettings) return organizationSettings[field] === settings[key];
      return false;
    });

  // ------------------------------ ШАПКА - НАВИГАЦИЯ

  const navigation = (
    <HeaderNavigation
      title={titlePage}
      needSearchButton
      buttonsStart={<ApplyButton hasDifferent={hasDifferent} />}
      section={{ name: "Администрирование", rout: ADMINISTRATION }}
    />
  );

  return {
    loadableData,
    totalCount,
    data,

    navigation,

    canView,
    canEdit,

    api,
    queryKey,
    queryKeys,

    organization,
  };
};
