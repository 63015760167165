import React, { DragEventHandler, FC } from "react";
import { Export } from "../Icons";
import { GeneralPropsType } from "./FilesInput";
import * as elements from "./FilesInput.styles";

export const FilesInputEmpty: FC<GeneralPropsType> = (props) => {
  const { name, label, accept, description, addFile, element, multiFile } = props;

  const { Container, Title, Description, Text, LoadBarAndText, LoadBar } = elements;

  const handleDragOver: DragEventHandler<HTMLElement> = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  const handleDragLeave: DragEventHandler = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  const handleDrop: DragEventHandler = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();

    const file = evt.dataTransfer.files?.[0];

    addFile(name, file);
  };

  return (
    <Container
      status="empty"
      multiFile={multiFile}
      onClick={() => element.current?.click()}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <LoadBarAndText status="empty" multiFile={multiFile}>
        <LoadBar status="empty">
          <Export />
        </LoadBar>
        <Text>
          <Title status="empty">{label}</Title>
          <Description status="empty">
            {description ? description : "Подходят файлы в формате 24x24px "}
            {accept.join(" ")}
          </Description>
        </Text>
      </LoadBarAndText>
    </Container>
  );
};
