import React, { FC } from "react";
import { ConfirmBody, ConfirmHeader, NotificationContainer } from "./ConfirmModal.styles";
import { ConfirmMessageType } from "./ConfirmModal";
import { UniversalTextButton } from "../Buttons";

export const NotificationModal: FC<PropsType> = (props) => {
  const { isOpen, onClose, message, confirmText = "Понятно" } = props;

  const headerText = typeof message === "string" ? message : message.header;
  const bodyText = typeof message === "string" ? "" : message.body;

  return (
    <NotificationContainer open={isOpen}>
      <ConfirmHeader>{headerText}</ConfirmHeader>
      {bodyText && <ConfirmBody>{bodyText}</ConfirmBody>}

      <UniversalTextButton text={confirmText} type="outlined" onClick={onClose} />
    </NotificationContainer>
  );
};

type PropsType = {
  isOpen: boolean;
  onClose: () => void;
  message: ConfirmMessageType | string;
  confirmText?: string;
};
