import styled from "@emotion/styled/macro";
import { borders, colors, flex, margins, text } from "../../../../styles";

const { grayscaleLabel, white, black, grayscaleChips, grayscalePlaceholder } = colors;

export const Container = styled.div`
  ${flex({ gap: 16 })};
  ${margins({ m: "0 0 16 0" })};
`;

export const DayBlock = styled.div<{ full?: boolean }>`
  ${margins({ p: "16" })};
  ${borders({ radius: 16 })};
  min-width: 124px;
  height: 98px;
  width: ${({ full }) => (full ? "100%" : "auto")};
  background: ${white};
`;

export const SubTitle = styled.p`
  ${text({ weight: 800, size: 10, height: 26, color: grayscaleLabel })};
`;

export const Times = styled.p`
  ${flex({ gap: 16 })};
`;

export const Time = styled.span<{ selected: boolean }>`
  ${margins({ p: "11 16" })};
  ${borders({ totalRadius: true })};
  ${({ selected }) => text({ size: 13, weight: 700, height: 18, color: selected ? white : black })};
  background: ${({ selected }) => (selected ? grayscaleLabel : grayscaleChips)};
  cursor: pointer;

  &:hover {
    background: ${grayscalePlaceholder};
  }
`;
