import React, { FC, useEffect, useRef, useState } from "react";
import { ChartContainer } from "../../InfographicPage.styled";
import Chart from "chart.js/auto";
import { FiltersType } from "../../utils/atoms";
import { useCharts } from "../../ChartsPage/hooks/useCharts";
import { OrganizationStatisticType } from "../../../../api/apiInfographic";

export const OrganizationChart: FC<ChartPageProps> = (props) => {
  const [parentHeight, setParentHeight] = useState(0);

  const { statsForOrganizations, dataWrapper, getDatasets, getOptions, getLabels } =
    useCharts(props);

  const ref = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const updateParentHeight = () => {
      const height = document.querySelector(".main")?.clientHeight;
      setParentHeight(height ? height - 115 - 136 - 48 - 16 * 3 : 0);
      // (115) - высота header
      // (136) - высота панели организаций
      // (48) - высота заголовка
      // (16px * 3) - падинги
    };

    window.addEventListener("resize", updateParentHeight);
    updateParentHeight();

    return () => window.removeEventListener("resize", updateParentHeight);
  }, []);

  useEffect(() => {
    const { labels } = getLabels();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const canvasElement = ref.current!;

    if (!canvasElement) return;

    const datasets = getDatasets();
    const options = getOptions();

    const myChart = new Chart(canvasElement, {
      type: "bar",
      options: options,
      data: { labels, datasets },
    });

    return () => {
      myChart.destroy();
    };
  }, [statsForOrganizations]);

  return (
    <>
      {dataWrapper(
        <ChartContainer mainHeight={parentHeight}>
          <canvas ref={ref} />
        </ChartContainer>
      )}
    </>
  );
};

export type ChartPageProps = {
  filters: FiltersType;
  selectedOrganizationName: string;
  stats: OrganizationStatisticType | undefined;
};
