import { izApi } from "./api";
import { entitiesBase, EntityNameType } from "./apiRequest";
import { IdType, PaginationType } from "./types";

// ------------------------------ МЕЛКИЕ СПРАВОЧНИКИ

const entities = <DATA, VOBJ>(entityName: EntityNameType) =>
  entitiesBase<DATA, VOBJ, EntityRequestType>(izApi, entityName);

export const nationalitiesApi = <E>() => entities<MediumOtherType, E>("vs_Citizenship");
export const healthCentersApi = <E>() => entities<HealthCenterType, E>("vs_HealthCenter");
export const personnelCategoriesApi = <E>() => entities<MediumOtherType, E>("vs_PersonnelCategory");
export const diseaseSeveritiesApi = <E>() => entities<MediumOtherType, E>("vs_Severity");
export const treatmentPlacesApi = <E>() => entities<MediumOtherType, E>("vs_TreatmentPlace");
export const diseasesClassesApi = <E>() => entities<DiseaseClassType, E>("vs_ICD");

// ------------------------------ ТИПЫ - СПРАВОЧНИКИ

export type LittleOtherType = IdType & { name: string };
export type MediumOtherType = LittleOtherType & { description: string };

export type HealthCenterType = LittleOtherType & { shortName: string; fmba: boolean };
export type DiseaseClassType = MediumOtherType & { code: string };
export type DiseasesClassType = DiseaseClassType[];

// передаваемые значения

export type EntityRequestType = PaginationType & {
  query?: string;
};
