import { useField } from "formik";
import React, { FC, useEffect } from "react";
import { VaccineReVaccineType } from "../../../../api/apiVaccine";
import { vaccineTypeOptions } from "../../../../constants/options";
import { CheckInput, DateInput, SelectInput, TextInput } from "../../../../uiKit";
import { FieldsBlock, FormSpacer } from "../../../../uiKit/Form/Form.styles";
import { now } from "../../../../utils/workingWithDates";

export const VaccinationFactFormField: FC<PropsType> = (props) => {
  const { vaccine } = props;
  const [{ value: type }] = useField<string>("type");
  const [{ value: number }, __, { setValue: setNumber }] = useField<string>("component");

  const vaccineComponentOptions = vaccine.vaccineComponents.map((component) => ({
    id: component.id,
    name: `${component.number} этап`,
  }));
  const reVaccineComponentOptions = vaccine.reVaccineComponents.map((component) => ({
    id: component.id,
    name: `${component.number} этап`,
  }));

  const stepOptions = type === "vaccine" ? vaccineComponentOptions : reVaccineComponentOptions;

  useEffect(() => {
    if (!number || !stepOptions.find(({ id }) => id === number)) setNumber("");
  }, [type]);

  return (
    <FieldsBlock>
      <DateInput
        name="date"
        placeholder="ДД.ММ.ГГГГ"
        maxDate={now}
        outsideLabel="Дата вакцинации"
      />
      <SelectInput name="type" options={vaccineTypeOptions} outsideLabel="Тип*" />

      <SelectInput
        name="component"
        options={stepOptions}
        disabled={stepOptions.length === 0}
        outsideLabel="Этап*"
      />

      <TextInput name="organization" outsideLabel="Медицинская организация" />

      <FormSpacer />
      <CheckInput name="abroad" label="В связи с поездкой за рубеж" />
    </FieldsBlock>
  );
};

type PropsType = {
  vaccine: VaccineReVaccineType;
};
