import styled from "@emotion/styled/macro";
import { borders, colors, margins } from "../../styles";

export const Container = styled.header`
  ${borders({ side: "bottom", color: colors.grayscaleInput })};
  ${margins({ m: "0", p: "16 0 0 0" })};

  width: 100%;
  position: relative;
  background: ${colors.white};
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
`;
