import React, { FC, useEffect } from "react";
import { Page } from "../../../uiKit";
import { useVaccinationEmployeesPage } from "./useVaccinationEmployeesPage";
import { useUpdateAtom } from "jotai/utils";
import { selectedInfectionState } from "../../CalendarPages/CalendarPage/utils/atoms";

export const VaccinationEmployeesPage: FC = () => {
  const methods = useVaccinationEmployeesPage();
  const setCalendarInfection = useUpdateAtom(selectedInfectionState);

  const { setDateSwitcherType } = methods;

  useEffect(() => {
    setDateSwitcherType("year");
    setCalendarInfection("");
  }, []);

  return <Page methods={methods} />;
};
