/**
 *
 * ------------------------------------------------------------------------------------------
 * Возвращает объект с полями переданного объекта, исключая undefined | null
 *
 *
 * @param props - любой объект
 *
 */
export const getClearedObject = <OBJ>(props: OBJ) => {
  const resultObj = {} as Partial<OBJ>;

  Object.keys(props || {}).forEach((keyStr) => {
    const key = keyStr as keyof OBJ;
    if (props && props[key]) resultObj[key] = props[key];
  });

  return resultObj;
};
