import React, { FC } from "react";
import { PopoverModal } from "../../../../uiKit";
import { MenuButtons } from "../../../../uiKit/Table/TableBodyRow/TableBodyRow.styles";
import { TableMenuButton, TableMenuItemButton } from "../../../../uiKit/Buttons";
import { Pencil, Trash } from "../../../../uiKit/Icons";
import { vaccineTypeOptions } from "../../../../constants/options";
import { Record, Text } from "../../UserProfile.styles";
import { formatParseDate } from "../../../../utils/workingWithDates";
import { FactActions } from "./VaccinationSummary.styles";
import { usePopoverHover } from "../../../../uiKit/PopoverHover/usePopoverHover";
import { useUpdateAtom } from "jotai/utils";
import { modalDataFactState } from "../../utils/atoms";
import { VaccinationFactType } from "../../../../api/apiVaccination";

export const HistoryRow: FC<PropsType> = (props) => {
  const { fact, type, onDelete, canEdit } = props;

  const { date, component, appointment, abroad, id, comment } = fact;
  const { shortName: healthCenter } = appointment?.vaccinationPlan?.healthCenter || {};
  const { number } = component;

  const setModalData = useUpdateAtom(modalDataFactState);

  const { closePopoverHover, propsPopoverHover, openPopoverHover } = usePopoverHover({});
  const { isOpen } = propsPopoverHover;

  const typeName = vaccineTypeOptions.find(({ id }) => id === type)?.name ?? "";

  return (
    <>
      <Record key={id} type="fact">
        <Text notBold>{formatParseDate(date)}</Text>
        <Text notBold>{typeName}</Text>
        <Text>Этап {number}</Text>
        <Text>{appointment ? healthCenter : comment}</Text>
        {abroad && <Text>Поездка за рубеж</Text>}
        <FactActions>
          {canEdit && <TableMenuButton menuModalIsOpen={isOpen} onClick={openPopoverHover} />}
        </FactActions>
      </Record>
      <PopoverModal {...propsPopoverHover}>
        <MenuButtons>
          <TableMenuItemButton
            text="Изменить"
            icon={<Pencil />}
            onClick={() => {
              closePopoverHover();
              setModalData(fact);
            }}
          />

          <TableMenuItemButton
            text="Удалить"
            icon={<Trash />}
            onClick={() => {
              closePopoverHover();
              onDelete(id);
            }}
          />
        </MenuButtons>
      </PopoverModal>
    </>
  );
};

type PropsType = {
  fact: VaccinationFactType;
  type: "vaccine" | "revaccine";
  onDelete: (id: string) => void;
  canEdit: boolean;
};
