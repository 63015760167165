import { entitiesBase, EntityCreateType, EntityUpdateType } from "./apiRequest";
import { izApi, izServicesApi } from "./api";
import { IdType } from "./types";
import { EmployeeType } from "./apiEmployees";
import { DiseaseClassType, HealthCenterType, MediumOtherType } from "./apiDictionaries";
import { DocumentType } from "./apiFile";
import { InfectionDiseaseType } from "./apiInfectionDiseases";
import { FileLinkType } from "../utils/getFile";
import { AxiosResponse } from "axios";

export const diseaseApi = <E>() => ({
  ...entitiesBase<DiseaseType, E>(izApi, "vs_Disease"),
  create: async (props: EntityCreateType<E>): Promise<AxiosResponse<IdType>> => {
    const { data } = props;
    return izServicesApi.post(`/diseaseService/save`, { diseaseDto: data });
  },

  update: async (props: EntityUpdateType<E>): Promise<AxiosResponse<E>> => {
    const { data } = props;
    return izServicesApi.post(`/diseaseService/save`, { diseaseDto: data });
  },

  delete: async (id: string): Promise<AxiosResponse<DiseaseType, DiseaseType>> => {
    return izServicesApi.post(`/diseaseService/deleteDisease`, { id });
  },
});

export type DiseasesType = DiseaseType[];

export type DiseaseType = IdType & {
  employee: EmployeeType;
  infection: InfectionDiseaseType;
  code: DiseaseClassType;
  start: string;
  end: string | null;
  anamnesis: string;
  document: DocumentType | FileLinkType;
  treatmentPlace: MediumOtherType;
  healthCenter: HealthCenterType;
  severity: MediumOtherType;
  sickLeave: boolean;
  comment: string;
};
