import { useField } from "formik";
import React, { FC, MouseEvent } from "react";
import { CheckForSwitch, Cross } from "../../uiKit/Icons";
import { Container, Label, Switch } from "./SwitchLabelInput.styles";

export const SwitchLabelInput: FC<PropsType> = (props) => {
  const { name, label, disabled = false, onMouseEnter, onMouseLeave, variant = "free" } = props;

  const [{ value }, _, { setValue }] = useField<boolean>(name);

  return (
    <Container
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => {
        if (!disabled) setValue(!value);
      }}
      disabled={disabled}
    >
      <Label disabled={disabled} variant={variant}>
        {label}
      </Label>

      <Switch checked={value}>
        <div>{value ? <CheckForSwitch /> : <Cross size={8} />}</div>
      </Switch>
    </Container>
  );
};

type PropsType = {
  name: string;
  label: string;
  disabled?: boolean;
  onMouseEnter?: (evt: MouseEvent<HTMLElement>) => void;
  onMouseLeave?: () => void;
  variant?: "upper" | "free";
};
