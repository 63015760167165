import { FieldsBlock } from "../../../../uiKit/Form/Form.styles";
import React from "react";
import { SelectMultiplyInput } from "../../../../uiKit";
import * as Yup from "yup";
import { useInfectionDiseaseOptions } from "../../../../utils/getOptions";
import { useQuery } from "../../../../utils/useQuery";
import { NotificationTableType } from "./useTable";
import { useData } from "./useData";
import { NotificationSettingsType } from "../../../../api/apiVaccination";

export const useForms = () => {
  // ------------------------------ ХУКИ

  const infectionOptions = useInfectionDiseaseOptions();
  const { notificationSettings, organization, api, queryKey, loadableData } = useData();

  // ------------------------------ КОНСТАНТЫ

  const addFormTitle = "Добавить инфекционное заболевание";
  const editFormTitle = "Добавить инфекционное заболевание";

  const formName = "notificationSettings";

  // ------------------------------ ФОРМА - ПОЛЯ

  const fields = (
    <FieldsBlock>
      <SelectMultiplyInput name="infections" options={infectionOptions} type="chips" />
    </FieldsBlock>
  );

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesAddForm = {
    infections: [] as string[],
    field: "",
  };

  const initialValuesEditForm = (props: NotificationSettingsType) => {
    const { infections, field } = props as unknown as NotificationTableType;

    return { infections: infections.map(({ id }) => id), field };
  };

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchema = Yup.object().shape({
    infections: Yup.array(),
    field: Yup.string(),
  });

  // ------------------------------ ФОРМА - ЗНАЧЕНИЯ

  type InitialValuesType = {
    infections: string[];
    field: string;
  };

  // ------------------------------ ФОРМА - ОТПРАВКА

  const update = useQuery().useUpdate({ api, queryKey });
  const create = useQuery().useCreate({ api, queryKey });

  const onSubmit = async ({ infections, field }: InitialValuesType) => {
    const id = loadableData.state === "hasData" ? loadableData.data[0]?.id ?? "" : "";
    const data = {
      ...(!notificationSettings ? { organization } : {}),
      [`${field}`]: infections.map((infection) => ({ id: infection })),
    };

    await (notificationSettings ? update.mutateAsync({ id, data }) : create.mutateAsync({ data }));
  };

  return {
    addFormTitle,
    editFormTitle,
    formName,

    fields,

    initialValuesAddForm,
    initialValuesEditForm,

    validationSchema,
    onSubmit,
  };
};
