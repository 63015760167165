import { DateRange } from "@mui/lab";
import { PrimitiveAtom } from "jotai";
import React, { ReactNode } from "react";
import { ApiType, QueryKeysType, QueryKeyType } from "../../../utils/useQuery";
import { Buttons, Container, Title, TitleAndNav } from "./HeaderNavigation.styles";
import { HeaderNavigationBreadcrumbsNav } from "./HeaderNavigationBreadcrumbsNav";
import { HeaderNavigationLeftButtons } from "./HeaderNavigationLeftButtons";
import { HeaderNavigationRightButtons } from "./HeaderNavigationRightButtons";

export const HeaderNavigation = <VOBJ,>(props: HeaderNavigationPropsType<VOBJ>) => {
  const { children, title, section, needAddButton = false } = props;
  const { buttonsStart, buttonsCenter, buttonsEnd, needDateSwitcher, footer } = props;
  const { needSearchButton = false, needFilterButton = false } = props;

  const hasLeftButtons = buttonsStart || buttonsCenter || buttonsEnd || needAddButton;
  const hasAllButtons = hasLeftButtons || needSearchButton || needFilterButton || needDateSwitcher;

  return (
    <Container>
      <TitleAndNav>
        <Title>{typeof title === "string" ? title : title.element}</Title>

        <HeaderNavigationBreadcrumbsNav title={title} section={section} />
      </TitleAndNav>

      {!!hasAllButtons && (
        <Buttons>
          {!hasLeftButtons ? children ?? <div /> : <HeaderNavigationLeftButtons {...props} />}

          <HeaderNavigationRightButtons {...props} />
        </Buttons>
      )}

      {!!hasLeftButtons && children}
      {footer}
    </Container>
  );
};

export type HeaderNavigationPropsType<VOBJ> = {
  children?: ReactNode;
  title: TitleType;
  questionText?: string | ((props: { entity?: string; count?: number }) => string);
  successText?: string;
  buttonsStart?: ReactNode;
  buttonsCenter?: ReactNode;
  buttonsEnd?: ReactNode;
  buttonsRight?: ReactNode;
  needAddButton?: boolean;
  needSearchButton?: boolean;
  needFilterButton?: boolean;
  needDateSwitcher?: DateSwitcherType;
  section?: SectionType;
  footer?: ReactNode;
} & DeletionButtonType<VOBJ>;

type DeletionButtonType<VOBJ> =
  | {
      needDeleteButton?: undefined;
      queryKey?: undefined;
      queryKeyForOneEntity?: QueryKeysType;
      api?: undefined;
    }
  | {
      needDeleteButton: boolean;
      queryKey: QueryKeyType;
      queryKeyForOneEntity?: QueryKeysType;
      api: ApiType<VOBJ>;
    };

export type TitleType =
  | string
  | {
      text: string;
      element: ReactNode;
    };

export type SectionType = {
  name: string;
  rout: string;
};

export type DateSwitcherType = {
  typeInterval: TypeIntervalType;
  dateAtom?: DateAtomType;
  dateIntervalAtom?: DateIntervalAtomType;
  router?: (date: string | undefined) => string;
  needPeriodSwitcher?: boolean;
  color?: string;
  min?: Date;
  max?: Date;
};

type TypeIntervalType = "oneDate" | "twoDate";

export type DateAtomType = PrimitiveAtom<Date | null>;

export type DateIntervalAtomType = PrimitiveAtom<DateRange<Date | null>>;
