import styled from "@emotion/styled";
import { borders, colors, flex, margins, text } from "../../styles";

const { white, grayscaleInput, grayscaleBody, grayscalePlaceholder } = colors;

export const Container = styled.div<{ disabled: boolean }>`
  ${flex({ gap: 8, vertical: "center" })};
  ${borders({ color: grayscaleInput, totalRadius: true })};
  ${margins({ p: "8" })};

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  height: 40px;
  background: ${white};
`;

export const Switch = styled.div<{ checked: boolean }>`
  ${({ checked }) => flex({ horizontal: checked ? "end" : "start" })}
  ${margins({ p: "2" })};

  width: 42px;
  height: 24px;
  background: ${({ checked }) => (checked ? colors.primaryDefault : colors.grayscaleLine)};
  border-radius: 20em;
  padding: 2px;

  div {
    ${flex({ totalCenter: true })}
    ${margins({ m: "0" })};

    width: 20px;
    height: 20px;
    background: ${colors.white};
    border-radius: 99em;

    & > svg > path {
      fill: ${colors.grayscaleIcons};
    }
  }
`;

export const Label = styled.span<{ disabled: boolean; variant: "upper" | "free" }>`
  ${({ disabled, variant }) =>
    text({
      size: 11,
      height: 16,
      weight: variant === "upper" ? 700 : 400,
      color: disabled ? grayscalePlaceholder : grayscaleBody,
      align: "center",
      uppercase: variant === "upper",
    })};
`;
