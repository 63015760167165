import React, { FC, MouseEvent } from "react";
import { Trash } from "../../Icons";
import { Container } from "./TrashButton.styles";

export const TrashButton: FC<PropsType> = (props) => {
  const { onClick, size = 40, disabled = false } = props;

  return (
    <Container
      type="button"
      onClick={disabled ? undefined : onClick}
      size={size}
      disabled={disabled}
    >
      <Trash />
    </Container>
  );
};

type PropsType = {
  onClick: (evt: MouseEvent<HTMLElement>) => void;
  size?: number;
  disabled?: boolean;
};
