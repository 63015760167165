import React from "react";
import { colors } from "../../styles";

export const PersonEdit = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 6.5C12.75 5.11832 11.6317 4 10.25 4C8.86832 4 7.75 5.11832 7.75 6.5C7.75 7.88168 8.86832 9 10.25 9C11.6317 9 12.75 7.88168 12.75 6.5ZM14.75 6.5C14.75 4.01375 12.7362 2 10.25 2C7.76375 2 5.75 4.01375 5.75 6.5C5.75 8.98625 7.76375 11 10.25 11C12.7362 11 14.75 8.98625 14.75 6.5ZM12.8066 21.8323C11.9569 22.1368 11.1223 21.3448 11.382 20.4803L12.332 17.3185C12.6022 16.4191 13.0797 15.5957 13.7262 14.9146L17.4063 11.0369C18.1667 10.2357 19.4326 10.2027 20.2338 10.963L21.8485 12.4955C22.6496 13.2558 22.6827 14.5217 21.9224 15.3229L18.2422 19.2006C17.5958 19.8817 16.7985 20.4016 15.9145 20.7185L12.8066 21.8323ZM13.8105 19.3479L15.2397 18.8357C15.829 18.6245 16.3606 18.2779 16.7915 17.8238L20.4717 13.9461L18.857 12.4137L15.1768 16.2914C14.7459 16.7455 14.4275 17.2944 14.2474 17.894L13.8105 19.3479ZM10.3611 12C5.88147 12 2.25 15.6315 2.25 20.1111C2.25 21.1543 3.09568 22 4.13889 22H8.75C9.30228 22 9.75 21.5523 9.75 21C9.75 20.4477 9.30228 20 8.75 20H4.25099C4.31025 16.6762 7.02315 14 10.3611 14H11.75C12.3023 14 12.75 13.5523 12.75 13C12.75 12.4477 12.3023 12 11.75 12H10.3611Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
