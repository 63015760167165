import React, { ReactNode, useState } from "react";
import { useAtomValue } from "jotai/utils";
import { statusCreateOrUpdateState, statusDeletionState } from "../../atoms/atoms";
import { ConfirmModalSuccess } from "../../uiKit/ConfirmModal/ConfirmModalSuccess";
import { ConfirmModalError } from "../../uiKit/ConfirmModal/ConfirmModalError";
import { ConfirmContainer } from "../../uiKit/ConfirmModal/ConfirmModal.styles";
import { Loader } from "../../uiKit";
import { MultiStetConfirmModalBeforeRequest } from "./MultiStetConfirmModalBeforeRequest";
import { ModalStatus } from "./useMultiModal";

export const MultiStepConfirmModal = (props: MultiConfirmModalPropsType) => {
  const { isOpen, onDelete, onSend, onApprove } = props;

  const [status, setStatus] = useState(!!onDelete || !!onSend || !!onApprove ? "" : "success");
  const [error, setError] = useState<string | undefined>();

  const statusDeletion = useAtomValue(statusDeletionState);
  const statusCreateOrUpdate = useAtomValue(statusCreateOrUpdateState);

  return (
    <ConfirmContainer open={isOpen}>
      {status === "success" ? (
        <ConfirmModalSuccess {...props} />
      ) : status === "error" ? (
        <ConfirmModalError error={error} {...props} />
      ) : status === "" && (statusDeletion || statusCreateOrUpdate) ? (
        <ConfirmContainer open={true}>
          <Loader />
        </ConfirmContainer>
      ) : (
        <MultiStetConfirmModalBeforeRequest setStatus={setStatus} setError={setError} {...props} />
      )}
    </ConfirmContainer>
  );
};

export type MultiConfirmModalPropsType = {
  questionText: string;
  successText: string;
  confirmText?: string;
  isOpen: boolean;
  onClose: () => void;
  onDelete?: () => Promise<ModalStatus>;
  onSend?: () => Promise<ModalStatus>;
  onApprove?: () => Promise<ModalStatus>;
  additionalButton?: ReactNode;
};
