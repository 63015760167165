import React from "react";
import { colors } from "../../styles";

export const TimeEdit = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.25 20.8324C10.25 20.3215 9.86695 19.8975 9.3703 19.7779C5.85897 18.9324 3.25 15.7709 3.25 12C3.25 7.58172 6.83172 4 11.25 4C14.1784 4 16.7394 5.57346 18.1335 7.92108C18.339 8.26716 18.7011 8.5 19.1036 8.5C19.8379 8.5 20.3418 7.76587 19.9833 7.12504C18.2728 4.06721 15.0028 2 11.25 2C5.72715 2 1.25 6.47715 1.25 12C1.25 16.8008 4.63306 20.8115 9.14526 21.7781C9.73083 21.9036 10.25 21.4312 10.25 20.8324Z"
      fill={colors.grayscaleIcons}
    />
    <path
      d="M12.25 7C12.8023 7 13.25 7.44772 13.25 8V12.5C13.25 13.3284 12.5784 14 11.75 14H7.25C6.69772 14 6.25 13.5523 6.25 13C6.25 12.4477 6.69772 12 7.25 12H11.25V8C11.25 7.44772 11.6977 7 12.25 7Z"
      fill={colors.grayscaleIcons}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3066 21.8323C12.4568 22.1369 11.6223 21.3448 11.882 20.4803L12.8319 17.3185C13.1022 16.4191 13.5797 15.5958 14.2261 14.9146L17.9063 11.037C18.6667 10.2358 19.9326 10.2027 20.7337 10.963L22.3484 12.4955C23.1496 13.2559 23.1827 14.5218 22.4223 15.3229L18.7422 19.2006C18.0958 19.8818 17.2985 20.4017 16.4144 20.7185L13.3066 21.8323ZM14.3105 19.348L15.7397 18.8358C16.329 18.6245 16.8606 18.2779 17.2915 17.8238L20.9717 13.9462L19.357 12.4137L15.6768 16.2914C15.2459 16.7455 14.9275 17.2944 14.7474 17.894L14.3105 19.348Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
