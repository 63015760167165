import styled from "@emotion/styled/macro";
import { button, buttonTextDefault, margins } from "../../../styles";

export const Container = styled.button`
  ${button};
  ${buttonTextDefault};
  ${margins({ p: "0" })};

  height: fit-content;
`;
