import { AxiosError, AxiosResponse } from "axios";

export const multiModalStep = async (props: MultiModalProps): Promise<ModalStatus> => {
  const { requests, request, onError, onComplete } = props;
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      if (request) await request;
      if (requests) await Promise.all(await requests);

      const status = onComplete();
      resolve({ status });
    } catch (err) {
      const error = err as AxiosError;
      const status = onError(error);
      reject({ status: status, message: error.message ?? "" });
    }
  });
};

export type MultiModalProps = {
  requests?: Promise<AxiosResponse<unknown, unknown>[]>;
  request?: Promise<AxiosResponse<unknown, unknown>>;
  onError: (error: AxiosError) => ContinueCloseType;
  onComplete: () => ContinueCloseType;
};
export type ContinueCloseType = "continue" | "close";

export type ModalStatus = { status: ContinueCloseType; message?: string };
