import { useField, useFormikContext } from "formik";
import React, { FC, useEffect } from "react";
import { PlusMinusButton } from "../../Buttons";
import { FieldContainer } from "../../Form/Form.styles";
import { LabelField } from "../../Form/LabelField";
import * as elements from "./NumberInput.styles";

export const NumberInput: FC<PropsType> = (props) => {
  const { comment, disabled = false, maxDisabled = false } = props;
  const { name, minValue, maxValue, description, outsideLabel } = props;

  const { Comment, Container, Description, DescriptionText, Error, Input, Quantity } = elements;

  const { setFieldValue } = useFormikContext();

  const [{ value }] = useField<number>(name);

  const hasErrorMaxValue = !disabled && maxValue && value >= maxValue;
  const hasErrorMinValue = !disabled && minValue && value < minValue;

  useEffect(() => {
    disabled ? setFieldValue(name, 0) : setFieldValue(name, value);
  }, [disabled]);

  return (
    <FieldContainer>
      <LabelField outsideLabel={outsideLabel} />
      <Container disabled={disabled}>
        <Input>
          <PlusMinusButton
            type="minus"
            onClick={
              value === 0 || (!!minValue && value <= minValue)
                ? undefined
                : value === 1
                ? () => setFieldValue(name, 0)
                : maxValue && value > maxValue
                ? () => setFieldValue(name, maxValue)
                : () => setFieldValue(name, value - 1)
            }
            disabled={disabled || value === 0 || value === minValue}
          />

          <Quantity
            type="number"
            value={disabled || value === 0 ? "" : value}
            onChange={(evt) => {
              setFieldValue(name, Number(evt.currentTarget.value));
            }}
            onKeyPress={(evt) => {
              if (evt?.key === "-" || evt?.key === "+" || evt?.key === "e") {
                evt.preventDefault();
              }
            }}
            hasError={
              +(
                !!value &&
                ((!disabled && !!minValue && value < minValue) || (!!maxValue && value > maxValue))
              )
            }
            disabled={disabled}
          />

          <PlusMinusButton
            type="plus"
            onClick={
              maxValue && value >= maxValue
                ? undefined
                : value === 0
                ? () => setFieldValue(name, 1)
                : () => setFieldValue(name, value + 1)
            }
            disabled={disabled || value === maxValue || maxDisabled}
          />
        </Input>

        {description ? (
          <Description>
            <DescriptionText>{description}</DescriptionText>

            {comment && !hasErrorMaxValue && !hasErrorMinValue ? (
              <Comment>{`Максимум ${comment}`}</Comment>
            ) : null}

            {hasErrorMaxValue ? (
              <Error>
                {description} не должно превышать {maxValue}
              </Error>
            ) : null}
            {hasErrorMinValue ? (
              <Error>
                {description} не должно быть меньше {minValue}
              </Error>
            ) : null}
          </Description>
        ) : null}
      </Container>
    </FieldContainer>
  );
};

type PropsType = {
  outsideLabel?: string;
  name: string;
  minValue?: number;
  maxValue?: number;
  description?: string;
  comment?: number;
  disabled?: boolean;
  maxDisabled?: boolean;
};
