import React, { useState } from "react";
import * as Yup from "yup";
import {
  antibodiesApi,
  AntibodiesType,
  employeeVaccinationSettingsApi,
  EmployeeVaccinationSettingType,
} from "../../../../api/apiEmployees";
import {
  long,
  notJustSpaces,
  required,
  space,
  thisDateInFuture,
  thisDateIsToCorrect,
} from "../../../../constants/forValidationSchemes";
import { withoutSpaces } from "../../../../constants/regexs";
import { DateInput, TextInput } from "../../../../uiKit";
import { FilterBody } from "../../../../uiKit/Filters/Filters.styles";
import { QueryKeyType, useQuery } from "../../../../utils/useQuery";
import { formatDateForBackend, now, parseDate } from "../../../../utils/workingWithDates";

export const useAntibodies = (
  queryKey: QueryKeyType,
  settings: EmployeeVaccinationSettingType | undefined,
  employeeId: string | undefined,
  infectionId: string
) => {
  const [modalData, setModalData] = useState<AntibodiesType | undefined>(undefined);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [openForm, setIsOpenForm] = useState(false);
  const [showAll, setShowAll] = useState(false);

  // ------------------------------ КОНСТАНТЫ

  const addFormTitle = "Добавление результатов анализа крови на антитела";
  const editFormTitle = "Анализ крови на антитела";
  const formName = "antibodies";

  // ------------------------------ ФОРМА - ПОЛЯ

  const fields = (
    <FilterBody>
      <DateInput name="date" placeholder="ДД.ММ.ГГГГ" maxDate={now} outsideLabel="Дата*" />

      <TextInput name="description" placeholder="–" outsideLabel="Антитела*" />
    </FilterBody>
  );

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesAddForm: ValuesObjectType = {
    date: now,
    description: "",
  };

  const initialValuesEditForm = ({ date, description }: AntibodiesType) => ({
    date: parseDate(date),
    description,
  });

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchema = Yup.object().shape({
    date: Yup.date()
      .required(required)
      .typeError(thisDateIsToCorrect)
      .test({
        name: "is-futureDate",
        message: thisDateInFuture,
        test: async (_: unknown, ctx: { parent: { date: Date | undefined } }) => {
          const { date } = ctx.parent;
          return date ? date <= now : true;
        },
      }),
    description: Yup.string()
      .max(8, long)
      .matches(withoutSpaces, notJustSpaces)
      .test({
        name: "not-space-around",
        message: space,
        test: (description) => (description ? !/^\s+|\s+$/g.test(description) : true),
      }),
  });

  // ------------------------------ ФОРМА - ОТПРАВКА

  const api = antibodiesApi();

  const createAntibodies = useQuery().useCreate({ api: api, queryKey });
  const updateAntibodies = useQuery().useUpdate({ api: api, queryKey });
  const deleteAntibodies = useQuery().useDelete({ api: api, queryKey });

  const createSettings = useQuery().useCreate({ api: employeeVaccinationSettingsApi(), queryKey });
  const updateSettings = useQuery().useUpdate({ api: employeeVaccinationSettingsApi(), queryKey });

  const onSubmit = async (values: ValuesObjectType, isEdit?: boolean) => {
    const { date, description } = values;

    let settingsId = settings?.id;

    if (!settings) {
      settingsId = (
        await createSettings.mutateAsync({
          data: {
            employee: { id: employeeId },
            infection: { id: infectionId },
            access: false,
            agreement: false,
            exemption: [],
            antibodies: [],
          },
        })
      ).data.id;
    }

    const antibodies = {
      date: formatDateForBackend(date),
      description,
      vaccinationSettings: { id: settingsId },
    };

    let antibodiesId = "";

    if (isEdit) await updateAntibodies.mutateAsync({ data: antibodies, id: modalData?.id });
    else antibodiesId = (await createAntibodies.mutateAsync({ data: antibodies })).data.id;

    if (!isEdit && settingsId)
      await updateSettings.mutateAsync({
        data: {
          antibodies: [...(settings ? settings.antibodies : []), { id: antibodiesId }],
        },
        id: settingsId,
      });
  };

  // ------------------------------ УДАЛЕНИЕ

  const handleDeletion = (id: string) => async () => await deleteAntibodies.mutateAsync([id]);

  return {
    isOpenConfirmModal,
    setIsOpenConfirmModal,

    formName,
    openForm,
    setIsOpenForm,

    modalData,
    setModalData,

    addFormTitle,
    editFormTitle,

    fields,

    initialValuesAddForm,
    initialValuesEditForm,

    validationSchema,

    onSubmit,

    queryKey,
    api,
    handleDeletion,

    showAll,
    setShowAll,
  };
};

type ValuesObjectType = {
  date: Date;
  description: string;
};
