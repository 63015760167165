import React, { FC } from "react";
import { VaccinationFactType } from "../../../../api/apiVaccination";
import { EditButton } from "../../../../uiKit/Buttons";
import { ActionCard } from "../ActionCard/ActionCard";
import { formatParseDate } from "../../../../utils/workingWithDates";

export const VaccinationFact: FC<PropsType> = (props) => {
  const { fact, onEdit, canEdit } = props;
  const { vaccinated, component, type, abroad, comment, date } = fact;
  const { number } = component;

  const additionalInfo = `${type === "VACCINATION" ? "Вакцинация" : "Ревакцинация"}/Этап${number}`;

  const hoverText = (
    <>
      <p>Сотрудник {vaccinated ? "" : "не "} был вакцинирован</p>
      {!vaccinated && comment && <p>по причине: {comment}</p>}
      {abroad && <p>в связи с поездкой за рубеж</p>}
      <p>тип: {type === "VACCINATION" ? "Вакцинация" : "Ревакцинация"}</p>
      <p>этап: {number}</p>
    </>
  );

  return (
    <>
      <ActionCard
        title={formatParseDate(date)}
        description={additionalInfo}
        type={vaccinated ? "access" : "error"}
        hoverText={hoverText}
      >
        {canEdit && <EditButton onClick={() => onEdit(fact)} />}
      </ActionCard>
    </>
  );
};

type PropsType = {
  fact: VaccinationFactType;
  canEdit: boolean;
  onEdit: (fact: VaccinationFactType) => void;
};
