import { AxiosResponse } from "axios";
import { queryClientAtom } from "jotai/query";
import { useAtomValue, useUpdateAtom } from "jotai/utils";
import { useMutation } from "react-query";
import { IdType } from "../api/types";
import { selectedRowsState, statusCreateOrUpdateState, statusDeletionState } from "../atoms/atoms";
import { timerForQueries } from "./timerForQueries";

// ------------------------------ СОЗДАНИЕ ДАННЫХ

export const useQuery = <VOBJ>() => {
  const queryClient = useAtomValue(queryClientAtom);

  const setStatusCreateOrUpdate = useUpdateAtom(statusCreateOrUpdateState);
  const setStatusDeletion = useUpdateAtom(statusDeletionState);
  const setSelectedRows = useUpdateAtom(selectedRowsState);

  return {
    useCreate: (props: CreatePropsType<VOBJ>) => {
      const { api, queryKey, queryKeyForOneEntity } = props;

      return useMutation(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        async (props: CreateType<VOBJ>) => api.create!(props),

        {
          onMutate: async () => setStatusCreateOrUpdate(true),
          onSettled: async () => {
            await timerForQueries();

            if (queryKeyForOneEntity)
              queryKeyForOneEntity.forEach((key) => queryClient.invalidateQueries(key));

            queryClient.invalidateQueries(queryKey);

            setStatusCreateOrUpdate(false);
          },
        }
      );
    },

    useUpdate: (props: UpdatePropsType<VOBJ>) => {
      const { api, queryKey, queryKeyForOneEntity } = props;

      return useMutation(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        async (props: UpdateType<VOBJ>) => api.update!(props),

        {
          onMutate: async () => setStatusCreateOrUpdate(true),
          onSettled: async () => {
            await timerForQueries();

            if (queryKeyForOneEntity)
              queryKeyForOneEntity.forEach((key) => queryClient.invalidateQueries(key));

            queryClient.invalidateQueries(queryKey);

            setStatusCreateOrUpdate(false);
          },
        }
      );
    },

    useDelete: (props: DeletePropsType<VOBJ>) => {
      const { api, queryKey, queryKeyForOneEntity } = props;

      return useMutation(
        async (ids: DeleteType) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const promises = ids.map(async (id) => api.delete!(id));
          return Promise.all(promises);
        },

        {
          onMutate: async () => setStatusDeletion(true),
          onSettled: async () => {
            await timerForQueries();

            queryClient.invalidateQueries(queryKey);

            if (queryKeyForOneEntity)
              queryKeyForOneEntity.forEach((key) => queryClient.invalidateQueries(key));

            setStatusDeletion(false);
            setSelectedRows([]);
          },
        }
      );
    },
    useCustomRequest: <FIELD extends string>(props: CustomRequestPropsType<FIELD, VOBJ>) => {
      const { queryKey, queryKeyForOneEntity, key, api, isDeletion = true } = props;

      return useMutation(
        async (props: MultiPropsType<VOBJ>) => api[key](props),

        {
          onMutate: async () => {
            if (isDeletion) setStatusDeletion(true);
            else setStatusCreateOrUpdate(true);
          },
          onSettled: async () => {
            await timerForQueries();

            queryClient.invalidateQueries(queryKey);

            if (queryKeyForOneEntity)
              queryKeyForOneEntity.forEach((key) => queryClient.invalidateQueries(key));

            if (isDeletion) setStatusDeletion(false);
            else setStatusCreateOrUpdate(false);
          },
        }
      );
    },
  };
};

type CreateType<VOBJ> = { data: VOBJ };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UpdateType<VOBJ> = (IdType & { data: VOBJ }) | any;
type DeleteType = string[];

type MultiPropsType<VOBJ> = CreateType<VOBJ> | UpdateType<VOBJ> | VOBJ | string;

export type CreatePropsType<VOBJ> = {
  api: { create?: (props: { data: VOBJ }) => Promise<AxiosResponse<IdType>> };
  queryKey: QueryKeyType;
  queryKeyForOneEntity?: QueryKeysType;
};

export type UpdatePropsType<VOBJ> = {
  api: { update?: (props: IdType & { data: VOBJ }) => Promise<AxiosResponse<VOBJ>> };
  queryKey: QueryKeyType;
  queryKeyForOneEntity?: QueryKeysType;
};

export type DeletePropsType<VOBJ> = {
  api: { delete?: (id: string) => Promise<AxiosResponse<VOBJ, VOBJ>> };
  queryKey: QueryKeyType;
  queryKeyForOneEntity?: QueryKeysType;
};

export type CustomRequestPropsType<FIELD extends string, VOBJ> = {
  api: { [key in FIELD]: (props: MultiPropsType<VOBJ>) => Promise<AxiosResponse<IdType>> };
  key: FIELD;
  queryKey: QueryKeyType;
  queryKeyForOneEntity?: QueryKeysType;
  isDeletion?: boolean;
};

export type ApiType<VOBJ> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getAll?: (props?: any) => Promise<AxiosResponse>;
  get?: (id: string) => Promise<AxiosResponse>;
  create?: CreatePropsType<VOBJ>["api"]["create"];
  update?: UpdatePropsType<VOBJ>["api"]["update"];
  delete?: DeletePropsType<VOBJ>["api"]["delete"];
};

export type QueryKeyType = (string | string[] | number | undefined | boolean)[];
export type QueryKeysType = QueryKeyType[];
