import React, { FC } from "react";
import { Container, Description, Row, Title } from "./TitleRows.styles";

export const TitleRows: FC<PropsType> = (props) => {
  const { rows, isRows = false } = props;

  return (
    <Container>
      {rows.map((row) => (
        <Row key={row.title} column={!isRows}>
          <Title>{row.title}</Title>
          <Description>{row.description}</Description>
        </Row>
      ))}
    </Container>
  );
};

type PropsType = {
  rows: { title: string; description: string }[];
  isRows?: boolean;
};
