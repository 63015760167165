import { AxiosResponse } from "axios";
import { izApi, izServicesApi } from "./api";
import { DiseasesClassType, EntityRequestType, MediumOtherType } from "./apiDictionaries";
import { entitiesBase, EntitiesResponseType, EntityCreateType } from "./apiRequest";
import { VaccinationComponentsType, VaccineType } from "./apiVaccine";
import { IdType } from "./types";

export const infectionDiseasesApi = <E>() => ({
  ...entitiesBase<InfectionDiseaseType, E, EntityRequestType>(izApi, "vs_Infection"),
  delete: async (id: string): Promise<AxiosResponse<InfectionDiseaseType, InfectionDiseaseType>> =>
    await izApi.delete(`/vs_Infection${id ? "/" + id : ""}`),
});

export const infectionDiseasesServicesApi = <E>() => ({
  ...entitiesBase<InfectionDiseaseType, E, InfectionPropType>(izServicesApi, "infectionService"),
  getAll: async (
    props: InfectionPropType
  ): Promise<AxiosResponse<EntitiesResponseType<InfectionDiseaseType>>> => {
    const request = {
      ...props,
    };
    const response = await izServicesApi.post(`/infectionService/getAll`, { request });
    const items = response.data.infections;
    const totalCount = response.data.page.size;

    return { ...response, data: { items, totalCount } };
  },
  create: async (props: EntityCreateType<E>): Promise<AxiosResponse<IdType>> => {
    const { data } = props;
    return izServicesApi.post(`/infectionService/create`, data);
  },
  update: async (props: EntityCreateType<E>): Promise<AxiosResponse<IdType>> => {
    const { data } = props;
    return izServicesApi.post(`/infectionService/update`, data);
  },
  delete: async (
    id: string
  ): Promise<AxiosResponse<InfectionDiseaseType, InfectionDiseaseType>> => {
    return izServicesApi.post(`/infectionService/delete`, { id });
  },
});

// получаемые значения

export type InfectionDiseaseType = MediumOtherType & {
  icd: DiseasesClassType;
  startAge: number;
  endAge: number;

  vaccines: InfectionVaccineType[];
};

type InfectionVaccineType = VaccineType & { components: VaccinationComponentsType };
export type InfectionsDiseaseType = InfectionDiseaseType[];

// передаваемые значения

export type InfectionPropType = {
  name?: string;
  size: number;
  page: number;
};
