import { atom } from "jotai";
import { DateRange } from "@mui/lab";
import { endOfYear, startOfYear } from "date-fns";
import { formatDateForBackend, now, today } from "../../../../utils/workingWithDates";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { filtersState as paramState } from "../../utils/atoms";
import { employeeServiceApi } from "../../../../api/apiEmployees";
import { fetchAll } from "../../../../utils/fetchData";
import { getPaginationQueryKeys } from "../../../../utils/useQueryKeys";
import { IdType } from "../../../../api/types";

// ------------------------------ ТЕКУЩАЯ ДАТА

export const currentDateInterval = atom<DateRange<Date>>([startOfYear(today), endOfYear(today)]);

// ------------------------------ ФИЛЬТРЫ

export const absoluteInitialValues: FiltersType = {
  status: "",
  startDate: null,
  endDate: null,
};

export const filtersState = atom(absoluteInitialValues);

export type FiltersType = {
  status: string;
  startDate: Date | null;
  endDate: Date | null;
};
// ------------------------------ ДАННЫЕ ВАКЦИНАЦИИ - РАБОТНИКИ ПОДРАЗДЕЛЕНИЯ

const infectionEmployeesAtom = atomWithQuery((get) => {
  const { department } = get(paramState);
  const [start, end] = get(currentDateInterval);
  const { status } = get(filtersState);
  const { startIndex, size, query } = getPaginationQueryKeys(get);

  const args: InfectionEmployeesPropsType = {
    startDate: formatDateForBackend(start ?? now),
    endDate: formatDateForBackend(end ?? now),
    // infection: { id: infection },
    mainDepartmentCode: department,
    startIndex,
    size,
    status: status !== "" ? status : undefined,
    query,
  };

  return infectionEmployeesQuery(args);
});

export const infectionEmployeesState = loadable(infectionEmployeesAtom);

const infectionEmployeesQuery = (args: InfectionEmployeesPropsType) => {
  return {
    queryKey: [...Object.values(args), "infectionEmployeesState"],
    queryFn: () => fetchAll(() => employeeServiceApi().getInfectionData(args)),
    enabled: !!args.mainDepartmentCode && args.mainDepartmentCode !== ":department",
  };
};

export const useInfectionEmployeesKeys = () => {
  return {
    queryKey: ["infectionEmployeesState"],
    api: employeeServiceApi(),
  };
};

type InfectionEmployeesPropsType = {
  startDate: string;
  endDate: string;
  mainDepartmentCode: string;
  startIndex: number;
  size: number;
  status?: string;
  query?: string;
  infection?: IdType;
};
