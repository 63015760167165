import saveAs from "file-saver";
import React, { FC } from "react";
import { TrashButton } from "../Buttons";
import { Book } from "../Icons";
import { GeneralPropsType } from "./FilesInput";
import * as elements from "./FilesInput.styles";

export const FilesInputWasFile: FC<GeneralPropsType> = (props) => {
  const { name, filesValue, fileName, prefix, fileNameWithoutAccept } = props;
  const { fileAccept, element, multiFile, handleRemove } = props;

  const { Container, DocumentIconAndName, Title, Text, LoadBar } = elements;

  const hasFile = filesValue instanceof File && !filesValue.type.includes("image");

  return (
    <Container status="wasFile" multiFile={multiFile} as="div">
      {fileName ? (
        <DocumentIconAndName>
          <LoadBar status="loaded">
            <Book />
            {fileAccept}
          </LoadBar>

          {/* <FileLink file={file} name={name} /> */}

          <Text>
            <Title
              status="wasFile"
              onClick={() => (hasFile ? saveAs(filesValue, fileName) : undefined)}
              needDownload={hasFile}
            >
              {fileNameWithoutAccept}
            </Title>
          </Text>
        </DocumentIconAndName>
      ) : (
        <img alt="picture" src={`${prefix}${filesValue}`} />
      )}

      <TrashButton onClick={() => handleRemove(name, element)} />
    </Container>
  );
};
