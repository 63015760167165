import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { atomWithStorage, useAtomValue } from "jotai/utils";
import { appointmentApi } from "../../../api/apiAppointment";
import { diseaseApi, DiseaseType } from "../../../api/apiDisease";
import { employeesApi } from "../../../api/apiEmployees";
import { vaccinationFactApi, VaccinationFactType } from "../../../api/apiVaccination";
import { vaccineApi } from "../../../api/apiVaccine";
import { fetch, fetchById } from "../../../utils/fetchData";
import { getEqualsFilter } from "../../../utils/getQueryFilter";
import { getUserOrganizationKey } from "../../../utils/useQueryKeys";
import { VaccinationRecordViewType } from "../VaccinationRecord/hooks/useForms";
import { formatDateForBackend, now } from "../../../utils/workingWithDates";

// ------------------------------ ФОРМА - ЗАПИСЬ НА ВАКЦИНАЦИЮ

export const modalDataVaccinationRecordState = atom<VaccinationRecordViewType | undefined>(
  undefined
);

// ------------------------------ ФОРМА - ИНФЕКЦИОННОЕ ЗАБОЛЕВАНИЕ

export const modalDataInfectionState = atom<DiseaseType | undefined>(undefined);

// ------------------------------ ФОРМА - ФАКТ ВАКЦИНАЦИИ
export const modalDataFactState = atom<VaccinationFactType | undefined>(undefined);

// ------------------------------ ВАКЦИНАЦИЯ - ВЫБРАННЫЕ ЗАБОЛЕВАНИЯ
export const selectedInfectionsState = atomWithStorage<string[]>(
  "selectedInfectionDiseasesState",
  []
);
export const currentInfectionState = atom<string>("");

// ------------------------------ ПАРАМЕТРЫ - СОТРУДНИК

export const employeeIdState = atom<string>("");

// ------------------------------ ДАННЫЕ - ЗАПИСЬ НА ВАКЦИНАЦИЮ

export const employeeAppointmentsState = atomWithQuery((get) => {
  const id = get(employeeIdState);

  return employeeAppointmentsQuery(id);
});

const employeeAppointmentsQuery = (employeeId: string) => {
  const filter = [
    {
      group: "AND",
      conditions: [
        { property: "employee.id", operator: "=", value: employeeId },
        { property: "date", operator: ">=", value: formatDateForBackend(now) },
        {
          group: "OR",
          conditions: [
            { property: "removed", operator: "isNull" },
            { property: "removed", operator: "=", value: "false" },
          ],
        },
      ],
    },
  ];

  return {
    queryKey: [employeeId, "employeeAppointmentsState"],
    queryFn: () => fetch(() => appointmentApi().getAll({ filter })),
  };
};

// ------------------------------ ДАННЫЕ - ИНФЕКЦИОННЫЕ ЗАБОЛЕВАНИЯ

export const employeeDiseasesState = atomWithQuery((get) => {
  const id = get(employeeIdState);

  return employeeDiseasesQuery(id);
});

const employeeDiseasesQuery = (employeeId: string) => {
  const filter = [getEqualsFilter(employeeId, "employee.id")];

  return {
    queryKey: [employeeId, "employeeDiseasesState"],
    queryFn: () => fetch(() => diseaseApi().getAll({ filter })),
  };
};

export const useEmployeeDiseasesKeys = () => {
  const id = useAtomValue(employeeIdState);

  return {
    queryKey: [id, "employeeDiseasesState"],
    api: diseaseApi(),
  };
};

// ------------------------------ ДАННЫЕ - РАБОТНИК

export const employeeProfileState = atomWithQuery((get) => {
  const id = get(employeeIdState);

  return employeeProfileQuery(id);
});

const employeeProfileQuery = (employeeId: string) => {
  return {
    queryKey: [employeeId, "employeeProfileState"],
    queryFn: () => fetchById(() => employeesApi().get(employeeId)),
    enabled: !!employeeId,
  };
};

export const useEmployeeProfileKeys = () => {
  const employeeId = useAtomValue(employeeIdState);

  return {
    queryKey: [employeeId, "employeeProfileState"],
    api: employeesApi(),
  };
};

// ------------------------------ ДАННЫЕ - ВАКЦИНАЦИЯ

export const employeeVaccinationHistoryState = atomWithQuery((get) => {
  const id = get(employeeIdState);
  const selectedInfection = get(currentInfectionState);

  return employeeVaccinationHistoryQuery(id, selectedInfection);
});

const employeeVaccinationHistoryQuery = (employeeId: string, infectionId: string) => {
  const filter = [
    getEqualsFilter(employeeId, "employee.id"),
    getEqualsFilter(infectionId, "component.vaccine.infection.id"),
    getEqualsFilter("true", "vaccinated"),
  ];

  return {
    queryKey: [infectionId, "employeeVaccinationHistoryState"],
    queryFn: () => fetch(() => vaccinationFactApi().getAll({ filter })),
    enabled: !!employeeId && !!infectionId,
  };
};

export const useEmployeeVaccinationHistoryKeys = () => {
  const infectionId = useAtomValue(currentInfectionState);

  return {
    queryKey: [infectionId, "employeeVaccinationHistoryState"],
    api: vaccinationFactApi(),
  };
};

// ------------------------------ ДАННЫЕ - ЗАПИСИ НА ВАКЦИНАЦИЮ ДЛЯ ВЫБРАННОГО ЗАБОЛЕВАНИЯ

export const appointmentsByInfectionState = atomWithQuery((get) => {
  const id = get(employeeIdState);
  const selectedInfection = get(currentInfectionState);

  return appointmentsByInfectionQuery(id, selectedInfection);
});

const appointmentsByInfectionQuery = (employeeId: string, infectionId: string) => {
  const filter = [
    {
      group: "AND",
      conditions: [
        { property: "employee.id", operator: "=", value: employeeId },
        { property: "vaccinationPlan.infection.id", operator: "=", value: infectionId },
        {
          group: "OR",
          conditions: [
            { property: "removed", operator: "isNull" },
            { property: "removed", operator: "=", value: "false" },
          ],
        },
      ],
    },
  ];

  return {
    queryKey: [employeeId, infectionId, "appointmentsByInfectionState"],
    queryFn: () => fetch(() => appointmentApi().getAll({ filter, size: 0 })),
    enabled: !!employeeId && !!infectionId,
  };
};

// ------------------------------ ДАННЫЕ - ВАКЦИНЫ И КОМОНЕНТЫ

export const vaccineInfectionProfileState = atomWithQuery((get) => {
  const organization = getUserOrganizationKey(get);
  const infectionId = get(currentInfectionState);

  return {
    queryKey: [organization, infectionId, "vaccineInfectionProfileState"],
    queryFn: () => fetchById(() => vaccineApi().getByInfection(infectionId ?? "", organization)),
  };
});
