import React, { FC } from "react";
import { PopoverHover } from "../../PopoverHover";
import { PositionType } from "../../PopoverHover/PopoverHover";
import { PopoverHoverMethodsType, TypeType } from "./useTooltip";

export const Tooltip: FC<PropsType> = (props) => {
  const { type, popoverHoverMethods } = props;

  const { id, closePopoverHover, popoverHoverIsOpen } = popoverHoverMethods;
  const { anchorElement, popoverHoverText } = popoverHoverMethods;

  const anchorOriginHorizontal = type !== "subSectionButton" ? 44 : 276;
  const anchorOrigin = { vertical: "center", horizontal: anchorOriginHorizontal } as PositionType;
  const transformOrigin = { vertical: "center", horizontal: "left" } as PositionType;

  return (
    <>
      {popoverHoverText && (
        <PopoverHover
          id={id}
          isOpen={popoverHoverIsOpen}
          element={anchorElement}
          onClose={closePopoverHover}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          {popoverHoverText}
        </PopoverHover>
      )}
    </>
  );
};

type PropsType = {
  type: TypeType;
  popoverHoverMethods: PopoverHoverMethodsType;
};
