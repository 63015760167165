import React, { FC, useEffect } from "react";
import { useVaccinationStatisticPage } from "./useVaccinationStatisticPage";
import { Page } from "../../uiKit";
import { absoluteInitialValues } from "./utils/atoms";

export const VaccinationStatisticPage: FC = () => {
  const methods = useVaccinationStatisticPage();

  const { setDateSwitcherType, setFilters } = methods;

  useEffect(() => {
    setDateSwitcherType("year");

    return () => setFilters(absoluteInitialValues);
  }, []);

  return <Page methods={methods} />;
};
