import React, { FC } from "react";
import { Square, SquareWithCheck } from "../Icons";
import { SquareFilledWithCheck } from "../Icons";
import { Container } from "./Check.styles";

export const Check: FC<CheckPropsType> = (props) => {
  const { checked = false, variant = "outline", disabled = false } = props;

  return (
    <Container
      icon={<Square />}
      checkedIcon={variant === "outline" ? <SquareWithCheck /> : <SquareFilledWithCheck />}
      checked={checked}
      disabled={disabled}
    />
  );
};

export type CheckPropsType = {
  checked: boolean;
  variant?: CheckboxVariantType;
  disabled?: boolean;
};

export type CheckboxVariantType = "outline" | "filled";
