import styled from "@emotion/styled/macro";
import { button, buttonOnlyText, colors } from "../../../styles";

export const Container = styled.button<{ size: number; disabled: boolean }>`
  ${button};
  ${buttonOnlyText};

  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  flex-shrink: 0;
  flex-grow: 0;

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  :hover {
    background: ${({ disabled }) => (disabled ? colors.transparent : colors.redBackground)};
  }

  & > svg > path {
    fill: ${({ disabled }) => (disabled ? colors.grayscaleIcons : colors.redDefault)};
  }
`;
