import { MediumOtherType, nationalitiesApi } from "../../../../api/apiDictionaries";
import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { fetch } from "../../../../utils/fetchData";

export const modalDataState = atom<MediumOtherType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

export const nationalitiesState = atomWithQuery(() => ({
  queryKey: "nationalitiesState",
  queryFn: () => fetch(() => nationalitiesApi().getAll()),
}));

export const useNationalitiesQuery = () => ({
  queryKey: ["nationalitiesState"],
  api: nationalitiesApi(),
});
