import React from "react";
import { usePanel } from "../../hooks/usePanel";
import * as elements from "./Panel.styles";
import { PanelItem } from "./PanelItem";

export const Panel = () => {
  const { Container, Data, Name, NameAndSex, Sex } = elements;
  const { employeeDataForPanel, sex, fullName } = usePanel();

  return (
    <Container>
      <NameAndSex>
        <Name>{fullName}</Name>
        <Sex>{sex}</Sex>
      </NameAndSex>
      <Data hasMenuButton={false}>
        {employeeDataForPanel().map((datum, i) => (
          <PanelItem key={i} i={i} datum={datum} />
        ))}
      </Data>
    </Container>
  );
};
