import { atomWithQuery } from "jotai/query";
import { deletionReasonsApi, ReasonsPropsType } from "../../../api/apiDeletionReason";
import { fetchAll } from "../../../utils/fetchData";
import { endOfYear, startOfYear } from "date-fns";
import { atom } from "jotai";
import { DateRange } from "@mui/lab";
import { formatDateForBackend, today } from "../../../utils/workingWithDates";
import { loadable, useAtomValue } from "jotai/utils";
import { getPaginationKeys, usePaginationKeys } from "../../../utils/useQueryKeys";

// ------------------------------ ТЕКУЩАЯ ДАТА

export const currentDateInterval = atom<DateRange<Date>>([startOfYear(today), endOfYear(today)]);

// ------------------------------ ДАННЫЕ
export const deletionReasonsAtom = atomWithQuery((get) => {
  const [startDate, endDate] = get(currentDateInterval);
  const { page, size } = getPaginationKeys(get);

  const args = {
    since: startDate ? formatDateForBackend(startDate) : "",
    until: endDate ? formatDateForBackend(endDate) : "",
    page,
    size,
  };
  return deletionReasonsQuery(args);
});

export const deletionReasonsState = loadable(deletionReasonsAtom);

const deletionReasonsQuery = (props: ReasonsPropsType) => {
  const { since, size, page, until } = props;

  return {
    queryKey: [since, size, page, until, "deletionReasonsState"],
    queryFn: () => fetchAll(() => deletionReasonsApi().getAll(props)),
  };
};
export const useDeletionReasonsQuery = () => {
  const [startDate, endDate] = useAtomValue(currentDateInterval);
  const { page, size } = usePaginationKeys();

  const since = startDate ? formatDateForBackend(startDate) : "";
  const until = endDate ? formatDateForBackend(endDate) : "";

  return {
    queryKey: [since, until, page, size, "deletionReasonsState"],
    api: deletionReasonsApi(),
  };
};
