import { TypePropsType } from "../../InfographicPage";
import { atom, useAtom } from "jotai";
import { useAtomValue } from "jotai/utils";
import {
  currentDateInterval,
  departmentsStatsState,
  exportElementAtom,
  filtersState,
  organizationStatsState,
  summaryDataState,
  useOrganizationStatsQuery,
} from "../../utils/atoms";
import { HeaderNavigation } from "../../../../uiKit";
import React from "react";
import { UniversalTextButton } from "../../../../uiKit/Buttons";
import {
  INFOGRAPHIC,
  INFOGRAPHIC_DISEASES,
  INFOGRAPHIC_VACCINATION,
} from "../../../../constants/routes";
import {
  useDepartmentOptions,
  useInfectionDiseaseOptions,
  useOrganizationOptions,
} from "../../../../utils/getOptions";
import { currentUserAtom } from "../../../../atoms/atomCurrentUser";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const nullAtom = atom(null);

export const useData = (props: TypePropsType) => {
  const { type, page } = props;

  // ------------------------------ АТОМЫ

  const { roleModel, res } = useAtomValue(currentUserAtom);
  const summaryStatisticData = useAtomValue(summaryDataState);

  const loadableOrganizationStats = useAtomValue(
    type === "organization" ? organizationStatsState : nullAtom
  );
  const loadableDepartmentStats = useAtomValue(
    type === "department" ? departmentsStatsState : nullAtom
  );

  const [filters, setFilters] = useAtom(filtersState);
  const [exportElement, setExportElement] = useAtom(exportElementAtom);

  // ------------------------------ ХУКИ

  const navigate = useNavigate();
  const organizationOptions = useOrganizationOptions();
  const infectionOptions = useInfectionDiseaseOptions();
  const departmentOptions = useDepartmentOptions();

  // ------------------------------ КОНСТАНТЫ

  const title = page === "infection" ? "Заболеваемость" : "Вакцинация";
  const {
    department,
    canViewOrganizationChart,
    canViewDepartmentChart,

    canDownloadInfographicVaccination,
    canDownloadInfographicInfection,
  } = roleModel;

  const canView = type == "organization" ? canViewOrganizationChart : canViewDepartmentChart;
  const isDepartment = department ? res.department : undefined;
  const canDownload =
    page == "infection" ? canDownloadInfographicInfection : canDownloadInfographicVaccination;

  const isLoading =
    loadableOrganizationStats?.state === "loading" || loadableDepartmentStats?.state === "loading";

  const organizationStats =
    loadableOrganizationStats?.state === "hasData" ? loadableOrganizationStats.data : undefined;
  const departmentsStats =
    loadableDepartmentStats?.state === "hasData" ? loadableDepartmentStats.data.items : undefined;
  const totalCount =
    loadableDepartmentStats?.state === "hasData"
      ? loadableDepartmentStats.data.totalCount
      : undefined;

  const { api, queryKey } = useOrganizationStatsQuery();

  const selectedOrganizationName =
    organizationOptions.find(({ id }) => id === res.organization)?.name.replaceAll('"', "") ?? "—";
  const selectedInfectionName =
    infectionOptions.find(({ id }) => id === filters.infection)?.name ?? "—";
  const { infection } = filters;

  const selectedDepartmentName = isDepartment
    ? departmentOptions.find(({ id }) => id === isDepartment)?.name ?? "—"
    : undefined;

  // ------------------------------ КАРТОЧКА ДЕПАРТАМЕНТА

  const handleClickDepartment = (department: string) => {
    if (page === "infection") navigate(INFOGRAPHIC_DISEASES("users", infection, department));
    else navigate(INFOGRAPHIC_VACCINATION("users", infection, department));
  };

  // ------------------------------ ЭКСПОРТ

  const screenshot = (options: OptionsScreenshotType) => {
    const cropper = document.createElement("canvas").getContext("2d");
    if (!exportElement || !cropper) return;
    const finalWidth = options.width;
    const finalHeight = options.height;
    if (options.x) {
      options.width = finalWidth + options.x;
    }
    if (options.y) {
      options.height = finalHeight + options.y;
    }
    return html2canvas(exportElement, options).then((canvas) => {
      cropper.canvas.width = finalWidth;
      cropper.canvas.height = finalHeight;
      cropper.drawImage(canvas, -options.x, -options.y, finalWidth, finalHeight + options.y);
      return cropper.canvas;
    });
  };

  const exportChartsToPDF = async () => {
    if (!exportElement) return;

    const height = exportElement.clientHeight / 2;
    const pdf = new jsPDF({ orientation: "landscape" });
    let currentOffset = 0;

    do {
      await screenshot({
        width: 1122,
        height: currentOffset ? 780 : type === "department" ? 710 : 750,
        x: 0,
        y: currentOffset,
      })?.then((canvas) => {
        const imgData = canvas.toDataURL("image/png", 2.0);

        if (height - currentOffset > 50)
          if (!currentOffset) {
            pdf.addImage(imgData, "PNG", 0, 0, 290, type === "department" ? 190 : 210);
            currentOffset += 831 / 2 - (type === "department" ? 50 : 0);
          } else {
            pdf.addPage();
            pdf.addImage(imgData, "PNG", 0, 0, 297, 205);
            currentOffset += 792 / 2;
          }
        else currentOffset += 400;
      });
    } while (currentOffset < height);

    pdf.save(
      `График ${page === "infection" ? "заболеваемости" : "вакцинации"} по ${
        type === "department" ? "подразделениям" : "организации"
      }.pdf`
    );
  };

  // ------------------------------ ШАПКА - СКАЧИВАНИЕ

  const downloadButton = (
    <UniversalTextButton
      text="Скачать график"
      onClick={exportChartsToPDF}
      enabled={
        !!(
          !isLoading &&
          !!filters.infection &&
          ((type === "organization" && organizationStats) ||
            (departmentsStats && departmentsStats.length))
        )
      }
    />
  );

  // ------------------------------ ШАПКА - НАВИГАЦИЯ

  const navigation = (
    <HeaderNavigation
      title={title}
      section={{ name: "Инфографика", rout: INFOGRAPHIC }}
      needDateSwitcher={{
        dateIntervalAtom: currentDateInterval,
        typeInterval: "twoDate",
      }}
      buttonsStart={canDownload ? downloadButton : undefined}
    />
  );

  // ------------------------------ ОБЩИЕ ДАННЫЕ

  const summaryTitle = "Сводные данные";
  const summaryData = [
    { title: summaryStatisticData?.all ?? "—", description: "Кол-во работников" },
    {
      title:
        page === "vaccination"
          ? summaryStatisticData?.vaccinated ?? "0"
          : summaryStatisticData?.sick ?? "0",
      description: `${page === "vaccination" ? "Вакцинированы" : "Болеет"}`,
    },
    {
      title:
        page === "vaccination"
          ? summaryStatisticData?.vaccinated
            ? ((summaryStatisticData.vaccinated / summaryStatisticData.all) * 100).toFixed(1) + "%"
            : "0"
          : summaryStatisticData?.sick
          ? ((summaryStatisticData.sick / summaryStatisticData.all) * 100).toFixed(1) + "%"
          : "0",
      description: `Индекс ${page === "vaccination" ? "вакцинации" : "заболеваемости"} `,
    },
  ];

  return {
    isDepartment,
    type,
    setExportElement,

    navigation,

    organizationStats,
    departmentsStats,
    isLoading,

    api,
    queryKey,

    summaryTitle,
    summaryData,
    totalCount,

    handleClickDepartment,

    filters,
    setFilters,
    selectedOrganizationName,
    selectedDepartmentName,
    selectedInfectionName,
    canView,
  };
};

type OptionsScreenshotType = {
  width: number;
  height: number;
  x: number;
  y: number;
};
