import React, { ForwardedRef, forwardRef, ReactNode } from "react";
import { Container } from "./FormButton.styles";

export const FormButton = forwardRef(
  (props: PropsType, ref: ForwardedRef<HTMLButtonElement | null>) => {
    const { text, type, icon, formName, active = true, variant = "default", onClick } = props;

    return (
      <Container
        ref={ref}
        form={formName}
        type={type}
        active={active}
        disabled={!active}
        needIcon={!!icon}
        onClick={onClick}
        variant={variant}
      >
        {icon}
        <p>{text}</p>
      </Container>
    );
  }
);

FormButton.displayName = "FormButton";

type PropsType = {
  text: string;
  icon?: ReactNode;
  formName?: string;
  type: "button" | "submit";
  active?: boolean;
  variant?: "text" | "default";
  onClick?: () => void;
};
