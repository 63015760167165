import { AxiosResponse } from "axios";
import { FileLinkType } from "../utils/getFile";
import { MediumOtherType } from "./apiDictionaries";
import { FileType, IdType } from "./types";
import { izApi, izFilesApi } from "./api";
import { entitiesBase } from "./apiRequest";

export const fileApi = {
  upload: async (props: FormData): Promise<AxiosResponse<FileType>> =>
    izFilesApi.post("", props, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data; boundary=WebAppBoundary",
      },
    }),
  download: async (fileRef: string): Promise<AxiosResponse<BlobPart>> =>
    izFilesApi.get(`?fileRef=${fileRef}`, {
      responseType: "arraybuffer",
    }),
};

export const documentsApi = <D>() => entitiesBase<undefined, D>(izApi, "vs_Document");

export type DocumentsType = DocumentType[];

export type DocumentType = IdType & {
  file: File | FileLinkType | undefined;
  name: string;
  type: MediumOtherType;
  comment: string;
  createdDate: string;
};

export type DocumentsForFormType = DocumentForFormType[];

export type DocumentForFormType = Omit<DocumentType, "type"> & { type: string };
