import styled from "@emotion/styled/macro";
import { borders, colors, margins, text } from "../../styles";

export const Info = styled.p`
  ${margins({ p: "24" })};
  ${borders({ radius: 16 })};
  ${text({ size: 15, height: 20, weight: 400, color: colors.grayscaleBody })};

  background: ${colors.warningBackground};
`;
