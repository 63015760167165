import React, { FC } from "react";
import { useField } from "formik";

export const AdditionalFormField: FC<PropsType> = (props) => {
  const { name, equals, children } = props;

  const [{ value }] = useField(name);

  if (value === equals) return <>{children}</>;
  else return <></>;
};

type PropsType = {
  name: string;
  equals: string | boolean | number;
};
