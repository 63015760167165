import { useData } from "./hooks/useData";
import { useForms } from "./hooks/useForms";

export const useCalendarPage = () => {
  // ------------------------------ ХУКИ

  const data = useData();
  const { activeInfections, queryKey } = data;
  const forms = useForms(activeInfections, queryKey);

  return {
    ...data,
    ...forms,
  };
};
