import React, { FC } from "react";
import { InsideLabel, OutsideLabel } from "./Form.styles";

export const LabelField: FC<LabelType> = (props) => {
  const { outsideLabel, insideLabel } = props;

  if (!outsideLabel && !insideLabel) return null;

  if (outsideLabel) return <OutsideLabel>{outsideLabel}</OutsideLabel>;

  return <InsideLabel>{insideLabel}</InsideLabel>;
};

export type LabelType = {
  outsideLabel?: string;
  insideLabel?: string;
};
