import React, { FC } from "react";
import { VaccinationFactsType, VaccinationFactType } from "../../../../api/apiVaccination";
import { Record, RecordContainer } from "../../UserProfile.styles";
import { UniversalTextButton } from "../../../../uiKit/Buttons";
import { useUpdateAtom } from "jotai/utils";
import { addFormState } from "../../../../atoms/atoms";
import { HistoryRow } from "./HistoryRow";
import { QueryKeysType, QueryKeyType } from "../../../../utils/useQuery";
import { AxiosResponse } from "axios";
import { Form } from "../../../../uiKit";
import { useFactDeletionForm } from "../../Vaccinations/hooks/useFactDeletionForm";
import { Card } from "../Card/Card";

export const VaccinationHistory: FC<PropsType> = (props) => {
  const { history, getTypeRecord } = props;
  const { queryKey, queryKeyForOneEntity, api, canEdit } = props;

  const factDeletionMethods = useFactDeletionForm();
  const { modalData, setModalData } = factDeletionMethods;

  const setAddForm = useUpdateAtom(addFormState);

  const handleDelete = (idFact: string) => {
    const fact = history?.find(({ id }) => id === idFact);
    setModalData(fact);
  };

  return (
    <>
      <Card
        title="История вакцинаций"
        action={
          canEdit && (
            <UniversalTextButton
              text="Добавить факт вакцинации"
              type="outlined"
              onClick={() => setAddForm(true)}
            />
          )
        }
      >
        {history && history.length ? (
          <RecordContainer>
            {history.map((fact) => (
              <HistoryRow
                canEdit={canEdit}
                key={fact.id}
                fact={fact}
                type={getTypeRecord(fact)}
                onDelete={handleDelete}
              />
            ))}
          </RecordContainer>
        ) : (
          <Record type="empty">Данных о прошлых вакцинациях нет</Record>
        )}
      </Card>
      {modalData && (
        <Form
          methods={{ ...factDeletionMethods, queryKey, queryKeyForOneEntity, api }}
          isEdit
          id={modalData.id}
        />
      )}
    </>
  );
};

type PropsType = {
  history?: VaccinationFactsType;
  getTypeRecord: (fact: VaccinationFactType) => "vaccine" | "revaccine";
  queryKey: QueryKeyType;
  canEdit: boolean;
  queryKeyForOneEntity: QueryKeysType;
  api: { delete?: (id: string) => Promise<AxiosResponse<unknown, unknown>> };
};
