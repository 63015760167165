import { colors } from "./styles";

const { white, grayscaleLabel, grayscaleInput, grayscaleLine } = colors;

export const charA = 65;

// ------------------------------ шрифты

const fontHead = { sz: 14, bold: true, color: { rgb: `${grayscaleLabel}`.slice(1) } };
const fontTitle = { bold: true, color: { rgb: `${white}`.slice(1) } };
const fontSubtitle = { bold: true, color: { rgb: `${grayscaleLabel}`.slice(1) } };
const fontData = { sz: 10, bold: false, color: { rgb: `${grayscaleLabel}`.slice(1) } };

// ------------------------------ заливки

const colorTitle = { fgColor: { rgb: `${grayscaleLabel}`.slice(1) } };
const colorSubtitle = { fgColor: { rgb: `${grayscaleLine}`.slice(1) } };

// ------------------------------ границы

const borderW = { style: "thin", color: { rgb: `${grayscaleInput}`.slice(1) } };
const bordersWhite = { top: borderW, bottom: borderW, left: borderW, right: borderW };

const borderB = { style: "thin", color: { rgb: `${grayscaleLabel}`.slice(1) } };
const bordersBlue = { top: borderB, bottom: borderB, left: borderB, right: borderB };

// ------------------------------ выравнивания

const alignmentCenter = { vertical: "top", horizontal: "center", wrapText: true };
const alignmentTotalCenter = { vertical: "center", horizontal: "center", wrapText: true };
const alignmentVerticalCenter = { vertical: "center", horizontal: "left", wrapText: true };
const alignment = { vertical: "top", horizontal: "left", wrapText: true };

// ------------------------------ ГОТОВЫЕ СТИЛИ

export const headerStyle = { font: fontHead, alignment: alignmentVerticalCenter };
export const subHeaderStyle = { font: fontSubtitle, alignment: alignment };
export const titleRowStyle = {
  fill: colorTitle,
  font: fontTitle,
  alignment: alignmentTotalCenter,
  border: bordersWhite,
};
export const subTitleRowStyle = {
  font: fontSubtitle,
  alignment: alignmentCenter,
  border: bordersBlue,
};
export const subTitleStyle = {
  fill: colorSubtitle,
};
export const dataStyle = { font: fontData, alignment: alignment, border: bordersBlue };
export const dataCenterStyle = { font: fontData, alignment: alignmentCenter, border: bordersBlue };
