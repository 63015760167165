import { useData } from "./useData";
import { DiseaseClassType } from "../../../../api/apiDictionaries";

export const useTable = () => {
  // ------------------------------ ХУКИ

  const { canEdit } = useData();

  // ------------------------------ ТАБЛИЦА - ЗАГОЛОВКИ

  const titlesInit = [
    { id: 1, title: "№", name: "number" },
    { id: 2, title: "Код МКБ-10", name: "code" },
    { id: 3, title: "Наименование", name: "name" },
    { id: 4, title: "Описание", name: "description" },
  ];

  // ------------------------------ ТАБЛИЦА - ШИРИНЫ ТАБЛИЦ

  const gridsInit = [
    ...(canEdit ? [{ titleId: null, size: "56px" }] : []),
    { titleId: 1, size: "76px" },
    { titleId: 2, size: "1fr" },
    { titleId: 3, size: "1fr" },
    { titleId: 4, size: "1fr" },
    ...(canEdit ? [{ titleId: null, size: "56px" }] : []),
  ];

  // ------------------------------ ТАБЛИЦА - ДАННЫЕ ДЛЯ СТРОКИ

  const object = ({ name, description, code }: DiseaseClassType, number?: number): ObjectType => ({
    ...(number ? { number } : {}),
    code,
    name,
    description: description || "—",
  });

  return {
    titlesInit,
    gridsInit,

    object,
  };
};

type ObjectType = Omit<DiseaseClassType, "id"> & { number?: number };
