import { MediumOtherType, treatmentPlacesApi } from "../../../../api/apiDictionaries";
import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { fetch } from "../../../../utils/fetchData";

export const modalDataState = atom<MediumOtherType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

export const treatmentPlacesState = atomWithQuery(() => ({
  queryKey: "treatmentPlacesState",
  queryFn: () => fetch(() => treatmentPlacesApi().getAll()),
}));

export const useTreatmentPlacesQuery = () => ({
  queryKey: ["treatmentPlacesState"],
  api: treatmentPlacesApi(),
});
