import styled from "@emotion/styled/macro";
import { InputLabel } from "@mui/material";
import { borders, colors, flex, margins, text } from "../../styles";

export const FormContainer = styled.div``;

export const TitleField = styled.h5`
  ${text({ size: 17, height: 27, weight: 700, color: colors.grayscaleBeautifulBlack })};
  ${margins({ m: "40 0 8" })};

  display: block;

  :first-of-type {
    ${margins({ m: "0 0 8" })};
  }
`;

export const FormSubTitle = styled.div`
  ${flex({ gap: 16, horizontal: "start" })};
  ${text({ size: 17, height: 24, weight: 700 })};
  ${margins({ m: "24 0 8" })};

  :first-of-type {
    ${margins({ m: "0 0 8" })};
  }
`;

export const InfoCardContainer = styled.div<{ hasError: boolean }>`
  ${({ hasError }) =>
    borders({ radius: 16, color: hasError ? colors.redDefault : colors.grayscaleInput })};
  ${margins({ m: "16 0", p: "20" })};

  background: ${({ hasError }) => (hasError ? colors.redBackground : colors.grayscaleOffWhite)};
`;

export const TitleInfoCard = styled.h4`
  ${text({ size: 15, height: 19, weight: 700, color: colors.black })};
  ${margins({ m: "0 0 8" })};
`;

export const TextInfoCard = styled.p`
  ${text({ size: 15, height: 20, weight: 400, color: colors.grayscaleLabel })};
  ${margins({ m: "0" })};
`;

export const FieldsBlock = styled.div`
  ${flex({ gap: 16, isColumn: true })};
  ${margins({ p: "0 0 64 0" })};
`;

export const OutsideLabel = styled(InputLabel)`
  ${margins({ m: "0 0 8 0" })}
  ${text({ size: 17, height: 24, weight: 700, color: colors.grayscaleBeautifulBlack })}
`;

export const InsideLabel = styled(InputLabel)`
  ${text({ size: 12, height: 14, weight: 600, color: colors.grayscaleLabel })};
  ${margins({ m: "0" })};

  position: absolute;
  left: 16px;
  top: 6px;
  z-index: 1;
  transform: none;
`;

export const FormSpacer = styled.div``;

export const FieldContainer = styled.div``;
