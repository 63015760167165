import React, { FC, useEffect } from "react";
import { Form, Page, Table } from "../../../uiKit";
import { useCalendarDay } from "./useCalendarDay";
import { currentDateDay, paramsState } from "./utils/atoms";
import { useUpdateAtom } from "jotai/utils";
import { parseDate } from "../../../utils/workingWithDates";
import { useParams } from "react-router-dom";
import { dateSwitcherTypeState } from "../../../atoms/atoms";
import { SummaryDayInfo } from "./SummaryDayInfo/SummaryDayInfo";
import { useAppointmentForms } from "./hooks/useAppointmentForms";
import { useVaccinationFactForms } from "./hooks/useVaccinationFactForms";
import { SimpleConfirmModal } from "../../../uiKit/ConfirmModal/SimpleConfirmModal";

export const CalendarDayPage: FC = () => {
  const methods = useCalendarDay();

  const { dayData, queryKey, queryKeyForOneEntity } = methods;

  const factFormMethods = useVaccinationFactForms(dayData, queryKey);
  const { isOpenFactForm, isEditFactForm, idFact } = factFormMethods;

  const appointmentFormMethods = useAppointmentForms(dayData, queryKey, queryKeyForOneEntity);
  const { isOpenAppointmentForm, isEditAppointmentForm, idAppointment } = appointmentFormMethods;
  const { handleClose, handleConfirmPeriodicity, handleConfirmAge, confirmText, formPassed } =
    appointmentFormMethods;

  const setCurrentDay = useUpdateAtom(currentDateDay);
  const setParams = useUpdateAtom(paramsState);
  const setDateSwitcherType = useUpdateAtom(dateSwitcherTypeState);
  const params = useParams();

  const date = params["date"];
  const infectionId = params["infection"];
  const healthCenterId = params["center"];

  useEffect(() => {
    setParams({ infectionId, healthCenterId });
    setDateSwitcherType("day");
    setCurrentDay(date ? parseDate(date) : null);
  }, []);

  useEffect(() => {
    if (dayData)
      setParams((prevState) => ({ ...prevState, vaccinationPlanId: dayData.vaccinationPlan.id }));
  }, [dayData]);

  return (
    <Page methods={methods}>
      <SummaryDayInfo day={dayData} />
      <Table methods={methods} panelHeight="114px" needPagination />
      {isOpenAppointmentForm && (
        <Form
          methods={appointmentFormMethods}
          isEdit={isEditAppointmentForm}
          withoutChanges
          id={idAppointment}
        />
      )}
      {isOpenFactForm && <Form methods={factFormMethods} isEdit={isEditFactForm} id={idFact} />}
      {formPassed && confirmText && (
        <SimpleConfirmModal
          questionText={confirmText}
          confirmText="Далее"
          isOpen
          onClose={handleClose}
          onApprove={formPassed === "age" ? handleConfirmAge : handleConfirmPeriodicity}
        />
      )}
    </Page>
  );
};
