import { DateSwitcherTypeType } from "../atoms/atoms";

export const monthNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const monthNames = [
  ["Январь", "января"],
  ["Февраль", "февраля"],
  ["Март", "марта"],
  ["Апрель", "апреля"],
  ["Май", "мая"],
  ["Июнь", "июня"],
  ["Июль", "июля"],
  ["Август", "августа"],
  ["Сентябрь", "сентября"],
  ["Октябрь", "октября"],
  ["Ноябрь", "ноября"],
  ["Декабрь", "декабря"],
];

export const weekday = ["пн", "вт", "ср", "чт", "пт", "сб", "вс"];

export const arrYears = ["год", "года", "лет"];
export const arrMonths = ["месяц", "месяца", "месяцев"];
export const arrDays = ["день", "дня", "дней"];

export const periodName = {
  year: "Год",
  quarter: "Квартал",
  month: "Месяц",
  period: "Период",
} as Record<DateSwitcherTypeType, string>;
