import { useField } from "formik";
import { useAtom } from "jotai";
import React, { ChangeEvent, FC, RefObject, useEffect, useRef, useState } from "react";
import { statusFileInputState } from "../../atoms/atoms";
import { FileList as FileListView } from "./FileList";
import { Input } from "./FilesInput.styles";
import { FilesInputEmpty } from "./FilesInputEmpty";
import { FilesInputError } from "./FilesInputError";
import { FilesInputLoaded } from "./FilesInputLoaded";
import { FilesInputProgress } from "./FilesInputProgress";
import { FilesInputSuccess } from "./FilesInputSuccess";
import { FilesInputWasFile } from "./FilesInputWasFile";
import { useFilesInput } from "./useFilesInput";

export const FilesInput: FC<PropsType> = (props) => {
  const { name, label, accept, maxSize, description } = props;
  const { prefix, multiFile = false, displayNone = false } = props;

  const [{ value }] = useField<FileList | undefined | string>(name);

  const [status, setStatus] = useAtom(statusFileInputState);

  const [files, setFiles] = useState<File[]>([]);

  const ref = useRef<HTMLInputElement>(null);

  const { error, progress, addFile, handleRemove } = useFilesInput({ accept, maxSize });

  useEffect(() => {
    !!value && setStatus("wasFile");

    return () => setStatus("empty");
  }, []);

  const onChange = (evt: ChangeEvent) => {
    const files = (evt.target as HTMLInputElement).files;

    if (!files) return;

    // const x = Array.from(files).map((file) => ({ file, type: "", name: '' }));

    // form.setValue('files', x)

    // {
    //   name:'',
    //   files: [
    //     { file: file, type: ''},
    //     { file: file, type: ''},
    //     { file: file, type: ''},
    //     { file: file, type: ''},
    //   ]
    // }

    // form.g

    // 'files.0.file'
    // 'files.0.type'

    addFile(name, files);
  };

  const isFile = !!value && typeof value !== "string";

  const filesArr = isFile ? Array.from(value) : [];
  const fileName = filesArr[0]?.name;
  const fileNameWithoutAccept = fileName?.slice(0, fileName.lastIndexOf("."));
  const fileAccept = fileName?.slice(fileName.lastIndexOf("."));
  const fileSize = `${Math.round(filesArr[0]?.size / (1024 * 1024))}Мб`;

  const args = {
    name,
    label,
    filesValue: value,
    fileName,
    fileNameWithoutAccept,
    fileSize,
    fileAccept,
    accept,
    prefix,
    description,
    element: ref,
    multiFile,
    progress,
    error,
    addFile,
    handleRemove,
  };

  return (
    <div style={{ display: displayNone ? "none" : "block" }}>
      {status === "error" ? (
        <FilesInputError {...args} />
      ) : status === "progress" ? (
        <FilesInputProgress {...args} />
      ) : status === "success" ? (
        <FilesInputSuccess {...args} />
      ) : status === "loaded" ? (
        multiFile ? (
          <FilesInputEmpty {...args} />
        ) : (
          <FilesInputLoaded {...args} />
        )
      ) : status === "wasFile" ? (
        multiFile ? (
          <FilesInputEmpty {...args} />
        ) : (
          <FilesInputWasFile {...args} />
        )
      ) : (
        <FilesInputEmpty {...args} />
      )}

      {multiFile && (status === "loaded" || status === "wasFile") ? (
        <FileListView name={name} files={files} setFiles={setFiles} />
      ) : null}

      <Input
        ref={ref}
        name={name}
        type="file"
        accept={accept.join(",")}
        onChange={onChange}
        multiple={multiFile}
      />
    </div>
  );
};

type PropsType = {
  name: string;
  label: string;
  accept: string[];
  prefix?: string;
  maxCount?: number;
  maxSize?: number;
  maxDimension?: number;
  documentName?: string;
  description?: string;
  sourceFile?: File;
  multiFile?: boolean;
  displayNone?: boolean;
};

export type GeneralPropsType = {
  name: string;
  label: string;
  filesValue?: FileList | string;
  fileName?: string;
  fileNameWithoutAccept?: string;
  fileSize?: string;
  fileAccept?: string;
  accept: string[];
  prefix?: string;
  description?: string;
  element: RefObject<HTMLInputElement>;
  multiFile: boolean;
  progress: number;
  error: string;
  addFile: (name: string, file: File | undefined) => void;
  handleRemove: (name: string, ref: RefObject<HTMLInputElement>) => void;
};
