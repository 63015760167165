import styled from "@emotion/styled/macro";
import { TextField, TextFieldProps } from "@mui/material";
import { borders, colors, flex, margins, text } from "../../styles";

export const Container = styled.div`
  ${flex({ gap: 8, vertical: "center" })};
  ${margins({ m: "0 0 16" })};

  * {
    cursor: inherit !important;
  }
`;

export const Input = styled(TextField)<InputPropsType>`
  ${borders({ none: true, radius: 16 })};

  width: ${({ internal }) => (internal ? "350px;" : "100%")};
  height: ${({ multiline }) => (multiline ? "150px" : "56px")};
  background: ${({ haserror }) => (haserror ? colors.redBackground : colors.grayscaleInput)};
  outline: none;

  .MuiInputLabel-shrink,
  .MuiInputLabel-root {
    ${text({ size: 12, height: 14, weight: 600, color: colors.grayscaleLabel })};
    ${margins({ m: "0" })};

    max-width: 100%;
    left: 16px;
    top: 6px;
    transform: none;

    &.Mui-focused {
      color: ${colors.grayscaleLabel};
    }
  }

  .MuiOutlinedInput {
    &-notchedOutline {
      ${borders({ radius: 16 })};

      background: ${colors.white};
      border-color: ${colors.grayscaleInput} !important;
    }
    &-input.Mui-disabled {
      -webkit-text-fill-color: ${colors.grayscaleBeautifulBlack};
      z-index: 1;
    }
  }

  .MuiFilledInput-root {
    ${text({ size: 16, height: 24, weight: 400, color: colors.grayscaleAsh })};

    background: none;

    &.Mui-focused {
      background: none;
    }

    &.MuiFilledInput-root {
      ${({ multiline }) =>
        multiline ? `overflow-y: scroll; ${margins({ m: "16", p: "0" })};` : ""};

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-thumb {
        ${borders({ totalRadius: true })};

        background: ${colors.grayscaleLine};
      }

      &.Mui-disabled {
        background: ${colors.transparent};
        &:before {
          border-bottom-style: none;
        }
      }

      input {
        ${({ haslabel }) => margins({ m: haslabel ? "22 16 8" : "16", p: "0" })};

        -moz-appearance: textfield;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        ${margins({ m: "0" })};

        -webkit-appearance: none;
      }
    }

    textarea::placeholder,
    input::placeholder {
      ${text({ size: 16, height: 24, weight: 400, color: colors.grayscalePlaceholder })};
      ${margins({ m: "0" })};

      opacity: 1;
    }

    &:hover {
      background: none;
    }

    &:hover:not(.Mui-disabled):before,
    &:after,
    &:before {
      ${borders({ none: true })};
    }
  }

  label[data-shrink="false"],
  + .MuiInputBase-formControl,
  .css-10botns-MuiInputBase-input-MuiFilledInput-input::-webkit-input-placeholder {
    opacity: 1 !important;
  }
` as (
  props: TextFieldProps & { haslabel: number; haserror: number; internal: number }
) => JSX.Element;

export const Error = styled.p<{ multiline: boolean }>`
  ${text({ size: 12, height: 14, weight: 400, color: colors.redDefault })};

  position: absolute;
  top: ${({ multiline }) => (multiline ? "50px" : "56px")};
  left: 16px;
`;

type InputPropsType = {
  multiline: boolean;
  haslabel: number;
  haserror: number;
  internal: number;
};
