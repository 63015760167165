import styled from "@emotion/styled/macro";
import { borders, colors, flex, margins, text } from "../../../styles";

const { transparent, grayscaleAsh, indigo } = colors;

export const Container = styled.a`
  ${flex({ vertical: "center" })};
  ${margins({ m: "0 0 0 4" })};

  cursor: pointer;
`;

export const Icon = styled.div`
  ${margins({ p: "0 8 0 0" })};

  svg {
    :hover {
      transition: transform 1.5s cubic-bezier(0, 0.95, 0.83, -0.52);
      transform: rotate(360deg);
      transform-origin: 43% center;
    }
  }
`;

export const Text = styled.div<{ isExpand?: boolean }>`
  ${flex({ gap: 2, isColumn: true, vertical: "center" })};
  ${({ isExpand }) => (isExpand ? borders({ side: "left", color: grayscaleAsh }) : undefined)};
  ${margins({ p: "0 0 0 8" })};

  letter-spacing: 0.1em;
`;

export const TextMedTech = styled.p<{ gradientText?: string }>`
  ${text({ size: 13, height: 16, weight: 700, color: grayscaleAsh, uppercase: true })};

  ${({ gradientText }) =>
    gradientText
      ? `background: ${gradientText}; -webkit-background-clip: text; -moz-background-clip: text; background-clip: text; color: ${transparent};`
      : undefined};
`;

export const TextRosatom = styled.p`
  ${text({ size: 10, height: 13, weight: 400, color: indigo, uppercase: true })};
`;
