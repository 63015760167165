import styled from "@emotion/styled/macro";
import { borders, colors, flex, margins, text } from "../../styles";

export const Container = styled.div`
  ${flex({ gap: 16, horizontal: "evenly" })};
`;

export const Title = styled.p`
  ${text({ weight: 700, size: 20, color: colors.grayscaleBeautifulBlack, height: 26 })};
`;

export const InfoContainer = styled.div`
  ${margins({ p: "16 24" })};
  width: 344px;
  max-height: 456px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    background: ${colors.white};
    width: 24px;
  }

  &::-webkit-scrollbar-thumb {
    ${borders({ totalRadius: true, width: 8, color: colors.white })};

    background: ${colors.grayscaleLabelOpacity77};
  }
`;

export const InfoText = styled.p`
  ${text({ weight: 400, size: 15, color: colors.grayscaleAsh, height: 19 })};
`;

export const InfoIcon = styled.div``;
