import React, { FC } from "react";
import { usePopoverHover } from "../../../../uiKit/PopoverHover/usePopoverHover";
import { DataItem, Description, Title } from "./Panel.styles";
import { PopoverHover } from "../../../../uiKit";

export const PanelItem: FC<PropsType> = (props) => {
  const { i, datum } = props;
  const { title, description, needHint = false } = datum;

  const { closePopoverHover, propsPopoverHover, openPopoverHover } = usePopoverHover({
    anchorOrigin: { vertical: "bottom", horizontal: "center" },
    transformOrigin: { vertical: 10, horizontal: "center" },
  });

  return (
    <DataItem key={i} hasMenuButton={false}>
      <Title>{title}</Title>
      <Description
        aria-haspopup="true"
        onMouseEnter={openPopoverHover}
        onMouseLeave={closePopoverHover}
      >
        {description}
      </Description>

      {needHint && <PopoverHover {...propsPopoverHover}>{description}</PopoverHover>}
    </DataItem>
  );
};

type PropsType = {
  i: number;
  datum: {
    title: string;
    description: string;
    needHint?: boolean;
  };
};
