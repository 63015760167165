import styled from "@emotion/styled/macro";
import { borders, colors, flex, margins, text } from "../../styles";

export const SummaryLabel = styled.p<{ bold: boolean }>`
  ${({ bold }) =>
    bold
      ? text({ weight: 800, size: 20, height: 26 })
      : text({ weight: 700, size: 17, height: 24 })};
`;

export const Container = styled.div`
  ${flex({ gap: 12, isColumn: true })};
  ${margins({ p: "16" })};
  ${borders({ radius: 16 })};
  background: ${colors.white};
`;

export const Details = styled.div`
  ${flex({ gap: 24 })};
`;

export const CountContainer = styled.div<{ minWidth: number }>`
  ${flex({ isColumn: true })};
  ${({ minWidth }) => `min-width: ${minWidth}px`};
`;

export const Count = styled.label`
  ${text({ weight: 400, size: 32, height: 42 })};
`;
export const Description = styled.span`
  ${text({ weight: 400, size: 13, height: 17, color: colors.grayscalePlaceholder })};
`;
