import * as muiElements from "@mui/material";
import { AutocompleteRenderInputParams, createFilterOptions } from "@mui/material";
import React, { FC, HTMLAttributes, SyntheticEvent } from "react";
import { colors } from "../../styles";
import { OptionType } from "../../utils/getOptions";
import { FieldContainer } from "../Form/Form.styles";
import { LabelField } from "../Form/LabelField";
import { TriangleDown } from "../Icons";
import { SelectAutocompleteInputPropsType } from "./SelectAutocompleteInput";
import * as elements from "./SelectAutocompleteInput.styles";

export const SelectAutocomplete: FC<PropsType> = (props) => {
  const { options, placeholder = "Выберите из списка", iconStart, limit } = props;
  const { disabled, filter = false, color = colors.grayscaleInput, onBlur } = props;

  const { size = "", name = "", hasError = false, value, onChange } = props;
  const { insideLabel, outsideLabel } = props;

  const { Item, MenuItemWrapper, PopperComponent, PaperProps } = elements;
  const { MenuItem, TextField, InputAdornment } = muiElements;

  const selectedOption = options.find(({ id }) => id === value);

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      name={name}
      {...params}
      label={insideLabel}
      placeholder={placeholder}
      InputProps={
        iconStart
          ? {
              ...params.InputProps,
              startAdornment: <InputAdornment position="start">{iconStart}</InputAdornment>,
            }
          : params.InputProps
      }
      InputLabelProps={{ shrink: true }}
    />
  );

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: OptionType) =>
    option.id !== "" ? (
      <MenuItem {...props} key={option.id}>
        <MenuItemWrapper>{option.name}</MenuItemWrapper>
      </MenuItem>
    ) : null;

  return (
    <FieldContainer>
      <LabelField outsideLabel={outsideLabel} />
      <Item
        value={selectedOption || null}
        options={options}
        getOptionLabel={(option) => option?.name as string}
        onChange={onChange}
        onBlur={onBlur}
        filterOptions={limit ? createFilterOptions({ limit: limit }) : undefined}
        renderInput={renderInput}
        renderOption={renderOption}
        popupIcon={<TriangleDown />}
        closeText=""
        openText=""
        haserror={+hasError}
        PopperComponent={PopperComponent}
        componentsProps={{ paper: PaperProps(size) }}
        noOptionsText="Нет совпадений"
        isOptionEqualToValue={(option, value) => option.id === value.id}
        defaultValue=""
        haslabel={+!!insideLabel}
        second={+filter}
        disabled={disabled}
        color={color}
      />
    </FieldContainer>
  );
};

type PropsType = Omit<SelectAutocompleteInputPropsType, "name"> & {
  name?: string;
  hasError?: boolean;
  value: string;
  onChange: (
    _evt: SyntheticEvent<Element, Event>,
    arg: string | OptionType | (string | OptionType)[] | null
  ) => void;
  onBlur?: (e: React.FocusEvent) => void;
};
