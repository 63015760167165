import { useAtomValue } from "jotai/utils";
import React, { Children, cloneElement, FC, isValidElement, ReactNode } from "react";
import { searchQueryState } from "../../../atoms/atoms";
import { TableBodyItem, TableBodyItemText, TableBodyItemTextWrapper } from "./TableBodyRow.styles";

export const TableBodyRowItem: FC<PropsType> = (props) => {
  const { data } = props;

  const searchQuery = useAtomValue(searchQueryState);

  const escapeRegex = (string: string) => string.replace(/[/\-\\^$*+?.()|[\]{}]/g, "\\$&");

  const itemWithSearch = (item: string[] | string | number | JSX.Element) => {
    const regExp = new RegExp(`(${escapeRegex(searchQuery)})`, "gi");

    const highlight = (part: string | JSX.Element, i: number) =>
      typeof part === "string" && part.toLowerCase() === searchQuery.toLowerCase() ? (
        <span key={i}>{part}</span>
      ) : (
        part
      );

    if (Array.isArray(item)) {
      item.map((itemNew) => {
        const parts = String(itemNew).split(regExp);

        return parts.map((part, i) => highlight(part, i));
      });
    } else if (typeof item === "string" || typeof item === "number") {
      const parts = String(item).split(regExp);

      return parts.map((part, i) => highlight(part, i));
    } else {
      const traverse = (node: ReactNode): ReactNode => {
        if (typeof node === "string") {
          const parts = node.split(regExp);

          return parts.map((part, i) => highlight(part, i));
        }

        if (isValidElement(node)) {
          const children = Children.map(node.props.children, (child) => traverse(child));

          return cloneElement(node, {}, children);
        }

        return node;
      };

      return traverse(item);
    }
  };

  return (
    <TableBodyItem>
      {typeof data === "number" || typeof data === "string" ? (
        <TableBodyItemText>{itemWithSearch(data)}</TableBodyItemText>
      ) : isValidElement(data) ? (
        itemWithSearch(data)
      ) : Array.isArray(data) ? (
        <TableBodyItemTextWrapper>
          {data.map((datum, i) => (
            <TableBodyItemText key={i}>{itemWithSearch(datum)}</TableBodyItemText>
          ))}
        </TableBodyItemTextWrapper>
      ) : (
        data
      )}
    </TableBodyItem>
  );
};

type PropsType = {
  data: ReactNode;
};
