import { useField, useFormikContext } from "formik";
import React, { FC } from "react";
import { Check } from "../Check";
import { CheckboxVariantType } from "../Check/Check";
import { Container } from "./CheckInput.styles";

export const CheckInput: FC<PropsType> = (props) => {
  const { name, label = "", variant, defaultValue } = props;
  const { disabled = false, cursive = false } = props;

  const { setFieldValue } = useFormikContext<Record<string, boolean>>();

  const [{ value = !!defaultValue }] = useField<boolean>(name);

  return (
    <Container
      weight={cursive ? 400 : 700}
      onClick={() => {
        if (!disabled) setFieldValue(name, !value);
      }}
    >
      <Check checked={value} variant={variant} disabled={disabled} />
      {label}
    </Container>
  );
};

type PropsType = {
  name: string;
  label?: string;
  variant?: CheckboxVariantType;
  cursive?: boolean;
  disabled?: boolean;
  defaultValue?: boolean;
};
