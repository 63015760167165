import {
  currentDateInterval,
  useVaccinationStaticKeys,
  vaccinationStaticState,
} from "../utils/atoms";
import { useAtomValue, useUpdateAtom } from "jotai/utils";
import { currentUserAtom } from "../../../atoms/atomCurrentUser";
import { HeaderNavigation } from "../../../uiKit";
import React from "react";
import { InfectionList } from "../../CalendarPages/CalendarPage/InfectionList/InfectionList";
import { UniversalTextButton } from "../../../uiKit/Buttons";
import { Download } from "../../../uiKit/Icons";
import { dateSwitcherTypeState, exportFormState } from "../../../atoms/atoms";
import { activeVaccinationState } from "../../AdministrationPages/VaccinationSettings/utils/atoms";
import { useLoadableData } from "../../../utils/useLoadableData";
import { selectedInfectionState } from "../../CalendarPages/CalendarPage/utils/atoms";
import { filtersState as filtersInfographic } from "../../InfographicPages/utils/atoms";

export const useData = () => {
  // ------------------------------ АТОМЫ

  const { roleModel, res } = useAtomValue(currentUserAtom);
  const setDateSwitcherType = useUpdateAtom(dateSwitcherTypeState);
  const setExportFormIsOpen = useUpdateAtom(exportFormState);
  const selectedInfection = useAtomValue(selectedInfectionState);
  const { infection: infectionInfographic } = useAtomValue(filtersInfographic);

  const activeInfections = useAtomValue(activeVaccinationState);

  const { loadableData, data, totalCount } = useLoadableData(vaccinationStaticState, []);

  // ------------------------------ ХУКИ

  const { queryKey, api } = useVaccinationStaticKeys();

  // ------------------------------ КОНСТАНТЫ

  const titlePage = "Данные о вакцинации";
  const {
    canViewVaccinationStatistic: canView,
    osr,
    osrCa,
    superUser,
    canViewEmployeeCard,
  } = roleModel;

  const isOsr = osr || osrCa || superUser;

  const currentInfection = selectedInfection !== "" ? selectedInfection : infectionInfographic;
  const currentSettings = activeInfections.find(
    ({ infection }) => infection.id === currentInfection
  );

  // ------------------------------ ШАПКА - СПИСОК БОЛЕЗНЕЙ

  const headerFooter = <InfectionList activeInfections={activeInfections} />;

  // ------------------------------ ШАПКА

  const exportButton = (
    <UniversalTextButton
      text="Экспортировать"
      iconStart={<Download />}
      type="secondary"
      onClick={() => setExportFormIsOpen(true)}
    />
  );

  const navigation = (
    <HeaderNavigation
      title={titlePage}
      needSearchButton
      needFilterButton
      needDateSwitcher={{ dateIntervalAtom: currentDateInterval, typeInterval: "oneDate" }}
      buttonsStart={exportButton}
      footer={headerFooter}
    />
  );

  return {
    loadableData,
    data,
    totalCount,

    isOsr,
    canViewEmployeeCard,

    setDateSwitcherType,

    canView,
    navigation,

    queryKey,
    api,

    activeInfections,
    currentSettings,

    roleModel,
    res,
  };
};
