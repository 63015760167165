import React, { ReactNode } from "react";
import { DiseaseType } from "../../../../api/apiDisease";
import { formatParseDate, now, parseDate } from "../../../../utils/workingWithDates";
import { EmployeeStatus, FileLink } from "../../../../components";
import { differenceInCalendarDays } from "date-fns";
import { employeeHealthyStatus, employeeSickStatus } from "../../../../constants/options";

export const useTable = (canEdit: boolean, canAdd: boolean) => {
  // ------------------------------ ТАБЛИЦА - ЗАГОЛОВКИ

  const titlesInit = [
    { id: 1, title: "№", name: "number" },
    { id: 2, title: "Период заболевания", name: "period" },
    { id: 3, title: "Текущий статус", name: "status" },
    { id: 4, title: "Наименование заболевание", name: "name" },
    { id: 5, title: "Место прохождения лечения", name: "treatmentPlace" },
    { id: 6, title: "Комментарий", name: "comment" },
    { id: 7, title: "Документы", name: "document" },
  ];

  // ------------------------------ ТАБЛИЦА - ШИРИНЫ ТАБЛИЦ

  const gridsInit = [
    ...(canAdd ? [{ titleId: null, size: "56px" }] : []),
    { titleId: 1, size: "76px" },
    { titleId: 2, size: "1fr" },
    { titleId: 3, size: "1fr" },
    { titleId: 4, size: "1fr" },
    { titleId: 5, size: "1fr" },
    { titleId: 6, size: "1fr" },
    { titleId: 7, size: "1fr" },
    ...(canEdit ? [{ titleId: null, size: "56px" }] : []),
  ];

  // ------------------------------ ТАБЛИЦА - ДАННЫЕ ДЛЯ СТРОКИ

  const object = (
    { start, end, infection, treatmentPlace, comment, document }: DiseaseType,
    number?: number
  ): ObjectType => {
    const status =
      !end || differenceInCalendarDays(parseDate(end), now) >= 0
        ? employeeSickStatus
        : employeeHealthyStatus;

    return {
      ...(number ? { number } : {}),
      period: `${formatParseDate(start)} - ${end ? formatParseDate(end) : "Н.В"}`,
      status: <EmployeeStatus status={status.id} />,
      name: infection?.name ?? "—",
      treatmentPlace: treatmentPlace?.name ?? "—",
      comment: comment ?? "—",
      document: document ? <FileLink file={document} /> : "—",
    };
  };

  return {
    titlesInit,
    gridsInit,
    object,
  };
};

type ObjectType = {
  number?: number;
  period: string;
  status: JSX.Element;
  name: string;
  treatmentPlace: string;
  comment: string;
  document: ReactNode;
};
