import { atomWithQuery } from "jotai/query";
import { fetchAll } from "../../../utils/fetchData";
import { atom } from "jotai";
import { formatDateForBackend, today } from "../../../utils/workingWithDates";
import { DateRange } from "@mui/lab";
import { endOfYear, startOfYear } from "date-fns";
import { EmployeePropsType, employeeServiceApi } from "../../../api/apiEmployees";
import { selectedInfectionState } from "../../CalendarPages/CalendarPage/utils/atoms";
import { loadable } from "jotai/utils";
import { getPaginationQueryKeys } from "../../../utils/useQueryKeys";
import { filtersState as filtersInfographic } from "../../InfographicPages/utils/atoms";

// ------------------------------ ТЕКУЩАЯ ДАТА

export const currentDateInterval = atom<DateRange<Date>>([startOfYear(today), endOfYear(today)]);

// ------------------------------ ФИЛЬТРЫ

export const absoluteInitialValues: FiltersType = {
  departmentCode: "",
  hasAppointment: "",
  access: "",
  agreement: "",
  exemption: "",
  plannedExpired: "",
  startDate: null,
  endDate: null,
  plannedComes: false,
};

export const filtersState = atom(absoluteInitialValues);

export type FiltersType = {
  departmentCode: string;
  hasAppointment: string;
  access: string;
  agreement: string;
  exemption: string;
  plannedExpired: string;
  startDate: Date | null;
  endDate: Date | null;
  plannedComes: boolean;
};

// ------------------------------ ДАННЫЕ

const vaccinationStaticAtom = atomWithQuery((get) => {
  const infection = get(selectedInfectionState);
  const interval = get(currentDateInterval);
  const { page, size, query } = getPaginationQueryKeys(get);
  const filters = get(filtersState);
  const { infection: infectionInfographic, department: departmentInfographic } =
    get(filtersInfographic);
  const args = {
    ...filters,
    startDate: interval[0] ? formatDateForBackend(interval[0]) : "",
    endDate: interval[1] ? formatDateForBackend(interval[1]) : "",
    infectionId: infection !== "" ? infection : infectionInfographic,
    departmentCode:
      filters.departmentCode !== ""
        ? filters.departmentCode
        : departmentInfographic !== "" && departmentInfographic !== ":department"
        ? departmentInfographic
        : undefined,
    page,
    size,
    textSearch: query,
  };

  return vaccinationStaticQuery(args);
});

export const vaccinationStaticState = loadable(vaccinationStaticAtom);

const vaccinationStaticQuery = (args: EmployeePropsType) => {
  const { startDate, endDate, infectionId } = args;
  const enabled = !!startDate && !!endDate && !!infectionId;

  return {
    queryKey: [...Object.values(args), "vaccinationStaticState"],
    queryFn: () => fetchAll(() => employeeServiceApi().getVaccinationData(args)),
    enabled,
  };
};

export const useVaccinationStaticKeys = () => {
  return {
    queryKey: ["vaccinationStaticState"],
    api: employeeServiceApi(),
  };
};
