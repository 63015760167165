import { MenuItem } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React, { FC, useState } from "react";
import { colors } from "../../styles";
import { OpenListButton } from "../Buttons";
import { FieldContainer } from "../Form/Form.styles";
import { LabelField } from "../Form/LabelField";
import { SelectInputPropsType } from "./SelectInput";
import * as elements from "./SelectInput.styles";

export const SelectMultiplyInput: FC<PropsType> = (props) => {
  const { name, defaultValue, options, placeholder = "Выберите из доступных" } = props;
  const { insideLabel, outsideLabel } = props;
  const { disabled = false, size = "", color = colors.grayscaleInput, type = "default" } = props;

  const { Container, Item, Placeholder, MenuItemWrapper, MenuProps, Error } = elements;
  const { SelectedOptionsChips, SelectedChipsItem } = elements;

  const { handleChange, handleBlur, setFieldValue } = useFormikContext();

  const [{ value: values }, { touched, error }] = useField<string[]>(name);

  const [isOpen, setIsOpen] = useState(false);
  const [wasInFocus, setWasInFocus] = useState(false);

  const hasError = touched && !!error && wasInFocus;

  const filteredOptions = () => options.filter(({ id }) => id !== "");

  const renderValue = () => <Placeholder haslabel={+!!insideLabel}>{placeholder}</Placeholder>;

  const handleClick = () => (disabled ? undefined : setIsOpen(!isOpen));

  const handleDelete = (id: string) =>
    setFieldValue(
      name,
      values.filter((value) => value !== id)
    );

  const icon = () => (
    <OpenListButton isOpen={isOpen} onClick={() => undefined} disabled={disabled} />
  );

  return (
    <FieldContainer>
      <LabelField outsideLabel={outsideLabel} />
      <Container disabled={disabled} onClick={handleClick}>
        <LabelField insideLabel={insideLabel} />
        <Item
          name={name}
          value={values}
          multiple
          open={isOpen}
          displayEmpty
          label={insideLabel}
          labelId={insideLabel ? `${name}-label` : ""}
          haslabel={+!!insideLabel}
          renderValue={renderValue}
          IconComponent={icon}
          MenuProps={MenuProps(size)}
          onChange={(evt) => {
            handleChange(evt);
            setWasInFocus(!!error);
          }}
          onBlur={(evt) => {
            handleBlur(evt);
            setWasInFocus(!!error);
          }}
          onClick={() => undefined}
          haserror={+hasError}
          defaultValue={defaultValue ? defaultValue : ""}
          autoComplete="off"
          disabled={disabled}
          backgroundcolor={color}
        >
          {filteredOptions().map(({ id, name }, i) => (
            <MenuItem key={i} value={id}>
              <MenuItemWrapper>{name}</MenuItemWrapper>
            </MenuItem>
          ))}
        </Item>

        {hasError ? <Error>{error}</Error> : null}

        {type === "chips" && (
          <SelectedOptionsChips>
            {values.map((value) => (
              <SelectedChipsItem
                key={value}
                label={options.find(({ id }) => id === value)?.name ?? ""}
                onDelete={() => handleDelete(value)}
              />
            ))}
          </SelectedOptionsChips>
        )}
      </Container>
    </FieldContainer>
  );
};

type PropsType = SelectInputPropsType & {
  type?: "chips" | "default";
};
