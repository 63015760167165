import React, { FC } from "react";
import { SectionType, TitleType } from "./HeaderNavigation";
import { BreadcrumbsLink, BreadcrumbsNav, Separator, SubTitle } from "./HeaderNavigation.styles";

export const HeaderNavigationBreadcrumbsNav: FC<PropsType> = (props) => {
  const { title, section } = props;

  return (
    <BreadcrumbsNav aria-label="breadcrumb">
      <BreadcrumbsLink to="/">
        Инфекционные заболевания<Separator>&#160;/&#160;</Separator>
      </BreadcrumbsLink>
      {section ? (
        <BreadcrumbsLink to={section.rout}>
          {section.name}
          <Separator>&#160;/&#160;</Separator>
        </BreadcrumbsLink>
      ) : null}
      <SubTitle>{typeof title === "string" ? title : title.text}</SubTitle>
    </BreadcrumbsNav>
  );
};

type PropsType = {
  title: TitleType;
  section?: SectionType;
};
