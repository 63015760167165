import styled from "@emotion/styled/macro";
import { button, buttonOnlyIcon, buttonTextDefault, colors, margins } from "../../../styles";

export const Container = styled.button`
  ${button};
  ${buttonOnlyIcon};
  ${buttonTextDefault};

  ${margins({ p: "0" })};

  & > svg > path {
    fill: ${colors.grayscaleIcons};
  }
`;
