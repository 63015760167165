import styled from "@emotion/styled/macro";
import { borders, colors, flex, margins } from "../../../../styles";

export const Navigation = styled.nav`
  ${flex()};
  ${margins({ m: "0 0 16 0" })};

  ${borders({ side: "bottom", color: colors.grayscaleInput, width: 1 })};

  height: 40px;
`;
