import React from "react";
import { Navigation } from "./PageSwitcher.styles";
import { NavigationLink } from "../../../../uiKit/Header/HeaderPagesSwitcher/HeaderPagesSwitcher.styles";
import {
  USER_INFECTION_DISEASES,
  USER_VACCINATION_RECORDS,
  USER_VACCINATIONS,
} from "../../../../constants/routes";
import { useAtomValue } from "jotai/utils";
import { employeeIdState } from "../../utils/atoms";

export const PageSwitcher = () => {
  const employeeId = useAtomValue(employeeIdState);

  const routes = [
    { text: "Запись на вакцинацию", route: USER_VACCINATION_RECORDS(employeeId) },
    { text: "Инфекционные заболевания", route: USER_INFECTION_DISEASES(employeeId) },
    { text: "Вакцинация", route: USER_VACCINATIONS(employeeId) },
  ];

  return (
    <Navigation>
      {routes.map(({ text, route }) => (
        <NavigationLink
          key={route}
          to={route}
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          {text}
        </NavigationLink>
      ))}
    </Navigation>
  );
};
