import React, { FC } from "react";
import { EmptyCell, DayNumber } from "./CalendarView.styles";

export const CalendarEmptyDays: FC<PropsType> = (props) => {
  const { days } = props;

  return (
    <>
      {days.map((dayNumber) => (
        <EmptyCell key={dayNumber}>
          <DayNumber isEmpty>{dayNumber}</DayNumber>
        </EmptyCell>
      ))}
    </>
  );
};

type PropsType = {
  days: number[];
  loading?: boolean;
};
