import React from "react";
import { useAtom } from "jotai";
import { useAtomValue } from "jotai/utils";
import { currentUserAtom } from "../../../../atoms/atomCurrentUser";
import { HeaderNavigation } from "../../../../uiKit";
import { ADMINISTRATION } from "../../../../constants/routes";
import {
  modalDataState,
  notificationSettingsState,
  useNotificationSettingsQuery,
} from "../utils/atoms";
import { NotificationSettingsType } from "../../../../api/apiVaccination";

export const useData = () => {
  // ------------------------------ АТОМЫ

  const { roleModel, res } = useAtomValue(currentUserAtom);
  const loadableData = useAtomValue(notificationSettingsState);

  const data = [
    {
      id: "01",
      name: "Уведомление о необходимости прохождения вакцинации сотруднику, который записан на ре/вакцинацию на электронную почту и в уведомления системы",
      infections: loadableData.state === "hasData" ? loadableData.data[0]?.necessity ?? [] : [],
      field: "necessity",
    },
    {
      id: "02",
      name: "Уведомление о просроченной вакцинации сотрудника на электронную почту и в уведомления системы",
      infections: loadableData.state === "hasData" ? loadableData.data[0]?.expired ?? [] : [],
      field: "expired",
    },
  ] as unknown as NotificationSettingsType[];

  const [modalData, setModalData] = useAtom(modalDataState);
  // ------------------------------ ХУКИ

  const { queryKey, api } = useNotificationSettingsQuery();
  const { canViewVaccinationNotifications: canView, canEditVaccinationNotifications: canEdit } =
    roleModel;

  // ------------------------------ КОНСТАНТЫ

  const titlePage = "Настройка отправки уведомлений";
  const notificationSettings =
    loadableData.state === "hasData" ? loadableData.data[0] ?? undefined : undefined;
  const organization = res.organization;

  // ------------------------------ ШАПКА - НАВИГАЦИЯ

  const navigation = (
    <HeaderNavigation
      title={titlePage}
      section={{ name: "Администрирование", rout: ADMINISTRATION }}
    />
  );

  return {
    modalData,
    setModalData,

    loadableData,
    data,
    notificationSettings,
    organization,

    navigation,

    canView,
    canEdit,

    api,
    queryKey,
  };
};
