import { TestConfig, ValidationError } from "yup";
import { falseAcceptFile, falseSizeFile, missingFile } from "../constants/forValidationSchemes";

export const validateFiles = async (props: PropsType): Promise<string | undefined> => {
  const { file, accept, maxSize } = props;

  if (!file) return missingFile;

  if (file && typeof file !== "string" && accept.every((item) => !file.name.includes(item)))
    return falseAcceptFile(accept);

  if (file && typeof file !== "string" && file.size > maxSize) return falseSizeFile(maxSize);
};

export const validateFilesObj = (props: Omit<PropsType, "file">): TestConfig<File, unknown> => ({
  name: "validate-files",
  message: (msg) => msg,
  test: async (value, { path }) => {
    const result = await validateFiles({ file: value, ...props });
    return result ? new ValidationError(result, value, path) : true;
  },
});

type PropsType = {
  file: File | `fs://${string}` | undefined;
  accept: string[];
  maxSize: number;
};
