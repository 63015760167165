import { CircularProgress } from "@mui/material";
import React, { FC } from "react";
import { CrossOnCircle } from "../Icons";
import { GeneralPropsType } from "./FilesInput";
import * as elements from "./FilesInput.styles";

export const FilesInputProgress: FC<GeneralPropsType> = (props) => {
  const { name, fileName, fileSize, progress, element, multiFile, handleRemove } = props;

  const { Container, Title, Description, Text, Button } = elements;
  const { Progress, Percent, LoadBarAndText } = elements;

  return (
    <Container status="progress" multiFile={multiFile} as="div">
      <LoadBarAndText status="progress" multiFile={multiFile}>
        <Progress>
          <CircularProgress variant="determinate" value={progress} />
          <Percent>{`${Math.round(progress)}%`}</Percent>
        </Progress>
        <Text>
          <Title status="progress">{fileName}</Title>
          <Description status="progress">{fileSize}</Description>
        </Text>
      </LoadBarAndText>

      <Button onClick={() => handleRemove(name, element)}>
        <CrossOnCircle />
      </Button>
    </Container>
  );
};
