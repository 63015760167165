import React, { FC, useEffect } from "react";
import * as elements from "../Calendar.styles";
import { selectedInfectionState } from "../utils/atoms";
import { useAtom } from "jotai";
import { OrganizationsSettingsType } from "../../../../api/apiVaccination";

export const InfectionList: FC<PropsType> = (props) => {
  const { activeInfections } = props;

  const { HeaderFooter, ListLabel, Infection, InfectionName } = elements;

  const [selectedInfection, setSelectedInfection] = useAtom(selectedInfectionState);

  useEffect(() => {
    if (!activeInfections.some(({ infection }) => infection.id === selectedInfection)) {
      const infectionId = activeInfections[0]?.infection.id;
      setSelectedInfection(infectionId ?? "");
    }
  }, [activeInfections]);

  return activeInfections.length ? (
    <HeaderFooter>
      <ListLabel>Заболевание:</ListLabel>
      {activeInfections.map(({ infection }) => {
        const { id, name } = infection;
        return (
          <Infection
            isActive={selectedInfection === id}
            key={id}
            onClick={() => setSelectedInfection(id)}
          >
            <InfectionName>{name}</InfectionName>
          </Infection>
        );
      })}
    </HeaderFooter>
  ) : (
    <></>
  );
};

type PropsType = {
  activeInfections: OrganizationsSettingsType;
};
