import { atomWithStorage, loadable, useAtomValue } from "jotai/utils";
import { atomWithQuery } from "jotai/query";
import { fetch, fetchAll, fetchById } from "../../../../utils/fetchData";
import {
  VaccinationDateType,
  vaccinationPlanApi,
  vaccinationPlanServiceApi,
} from "../../../../api/apiVaccination";
import { endOfMonth, startOfMonth } from "date-fns";
import { formatDateForBackend, today } from "../../../../utils/workingWithDates";
import { DateRange } from "@mui/lab";
import { atom } from "jotai";
import { currentUserAtom } from "../../../../atoms/atomCurrentUser";
import { appointmentApi } from "../../../../api/apiAppointment";
import { summaryInfoApi, SummaryInfoPropType } from "../../../../api/apiSummaryInfo";
import { storageSession } from "../../../../atoms/atoms";

// ------------------------------ ДАННЫЕ ДЛЯ РЕДАКТИРОВАНИЯ

export const modalDataState = atom<ModalDataDayType | undefined>(undefined);

type ModalDataDayType = VaccinationDateType & { type: "limit" | "time" };

// ------------------------------ ДАННЫЕ ДЛЯ УДАЛЕНИЯ

export const modalDataDayState = atom<DeletionDayType | undefined>(undefined);

type DeletionDayType = {
  dayId: string;
  planId: string;
  startDate: string;
  endDate: string;
  infectionName: string;
  centerName: string;
  date: string;
};

// ------------------------------ ВЫБРАННОЕ ЗАБОЛЕВАНИЕ

export const selectedInfectionState = atomWithStorage<string>(
  "selectedInfection",
  "",
  storageSession
);

// ------------------------------ ТЕКУЩАЯ ДАТА

export const currentDateInterval = atom<DateRange<Date>>([startOfMonth(today), endOfMonth(today)]);

// ------------------------------ ДАННЫЕ

export const calendarAtom = atomWithQuery((get) => {
  const currentUser = get(currentUserAtom);
  const interval = get(currentDateInterval);

  const startDate = interval[0] ? formatDateForBackend(interval[0]) : "";
  const endDate = interval[1] ? formatDateForBackend(interval[1]) : "";
  const { organization } = currentUser.res;
  const infectionId = get(selectedInfectionState);

  return calendarQuery({ startDate, endDate, infectionId, organization });
});
export const calendarState = loadable(calendarAtom);

export const calendarQuery = (props: CalendarQueryPropsType) => {
  const { startDate, endDate, infectionId, organization } = props;

  const args = {
    startDate,
    endDate,
    organization,
    infectionId,
  };

  return {
    queryKey: [startDate, endDate, infectionId, organization, "calendarState"],
    queryFn: () => fetchAll(() => vaccinationPlanServiceApi().getAll(args)),
    enabled: !!infectionId,
  };
};

export const useCalendarQuery = () => {
  const currentUser = useAtomValue(currentUserAtom);
  const interval = useAtomValue(currentDateInterval);

  const startDate = interval[0] ? formatDateForBackend(interval[0]) : "";
  const endDate = interval[1] ? formatDateForBackend(interval[1]) : "";
  const { organization } = currentUser.res;
  const infectionId = useAtomValue(selectedInfectionState);

  return {
    queryKey: [startDate, endDate, infectionId, organization, "calendarState"],
    api: vaccinationPlanApi(),
  };
};

// ------------------------------ СВОДНЫЕ ДАННЫЕ ДЛЯ ВАКЦИНАЦИИ

export const summaryVaccinationState = atomWithQuery((get) => {
  const infectionId = get(selectedInfectionState);
  const interval = get(currentDateInterval);
  const startDate = interval[0] ? formatDateForBackend(interval[0]) : "";
  const endDate = interval[1] ? formatDateForBackend(interval[1]) : "";

  const args = {
    infectionId,
    startDate,
    endDate,
  };

  return summaryVaccinationQuery(args);
});

const summaryVaccinationQuery = (args: SummaryInfoPropType) => {
  return {
    queryKey: [...Object.values(args), "summaryVaccinationState"],
    queryFn: () => fetchById(() => summaryInfoApi().getVaccination(args)),
    enabled: !!args.infectionId,
  };
};

// ------------------------------ ПОЛУЧЕНИЕ ТЕКУЩИХ ЗАПИСЕЙ НА ДЕНЬ

export const currentAppointmentsState = atomWithQuery((get) => {
  const modalData = get(modalDataState);

  const ids = modalData?.times.map(({ id }) => id);

  return currentAppointmentQuery(ids);
});

const currentAppointmentQuery = (ids?: string[]) => {
  const filter = ids
    ? [
        {
          group: "AND",
          conditions: [
            {
              group: "OR",
              conditions: ids?.map((id) => ({ property: "time.id", operator: "=", value: id })),
            },
            {
              group: "OR",
              conditions: [
                { property: "removed", operator: "isNull" },
                { property: "removed", operator: "=", value: "false" },
              ],
            },
          ],
        },
      ]
    : [];

  return {
    queryKey: [ids, "currentAppointmentsState"],
    queryFn: () => fetch(() => appointmentApi().getAll({ filter })),
    enabled: !!ids,
  };
};

type CalendarQueryPropsType = {
  infectionId: string;
  organization: string;
  startDate: string;
  endDate: string;
};
