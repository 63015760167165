import { useAtom } from "jotai";
import { useAtomValue } from "jotai/utils";
import React, { FC, useEffect } from "react";
import { dateSwitcherTypeState, periodSwitcherTypeState } from "../../atoms/atoms";
import { DateSwitcherType } from "../Header/HeaderNavigation/HeaderNavigation";
import { Container } from "./DateSwitcher.styles";
import { DaySwitcher } from "./DaySwitcher";
import { MonthsSwitcher } from "./MonthsSwitcher";
import { PeriodSwitcher } from "./PeriodSwitcher";
import { QuarterSwitcher } from "./QuarterSwitcher";
import { YearSwitcher } from "./YearSwitcher";

export const DateSwitcher: FC<PropsType> = (props) => {
  const { dateAtom, dateIntervalAtom, needPeriodSwitcher } = props;

  const dateSwitcherType = useAtomValue(dateSwitcherTypeState);
  const [periodSwitcherType, setPeriodSwitcherType] = useAtom(periodSwitcherTypeState);

  useEffect(() => setPeriodSwitcherType("year"), []);

  const periodSwitcher =
    dateSwitcherType === "period" || dateSwitcherType === "day" ? (
      <DaySwitcher {...props} />
    ) : dateSwitcherType === "quarter" && dateIntervalAtom ? (
      <QuarterSwitcher dateIntervalAtom={dateIntervalAtom} />
    ) : dateSwitcherType === "month" && dateIntervalAtom ? (
      <MonthsSwitcher dateIntervalAtom={dateIntervalAtom} />
    ) : dateSwitcherType === "year" ? (
      dateIntervalAtom ? (
        <YearSwitcher dateIntervalAtom={dateIntervalAtom} />
      ) : (
        <YearSwitcher dateAtom={dateAtom} />
      )
    ) : null;

  return (
    <Container needPeriodSwitcher={needPeriodSwitcher}>
      {needPeriodSwitcher && periodSwitcherType === dateSwitcherType && dateIntervalAtom && (
        <PeriodSwitcher dateIntervalAtom={dateIntervalAtom} />
      )}

      {periodSwitcher}
    </Container>
  );
};

type PropsType = DateSwitcherType;
