import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import { CurrentUserType, userApi } from "../api/apiUser";
import { ContactTypeType } from "../api/types";
import { emptyObject, incorrectRes } from "../constants/errorsMessages";

// ------------------------------ ТЕКУЩИЙ ПОЛЬЗОВАТЕЛЬ

export const currentUserAtom = atomWithQuery(() => currentUserQuery());

export const currentUserQuery = () => ({
  queryKey: ["currentUser"],
  queryFn: fetchCurrentUser,
});

export const currentUserState = loadable(currentUserAtom);

export const fetchCurrentUser = async (): Promise<UserType> => {
  const res = await userApi().get();

  if (!res) throw new Error(incorrectRes);
  if (Object.keys(res).length === 0) throw new Error(emptyObject);

  const containsRoles = (roles: string[]) => res?.roles.some((role) => roles?.includes(role));

  const roles = {
    // ------------------------------ СУПЕРПОЛЬЗОВАТЕЛЬ (разработчик)

    superUser: containsRoles([userRoles.superUser]),

    // ------------------------------ РОЛИ

    department: containsRoles([userRoles.department]),
    osr: containsRoles([userRoles.osr]),
    oot: containsRoles([userRoles.oot]),
    center: containsRoles([userRoles.center]),
    module: containsRoles([userRoles.module]),
    osrCa: containsRoles([userRoles.osrCa]),

    moduleTwoAccess: containsRoles(["mr-"]),
  };

  const rights = getRights(roles);

  return {
    res,
    settings: res.settings ? JSON.parse(res.settings) : "",
    roleModel: { ...roles, ...rights },
  };
};

const getRights = ({
  superUser,
  oot,
  osr,
  module,
  center,
  department,
  moduleTwoAccess,
}: UserRolesType) => ({
  // ------------------------------ СПРАВОЧНИКИ

  canViewDictionaries: superUser || oot || osr || module || center,

  canViewEmployeeDictionary: superUser || osr || oot || department || center,
  canEditEmployeeDictionary: superUser,

  canViewEmployeeCard: superUser || department || osr || center,

  canViewVaccinationDictionary: superUser || osr || oot || center || module,
  canEditVaccinationDictionary: superUser || module,

  canViewHealthCenterDictionary: superUser || osr || oot || center || module,
  canEditHealthCenterDictionary: superUser || module || osr || center,

  canViewInfectionDiseaseDictionary: superUser || module || osr || oot || center,
  canEditInfectionDiseaseDictionary: superUser || module,

  canViewInfectionVaccinationDictionary: superUser || osr || oot || center,
  canEditInfectionVaccinationDictionary: superUser || osr || center,

  canViewOtherDictionaries: superUser || osr || oot || module || center,
  canEditOtherDictionaries: superUser || module,

  // ------------------------------ АДМИНИСТРИРОВАНИЕ

  canViewAdministration: superUser || osr || center || department,

  canViewVaccinationSettings: superUser || osr || center,
  canEditVaccinationSettings: superUser || osr || center,

  canViewVaccinationNotifications: superUser || osr || department,
  canEditVaccinationNotifications: superUser || osr,

  // ------------------------------ КАЛЕНДАРЬ

  canViewCalendar: superUser || department || osr || oot || center,
  canEditCalendar: superUser || osr || center,
  canAddPeriodCalendar: superUser || osr || center,
  canViewCalendarDay: superUser || osr || oot || department || center,
  canEditCalendarDay: superUser || osr || department || center,
  canAdditionalFilterCalendarDay: superUser || osr || center,

  // ------------------------------ ПРОФИЛЬ ПОЛЬЗОВАТЕЛЯ

  canViewProfile: superUser || department || osr || center || oot,
  canEditProfile: superUser || department || osr || center,
  canEditDiseasesProfile: !moduleTwoAccess && (superUser || department || osr || center),
  canAddDiseasesProfile: !moduleTwoAccess && (superUser || department || osr || center),

  // ------------------------------ ПРИЧИНЫ УДАЛЕНИЯ

  canViewDeleteReasons: superUser || osr || oot || center,

  // ------------------------------ ДАННЫЕ О ВАКЦИНАЦИИ

  canViewVaccinationStatistic: superUser || osr || oot || center || department,

  // ------------------------------ ИНФОГРАФИКА
  canViewInfographic: superUser || department || osr || oot || center,
  canViewOrganizationChart: superUser || department || osr || oot || center,
  canViewDepartmentChart: superUser || osr || oot || center,

  canViewInfographicVaccinationEmployees: superUser || osr || oot || center,
  canViewInfographicInfectionEmployees: superUser || osr || oot || center || department,

  canDownloadInfographic: superUser || department || osr || oot || module,
  canDownloadInfographicVaccination: superUser || department || osr || oot || center,
  canDownloadInfographicInfection: superUser || department || osr || oot || module,
});

export type UserType = {
  res: CurrentUserType;
  settings: SettingsType;
  roleModel: RoleModelType;
};

export type SettingsType = Record<
  TablesNameType,
  {
    tableSettings?: { name: string; visible: boolean }[];
    contactsSetting?: Record<ContactTypeType, string[]>;
  }
>;

type TablesNameType = "";

export type RoleModelType = UserRolesType & ReturnType<typeof getRights>;

export type TypeSettingsType = keyof SettingsType;

type UserRolesType = {
  moduleTwoAccess: boolean;
  superUser: boolean;
  department: boolean;
  osr: boolean;
  oot: boolean;
  center: boolean;
  module: boolean;
  osrCa: boolean;
};

export const userRoles = {
  superUser: "system-full-access",
  department: "vaccination-department-role",
  osr: "vaccination-osr-role",
  oot: "vaccination-oot-role",
  center: "vaccination-health-center-role",
  module: "vaccination-module-role",
  osrCa: "vaccination-osr-ca-role",
};
