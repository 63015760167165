import { atom } from "jotai";
import { MediumOtherType, personnelCategoriesApi } from "../../../../api/apiDictionaries";
import { atomWithQuery } from "jotai/query";
import { fetch } from "../../../../utils/fetchData";

export const modalDataState = atom<MediumOtherType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

export const personnelCategoriesState = atomWithQuery(() => ({
  queryKey: "personnelCategoriesState",
  queryFn: () => fetch(() => personnelCategoriesApi().getAll()),
}));

export const usePersonnelCategoriesQuery = () => ({
  queryKey: ["personnelCategoriesState"],
  api: personnelCategoriesApi(),
});
