// ------------------------------ ПОЛ

import { userRoles } from "../atoms/atomCurrentUser";

export const sexOptions = [
  { id: "MALE", name: "Мужской" },
  { id: "FEMALE", name: "Женский" },
];

// ------------------------------ ДА / НЕТ

export const yesAndNoOptions = [
  { id: "true", name: "Да" },
  { id: "false", name: "Нет" },
];

// ------------------------------ ВАКЦИНАЦИЯ / РЕВАКЦИНАЦИЯ

export const vaccineTypeOptions = [
  { id: "vaccine", name: "Вакцинация" },
  { id: "revaccine", name: "Ревакцинация" },
];

// ------------------------------ ЭТАПЫ ВАКЦИНАЦИИ

export const stepVaccineOptions = [
  { id: "1", name: "Этап 1" },
  { id: "2", name: "Этап 2" },
  { id: "3", name: "Этап 3" },
  { id: "4", name: "Этап 4" },
  { id: "5", name: "Этап 5" },
];

// ------------------------------ КОЛИЧЕСТВО КОМПОНЕНТОВ

export const countComponentOptions = [
  { id: "1", name: "1" },
  { id: "2", name: "2" },
  { id: "3", name: "3" },
  { id: "4", name: "4" },
  { id: "5", name: "5" },
];

// ------------------------------ СТАТУСЫ СОТРУДНИКОВ

export const statusOptions = [
  { id: "ACTIVE", name: "Активные" },
  { id: "FIRED", name: "Уволенный" },
  { id: "SUSPENDED", name: "Приостановленный" },
  { id: "PENSIONER", name: "Пенсионер" },
  { id: "REMOVED", name: "Удаленный" },
];

// ------------------------------ ЕДИНИЦЫ ИЗМЕРЕНИЯ ВРЕМЕНИ

export const dateYearTypeOptions = [{ id: "year", name: "года" }];
export const dateDayTypeOptions = [{ id: "days", name: "дни" }];
export const dateTypeOptions = [
  ...dateDayTypeOptions,
  { id: "month", name: "месяцы" },
  ...dateYearTypeOptions,
];

// ------------------------------ ТИПЫ СТАТУСОВ СОТРУДНИКОВ

export const employeeDeathStatus = {
  id: "DEAD",
  name: "Умер",
};
export const employeeHealthyStatus = { id: "HEALTHY", name: "Здоров" };
export const employeeSickStatus = { id: "SICK", name: "Болен" };

export const statusEmployeeTypeOptions = [
  employeeSickStatus,
  employeeHealthyStatus,
  employeeDeathStatus,
];

export const roleOptions = [
  { id: userRoles.superUser, name: "Суперпользователь" },
  { id: userRoles.department, name: "Администратор подразделения" },
  { id: userRoles.osr, name: "Администратор ОСР" },
  { id: userRoles.oot, name: "Администратор ООТ" },
  { id: userRoles.center, name: "Администратор ЗдравПункта" },
  { id: userRoles.module, name: "Администратор Модуля" },
];
