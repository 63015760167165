import styled from "@emotion/styled/macro";
import { colors, flex, margins, text } from "../../styles";

export const Error = styled.div`
  ${flex({ horizontal: "center" })};
  ${margins({ m: "20 0" })};
`;

export const ErrorText = styled.p`
  ${text({ size: 21, height: 23, weight: 500, color: colors.redDark, align: "center" })};
`;

export const WarningText = styled.p`
  ${text({ size: 21, height: 23, weight: 500, color: colors.warningDark, align: "center" })};
`;

export const InfoText = styled.p<{ paddingAuto?: boolean }>`
  ${text({ size: 21, height: 23, weight: 500, color: colors.grayscaleLabel, align: "center" })};

  padding: ${({ paddingAuto }) => (paddingAuto ? "25% 0" : "16px 0")};
`;

export const SuccessText = styled.p<{ visible: boolean }>`
  ${text({ size: 21, height: 23, weight: 500, color: colors.greenDark, align: "center" })};

  opacity: ${({ visible }) => (visible ? "1" : "0")};
  transition: opacity 0.2s ease-out;
`;
