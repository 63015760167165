import React, { FC } from "react";
import { SelectedItemPropsType } from "./SelectedItem";
import { SelectedChipsItem } from "./SelectAutocompleteMultilineInput.styles";

export const SelectedItemChips: FC<SelectedItemPropsType> = (props) => {
  const { id, inputMethods } = props;

  const { markOptions, handleDelete } = inputMethods;

  const selectedLabel = markOptions.find(({ option }) => option.id === id)?.option.name ?? "";

  return <SelectedChipsItem label={selectedLabel} onDelete={() => handleDelete(id)} />;
};
