import { entitiesBase, EntityCreateType, EntityUpdateType } from "./apiRequest";
import { izApi, izServicesApi } from "./api";
import { IdType } from "./types";
import { EmployeeType } from "./apiEmployees";
import { VaccinationPlanType } from "./apiVaccination";
import { AxiosResponse } from "axios";

export const appointmentApi = <E>() => ({
  ...entitiesBase<AppointmentType, E>(izApi, "vs_Appointment"),
  create: async (props: EntityCreateType<E>): Promise<AxiosResponse<IdType>> => {
    const { data } = props;
    return izServicesApi.post(`/appointmentService/create`, data);
  },
  update: async (props: EntityUpdateType<E>): Promise<AxiosResponse<E>> => {
    const { data } = props;
    return izServicesApi.post(`/appointmentService/update`, data);
  },
  deleteAppointment: async ({ data, id }: EntityUpdateType<E>): Promise<AxiosResponse<IdType>> => {
    return izApi.put(`/vs_Appointment/${id}`, data);
  },
});

// получаемые значения

export type AppointmentType = IdType & {
  employee: EmployeeType;
  date: string;
  time: TimeType;
  vaccinationPlan: VaccinationPlanType;
  removed: boolean;
  removeReason: string;
  abroad: boolean;
};

export type TimeType = IdType & { startTime: string; endTime: string };
