import React from "react";
import { BigCrossInCircle } from "../Icons";
import { ConfirmModalPropsType } from "./ConfirmModal";
import { ConfirmHeader } from "./ConfirmModal.styles";

export const ConfirmModalError = <DATA, VOBJ>(props: PropsType<DATA, VOBJ>) => {
  const { error, onDelete, onSend, onApprove } = props;

  return (
    <>
      <BigCrossInCircle />
      <ConfirmHeader>
        {onDelete
          ? "При удалении в"
          : onSend
          ? "При отправке в"
          : onApprove
          ? "При согласовании в"
          : "В"}
        озникла следующая ошибка: {error}
      </ConfirmHeader>
    </>
  );
};

type PropsType<DATA, VOBJ> = ConfirmModalPropsType<DATA, VOBJ> & {
  error: string | undefined;
};
