import React, { FC } from "react";
import { useField } from "formik";
import * as elements from "./SwitchInput.styles";
import { Switch } from "./Switch";

export const SwitchInput: FC<PropsType> = (props) => {
  const { name, label } = props;

  const { Container, Label, Error } = elements;

  const [{ value }, { touched, error }, { setValue }] = useField<boolean>(name);

  const hasError = touched && !!error;

  return (
    <Container onClick={() => setValue(!value)}>
      <Switch checked={value} />
      <Label>{label}</Label>
      {hasError && <Error>{error}</Error>}
    </Container>
  );
};

type PropsType = {
  name: string;
  label: string;
};
