import { useField } from "formik";
import React, { FC, Fragment, useEffect } from "react";
import { countComponentOptions } from "../../../../constants/options";
import { SelectInput, TextInput } from "../../../../uiKit";
import { FieldContainer } from "../../../../uiKit/Form/Form.styles";
import { LabelField } from "../../../../uiKit/Form/LabelField";
import { ComponentsContainer } from "../InfectionVaccination.styles";

export const ComponentsField: FC<PropsType> = (props) => {
  const { name, outsideLabel } = props;

  const [{ value: intervals }, _, { setValue }] = useField<string[]>(name);

  useEffect(() => {
    const length = intervals.filter((item) => item || item === "").length;

    if (length !== intervals.length) {
      const updateIntervals: string[] = [];
      for (let i = 0; i < intervals.length; i++) {
        updateIntervals.push(!intervals[i] ? "" : intervals[i]);
      }
      setValue(updateIntervals);
    }
  }, [intervals]);

  return (
    <FieldContainer>
      <LabelField outsideLabel={outsideLabel} />

      <ComponentsContainer>
        <SelectInput
          name={`${name}.length`}
          options={countComponentOptions}
          insideLabel="Количество компонент"
        />
        {intervals.map((_, i) => {
          return (
            <Fragment key={i}>
              {intervals.length > i + 1 ? (
                <TextInput
                  name={`${name}[${i}]`}
                  type="number"
                  placeholder="Дни"
                  insideLabel={`Кол-во дней между ${i + 1}-й и ${i + 2}-й`}
                />
              ) : null}
            </Fragment>
          );
        })}
      </ComponentsContainer>
    </FieldContainer>
  );
};

type PropsType = {
  name: string;
  outsideLabel?: string;
};
