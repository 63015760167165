import styled from "@emotion/styled";
import { borders, colors, flex, grid, margins, overflowOneLine, text } from "../../../../styles";

const { white, grayscaleOffWhite, grayscaleInput, grayscaleIcons } = colors;
const { grayscaleAsh, grayscaleLabel } = colors;

export const Container = styled.div`
  ${borders({ radius: 16 })};
  ${margins({ m: "0 0 16 0", p: "24" })};

  background: ${white};
`;
export const NameAndSex = styled.div`
  ${flex({ vertical: "center" })};
  ${margins({ m: "0 12 12 0" })};
`;

export const Name = styled.p`
  ${text({ size: 20, height: 26, weight: 700, color: grayscaleAsh })};
`;

export const Sex = styled.p`
  ${text({ size: 20, height: 26, weight: 800, color: grayscaleLabel, uppercase: true })};
  ${margins({ m: "0 27 0 8" })};
`;

export const Icon = styled.div`
  ${flex({ totalCenter: true })};
  ${borders({ radius: 8 })};

  width: 40px;
  height: 40px;
  background: ${grayscaleOffWhite};
  border: 1px solid ${grayscaleInput};

  p {
    ${text({ size: 15, height: 20, weight: 700, color: grayscaleIcons })};
  }
`;

export const Data = styled.div<{ hasMenuButton: boolean }>`
  ${({ hasMenuButton }) =>
    grid({ columns: `repeat(9, 1fr) ${hasMenuButton ? "56px" : ""}`, gap: "" })};
`;

export const DataItem = styled.div<{ hasMenuButton: boolean }>`
  ${margins({ p: "0 0 0 13" })};

  min-width: 100%;
  border-right: 2px solid ${grayscaleInput};

  &:first-of-type {
    ${margins({ p: "0" })};
  }

  &:last-of-type {
    ${({ hasMenuButton }) => (hasMenuButton ? undefined : "border-right: none")};
  }
`;

export const Title = styled.h5`
  ${overflowOneLine};
  ${text({ size: 13, height: 18, weight: 700, color: grayscaleLabel })};
  cursor: default;
`;

export const Description = styled.p`
  ${overflowOneLine};
  ${text({ size: 15, height: 20, weight: 400, color: grayscaleAsh })};
  cursor: default;
`;
