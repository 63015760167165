import React from "react";
import { CheckInput, TextInput } from "../../../../uiKit";
import { FieldsBlock, FormSpacer } from "../../../../uiKit/Form/Form.styles";
import { modalDataVaccinationRecordState } from "../../utils/atoms";
import { useAtom } from "jotai";

export const useViewForms = () => {
  // ------------------------------ ХУКИ

  const [modalViewData, setModalViewData] = useAtom(modalDataVaccinationRecordState);

  // ------------------------------ КОНСТАНТЫ

  const viewFormTitle = "Запись на вакцинацию";

  const viewFormName = "vaccinationRecord";

  // ------------------------------ ФОРМА - ПОЛЯ

  const viewFields = (
    <FieldsBlock>
      <TextInput name="date" disabled outsideLabel="Дата вакцинации" />

      <TextInput name="infection" disabled outsideLabel="Заболевание" />

      <TextInput name="time" disabled outsideLabel="Время" />

      <TextInput name="healthCenter" disabled outsideLabel="Медицинская организация" />

      <FormSpacer />
      <CheckInput name="abroad" label="В связи с поездкой за рубеж" disabled />
    </FieldsBlock>
  );

  // ------------------------------ ФОРМА - ЗНАЧЕНИЯ

  const viewInitialValues = (props: VaccinationRecordViewType) => props;

  return {
    viewInitialValues,

    viewFormTitle,
    viewFormName,

    viewFields,
    modalViewData,
    setModalViewData,
  };
};

export type VaccinationRecordViewType = {
  date: string;
  infection: string;
  time: string;
  healthCenter: string;
  abroad: boolean;
};
