import styled from "@emotion/styled/macro";
import { button, buttonOnlyText, colors } from "../../../styles";

export const Container = styled.button`
  ${button};
  ${buttonOnlyText};

  width: 40px;
  height: 40px;

  :hover {
    background: ${colors.grayscaleHoverBackground};
  }
`;
