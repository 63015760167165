import { izServicesApi } from "./api";
import { AxiosResponse } from "axios";

export const summaryInfoApi = () => ({
  getVaccination: async (
    request: SummaryInfoPropType
  ): Promise<AxiosResponse<SummaryVaccinationInfoType>> => {
    return izServicesApi.post(`/calendarSummaryDataService/get`, { request });
  },
});

export type SummaryInfoPropType = {
  infectionId: string;
  startDate: string;
  endDate: string;
};

export type SummaryVaccinationInfoType = {
  all: number;
  appointed: number;
  vaccinated: number;
};
