import { useAtomValue } from "jotai/utils";
import React, { FC } from "react";
import { statusCreateOrUpdateState } from "../../atoms/atoms";
import { CloseButton } from "../Buttons";
import { Loader } from "../Loader";
import * as elements from "./RightModal.styles";

export const RightModal: FC<PropsType> = (props) => {
  const { title, description, children } = props;
  const { isOpen = false, onClose, size = "", needBlur = false } = props;

  const { Container, Description, Head, Main, Title, TitleAndCloseButton } = elements;

  const status = useAtomValue(statusCreateOrUpdateState);

  return (
    <Container anchor="right" open={isOpen} onClose={onClose} size={size} needblur={+needBlur}>
      {status && <Loader over />}

      <Head>
        <TitleAndCloseButton>
          <Title>{title}</Title>
          <CloseButton onClick={onClose} />
        </TitleAndCloseButton>

        {!!description && <Description>{description}</Description>}
      </Head>

      <Main>{children}</Main>
    </Container>
  );
};

type PropsType = {
  title: string;
  description?: string;
  isOpen?: boolean;
  onClose: () => void;
  size?: string;
  needBlur?: boolean;
};
