import { useAtom } from "jotai";
import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { menuIsExpandState } from "../../../atoms/atoms";
import { SectionType } from "../../../constants/sections";
import { TriangleDown, TriangleUp } from "../../Icons";
import { Tooltip } from "../Tooltip/Tooltip";
import { useTooltip } from "../Tooltip/useTooltip";
import { openSubmenuState } from "../utils/atoms";
import { Button, SubSections, TextAndArrow, Wrapper } from "./SectionButtons.styles";
import { SubSectionsButton } from "./SubSectionsButton";

export const FolderButton: FC<PropsType> = (props) => {
  const { section, subSections } = props;

  const { name, rout, text, icon, not } = section;

  const popoverHoverMethods = useTooltip();
  const { popoverHoverIsOpen, openPopoverHover, closePopoverHover } = popoverHoverMethods;

  const [menuIsExpand, setMenuIsExpand] = useAtom(menuIsExpandState);
  const [openSubMenu, setOpenSubMenu] = useAtom(openSubmenuState);

  const navigate = useNavigate();
  const location = useLocation();

  const handleOpenSubMenu = (type: string) =>
    openSubMenu === type ? setOpenSubMenu("") : setOpenSubMenu(type);

  const active =
    openSubMenu === ""
      ? location.pathname.includes(name) && (not ? !location.pathname.includes(not) : true)
      : openSubMenu === name;

  const canOpenSubMenu =
    !location.pathname.includes(name) ||
    (location.pathname.includes(name) && (not ? location.pathname.includes(not) : true));

  return (
    <Wrapper active={active}>
      <Button
        isExpand={menuIsExpand}
        active={active}
        onClick={() => {
          canOpenSubMenu && navigate(rout);
          !menuIsExpand ? setMenuIsExpand(true) : undefined;
          handleOpenSubMenu(name);
        }}
        aria-owns={popoverHoverIsOpen ? "popover-hover" : undefined}
        aria-haspopup="true"
        onMouseEnter={(evt) => openPopoverHover({ evt, type: "folderButton", text: text })}
        onMouseLeave={closePopoverHover}
      >
        <div className="icon">{icon}</div>{" "}
        {menuIsExpand && (
          <TextAndArrow active={active}>
            <p onClick={() => handleOpenSubMenu(name)}>{text}</p>

            {openSubMenu === name ? <TriangleUp /> : <TriangleDown />}
          </TextAndArrow>
        )}
      </Button>

      <SubSections isOpen={openSubMenu === name}>
        {subSections.map((subSection, i) =>
          subSection ? <SubSectionsButton key={i} subSection={subSection} /> : null
        )}
      </SubSections>

      <Tooltip type="folderButton" popoverHoverMethods={popoverHoverMethods} />
    </Wrapper>
  );
};

type PropsType = {
  section: SectionType;
  subSections: SectionType[];
};
