import { useAtomValue } from "jotai/utils";
import React from "react";
import { currentUserAtom } from "../../atoms/atomCurrentUser";
import { HeaderNavigation } from "../../uiKit";
import { employeeProfileState, useEmployeeProfileKeys } from "./utils/atoms";
import { NavigateButton } from "../../uiKit/Buttons";

export const useUserProfilePage = () => {
  // ------------------------------ АТОМЫ

  const { roleModel } = useAtomValue(currentUserAtom);
  const employeeProfile = useAtomValue(employeeProfileState);

  // ------------------------------ КОНСТАНТЫ
  const { queryKey, api } = useEmployeeProfileKeys();

  const titlePage = "Карточка работника";

  // ------------------------------ ПРОВЕРКА РОЛЕЙ

  const { canViewProfile: canView, canEditProfile: canEdit } = roleModel;

  // ------------------------------ ШАПКА - НАВИГАЦИЯ

  const navigation = (
    <HeaderNavigation
      title={{
        text: titlePage,
        element: <NavigateButton link={-1} title={titlePage} />,
      }}
    />
  );

  return {
    employeeProfile,

    api,
    queryKey,

    canView,
    canEdit,

    navigation,
  };
};
