import { useAtomValue } from "jotai/utils";
import React, { FC } from "react";
import { DateSwitcherTypeType, periodSwitcherTypeState } from "../../atoms/atoms";
import { periodName } from "../../constants/dates";
import { DateIntervalAtomType } from "../Header/HeaderNavigation/HeaderNavigation";
import * as elements from "./DateSwitcher.styles";
import { useDateSwitcher } from "./useDateSwitcher";

export const PeriodSwitcher: FC<PropsType> = (props) => {
  const { dateIntervalAtom } = props;

  const methods = useDateSwitcher({ dateIntervalAtom });
  const { handleClickPeriod } = methods;

  const { PeriodSwitcherButton, PeriodSwitcherButtonText, PeriodSwitcherContainer } = elements;

  const periodSwitcherType = useAtomValue(periodSwitcherTypeState);

  return (
    <PeriodSwitcherContainer>
      {["year", "quarter", "month", "period"].map((period, i) => (
        <PeriodSwitcherButton
          key={i}
          onClick={() =>
            periodSwitcherType !== period && handleClickPeriod(period as DateSwitcherTypeType)
          }
          enabled={periodSwitcherType === period}
        >
          <PeriodSwitcherButtonText>
            {periodName[period as DateSwitcherTypeType]}
          </PeriodSwitcherButtonText>
        </PeriodSwitcherButton>
      ))}
    </PeriodSwitcherContainer>
  );
};

type PropsType = {
  dateIntervalAtom?: DateIntervalAtomType;
};
