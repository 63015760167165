import saveAs from "file-saver";
import { useField, useFormikContext } from "formik";
import React, { FC, useEffect, useState } from "react";
import { FilesInput } from ".";
import { useDocumentTypeOptions } from "../../utils/getOptions";
import { EditButton, OkButton, TrashButton } from "../Buttons";
import { FormSubTitle } from "../Form/Form.styles";
import { Book } from "../Icons";
import { TextInput } from "../Inputs";
import { SelectInput } from "../SelectInput";
import * as elements from "./FilesInput.styles";

export const FileList: FC<PropsType> = (props) => {
  const { name, files, setFiles } = props;

  const { Text, Title, Description, FileBlock, Container } = elements;
  const { FileCardButtons, DocumentIconAndName, LoadBar } = elements;

  const [editing, setEditing] = useState<number>();
  const [fileNameForTextInput, setFileNameForTextInput] = useState<string>();

  const documentTypeOptions = useDocumentTypeOptions();

  const { values, setFieldValue } =
    useFormikContext<Record<string, FileList | undefined | string>>();

  const [{ value }] = useField<FileList | undefined | string>(name);

  const filesArr = value && typeof value !== "string" ? Array.from(value) : [];

  useEffect(() => {
    if (value?.length !== 0) {
      setFiles((prevState: File[]) => [...prevState, ...filesArr]);
    }

    files.forEach((file, i) => {
      setFieldValue(`documentName-${i}`, file.name);
      setFieldValue(`document-${i}`, file);
      setFieldValue(`documentType-${i}`, "");
    });
  }, [value]);

  useEffect(() => {
    setFieldValue("files", "");
  }, [files.length]);

  const handleRemove = (fileName: string) => {
    setFiles((prevState) => prevState.filter((file) => file.name !== fileName));
  };

  if (files.length === 0) return null;

  return (
    <>
      <FormSubTitle>Загруженные документы</FormSubTitle>

      {files.map((file, i) => {
        const hasFile = !file.type.includes("image");
        const fileName = file.name;
        const fileNameWithoutAccept = fileName?.slice(0, fileName.lastIndexOf("."));
        const fileAccept = fileName?.slice(fileName.lastIndexOf("."));
        const fileSize = `${Math.round(file.size / (1024 * 1024))}Мб`;

        if (values[`documentType-${i}`] === undefined) return null;

        return (
          <FileBlock key={i}>
            <Container status="wasFile" multiFile={false}>
              {fileName ? (
                <DocumentIconAndName>
                  <LoadBar status="loaded">
                    <Book />
                    {fileAccept}
                  </LoadBar>

                  <Text>
                    <Title
                      status="wasFile"
                      onClick={() => (hasFile ? saveAs(file, fileName) : undefined)}
                      needDownload={hasFile}
                    >
                      {fileNameWithoutAccept}
                    </Title>
                    {fileSize && <Description status="wasFile">{fileSize}</Description>}
                  </Text>
                </DocumentIconAndName>
              ) : (
                <img alt="picture" src={`${value}`} />
              )}

              <FileCardButtons>
                {editing === i ? null : <TrashButton onClick={() => handleRemove(fileName)} />}

                {editing === i ? (
                  <OkButton
                    onClick={() => {
                      file = new File([file], `${fileNameForTextInput}${fileAccept}`);

                      files[i] = file;

                      setFieldValue(`documentName-${i}`, `${fileNameForTextInput}${fileAccept}`);
                      setFieldValue(`document-${i}`, file);

                      setEditing(undefined);
                      setFileNameForTextInput(undefined);
                    }}
                  />
                ) : (
                  <EditButton
                    onClick={() => {
                      setEditing(i);
                      setFileNameForTextInput(fileNameWithoutAccept);
                    }}
                  />
                )}
              </FileCardButtons>

              {editing === i && <TextInput name={`documentName-${i}`} internal />}
            </Container>

            <FilesInput
              name={`document-${i}`}
              label=""
              accept={[".pdf", ".doc", ".docx"]}
              displayNone
            />

            <SelectInput
              name={`documentType-${i}`}
              options={[{ id: "", name: "" }, ...documentTypeOptions]}
              insideLabel="Тип документа *"
            />
          </FileBlock>
        );
      })}
    </>
  );
};

type PropsType = {
  name: string;
  files: File[];
  setFiles: (arg: (arg: File[]) => File[]) => void;
};
