import styled from "@emotion/styled/macro";
import { borders, button, colors, flex, margins, text } from "../../../styles";

export const Container = styled.button<{ long: boolean; xLong: boolean }>`
  ${button};

  ${flex({ horizontal: "start" })};
  ${borders({ radius: 8 })};

  width: ${({ long, xLong }) => (long ? "180px" : xLong ? "328px" : "135px")};
  height: 40px;

  p {
    ${text({ size: 15, height: 20, weight: 400, color: colors.grayscaleBeautifulBlack })};
    ${margins({ m: "0 0 0 8" })};
  }

  svg {
    ${margins({ m: "0 0 0 16" })};
  }

  &:hover {
    background: ${colors.grayscaleHoverBackground};

    svg > path {
      fill: ${colors.grayscaleLabel};
    }
  }
`;
