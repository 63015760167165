import { useAtom } from "jotai";
import React from "react";
import * as Yup from "yup";
import { FilterBody } from "../../../../uiKit/Filters/Filters.styles";

import { SelectInput } from "../../../../uiKit";
import { statusEmployeeTypeOptions } from "../../../../constants/options";
import { withoutSpaces } from "../../../../constants/regexs";
import { notJustSpaces } from "../../../../constants/forValidationSchemes";
import { absoluteInitialValues, filtersState } from "../utils/atoms";

export const useFilters = () => {
  // ------------------------------ АТОМЫ

  const [filters, setFilters] = useAtom(filtersState);

  // ------------------------------ ФОРМА - ПОЛЯ

  const getFieldsFilters = (additional?: JSX.Element) => (
    <FilterBody>
      <SelectInput
        name="status"
        size="small"
        options={statusEmployeeTypeOptions}
        outsideLabel="Статус"
      />
      {additional}
    </FilterBody>
  );

  const fieldsFilters = getFieldsFilters();
  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesFilters = {
    status: filters.status,
    startDate: filters.startDate,
    endDate: filters.endDate,
  };

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchemaFilters = Yup.object().shape({
    status: Yup.string().matches(withoutSpaces, notJustSpaces),
  });

  return {
    getFieldsFilters,
    fieldsFilters,

    filters,
    setFilters,
    absoluteInitialValues,

    initialValuesFilters,
    validationSchemaFilters,
  };
};
