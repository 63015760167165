import styled from "@emotion/styled/macro";
import { colors, flex, margins, text } from "../../styles";

export const Container = styled.div`
  ${flex({ isColumn: true, gap: 2 })};
`;

export const Row = styled.div<{ column: boolean }>`
  ${flex({ gap: 2 })};
  flex-flow: ${({ column }) => (column ? "column" : "wrap")};
`;

export const Title = styled.p`
  ${margins({ p: "0 4 0 0" })};
  ${text({ weight: 400, size: 15, height: 20, color: colors.grayscalePlaceholder })};
`;

export const Description = styled.p`
  flex: 1 1 calc(50% - 8px);
  ${text({ weight: 400, size: 15, height: 20, color: colors.grayscaleBody })};
  ${margins({ m: "0 0 2 0" })};
`;
