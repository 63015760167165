import styled from "@emotion/styled/macro";
import { borders, colors, margins } from "../../../styles";

const { grayscaleLine } = colors;

export const Container = styled.div`
  ${borders({ side: "top", color: grayscaleLine })};
  ${margins({ p: "16 64" })};

  background: ${colors.primaryBackgroundOpacity};
`;
