import React, { FC } from "react";
import { useUpdateAtom } from "jotai/utils";
import { modalDataVaccinationRecordState } from "../../utils/atoms";
import { AppointmentType } from "../../../../api/apiAppointment";
import { formatParseDate, timeString } from "../../../../utils/workingWithDates";
import { Record, RecordContainer, Text } from "../../UserProfile.styles";
import { useViewForms } from "../../VaccinationRecord/hooks/useForms";
import { ViewForm } from "../../../../uiKit/Form/ViewForm";

export const Appointments: FC<PropsType> = (props) => {
  const { appointments, showInfection } = props;

  const setModalDataView = useUpdateAtom(modalDataVaccinationRecordState);
  const formMethods = useViewForms();
  const { modalViewData } = formMethods;
  const onView = (appointment: AppointmentType) => () => {
    const { date, abroad, vaccinationPlan, time } = appointment;
    const { infection, healthCenter } = vaccinationPlan || {};
    const { endTime, startTime } = time || {};

    const modalObject = {
      date: formatParseDate(date),
      infection: infection?.name,
      time: timeString(startTime, endTime),
      healthCenter: healthCenter?.shortName,
      abroad,
    };

    setModalDataView(modalObject);
  };

  return (
    <RecordContainer>
      {appointments?.length ? (
        appointments.map((appointment) => {
          const { date, abroad, vaccinationPlan, time, id } = appointment;
          const { infection, healthCenter } = vaccinationPlan || {};
          const { endTime, startTime } = time || {};

          return (
            <Record onClick={onView(appointment)} key={id} type="record">
              <Text>{formatParseDate(date)}</Text>
              {showInfection && <Text>{infection?.name}</Text>}
              <Text disableTwoSelection={!showInfection}>{timeString(startTime, endTime)}</Text>
              <Text>{healthCenter?.shortName}</Text>
              {abroad ?? <Text>Поездка за рубеж</Text>}
            </Record>
          );
        })
      ) : (
        <Record type="empty">Актуальных записей нет</Record>
      )}
      {modalViewData && <ViewForm methods={formMethods} />}
    </RecordContainer>
  );
};

type PropsType = {
  appointments?: AppointmentType[];
  showInfection?: boolean;
};
