import { Formik } from "formik";
import React, { FC } from "react";
import { ConfirmModal, Page, Table } from "../../../uiKit";
import { useVaccinationSettingsPage } from "./useVaccinationSettingsPage";

export const VaccinationSettingsPage: FC = () => {
  const methods = useVaccinationSettingsPage();
  const { onSubmit, onConfirm, isOpenConfirmModal, initialValuesForm } = methods;

  return (
    <>
      <Formik initialValues={initialValuesForm} onSubmit={onSubmit}>
        {() => (
          <Page methods={methods}>
            <Table methods={methods} needPagination needScroll />
          </Page>
        )}
      </Formik>

      <ConfirmModal
        questionText=""
        successText="Готово"
        isOpen={isOpenConfirmModal}
        onClose={onConfirm}
      />
    </>
  );
};
