import produce from "immer";
import { useAtom } from "jotai";
import { ReactNode } from "react";
import { openedListsState, selectedColumnsState, selectedRowsState } from "../../atoms/atoms";

export const useTable = <OBJ>() => {
  const [selectedRows, setSelectedRows] = useAtom(selectedRowsState);

  const selectingRows = (identifier: string) => {
    const allIdentifiersSelected = selectedRows.includes(identifier);

    allIdentifiersSelected
      ? setSelectedRows((draft) => draft.filter((item) => !selectedRows.includes(item)))
      : setSelectedRows([...selectedRows, identifier]);
  };

  const isRowSelected = (identifier: string) => selectedRows.includes(identifier);

  const [selectedColumns, setSelectedColumns] = useAtom(selectedColumnsState);

  const selectingColumns = (number: number, setUserSettings?: (arg: number[]) => void) => {
    const index = selectedColumns.findIndex((row) => row === number);

    const newSelectedColumns = produce(selectedColumns, (draft) => {
      index === -1 ? draft.push(number) : draft.splice(index, 1);
    });

    setSelectedColumns(newSelectedColumns);

    setUserSettings && setUserSettings(newSelectedColumns);
  };

  const isColumnSelected = (number: number) =>
    selectedColumns.findIndex((column) => column === number) !== -1;

  const [openedList, setOpenedList] = useAtom(openedListsState);

  const openingList = (identifier: string) => {
    const index = openedList.findIndex((row) => row === identifier);

    setOpenedList((draft) => {
      index === -1 ? draft.push(identifier) : draft.splice(index, 1);
    });
  };

  const listIsOpened = (identifier: string) =>
    openedList.findIndex((row) => row === identifier) !== -1;

  const formatData = (
    object: OBJ,
    titlesInit: TitlesType
  ): Record<string, string | string[] | number | undefined> =>
    Object.keys(object)
      .filter((key) => columnsNames(titlesInit).includes(key))
      .reduce((obj, key) => ({ ...obj, [key]: object[key as keyof typeof object] }), {});

  const getGrids = (gridInit: GridsType) =>
    gridInit
      // .filter((item) => (!item.titleId ? item : selectedColumns.includes(item.titleId)))
      .map(({ size }) => size)
      .join(" ");

  const getTitles = (titlesInit: TitlesType) =>
    titlesInit.filter(({ id }) => selectedColumns.includes(id));

  const columnsNames = (titlesInit: { id: number; title: string; name: string }[]) =>
    titlesInit.filter(({ id }) => selectedColumns.includes(id)).map(({ name }) => name);

  return {
    selectingRows,
    isRowSelected,
    selectedRows,
    setSelectedRows,

    selectedColumns,
    setSelectedColumns,
    selectingColumns,
    isColumnSelected,

    openingList,
    listIsOpened,

    formatData,

    getGrids,
    getTitles,
    columnsNames,
  };
};

export type GridsType = {
  titleId: number | null;
  size: string;
}[];

export type TitlesType = {
  id: number;
  title: string;
  name: string;
  icon?: ReactNode;
  iconPosition?: "left" | "right";
}[];
