import styled from "@emotion/styled/macro";
import { DatePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import { borders, colors, flex, grid, margins, shadow, text } from "../../styles";

const { grayscalePlaceholder, grayscaleHoverBackground, grayscaleLabel, grayscaleAsh } = colors;
const { redBackground, redDefault, primaryActiveElement } = colors;

export const Container = styled.div<ContainerPropsType>`
  ${({ column, merge }) =>
    grid({
      gap: merge ? 0 : 16,
      columns: column ? undefined : `1fr ${merge ? "17px" : ""} 1fr`,
      rows: column ? `1fr ${merge ? "17px" : ""} 1fr` : undefined,
    })};
  ${borders({ radius: 12 })};

  position: relative;
  align-items: center;
  width: 100%;
  background: ${({ merge, backgroundcolor }) => (merge ? `${backgroundcolor}` : "transparent")};
`;

export const Hyphen = styled.span<{ needLabel: boolean }>`
  ${text({ size: 16, height: 23, weight: 400, color: grayscalePlaceholder, align: "center" })};
  ${({ needLabel }) => (needLabel ? margins({ m: "12 0 0" }) : undefined)};
`;

export const ContainerCalendar = styled.div`
  ${margins({ m: "0" })};

  position: relative;
`;

export const Calendar = styled(DatePicker)``;

export const InputAndIcon = styled.div<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? "no-drop" : "auto")};

  * {
    cursor: inherit !important;
  }
`;

export const Input = styled(TextField)<InputPropsType>`
  ${borders({ none: true, radius: 16 })};
  ${margins({ p: "0" })};

  width: 100%;
  height: 56px;
  background: ${({ haserror, backgroundcolor }) => (haserror ? redBackground : backgroundcolor)};

  input {
    ${margins({ p: "0 0 0 14" })};
  }

  .MuiFormLabel-root {
    ${text({ size: 16, height: 24, weight: 400, color: grayscalePlaceholder })};
    ${({ needlabel }) => margins({ m: needlabel ? "8 0 0" : "0" })};

    opacity: 1;
  }

  .MuiInputLabel-shrink {
    display: none;
  }

  /* .MuiInputBase-input {
    ${margins({ p: "0" })};
  } */

  .Mui-disabled {
    -webkit-text-fill-color: ${colors.grayscaleInputDisabled};
  }
  .MuiInputBase-root {
    ${text({ size: 16, height: 24, weight: 400, color: grayscaleAsh })};
    ${({ needlabel }) => margins({ m: needlabel ? "16 0 0" : "0", p: "0" })};

    height: ${({ needlabel }) => (needlabel ? "48px" : "56px")};

    .MuiInputBase-input::placeholder {
      ${text({ size: 16, height: 24, weight: 400, color: grayscalePlaceholder })};

      opacity: 1;
    }

    .MuiInputAdornment-root {
      .MuiButtonBase-root {
        ${({ needlabel }) => margins({ m: needlabel ? "-5 6 0 0" : "0 6 0 0" })};

        width: 40px;
        height: 40px;
        z-index: 1;

        svg {
          display: none;
        }
      }
    }

    .MuiOutlinedInput-notchedOutline {
      ${borders({ none: true })};
    }
  }
`;

export const Icon = styled.div<{ needlabel: number }>`
  ${flex({ totalCenter: true })};
  ${borders({ totalRadius: true })};

  width: 40px;
  height: 40px;
  position: absolute;
  top: ${({ needlabel }) => (needlabel ? "14px" : "9px")};
  right: 6px;

  :hover {
    background: ${grayscaleHoverBackground};
  }
`;

export const Error = styled.p`
  ${text({ size: 12, height: 12, weight: 400, color: redDefault })};

  position: absolute;
  bottom: -14px;
  left: 16px;
  white-space: nowrap;
`;

export const PaperProps = {
  sx: {
    borderRadius: 4,
    boxShadow: shadow,
    padding: 1,

    "&:has(div)": { margin: "30px" },

    ".PrivatePickersYear-yearButton, .PrivatePickersMonth-root, .MuiPickersDay-root": {
      "&:hover, &:focus, &:active": {
        background: grayscaleHoverBackground,
      },
    },

    ".PrivatePickersYear-yearButton.Mui-selected, .PrivatePickersMonth-root.Mui-selected, .MuiPickersDay-root.Mui-selected ":
      {
        background: primaryActiveElement,
        color: grayscaleLabel,

        "&:hover": {
          background: grayscaleHoverBackground,
          cursor: "default",
        },

        "&:focus, &:active": {
          background: primaryActiveElement,
          color: grayscaleLabel,
        },
      },
  },
};

type ContainerPropsType = {
  column: boolean;
  merge: boolean;
  backgroundcolor: string;
};

type InputPropsType = {
  needlabel: number;
  haserror: number;
  backgroundcolor: string;
};
