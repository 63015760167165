import { monthNumbers } from "../../../../constants/dates";
import { DiseasesType } from "../../../../api/apiDisease";
import { areIntervalsOverlapping, getDaysInMonth, getMonth } from "date-fns";
import { endOfToday, parseDate, today } from "../../../../utils/workingWithDates";
import { DateInterval } from "../hooks/useChartsBar";

// ------------------------------ СУММАРНОЕ КОЛИЧЕСТВО ДНЕЙ НА БОЛЬНИЧНОМ ЗА ГОД

export const getSickDaysInYear = (diseases: DiseasesType) =>
  monthNumbers.reduce((sum, monthNumber) => {
    const currentMonth = getSickDaysInMonth(diseases, monthNumber - 1);
    return currentMonth === null ? sum : sum + currentMonth;
  }, 0);

// ------------------------------ ПОЛУЧЕНИЕ КОЛИЧЕСТВА ДНЕЙ НА БОЛЬНИЧНОМ в МЕСЯЦ

export const getSickDaysInMonth = (diseases: DiseasesType, monthSelect: number) => {
  const monthNumber = monthSelect - 1;
  if (monthNumber > getMonth(endOfToday) || !diseases) return null;

  let sickDays = 0;
  const monthIntervals: DateInterval[] = [];

  diseases.forEach(({ start, end }) => {
    const fullInterval = {
      start: parseDate(start),
      end: end ? parseDate(end) : today,
    };

    if (monthNumber < getMonth(fullInterval.start) || monthNumber > getMonth(fullInterval.end))
      return;
    monthIntervals.push(clampInterval(fullInterval, monthNumber));
  });

  let monthIndex = 0;
  if (monthIntervals.length)
    do {
      const endIntervalIndex = intervalCollapse(monthIntervals, monthIndex);
      const startInterval = monthIntervals[monthIndex];
      const endInterval = monthIntervals[endIntervalIndex];
      const start =
        startInterval.start.getDate() <= endInterval.start.getDate()
          ? startInterval.start
          : endInterval.start;
      const end =
        endInterval.end.getDate() >= startInterval.end.getDate()
          ? endInterval.end
          : startInterval.end;

      sickDays += start.getDate() === 1 ? end.getDate() : end.getDate() + 1 - start.getDate();
      monthIndex = monthIndex === endIntervalIndex ? endIntervalIndex + 1 : endIntervalIndex + 1;
    } while (monthIntervals.length - 1 >= monthIndex);

  return sickDays;
};

const intervalCollapse = (intervals: DateInterval[], index: number): number => {
  if (!intervals[index + 1]) return index;

  if (areIntervalsOverlapping(intervals[index], intervals[index + 1], { inclusive: true }))
    return intervalCollapse(intervals, index + 1);
  else return index;
};

// ------------------------------ ОГРАНИЧЕНИЕ ИНТЕРВАЛА МЕСЯЦОМ

const clampInterval = (interval: DateInterval, month: number) => {
  const resultInterval = { start: interval.start, end: interval.end };

  if (getMonth(resultInterval.start) != month)
    resultInterval.start = new Date(resultInterval.start.getFullYear(), month, 1);
  if (getMonth(resultInterval.end) != month)
    resultInterval.end = new Date(
      resultInterval.end.getFullYear(),
      month,
      getDaysInMonth(new Date(interval.start).setMonth(month))
    );

  return resultInterval;
};
