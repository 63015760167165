import styled from "@emotion/styled/macro";
import { Autocomplete, AutocompleteProps, ChipTypeMap, Popper } from "@mui/material";
import { ElementType } from "react";
import * as styles from "../../styles";

const { borders, colors, grid, margins, overflowOneLine, shadow, text } = styles;

export const Container = styled.div`
  position: relative;
`;

export const Item = styled(Autocomplete)<ItemPropsType>`
  ${borders({ radius: 16 })};
  ${({ haslabel }) => margins({ p: haslabel ? "8 16" : "16" })};

  width: 100%;
  height: 56px;
  background: ${({ second, color, haserror }) =>
    haserror ? colors.redBackground : second ? colors.transparent : color};
  border: ${({ second }) => (second ? `1px solid ${colors.grayscaleInput}` : "none")};
  cursor: pointer;

  .MuiOutlinedInput-notchedOutline {
    ${borders({ none: true })};
  }

  .MuiOutlinedInput-root {
    ${({ haslabel }) => margins({ m: haslabel ? "16 0 0" : "0", p: "0 0 8" })};
  }

  .MuiButtonBase-root {
    width: 40px;
    height: 40px;
    position: absolute;
    top: ${({ haslabel }) => (haslabel ? "-16px" : "-8px")};
    right: -8px;

    :hover {
      background: ${colors.grayscaleHoverBackground};

      & > svg > path {
        fill: ${colors.grayscaleLabel};
      }
    }
  }

  .MuiAutocomplete-clearIndicator {
    display: none;
  }

  .MuiChip-root {
    display: none;
  }

  input {
    &.Mui-disabled {
      -webkit-text-fill-color: ${colors.grayscaleInputDisabled};
    }
    ${margins({ p: "0", needImportant: true })};

    z-index: 1;
  }

  label {
    ${text({ size: 12, height: 14, weight: 600, color: colors.grayscaleLabel })};
    ${margins({ m: "0" })};

    position: absolute;
    left: 0;
    top: 0;
    transform: none;

    &.Mui-focused {
      color: ${colors.grayscaleLabel};
    }
  }
` as <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap["defaultComponent"]
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent> & {
    haslabel: number;
    second: number;
    color: string;
    haserror: number;
  }
) => JSX.Element;

export const MenuItemWrapper = styled.div`
  ${grid({ autoFlowColumn: true })};
  ${overflowOneLine};
  ${text({ size: 15, height: 20, weight: 400 })};
`;

export const MenuCodeWrapper = styled.div`
  ${overflowOneLine};

  width: 54px;
`;

export const PaperProps = (size: string) => {
  return {
    sx: {
      width: size === "small" ? 372 : 472,
      maxHeight: 264,
      borderRadius: 4,
      boxShadow: shadow,
      overflowY: "hidden",
      ml: -2,
      padding: 1,

      ul: {
        width: "100%",
        maxHeight: 247,
        overflowY: "auto",
        padding: 0,

        li: {
          height: 56,
          borderRadius: 2,
          marginBottom: 1,
          px: 2,
          py: 0,

          "&:hover": { background: colors.grayscaleHoverBackground },
          "&:last-child": { marginBottom: 0 },
        },

        "::-webkit-scrollbar": {
          width: 8,
        },

        "::-webkit-scrollbar-thumb": {
          background: colors.grayscaleLine,
          borderRadius: "99em",
        },
      },
    },
  } as const;
};

export const PopperComponent = styled(Popper)`
  ${margins({ p: "16 0 0" })};
`;

export const Error = styled.p`
  ${text({ size: 12, height: 12, weight: 400, color: colors.redDefault })};

  position: absolute;
  bottom: -14px;
  left: 16px;
  white-space: nowrap;
`;

type ItemPropsType = {
  haslabel: number;
  second: number;
  color: string;
  haserror: number;
};
