import React, { FC, useEffect } from "react";
import { Summary } from "../../../components";
import { Form, NotificationModal, Page } from "../../../uiKit";
import { CalendarView } from "./CalendarView/CalendarView";
import { useCalendarPage } from "./useCalendarPage";

export const CalendarPage: FC = () => {
  const methods = useCalendarPage();
  const { loading, modalData, queryKey, canEdit, canViewDay } = methods;
  const { summaryData, setDateSwitcherType, getDayVaccinations } = methods;
  const { notificationText, handleClose } = methods;

  useEffect(() => {
    setDateSwitcherType("month");
  }, []);

  return (
    <Page methods={methods} needScroll>
      <Summary title="Сводные данные по вакцинации" bold content={summaryData} />
      <CalendarView
        getDayVaccinations={getDayVaccinations}
        loading={loading}
        queryKey={queryKey}
        canEdit={canEdit}
        canViewDay={canViewDay}
      />
      {modalData && <Form methods={methods} isEdit />}

      {notificationText && (
        <NotificationModal
          message={notificationText}
          onClose={handleClose}
          isOpen={!!notificationText}
        />
      )}
    </Page>
  );
};
