import React, { FC, useState } from "react";
import { ConfirmModal } from "../../../../uiKit";
import { AddListElement } from "../../../../components/AddList/AddListElement";
import { AddList } from "../../../../components";
import { InfectionsDiseaseType } from "../../../../api/apiInfectionDiseases";
import { UseMutationResult } from "react-query";
import { AxiosResponse } from "axios";
import { NotificationSettingsType } from "../../../../api/apiVaccination";

export const InfectionList: FC<PropsType> = (props) => {
  const { infections, field, canEdit, id, settings, name } = props;

  const { deletion, setModalData } = props;

  const [deletionModalData, setDeletionModalData] = useState<string | undefined>();

  const infectionName = infections.find(({ id }) => id === deletionModalData)?.name;

  const handleDeletion = !settings
    ? undefined
    : async () => {
        const value = settings[field];
        const pastArray = Array.isArray(value) ? value : [];

        const clearArray = pastArray
          .filter(({ id }) => id !== deletionModalData)
          .map(({ id }) => ({ id }));

        return [
          await deletion.mutateAsync({
            id: settings.id,
            data: { [`${field}`]: clearArray },
          }),
        ];
      };

  return (
    <>
      <AddList
        label={`Всего инфекционных заболеваний: ${infections.length}`}
        onAdd={
          canEdit
            ? () => {
                setModalData({
                  id,
                  name,
                  infections,
                  field,
                } as unknown as NotificationSettingsType);
              }
            : undefined
        }
      >
        {infections.map((infection) => (
          <AddListElement key={infection.id} item={infection} onDelete={setDeletionModalData} />
        ))}
      </AddList>
      {deletionModalData && handleDeletion && (
        <ConfirmModal
          questionText={`Вы действительно хотите удалить ${infectionName}?`}
          successText="Готово"
          isOpen={!!deletionModalData}
          onClose={() => setDeletionModalData(undefined)}
          onDelete={handleDeletion}
        />
      )}
    </>
  );
};

type PropsType = {
  infections: InfectionsDiseaseType;
  id: string;
  field: keyof NotificationSettingsType;
  canEdit: boolean;
  settings: NotificationSettingsType | undefined;
  deletion: UseMutationResult<AxiosResponse<unknown, unknown>, unknown, unknown, void>;
  setModalData: (modalData: NotificationSettingsType) => void;
  name: string;
};
