import React from "react";
import { usePopoverHover } from "../../../../uiKit/PopoverHover/usePopoverHover";
import { Day, DayNumber, ExpandDayContainer, PopoverDay } from "./CalendarView.styles";
import { VaccinationDatesType } from "../../../../api/apiVaccination";
import { VaccinationCard } from "./VaccinationCard";

export const CalendarDay = (props: PropsType) => {
  const { isCurrent, dayData, dayNumber, canEdit, canViewDay } = props;

  const { openPopoverHover, closePopoverHover, propsPopoverHover } = usePopoverHover({
    anchorOrigin: { vertical: "center", horizontal: "center" },
    transformOrigin: { vertical: "center", horizontal: "center" },
  });

  const { isOpen, id, transformOrigin, anchorOrigin, onClose, element } = propsPopoverHover;

  return (
    <Day
      onClick={openPopoverHover}
      isCurrentDay={isCurrent}
      aria-owns={propsPopoverHover.id}
      aria-haspopup="true"
    >
      <DayNumber>{dayNumber}</DayNumber>
      {dayData?.length && <VaccinationCard vaccinationData={dayData[0]} />}

      <PopoverDay
        id={id}
        open={isOpen}
        anchorEl={element}
        onClose={onClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <ExpandDayContainer onMouseLeave={closePopoverHover}>
          <DayNumber>{dayNumber}</DayNumber>
          {dayData?.map((vaccinationData) => (
            <VaccinationCard
              vaccinationData={vaccinationData}
              onEdit={closePopoverHover}
              key={vaccinationData.id}
              isOpen
              canEdit={canEdit}
              canViewDay={canViewDay}
            />
          ))}
        </ExpandDayContainer>
      </PopoverDay>
    </Day>
  );
};

type PropsType = {
  dayNumber: number;
  dayData?: VaccinationDatesType;
  loading?: boolean;
  isCurrent: boolean;
  canEdit: boolean;
  canViewDay: boolean;
};
