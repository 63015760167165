import { useField } from "formik";
import { useAtom } from "jotai";
import React, { FC, useEffect, useRef } from "react";
import { statusIconInputState } from "../../atoms/atoms";
import { prefixIcon } from "../Icons/IconSvg/IconSvg";
import { preventDefault } from "../../utils/preventDefault";
import { TrashButton } from "../Buttons";
import { ArrowRound, Check, CrossOnCircle, ExclamationMark, Export } from "../Icons";
import { Container, Input } from "./IconInput.styles";
import { useIconInput } from "./useIconInput";

export const IconInput: FC = () => {
  const name = "icon";

  const [status, setStatus] = useAtom(statusIconInputState);

  const [{ value }, { error }] = useField<File | string | undefined>(name);
  const ref = useRef<HTMLInputElement>(null);

  const { onChange, handleRemove, handleDrop, element } = useIconInput(name);

  useEffect(() => {
    !!value && setStatus("wasFile");
    return () => setStatus("empty");
  }, []);

  const icon = !!value && typeof value !== "string" ? value : undefined;
  const iconName = icon?.name;
  const iconSize = icon ? `${Math.round(icon?.size / 1024)}Кб` : "";

  return (
    <>
      {status === "empty" ? (
        <Container
          status={status}
          onClick={() => ref.current?.click()}
          onDragOver={preventDefault}
          onDragLeave={preventDefault}
          onDrop={handleDrop}
        >
          {element({
            icon: <Export />,
            title: "Прикрепите иконку для отображения *",
            description: "Подходят файлы в формате .svg 24x24px до 20Кб",
          })}
        </Container>
      ) : (
        <Container status={status} as="div">
          {status === "error" ? (
            element({
              icon: <ExclamationMark />,
              buttonIcon: <ArrowRound />,
              onClick: () => handleRemove(ref),
              title: iconName,
              description: error,
            })
          ) : status === "progress" ? (
            element({
              buttonIcon: <CrossOnCircle />,
              onClick: () => handleRemove(ref),
              needProgress: true,
              title: iconName,
              description: iconSize,
            })
          ) : status === "success" ? (
            element({
              icon: <Check />,
              needEmptiness: true,
              title: "Иконка загружена",
              description: "Обработка завершается...",
            })
          ) : status === "wasFile" ? (
            <>
              <img
                alt="picture"
                src={value instanceof File ? URL.createObjectURL(value) : `${prefixIcon}${value}`}
              />

              <TrashButton onClick={() => handleRemove(ref)} />
            </>
          ) : null}
        </Container>
      )}

      <Input ref={ref} name={name} type="file" accept=".svg" onChange={onChange} />
    </>
  );
};
