import React, { FC } from "react";
import { useData } from "./hooks/useData";
import { EmptyContainer, PageContainer } from "../UserProfile.styles";
import { EmptyBox } from "../../../uiKit/Icons";
import { Page } from "../../../uiKit";
import { Panel } from "../components/Panel/Panel";
import { PageSwitcher } from "../components/PageSwitcher/PageSwitcher";
import { Appointments } from "../components/Appointments/Appointments";

export const VaccinationRecord: FC = () => {
  const methods = useData();

  const { data } = methods;

  return (
    <Page methods={methods}>
      <Panel />
      <PageContainer>
        <PageSwitcher />

        {data.length ? (
          <Appointments showInfection appointments={data} />
        ) : (
          <EmptyContainer>
            <EmptyBox />
            <p>Записей на вакцинацию пока нет</p>
          </EmptyContainer>
        )}
      </PageContainer>
    </Page>
  );
};
