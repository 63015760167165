import React, { FC } from "react";
import { TextInput } from "../../../../uiKit";
import { FieldContainer } from "../../../../uiKit/Form/Form.styles";
import { LabelField } from "../../../../uiKit/Form/LabelField";
import { AgesContainer } from "../InfectionDisease.styles";

export const AgesFields: FC<PropsType> = (props) => {
  const { nameEnd, nameStart, outsideLabel } = props;

  return (
    <FieldContainer>
      <LabelField outsideLabel={outsideLabel} />
      <AgesContainer>
        <TextInput name={nameStart} placeholder="От" type="number" insideLabel="От" />
        <TextInput name={nameEnd} placeholder="До" type="number" insideLabel="До" />
      </AgesContainer>
    </FieldContainer>
  );
};

type PropsType = {
  nameStart: string;
  nameEnd: string;
  outsideLabel: string;
};
