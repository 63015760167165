import { useAtom } from "jotai";
import { infectionsDiseasesState, modalDataState, useInfectionDiseasesQuery } from "../utils/atoms";
import { useAtomValue } from "jotai/utils";
import { currentUserAtom } from "../../../../atoms/atomCurrentUser";
import { useLoadableData } from "../../../../utils/useLoadableData";
import { getQuestionText } from "../../../../utils/getQuestionText";
import React from "react";
import { HeaderNavigation } from "../../../../uiKit";
import { DICTIONARY } from "../../../../constants/routes";

export const useData = () => {
  // ------------------------------ АТОМЫ
  const [modalData, setModalData] = useAtom(modalDataState);
  const { roleModel, res } = useAtomValue(currentUserAtom);

  const { totalCount, loadableData, data } = useLoadableData(infectionsDiseasesState, []);

  // ------------------------------ ХУКИ

  const { queryKey, api } = useInfectionDiseasesQuery();

  // ------------------------------ КОНСТАНТЫ

  const { canViewInfectionDiseaseDictionary: canView, canEditInfectionDiseaseDictionary: canEdit } =
    roleModel;

  const titlePage = "Инфекционные заболевания";

  const questionWords = ["заболевание", "заболевания", "заболеваний"];
  const questionText = getQuestionText(questionWords);

  const organization = res.organization;

  // ------------------------------ ШАПКА - НАВИГАЦИЯ

  const navigation = (
    <HeaderNavigation
      title={titlePage}
      questionText={questionText}
      needSearchButton
      needAddButton={canEdit}
      section={{ name: "Справочники", rout: DICTIONARY }}
    />
  );

  return {
    organization,

    data,
    loadableData,
    totalCount,

    navigation,

    questionWords,
    questionText,

    canView,
    canEdit,

    modalData,
    setModalData,

    queryKey,
    api,
  };
};
