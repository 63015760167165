import React, { FC } from "react";
import { colors } from "../../styles";

export const Trash: FC<PropsType> = ({ onClick }) => (
  <svg
    onClick={onClick}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9842 6.72033C17.1133 6.76644 17.2305 6.81229 17.3366 6.85715C17.2305 6.90201 17.1133 6.94785 16.9842 6.99397C15.8039 7.41548 14.042 7.71429 12 7.71429C9.95805 7.71429 8.19606 7.41548 7.01582 6.99397C6.88669 6.94785 6.76946 6.90201 6.66344 6.85715C6.76946 6.81229 6.88669 6.76644 7.01582 6.72033C8.19606 6.29881 9.95805 6 12 6C14.042 6 15.8039 6.29881 16.9842 6.72033ZM4 6.85772C4.00001 6.88215 4.00063 7.67949 4.04065 8.88126C4.1236 11.3724 4.37585 15.6012 5.14286 18.2857C5.40296 19.1961 6.07966 20.0312 6.85714 20.5715C7.71457 21.1673 8.33171 21.1591 9.26705 21.1467C9.40824 21.1448 9.55668 21.1429 9.71429 21.1429H14.2857H14.3611C15.3084 21.1433 15.7849 21.1435 16.5714 20.5715C17.3371 20.0146 18.0256 19.1961 18.2857 18.2857C19.0505 15.6089 19.5594 11.3967 19.8124 8.90283C19.904 7.99967 19.9621 7.32187 19.9866 7.02398C19.9955 6.96877 20 6.91315 20 6.85715C20 5.27919 16.4183 4 12 4C7.58172 4 4 5.27976 4 6.85772ZM6.13919 10.9504C6.10233 10.3421 6.0752 9.76752 6.05526 9.25147C6.35335 9.32083 6.63939 9.39639 6.89045 9.47673C7.36787 9.6295 7.71393 9.8029 8.1697 10.0313C8.37218 10.1327 8.59631 10.245 8.86324 10.3712C9.67092 10.7529 10.6778 11.1429 12 11.1429C13.3222 11.1429 14.3291 10.7529 15.1368 10.3712C15.4037 10.245 15.6278 10.1327 15.8303 10.0313C16.2861 9.80292 16.6321 9.6295 17.1096 9.47673C17.3085 9.41308 17.5293 9.35243 17.7607 9.29549C17.7102 9.76866 17.652 10.2863 17.586 10.8308C17.301 13.1815 16.887 15.9013 16.3627 17.7363C16.2602 18.0949 15.9181 18.5736 15.3951 18.954C15.1858 19.1062 15.1205 19.1146 15.1079 19.1162L15.1061 19.1165C15.0746 19.1228 15.0104 19.1323 14.8725 19.1377C14.7363 19.143 14.584 19.1429 14.3531 19.1429H14.2857H9.71429C9.54936 19.1429 9.39548 19.1448 9.26053 19.1466L9.2463 19.1468C9.11333 19.1486 9.00348 19.15 8.89961 19.15C8.68369 19.15 8.55629 19.1431 8.46226 19.1309C8.34763 19.1161 8.23568 19.0939 7.99846 18.9291C7.5199 18.5965 7.17489 18.1178 7.06591 17.7363C6.5596 15.9642 6.28148 13.2982 6.13919 10.9504Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);

type PropsType = {
  onClick?: () => void;
};
