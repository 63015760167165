import { useField } from "formik";
import React, { FC } from "react";
import { SelectInput, TextInput } from "../../../../uiKit";
import { FieldContainer } from "../../../../uiKit/Form/Form.styles";
import { LabelField } from "../../../../uiKit/Form/LabelField";
import { getInclinedWord } from "../../../../utils/getInclinedWord";
import { inclinesInterval } from "../../../../utils/workingWithDates";
import { PeriodContainer } from "../InfectionVaccination.styles";

export const PeriodFields: FC<PropsType> = (props) => {
  const { periodName, unitName, options, outsideLabel } = props;

  const [{ value }] = useField<number>(periodName);

  const inclinedOptions = options.map(({ id }) => ({
    id,
    name: getInclinedWord(value, inclinesInterval[id]),
  }));

  return (
    <FieldContainer>
      <LabelField outsideLabel={outsideLabel} />

      <PeriodContainer>
        <TextInput
          name={periodName}
          integer
          placeholder="—"
          type="number"
          insideLabel="Числовое значение"
        />
        <SelectInput
          name={unitName}
          options={inclinedOptions}
          size={250}
          insideLabel="Единица измерения"
        />
      </PeriodContainer>
    </FieldContainer>
  );
};

type PropsType = {
  outsideLabel: string;
  periodName: string;
  unitName: string;
  options: { id: string; name: string }[];
};
