import styled from "@emotion/styled/macro";
import { borders, colors, flex, margins, overflowOneLine, text } from "../../styles";

export const TableWrapper = styled.div<TableWrapperPropsType>`
  width: 100%;
  height: 100%;
  max-height: ${({ needPagination, needScroll, panelHeight }) =>
    panelHeight ? `calc(100% - ${panelHeight})` : needPagination || needScroll ? "100%" : ""};
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  border-radius: 16px 16px 0 0;

  &::-webkit-scrollbar {
    background: ${({ needPagination }) =>
      needPagination ? colors.grayscaleLabelOpacityA0 : colors.white};
    height: 16px;
  }

  &::-webkit-scrollbar-thumb {
    ${borders({ totalRadius: true })};

    background: ${({ needPagination }) =>
      needPagination ? colors.whiteOpacity : colors.grayscaleLabelOpacity77};
  }

  &::-webkit-scrollbar:vertical {
    display: none;
  }
`;

export const Container = styled.div<ContainerPropsType>`
  ${({ needPagination, needScroll }) =>
    margins({ p: needPagination ? "0 0 106 0" : needScroll ? "0 0 140 0" : "0" })};

  min-width: ${({ needRightBlock }) =>
    needRightBlock
      ? `calc(1024px - 73px - 16px * 2 - 300px - 16px)`
      : `calc(1024px - 73px - 16px * 2)`};
  // (73px) - ширина левой панели
  // (16px * 2) - 2 паддинга компоненты Main
  // (300px) - ширина правого блока
  // (73px) - промежуток между основным и правым блоками
  width: 100%;
  min-height: ${({ needPagination, needScroll }) => (needPagination || needScroll ? "100%" : "")};
  background: ${colors.white};
  border-radius: 16px 16px 0 0;
`;

export const TitlesList = styled.div<{ isDraggingOver?: boolean }>`
  ${flex({ gap: 8, isColumn: true })};
  ${margins({ m: "0 8 16" })};

  overflow-y: hidden;

  div {
    ${flex({ vertical: "center" })};
  }

  p {
    ${overflowOneLine};
    ${text({ size: 14, height: 18, weight: 600, color: colors.grayscaleAsh })};
    ${margins({ m: "0 0 0 12" })};

    min-width: 200px;
    max-width: 300px;
  }
`;

type TableWrapperPropsType = {
  needPagination: boolean;
  needScroll: boolean;
  panelHeight?: string;
};

type ContainerPropsType = {
  needPagination: boolean;
  needScroll: boolean;
  needRightBlock: boolean;
};
