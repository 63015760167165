import { AxiosError } from "axios";
import React from "react";
import { timerForQueries } from "../../utils/timerForQueries";
import { SimpleConfirmModalPart, SimpleConfirmModalPropsType } from "./SimpleConfirmModal";
import { getErrorMessage, unknownErrorText } from "../../utils/getErrorMessage";

export const ConfirmModalBeforeRequest = <DATA, VOBJ>(props: PropsType<DATA, VOBJ>) => {
  const { onClose, onDelete, onSend, onApprove, setStatus, setError, ...rest } = props;

  const handleOk = async () => {
    let hasError = false;
    try {
      if (onDelete) {
        const deletionResult = onDelete();

        if (deletionResult instanceof Promise) {
          Promise.all(await deletionResult);
          setStatus("success");
        }
      } else if (onSend) {
        await onSend();
        setStatus("success");
      } else if (onApprove) {
        await onApprove();
        setStatus("success");
      }
    } catch (err) {
      hasError = true;
      const error = getErrorMessage(err);
      setError(error === unknownErrorText ? (err as AxiosError).message ?? "" : error);
      setStatus("error");
    }

    if (hasError) await timerForQueries(4000);
    else await timerForQueries(2000);

    onClose();
    setError(undefined);
    setStatus("");
  };

  return (
    <SimpleConfirmModalPart
      {...rest}
      onClose={onClose}
      {...{
        [(onDelete && "onDelete") || (onSend && "onSend") || (onApprove && "onApprove") || ""]:
          handleOk,
      }}
    />
  );
};

type PropsType<DATA, VOBJ> = SimpleConfirmModalPropsType<DATA, VOBJ> & {
  setStatus: (arg: "success" | "error" | "") => void;
  setError: (arg: string | undefined) => void;
};
