import { useAtomValue } from "jotai/utils";
import { MouseEvent, useState } from "react";
import { menuIsExpandState } from "../../../atoms/atoms";

export const useTooltip = () => {
  const menuIsExpand = useAtomValue(menuIsExpandState);

  const [anchorElement, setAnchorElement] = useState<Element | null>(null);
  const [popoverHoverText, setPopoverHoverText] = useState("");

  const openPopoverHover = (props: OpenPopoverHoverPropsType) => {
    const { evt, type, text } = props;

    if (type === "menu") {
      if (!menuIsExpand) {
        setAnchorElement(evt.currentTarget);
        setPopoverHoverText(text);
      }
    } else if (type === "expandButton") {
      setAnchorElement(evt.currentTarget);
      setPopoverHoverText(text);
    } else if (type === "folderButton" || type === "sectionButton") {
      if (!menuIsExpand) {
        setAnchorElement(evt.currentTarget);
        setPopoverHoverText(text);
      } else {
        setAnchorElement(null);
        setPopoverHoverText("");
      }
    } else {
      if (menuIsExpand && (evt.target as HTMLElement).innerText.length > 25) {
        setAnchorElement(evt.currentTarget);
        setPopoverHoverText(text);
      } else {
        setAnchorElement(null);
        setPopoverHoverText("");
      }
    }
  };

  const closePopoverHover = () => {
    setAnchorElement(null);
    setPopoverHoverText("");
  };

  const popoverHoverIsOpen = !!anchorElement;
  const id = popoverHoverIsOpen ? "popover-hover" : undefined;

  return {
    id,
    openPopoverHover,
    closePopoverHover,
    popoverHoverIsOpen,
    anchorElement,
    popoverHoverText,
    setPopoverHoverText,
  };
};

export type PopoverHoverMethodsType = {
  id?: string;
  openPopoverHover: (arg: OpenPopoverHoverPropsType) => void;
  closePopoverHover: () => void;
  popoverHoverIsOpen: boolean;
  anchorElement: Element | null;
  popoverHoverText: string;
  setPopoverHoverText: (arg: string) => void;
};

export type TypeType =
  | "menu"
  | "expandButton"
  | "folderButton"
  | "sectionButton"
  | "subSectionButton";

export type OpenPopoverHoverPropsType = {
  evt: MouseEvent<Element>;
  text: string;
  type: TypeType;
};
