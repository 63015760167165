import { useAtomValue } from "jotai/utils";
import React, { FC, ReactNode } from "react";
import { menuIsExpandState } from "../../../atoms/atoms";
import { Container, Icon, Text, TextMedTech, TextRosatom } from "./Logo.styles";

export const Logo: FC<PropsType> = (props) => {
  const { logo, full = false, gradientText } = props;

  const menuIsExpand = useAtomValue(menuIsExpandState);

  return (
    <Container href="/">
      <Icon>{logo}</Icon>

      {(menuIsExpand || full) && (
        <Text isExpand={menuIsExpand}>
          <TextMedTech gradientText={gradientText}>MedTech_</TextMedTech>
          <TextRosatom>rosatom</TextRosatom>
        </Text>
      )}
    </Container>
  );
};

type PropsType = {
  logo: ReactNode;
  full?: boolean;
  gradientText?: string;
};
