import { useAtomValue } from "jotai/utils";
import { differenceDate, formatParseDate } from "../../../utils/workingWithDates";
import { MouseEvent, useState } from "react";
import { sexOptions } from "../../../constants/options";
import { getFullName } from "../../../utils/workingWithNames";
import { employeeProfileState } from "../utils/atoms";
import { departmentsState } from "../../../atoms/atomsOrg";

export const usePanel = () => {
  // ------------------------------ АТОМЫ

  const currentUser = useAtomValue(employeeProfileState);
  const departments = useAtomValue(departmentsState);

  // ------------------------------ ДАННЫЕ - РАБОТНИК

  const fullName = currentUser ? getFullName({ nameParts: currentUser }) : "—";
  const sex = sexOptions.find(({ id }) => id === currentUser?.sex)?.name[0] || null;

  const employeeDataForPanel = () => {
    if (!currentUser) return [];
    const { birthDate, personnelNumber, employmentDate, contacts, address, position } = currentUser;

    const department = departments.find(
      ({ code }) => code === position?.department.mainDepartment
    )?.shortName;
    const insideDepartment = departments.find(
      ({ code }) => code === position?.department.code
    )?.shortName;

    const mainPhone = contacts
      .filter(({ type }) => type === "PHONE_NUMBER")
      .find(({ isMain }) => isMain)?.value;
    const mainMail = contacts
      .filter(({ type }) => type === "EMAIL")
      .find(({ isMain }) => isMain)?.value;

    return [
      {
        title: "Дата рождения",
        description: birthDate
          ? `${formatParseDate(birthDate)} (${differenceDate(birthDate)})`
          : "—",
        needHint: true,
      },
      {
        title: "Табельный номер",
        description: personnelNumber ?? "—",
      },
      {
        title: "Должность",
        description: position?.name ?? "—",
        needHint: true,
      },
      {
        title: "Подразделение",
        description: department ?? "—",
        needHint: true,
      },
      {
        title: "Вн. подразделение",
        description: insideDepartment ?? "—",
        needHint: true,
      },
      {
        title: "Стаж",
        description: employmentDate ? differenceDate(employmentDate) : "—",
      },
      {
        title: "Адрес регистрации",
        description: address ?? "—",
        needHint: true,
      },
      {
        title: "Номер телефона",
        description: mainPhone ?? "—",
      },
      {
        title: "E-mail",
        description: mainMail ?? "—",
        needHint: true,
      },
    ];
  };

  // ------------------------------ ДАННЫЕ - ПОДСКАЗКИ К ДЛИННЫМ НАИМЕНОВАНИЯМ

  const [anchorPopoverHoverElement, setAnchorPopoverHoverElement] = useState<HTMLElement | null>(
    null
  );

  const openPopoverHover = ({ currentTarget }: MouseEvent<HTMLElement>) =>
    setAnchorPopoverHoverElement(currentTarget);

  const closePopoverHover = () => setAnchorPopoverHoverElement(null);

  const popoverHoverIsOpen = !!anchorPopoverHoverElement;

  return {
    employeeDataForPanel,
    closePopoverHover,
    openPopoverHover,
    popoverHoverIsOpen,

    fullName,
    sex,
  };
};
