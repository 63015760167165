import { useData } from "./hooks/useData";
import { useFilters } from "./hooks/useFilters";
import { useTable } from "./hooks/useTable";

export const useCalendarDay = () => {
  const data = useData();
  const {
    canViewAdditionalFilter,
    canEdit,
    canViewProfile,
    access,
    agreement,
    infectionSettings,
    queryKey,
    dayData,
  } = data;

  const filters = useFilters(canViewAdditionalFilter);
  const date = dayData?.date;
  const table = useTable(
    access,
    agreement,
    canEdit,
    infectionSettings?.infection.id,
    queryKey,
    canViewProfile,
    date
  );

  return {
    ...data,
    ...filters,
    ...table,
  };
};
