import React, { FC } from "react";
import * as elements from "./Summary.styles";
import { CountContainer } from "./Summary.styles";

export const Summary: FC<PropsType> = (props) => {
  const { content, bold = false, title, minWidth = 0 } = props;

  const { Container, SummaryLabel, Description, Details, Count } = elements;

  return (
    <Container>
      <SummaryLabel bold={bold}>{title}</SummaryLabel>
      <Details>
        {content.map(({ title, description }, i) => (
          <CountContainer key={i} minWidth={minWidth}>
            <Count>{title}</Count>
            <Description>{description}</Description>
          </CountContainer>
        ))}
      </Details>
    </Container>
  );
};

type PropsType = {
  title: string;
  content: { title: string | number; description: string }[];
  bold?: boolean;
  minWidth?: number;
};
