import { useData } from "./hooks/useData";
import { useForms } from "./hooks/useForms";

export const useVaccinations = () => {
  const data = useData();
  const { getTypeRecord, infectionComponents, employeeProfile } = data;
  const forms = useForms(getTypeRecord, infectionComponents, employeeProfile?.id ?? "");

  return {
    ...data,
    ...forms,
  };
};
