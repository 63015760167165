import styled from "@emotion/styled/macro";
import { button, buttonOnlyIcon, margins } from "../../../styles";

export const Container = styled.button`
  ${button};
  ${buttonOnlyIcon};

  ${margins({ p: "0" })};

  height: fit-content;
`;
