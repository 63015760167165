import { useAtomValue } from "jotai/utils";
import React, { FC } from "react";
import { currentUserState } from "../../atoms/atomCurrentUser";
import { menuIsExpandState } from "../../atoms/atoms";
import { backendURI } from "../../utils/backendURI";
import { Logo as LogoIcon } from "../Icons";
import { ExpandButton } from "./ExpandButton/ExpandButton";
import { Container, Footer, Header, Main, MainWrapper } from "./LeftMenu.styles";
import { Logo } from "./Logo/Logo";
import { Profile } from "./Profile/Profile";
import { SectionButtons } from "./SectionButtons/SectionButtons";
import { WidgetsButton } from "./WidgetsButton/WidgetsButton";

export const LeftMenu: FC = () => {
  const currentUserLoadable = useAtomValue(currentUserState);
  const menuIsExpand = useAtomValue(menuIsExpandState);

  const baseUrl = process.env.REACT_APP_IZ_URL ? "localhost:8080" : backendURI();

  const currentUser =
    currentUserLoadable.state === "hasData" ? currentUserLoadable.data : undefined;

  return currentUser?.roleModel ? (
    <Container isExpand={menuIsExpand}>
      <Header>
        <Logo logo={<LogoIcon />} />
      </Header>

      <MainWrapper>
        <Main isExpand={menuIsExpand}>
          <WidgetsButton baseUrl={baseUrl} />

          <SectionButtons roleModel={currentUser.roleModel} />
        </Main>
      </MainWrapper>

      <Footer>
        <ExpandButton />

        <Profile baseUrl={baseUrl} />
      </Footer>
    </Container>
  ) : null;
};
