import React, { FC, useEffect, useState } from "react";
import { QueryKeyType, useQuery } from "../../../../utils/useQuery";
import {
  employeeVaccinationSettingsApi,
  EmployeeVaccinationSettingType,
} from "../../../../api/apiEmployees";
import { CheckLabel } from "../../../../uiKit/Check/CheckLabel";

export const UserSettingsCheckbox: FC<PropsType> = (props) => {
  const { field, settings, infectionId, employeeId, queryKey, label, enabled = true } = props;

  const value = settings?.[field];
  const [checked, setChecked] = useState(value);
  const [disabled, setDisabled] = useState(false);

  const update = useQuery().useUpdate({
    api: employeeVaccinationSettingsApi(),
    queryKey: queryKey,
  });
  const create = useQuery().useCreate({
    api: employeeVaccinationSettingsApi(),
    queryKey: queryKey,
  });

  useEffect(() => {
    setChecked(value);
  }, [value]);
  const handleChange = async () => {
    if (!disabled && enabled) {
      setChecked(!value);
      setDisabled(true);

      if (!settings)
        await create.mutateAsync({
          data: {
            employee: { id: employeeId },
            infection: { id: infectionId },
            access: false,
            agreement: false,
            exemption: [],
            antibodies: [],
            [field]: true,
          },
        });
      else await update.mutateAsync({ data: { [field]: !value }, id: settings.id ?? "" });
      setDisabled(false);
    }
  };

  return (
    <>
      <CheckLabel
        checked={!!checked}
        onClick={handleChange}
        disabled={!enabled || disabled}
        label={label}
      />
    </>
  );
};

type PropsType = {
  field: "access" | "agreement";
  settings?: EmployeeVaccinationSettingType;
  infectionId: string;
  employeeId: string;
  queryKey: QueryKeyType;
  label?: string;
  enabled?: boolean;
};
