import React, { FC } from "react";
import { PersonOutline } from "../../../../uiKit/Icons";
import { colors } from "../../../../styles";
import { PersonContainer } from "../TimesInput/TimesInput.styles";
import { PopoverHover } from "../../../../uiKit";
import { usePopoverHover } from "../../../../uiKit/PopoverHover/usePopoverHover";

export const PersonIcon: FC = () => {
  const { propsPopoverHover, closePopoverHover, openPopoverHover } = usePopoverHover({
    transformOrigin: { horizontal: "right", vertical: "bottom" },
    anchorOrigin: { horizontal: "left", vertical: "top" },
  });

  return (
    <>
      <PersonContainer onMouseEnter={openPopoverHover} onMouseLeave={closePopoverHover}>
        <PersonOutline color={colors.warningDefault} />
      </PersonContainer>

      <PopoverHover {...propsPopoverHover}>
        На это время записаны сотрудники. Чтобы удалить данный период, необходимо сначала убрать из
        записи всех сотрудников.
      </PopoverHover>
    </>
  );
};
