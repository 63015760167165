import styled from "@emotion/styled/macro";
import { colors, margins, text } from "../../../styles";

const { grayscaleBeautifulBlack, redDefault, grayscaleBody } = colors;

export const Header = styled.header``;

export const SubTitle = styled.span`
  ${text({ size: 15, weight: 400, height: 20, color: grayscaleBeautifulBlack })};
  padding-left: 28px;
`;

export const PlanedDateText = styled.span<{ isOverdue: boolean }>`
  ${({ isOverdue }) =>
    text({ size: 15, height: 20, weight: 400, color: isOverdue ? redDefault : grayscaleBody })};
`;

export const TextDate = styled.p`
  ${text({ size: 13, height: 21, weight: 700, color: grayscaleBeautifulBlack })};
  ${margins({ m: "0 18" })};
`;
