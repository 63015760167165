import React, { FC } from "react";
import { Card } from "../Card/Card";
import { Record, Text } from "../../UserProfile.styles";
import { differenceWithToday } from "../../../../utils/workingWithDates";

export const PlanedDate: FC<PropsType> = (props) => {
  const { getDate } = props;

  const date = getDate();

  const isExpiredDate = date ? differenceWithToday(date, "days") > 0 : false;

  return (
    <Card title="Плановая вакцинация">
      {date ? (
        <Record type={isExpiredDate ? "expired" : "correct"}>
          <Text isError={isExpiredDate}>{date}</Text>
        </Record>
      ) : (
        <Record type="empty">Данных нет</Record>
      )}
    </Card>
  );
};

type PropsType = {
  getDate: () => string | undefined;
};
