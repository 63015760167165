import styled from "@emotion/styled/macro";
import { button, colors, flex } from "../../../styles";

export const Container = styled.button`
  ${flex({ totalCenter: true })};

  ${button};

  width: 40px;
  height: 40px;
  z-index: 3;

  div {
    ${button};
  }

  svg {
    width: 20px;
    height: 20px;
  }

  svg > path {
    fill: ${colors.white};
  }

  :hover {
    background: ${colors.grayscaleHoverBackground};

    svg > path {
      fill: ${colors.grayscaleBeautifulBlack};
    }
  }
`;
