import { useAtom } from "jotai";
import { useAtomValue } from "jotai/utils";
import React from "react";
import * as Yup from "yup";
import {
  VaccinationDateType,
  vaccinationFactApi,
  VaccinationFactType,
} from "../../../../api/apiVaccination";
import { VaccinationComponentType } from "../../../../api/apiVaccine";
import { addFormState } from "../../../../atoms/atoms";
import { long, notJustSpaces, required } from "../../../../constants/forValidationSchemes";
import { withoutSpaces } from "../../../../constants/regexs";
import { QueryKeyType } from "../../../../utils/useQuery";
import { formatDateForBackend, now } from "../../../../utils/workingWithDates";
import {
  currentFormRowState,
  currentTypeFormState,
  modalDataFactState,
  nextComponentState,
  vaccineInfectionState,
} from "../utils/atoms";
import { VaccinationFormFields } from "../VaccinationFormFields/VaccinationFormFields";

export const useVaccinationFactForms = (
  day: VaccinationDateType | undefined,
  queryKey: QueryKeyType,
  addTitle?: string
) => {
  const { vaccinationPlan } = day || {};
  const { infection } = vaccinationPlan || {};

  // ------------------------------ АТОМЫ

  const vaccine = useAtomValue(vaccineInfectionState);
  const currentUserRow = useAtomValue(currentFormRowState);
  const [modalData, setModalData] = useAtom(modalDataFactState);
  const nextComponent = useAtomValue(nextComponentState);
  const addFormIsOpen = useAtomValue(addFormState);
  const currentForm = useAtomValue(currentTypeFormState);

  // ------------------------------ КОНСТАНТЫ

  const api = vaccinationFactApi();
  const isOpenFactForm = currentForm === "fact" && (addFormIsOpen || !!modalData);
  const isEditFactForm = !!modalData;
  const idFact = modalData?.id;

  const addFormTitle = `${addTitle ?? "Добавление факта вакцинации против заболевания"} «${
    infection?.name
  }»`;
  const editFormTitle = `Редактирование факта вакцинации против заболевания «${infection?.name}»`;

  const formName = "vaccinationFact";

  // ------------------------------ ФОРМА - ПОЛЯ

  const fields = (
    <VaccinationFormFields
      vaccine={vaccine}
      nextComponent={addTitle ? undefined : nextComponent?.id}
    />
  );

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesAddForm = {
    notVaccinated: false,
    comment: "",
    type: "",
    component: !addTitle ? nextComponent?.id ?? "" : "",
    abroad: !!currentUserRow?.appointment?.abroad,
  };

  const initialValuesEditForm = ({
    abroad,
    comment,
    vaccinated,
    component,
    date,
  }: VaccinationFactType) => ({
    notVaccinated: !vaccinated,
    comment,
    type: getTypeComponent(component),
    component: component?.id,
    abroad,
    date: date,
  });

  const getTypeComponent = (component?: VaccinationComponentType) => {
    return vaccine?.vaccineComponents.some(({ id }) => id === component?.id)
      ? "vaccine"
      : "revaccine";
  };

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchema = Yup.object().shape({
    notVaccinated: Yup.boolean(),
    component: Yup.string().required(required),
    type: Yup.string().matches(withoutSpaces, notJustSpaces).required(required),

    comment: Yup.string()
      .matches(withoutSpaces, notJustSpaces)
      .when("notVaccinated", (notVaccinated: boolean, schema) =>
        notVaccinated ? schema.required(required) : schema
      )
      .max(255, long),
    name: Yup.string().matches(withoutSpaces, notJustSpaces),
    abroad: Yup.boolean(),
  });

  // ------------------------------ ФОРМА - ОТПРАВКА

  const valuesObject = (
    { notVaccinated, comment, abroad, component, date }: InitialValuesFactFormType,
    isEdit?: boolean
  ) => {
    const formatNow = formatDateForBackend(now);
    const appointment = currentUserRow?.appointmentVaccinations?.find(
      ({ appointment }) => appointment.date === formatNow
    )?.appointment;

    return {
      employee: { id: currentUserRow?.employee.id },
      ...(!!appointment && !isEdit ? { appointment: { id: appointment.id } } : {}),
      date: isEdit ? date : formatNow,
      component: { id: component },
      vaccinated: !notVaccinated,
      comment: notVaccinated ? comment : "",
      abroad,
    };
  };
  return {
    api,
    queryKey,

    addFormTitle,
    editFormTitle,

    formName,

    isOpenFactForm,
    isEditFactForm,

    fields,
    initialValuesAddForm,
    initialValuesEditForm,

    valuesObject,
    validationSchema,

    modalData,
    setModalData,
    idFact,
  };
};

export type InitialValuesFactFormType = {
  notVaccinated: boolean;
  comment: string;
  type: string;
  component: string;
  abroad: boolean;
  date?: string;
};
