import { useField } from "formik";
import React, { FC, KeyboardEvent } from "react";
import { FieldContainer } from "../../Form/Form.styles";
import { LabelField, LabelType } from "../../Form/LabelField";
import * as elements from "./TextInput.styles";

export const TextInput: FC<PropsType> = (props) => {
  const { type = "text", name, outsideLabel, insideLabel, placeholder = "" } = props;
  const { multiline = false, integer = false } = props;
  const { prepend, postfix, autoFocus = false, disabled = false, internal = false } = props;

  const { TextInputContainer, Error, Input, MultilineInput, PrependContainer } = elements;

  const [{ value, onChange, onBlur }, { touched, error }] = useField<string | number>(name);

  const hasError = touched && !!error;

  const generalProps = {
    name,
    value: type === "number" && value === null ? "" : value,
    hasLabel: !!insideLabel,
    placeholder,
    onChange,
    onBlur,
    disabled,
    internal,
    hasError,
    autoComplete: "off",
  };

  const handleKeyPress =
    type === "number"
      ? (evt: KeyboardEvent<HTMLInputElement>) =>
          evt?.key === "-" ||
          evt?.key === "+" ||
          evt?.key === "e" ||
          (integer && (evt?.key === "." || evt?.key === ","))
            ? evt.preventDefault()
            : undefined
      : undefined;

  return (
    <FieldContainer>
      <LabelField outsideLabel={outsideLabel} />

      <TextInputContainer
        internal={internal}
        hasError={hasError}
        postfix={type === "number" && value === null ? "" : postfix}
        value={value}
      >
        <LabelField insideLabel={insideLabel} />

        {prepend ? <PrependContainer>{prepend}</PrependContainer> : null}

        {multiline ? (
          <MultilineInput {...generalProps} hasPrepend={!!prepend} autoFocus={autoFocus} />
        ) : (
          <Input
            type={type}
            {...generalProps}
            onKeyPress={handleKeyPress}
            autoComplete="off"
            autoFocus={autoFocus}
          />
        )}

        {hasError && <Error>{error}</Error>}
      </TextInputContainer>
    </FieldContainer>
  );
};

type PropsType = LabelType & {
  type?: "text" | "number";
  integer?: boolean;
  name: string;
  placeholder?: string;
  multiline?: boolean;
  prepend?: JSX.Element;
  postfix?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  internal?: boolean;
};
