import styled from "@emotion/styled/macro";
import { borders, colors, flex, margins, text } from "../../../../styles";

const {
  primaryBackground,
  grayscaleLabel,
  grayscaleLine,
  grayscaleBody,
  grayscaleInputDisabled,
  primaryDefault,
} = colors;

export const Container = styled.div`
  ${flex({ gap: 8 })};
  ${borders({ radius: 12 })};
  ${margins({ p: "8" })};

  background: ${primaryBackground};
`;

export const Title = styled.p`
  ${text({ size: 10, weight: 800, height: 20, color: grayscaleLabel })}
`;

export const Description = styled.span`
  ${text({ size: 13, height: 17, weight: 400, color: grayscaleBody })};
  ${borders({ width: 1, color: grayscaleLine, totalRadius: true })};

  min-width: 148px;
  min-height: 24px;
`;

export const VaccinationButton = styled.div<{ onClick?: unknown }>`
  ${margins({ p: "16" })};
  border-radius: 0 12px 12px 0;

  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
  background: ${({ onClick }) => (onClick ? primaryDefault : grayscaleInputDisabled)};
`;
