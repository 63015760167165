import React from "react";
import { VaccinationFactType } from "../../../../api/apiVaccination";
import { formatDateForBackend, now, parseDate } from "../../../../utils/workingWithDates";
import * as Yup from "yup";
import {
  IsDateNotPastFutureWithToday,
  required,
  thisDateIsToCorrect,
} from "../../../../constants/forValidationSchemes";
import { VaccineReVaccineType } from "../../../../api/apiVaccine";
import { VaccinationFactFormField } from "../../components/VaccinationFactFormField/VaccinationFactFormField";

export const useForms = (
  getTypeRecord: (vaccinationFact: VaccinationFactType) => "vaccine" | "revaccine",
  infectionComponents: VaccineReVaccineType,
  employeeId: string
) => {
  // ------------------------------ КОНСТАНТЫ
  const addFormTitle = "Добавление факта вакцинации  в историю";
  const editFormTitle = "Изменение факта вакцинации  в истории";

  const formName = "vaccinationRecordProfile";

  // ------------------------------ ФОРМА - ПОЛЯ

  const fields = <VaccinationFactFormField vaccine={infectionComponents} />;

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesAddForm = {
    date: now,
    type: "",
    component: "",
    organization: "",
    abroad: false,
  };

  const initialValuesEditForm = (props: VaccinationFactType) => {
    const { date, component, abroad, comment, appointment } = props;
    const { id } = component;

    return {
      date: parseDate(date),
      type: getTypeRecord(props),
      component: id,
      organization: appointment ? appointment.vaccinationPlan.healthCenter.name : comment ?? "",
      abroad: abroad,
    };
  };

  // ------------------------------ ФОРМА - ВАЛИДАЦИЯ

  const validationSchema = Yup.object().shape({
    date: Yup.date()
      .typeError(thisDateIsToCorrect)
      .test(IsDateNotPastFutureWithToday("future", "date"))
      .required(required),
    type: Yup.string().required(required),
    component: Yup.string().required(required),
    name: Yup.string(),
    organization: Yup.string(),
    abroad: Yup.boolean(),
  });

  // ------------------------------ ФОРМА - ОТПРАВКА

  const valuesObject = ({ date, organization, abroad, component }: ValuesObjectType) => ({
    employee: { id: employeeId },
    date: formatDateForBackend(date),
    component: { id: component },
    vaccinated: true,
    comment: organization,
    abroad,
  });

  return {
    addFormTitle,
    editFormTitle,
    formName,

    fields,

    initialValuesAddForm,
    initialValuesEditForm,
    validationSchema,

    valuesObject,
  };
};

type ValuesObjectType = {
  date: Date;
  type: string;
  component: string;
  organization: string;
  abroad: boolean;
};
