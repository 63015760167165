import styled from "@emotion/styled/macro";
import { colors, flex, margins, text } from "../../../styles";
import { buttonCustom } from "../../../styles/buttonsStyles";

const { black, grayscaleLabel, grayscaleChips, white } = colors;

export const HeaderFooter = styled.div`
  ${flex({ gap: 16, vertical: "center" })};
  ${margins({ m: "12 0 0 0" })};
  flex-wrap: wrap;
`;
export const ListLabel = styled.label`
  ${text({ weight: 700, size: 13, height: 18, color: black })};
`;

export const InfectionName = styled.p``;

export const Infection = styled.button<{ isActive: boolean }>`
  ${({ isActive }) =>
    buttonCustom({
      height: 40,
      color: isActive ? grayscaleLabel : grayscaleChips,
      text: { weight: 700, size: 13, height: 18, color: isActive ? white : black },
      p: "0 16",
    })};
`;
