import { atomWithQuery } from "jotai/query";
import { atom } from "jotai";
import {
  countriesApi,
  departmentsApi,
  DepartmentsFiltersType,
  organizationsApi,
} from "../api/apiOrganization";
import { fetch } from "../utils/fetchData";

// ------------------------------ ОРГАНИЗАЦИЯ

export const organizationState = atom("");

// ------------------------------ СПИСОК ОСНОВНЫХ ПОДРАЗДЕЛЕНИЙ

export const mainDepartmentsState = atomWithQuery((get) => {
  const organizationCode = get(organizationState);

  return mainDepartmentsQuery({ organizationCode });
});

export const mainDepartmentsQuery = ({ organizationCode }: DepartmentsFiltersType) => ({
  queryKey: [organizationCode, "mainDepartmentsState"],
  queryFn: () => fetchMainDepartments({ organizationCode, isMainDepartment: true }),
  keepPreviousData: true,
  enabled: !!organizationCode,
});

export const fetchMainDepartments = async (props: DepartmentsFiltersType) =>
  (await departmentsApi.get(props)).data.map((item) => ({ ...item, id: item.code }));

// ------------------------------ СПИСОК ПОДРАЗДЕЛЕНИЙ

export const departmentsState = atomWithQuery(() => {
  return departmentsQuery();
});

export const departmentsQuery = () => {
  return {
    queryKey: ["departmentsState"],
    queryFn: () => fetchDepartments(),
    keepPreviousData: true,
  };
};

export const fetchDepartments = async () =>
  (await departmentsApi.get()).data.map((item) => ({ ...item, id: item.code }));

// ------------------------------ СПИСОК ОРГАНИЗАЦИЙ

export const organizationsState = atomWithQuery(() => organizationsQuery());

export const organizationsQuery = () => ({
  queryKey: "organizationsState",
  queryFn: fetchOrganizations,
});

export const fetchOrganizations = async () =>
  (await organizationsApi.get()).data.items.map((item) => ({ ...item, id: item.code }));

// ------------------------------ СПИСОК СТРАН

export const countriesState = atomWithQuery(() => ({
  queryKey: "countriesState",
  queryFn: () => fetch(() => countriesApi().getAll()),
}));
