import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";
import { borders, button, colors, flex, margins, text } from "../../../styles";

export const Container = styled.div`
  ${margins({ m: "0 24 12" })};
`;

export const TitleAndNav = styled.div`
  ${flex({ horizontal: "evenly" })};
  ${margins({ m: "0 0 12" })};
`;

export const Title = styled.h1`
  ${text({ size: 20, height: 26, weight: 800, color: colors.black })};
`;

export const BreadcrumbsNav = styled.div`
  ${flex()};
  ${text({ size: 15, height: 20, weight: 400 })};
`;

export const BreadcrumbsLink = styled(Link)`
  color: ${colors.grayscalePlaceholder};
  text-decoration: none;

  :hover {
    color: ${colors.grayscaleBeautifulBlack};
  }
`;

export const Separator = styled.span`
  color: ${colors.grayscalePlaceholder};
`;

export const SubTitle = styled.p`
  ${text({ size: 15, height: 20, weight: 400, color: colors.grayscaleBeautifulBlack })};
`;

export const Buttons = styled.div`
  ${flex({ horizontal: "evenly" })};

  height: 48px;
`;

export const LeftButtons = styled.div`
  ${flex({ gap: 16 })};
`;

export const RightButtons = styled.div`
  ${flex({ vertical: "center" })};
`;

export const MenuButtons = styled.div`
  ${flex({ gap: 8, isColumn: true })};
  ${margins({ p: "8" })};
`;

export const ButtonProfileMenu = styled.button`
  ${flex({ horizontal: "evenly" })};
  ${borders({ radius: 8 })};

  ${button};

  width: 400px;
  height: 56px;

  p {
    ${text({ size: 15, height: 20, weight: 400, color: colors.grayscaleBeautifulBlack })};
    ${margins({ m: "0 0 0 8" })};
  }

  span {
    ${borders({ totalRadius: true })};
    ${text({ size: 12, height: 12, weight: 600, color: colors.white })};
    ${margins({ m: "22", p: "2 4" })};

    display: block;
    background: ${colors.grayscalePlaceholder};
  }

  &:hover {
    background: ${colors.grayscaleHoverBackground};

    svg > path {
      fill: ${colors.grayscaleLabel};
    }
  }
`;
