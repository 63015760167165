import React from "react";
import { timerForQueries } from "../../utils/timerForQueries";
import { SimpleConfirmModalPart } from "../../uiKit/ConfirmModal/SimpleConfirmModal";
import { MultiConfirmModalPropsType } from "./MultiStepConfirmModal";
import { ModalStatus } from "./useMultiModal";

export const MultiStetConfirmModalBeforeRequest = (props: PropsType) => {
  const { onClose, onDelete, onSend, onApprove, setStatus, setError, ...rest } = props;

  const handleOk = async () => {
    let closeStatus: ModalStatus = { status: "close" };

    try {
      if (onDelete) {
        closeStatus = await onDelete();
        if (closeStatus.status === "close") {
          setStatus("success");
        }
      } else if (onSend) {
        await onSend();
      } else if (onApprove) {
        await onApprove();
      }
    } catch (err) {
      const error = err as ModalStatus;
      const { status, message } = error;

      if (status === "close") {
        setError(message ?? "");
        setStatus("error");
      } else closeStatus = error;
    }

    if (closeStatus.status === "close") {
      await timerForQueries(2000);
      onClose();
      setError(undefined);
      setStatus("");
    }
  };

  return (
    <SimpleConfirmModalPart
      {...rest}
      onClose={onClose}
      {...{
        [(onDelete && "onDelete") || (onSend && "onSend") || (onApprove && "onApprove") || ""]:
          handleOk,
      }}
    />
  );
};

type PropsType = MultiConfirmModalPropsType & {
  setStatus: (arg: "success" | "error" | "") => void;
  setError: (arg: string | undefined) => void;
};
