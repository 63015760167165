import React from "react";
import { UniversalTextButton } from "../Buttons";
import { BigCheck } from "../Icons";
import { ConfirmModalPropsType } from "./ConfirmModal";
import { ConfirmHeader } from "./ConfirmModal.styles";

export const ConfirmModalSuccess = <DATA, VOBJ>(props: ConfirmModalPropsType<DATA, VOBJ>) => {
  const { successText, onClose, onDelete, onSend, onApprove } = props;

  return (
    <>
      <BigCheck />
      <ConfirmHeader>{successText}</ConfirmHeader>
      {!!onDelete || !!onSend || !!onApprove ? null : (
        <UniversalTextButton text="Хорошо" type="positive" onClick={onClose} />
      )}
    </>
  );
};
