import { diseaseSeveritiesApi, MediumOtherType } from "../../../../api/apiDictionaries";
import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { fetch } from "../../../../utils/fetchData";

export const modalDataState = atom<MediumOtherType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

export const diseaseSeveritiesState = atomWithQuery(() => ({
  queryKey: "diseaseSeveritiesState",
  queryFn: () => fetch(() => diseaseSeveritiesApi().getAll()),
}));

export const useDiseaseSeveritiesQuery = () => ({
  queryKey: ["diseaseSeveritiesState"],
  api: diseaseSeveritiesApi(),
});
