import styled from "@emotion/styled/macro";
import { borders, button, buttonOnlyIcon, colors, flex, grid, margins, text } from "../../styles";

export const Container = styled.div<{ isExpand: boolean }>`
  ${grid({ rows: "72px 1fr 124px" })};

  min-width: ${({ isExpand }) => (isExpand ? "300px" : "72px")};
  width: ${({ isExpand }) => (isExpand ? "300px" : "72px")};
  height: 100vh;
  border-right: 1px solid ${colors.grayscaleInput};
  transition: ${({ isExpand }) =>
    isExpand ? "min-width 0.3s ease-in-out, width 0.3s ease-in-out" : "none"};
`;

export const Block = styled.div`
  ${flex({ isColumn: true })};

  background: ${colors.white};
`;

export const Header = styled.div`
  ${flex({ isColumn: true, vertical: "center" })};
  ${margins({ m: "0 16" })};

  background: ${colors.white};
  z-index: 2;
`;

export const MainWrapper = styled.div`
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Main = styled(Block)<{ isExpand: boolean }>`
  ${margins({ m: "0 16" })};

  ${({ isExpand }) => (isExpand ? "width: 268px" : "width: 40px")};
  min-height: 100%;

  z-index: 1;
`;

export const Footer = styled(Block)`
  ${borders({ side: "top", color: colors.grayscaleInput })};
  ${margins({ m: "0 16" })};

  z-index: 2;
  overflow: hidden;
`;

export const WidgetsButtonContainer = styled.a<{ isExpand: boolean }>`
  ${button};
  ${({ isExpand }) => (isExpand ? undefined : buttonOnlyIcon)};

  ${({ isExpand }) => (isExpand ? flex({ horizontal: "start" }) : undefined)};
  ${borders({ radius: 12 })};
  ${margins({ m: "40 0" })};

  height: 40px;
  cursor: pointer;

  div.icon {
    ${flex({ totalCenter: true })};

    width: 40px;
  }

  :hover {
    background: ${colors.grayscaleHoverBackground};
  }

  p {
    ${text({ size: 17, height: 22, weight: 600, color: colors.grayscaleAsh })};
    ${margins({ m: "0 0 0 12" })};
  }
`;

export const ExpandButtonContainer = styled.button<{ isExpand?: boolean }>`
  ${button};
  ${buttonOnlyIcon};

  ${borders({ radius: 12 })};
  ${margins({ m: "8 0 6" })};

  :hover {
    background: ${colors.grayscaleHoverBackground};
  }
`;
