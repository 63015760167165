import { useNameDescriptionDictionaryForms } from "../../../../utils/useNameDescriptionDictionary";

export const useForms = () => {
  // ------------------------------ ХУКИ - СТАНДАРТНЫЕ ЗНАЧЕНИЯ

  const methods = useNameDescriptionDictionaryForms();

  // ------------------------------ КОНСТАНТЫ

  const addFormTitle = "Добавить гражданство";
  const editFormTitle = "Изменение гражданства";

  const formName = "nationality";

  return {
    ...methods,

    addFormTitle,
    editFormTitle,
    formName,
  };
};
