import { useField } from "formik";
import React, { FC, useEffect, useState } from "react";
import { VaccineReVaccineType } from "../../../../api/apiVaccine";
import { vaccineTypeOptions } from "../../../../constants/options";
import { CheckInput, SelectInput, TextInput } from "../../../../uiKit";
import { UniversalTextButton } from "../../../../uiKit/Buttons";
import { SimpleConfirmModal } from "../../../../uiKit/ConfirmModal/SimpleConfirmModal";
import { FieldsBlock } from "../../../../uiKit/Form/Form.styles";
import { ConfirmMessageType } from "../../../../uiKit/ConfirmModal/ConfirmModal";

export const VaccinationFormFields: FC<PropsType> = (props) => {
  const { vaccine, nextComponent } = props;

  const [{ value }] = useField<boolean>("notVaccinated");
  const [{ value: type }, ___, { setValue: setType }] = useField<string>("type");
  const [{ value: component }, __, { setValue: setNumber, setError }] =
    useField<string>("component");

  const [questionText, setQuestionText] = useState<ConfirmMessageType | string>("");

  const vaccineComponentOptions =
    vaccine?.vaccineComponents.map((component) => ({
      id: component.id,
      name: `Этап ${component.number}`,
    })) ?? [];
  const reVaccineComponentOptions =
    vaccine?.reVaccineComponents.map((component) => ({
      id: component.id,
      name: `Этап ${component.number}`,
    })) ?? [];

  const nextType = vaccineComponentOptions?.some(({ id }) => id === component)
    ? "vaccine"
    : "revaccine";
  const stepOptions = type === "vaccine" ? vaccineComponentOptions : reVaccineComponentOptions;

  useEffect(() => {
    if (component && !type) setType(nextType);
    else if (!component || !stepOptions?.find(({ id }) => id === component)) setNumber("");
  }, [type]);

  const handleReset = () => {
    setType("");
    setNumber(nextComponent ?? "");
  };

  const handleChangeType = (id: string) => {
    if (id !== nextType && nextComponent && nextComponent === component) {
      setQuestionText({
        header: "Вы действительно хотите установить другой тип?",
        body: type === "vaccine" ? "Ревакцинация" : "Вакцинация",
      });
    }
  };
  const handleChangeComponent = (changeId: string) => {
    if (nextComponent === component) {
      const vaccineNumber = vaccineComponentOptions?.find(({ id }) => id === changeId)?.name;
      const reVaccineNumber = reVaccineComponentOptions?.find(({ id }) => id === changeId)?.name;

      setQuestionText({
        header: "Вы действительно хотите установить другой этап вакцинации?",
        body: vaccineNumber ?? reVaccineNumber ?? "",
      });
    }
  };

  return (
    <FieldsBlock>
      <CheckInput name="notVaccinated" label="Не вакцинирован" />

      <>
        <SelectInput
          name="type"
          outsideLabel="Тип*"
          options={vaccineTypeOptions}
          onChange={handleChangeType}
        />
        <SelectInput
          name="component"
          outsideLabel="Этап*"
          options={stepOptions}
          onChange={handleChangeComponent}
        />
        {value ? (
          <TextInput name="comment" placeholder="—" multiline outsideLabel="Укажите причину*" />
        ) : (
          <CheckInput name="abroad" label="В связи с поездкой за рубеж" />
        )}
      </>

      {nextComponent && nextComponent !== component && (
        <UniversalTextButton text="Сбросить изменения" type="outlined" onClick={handleReset} />
      )}

      {questionText && (
        <SimpleConfirmModal
          questionText={questionText}
          isOpen={true}
          onClose={() => {
            handleReset();
            setQuestionText("");
          }}
          confirmText="Да"
          onApprove={() => {
            setQuestionText("");
            setError(undefined);
          }}
        />
      )}
    </FieldsBlock>
  );
};

type PropsType = {
  vaccine?: VaccineReVaccineType;
  nextComponent?: string;
};
