import { useAtomValue } from "jotai/utils";
import React, { FC } from "react";
import { menuIsExpandState } from "../../../atoms/atoms";
import { NineDots } from "../../Icons";
import { WidgetsButtonContainer } from "../LeftMenu.styles";
import { Tooltip } from "../Tooltip/Tooltip";
import { useTooltip } from "../Tooltip/useTooltip";

export const WidgetsButton: FC<PropsType> = (props) => {
  const { baseUrl } = props;

  const popoverHoverMethods = useTooltip();
  const { popoverHoverIsOpen, openPopoverHover, closePopoverHover } = popoverHoverMethods;

  const menuIsExpand = useAtomValue(menuIsExpandState);

  return (
    <WidgetsButtonContainer
      isExpand={menuIsExpand}
      aria-owns={popoverHoverIsOpen ? "popover-hover" : undefined}
      aria-haspopup="true"
      onMouseEnter={(evt) => openPopoverHover({ evt, type: "menu", text: "Меню" })}
      onMouseLeave={closePopoverHover}
      onClick={closePopoverHover}
      href={`http://${baseUrl}`}
    >
      <div className="icon">
        <NineDots />
      </div>

      {menuIsExpand && <p>Меню</p>}

      <Tooltip type="menu" popoverHoverMethods={popoverHoverMethods} />
    </WidgetsButtonContainer>
  );
};

type PropsType = {
  baseUrl?: string;
};
