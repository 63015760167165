import styled from "@emotion/styled/macro";
import { borders, colors, flex, margins, shadow, text } from "../../../../styles";
import { Popover } from "@mui/material";

const { grayscaleLabel, white, grayscaleInput, grayscaleLine, greenBackground } = colors;
const { primaryDefaultDark, grayscalePlaceholder, grayscaleAsh, primaryBackground } = colors;

export const Container = styled.div`
  min-width: 1400px;
  width: 100%;
  margin-top: 16px;
`;

export const Rows = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

export const HeaderRow = styled(Rows)`
  background: ${grayscaleLabel};
  border-radius: 16px 16px 0 0;
  margin: 0;
  z-index: 2;
`;

export const HeaderCell = styled.div`
  ${text({ weight: 700, size: 13, height: 18, color: white })};
  border-right: 1px solid ${grayscaleInput};
  border-bottom: 1px solid ${grayscaleInput};
  padding: 8px 12px;

  &:last-child {
    border-right: none;
  }
`;

export const BodyRows = styled(Rows)`
  grid-template-rows: repeat(6, 150px);
  background: ${white};

  &:last-child {
    border: none;
  }
`;

export const EmptyCell = styled.div`
  ${flex({ isColumn: true })};
  ${margins({ p: "8 12" })};

  border-right: 1px solid ${grayscaleInput};
  border-bottom: 1px solid ${grayscaleInput};
  color: ${grayscaleLine};

  &:nth-of-type(7n - 1) {
    color: ${grayscaleLine};
  }

  &:nth-of-type(7n) {
    border-right: none;
    color: ${grayscaleLine};
  }

  &:nth-of-type(n + 36) {
    border-bottom: none;
  }
`;

export const DayNumber = styled.p<{ isEmpty?: boolean }>`
  ${({ isEmpty }) =>
    text({
      weight: 700,
      size: 15,
      height: 20,
      color: isEmpty ? grayscalePlaceholder : grayscaleLabel,
    })};
`;

export const Day = styled(EmptyCell)<{ isCurrentDay?: boolean }>`
  ${flex({ isColumn: true, gap: 8 })};
  ${({ isCurrentDay }) =>
    borders({
      side: "bottom",
      width: isCurrentDay ? 4 : 1,
      color: isCurrentDay ? primaryDefaultDark : grayscaleInput,
    })}
  position: relative;
  overflow-y: hidden;
`;

export const ExpandDayContainer = styled(Day)`
  min-height: 160px;
  ${margins({ p: "8" })};
`;

export const PopoverDay = styled(Popover)`
  z-index: 1;

  .MuiPaper-root {
    ${borders({ radius: 8, none: true })};

    min-width: 210px;
    width: calc((100% - 106px) / 7 + 10px);
    box-shadow: ${shadow}, ${shadow};
  }
`;

export const Vaccination = styled.div`
  ${flex({ gap: 8, isColumn: true })};
  ${margins({ p: "8" })};
  ${borders({ radius: 12 })};
  background: ${primaryBackground};
`;

export const VaccinationPanel = styled.header`
  ${flex({ horizontal: "evenly" })};
  ${margins({ p: "0 8" })};
`;
export const VaccinationName = styled.p`
  ${text({ size: 10, height: 10, weight: 800, color: grayscaleLabel, uppercase: true })};
  ${margins({ p: "0 0 0 12" })};
`;

export const VaccinationDate = styled.div`
  ${borders({ totalRadius: true, width: 1, color: grayscaleLine })};
  ${text({ weight: 500, size: 12, height: 15, color: grayscaleAsh })};
  ${margins({ p: "4 0" })};
  text-align: center;
`;

export const UsedCount = styled.div`
  ${flex({ totalCenter: true, gap: 2 })};

  ${borders({ radius: 8 })};
  ${margins({ p: "0 2 0 0" })};
  ${text({ weight: 700, size: 13, height: 18, color: grayscaleAsh })};
  background: ${greenBackground};
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
`;

export const VaccinationActions = styled.div`
  ${flex({ gap: 8 })};
`;
