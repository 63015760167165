import { useAtom } from "jotai";
import { healthCentersState, modalDataState, useHealthCentersQuery } from "../utils/atoms";
import { useAtomValue } from "jotai/utils";
import { currentUserAtom } from "../../../../atoms/atomCurrentUser";
import { useLoadableData } from "../../../../utils/useLoadableData";
import { getQuestionText } from "../../../../utils/getQuestionText";
import React from "react";
import { HeaderNavigation } from "../../../../uiKit";
import { DICTIONARY } from "../../../../constants/routes";

export const useData = () => {
  // ------------------------------ АТОМЫ
  const [modalData, setModalData] = useAtom(modalDataState);
  const { roleModel } = useAtomValue(currentUserAtom);

  const { totalCount, data, loadableData } = useLoadableData(healthCentersState, []);

  // ------------------------------ ХУКИ

  const { queryKey, api } = useHealthCentersQuery();

  // ------------------------------ КОНСТАНТЫ

  const { canViewHealthCenterDictionary: canView, canEditHealthCenterDictionary: canEdit } =
    roleModel;

  const titlePage = "Медицинские организации";

  const questionWords = ["организацию", "организации", "организаций"];
  const questionText = getQuestionText(questionWords);

  // ------------------------------ ШАПКА - НАВИГАЦИЯ

  const navigation = (
    <HeaderNavigation
      title={titlePage}
      questionText={questionText}
      needSearchButton
      needAddButton={canEdit}
      needDeleteButton={canEdit}
      section={{ name: "Справочники", rout: DICTIONARY }}
      queryKey={queryKey}
      api={api}
    />
  );

  return {
    data,
    loadableData,
    totalCount,

    navigation,

    questionWords,
    questionText,

    canView,
    canEdit,

    modalData,
    setModalData,

    queryKey,
    api,
  };
};
