import styled from "@emotion/styled/macro";
import { PopperProps as PopperPropsType } from "@mui/material";
import { borders, colors, flex, grid, margins, shadow, text } from "../../styles";

export const Container = styled.div<{ needPeriodSwitcher?: boolean }>`
  ${flex({ horizontal: "evenly", vertical: "center" })};
  ${margins({ m: "0 0 0 16" })};

  width: ${({ needPeriodSwitcher }) => (needPeriodSwitcher ? "calc(195px + 300px)" : "195px")};
  // (195px) - ширина переключателя дат
  // (300px) - ширина переключателя периодов

  path {
    fill: ${colors.grayscaleBeautifulBlack};
  }
`;

export const DateSwitcher = styled.div<{ color?: string }>`
  ${flex({ isColumn: true, totalCenter: true })};</>
  ${borders({ totalRadius: true })};

  ${({ color }) => (color ? undefined : "height: 40px")};

  text-align: center;

  :hover {
    background: ${({ color }) => (color ? colors.transparent : colors.grayscaleHoverBackground)};
  }
`;

export const DateSwitcherText = styled.p<{ color?: string }>`
  ${({ color }) =>
    text({
      size: color ? 16 : 13,
      height: color ? 23 : 21,
      weight: color ? 400 : 700,
      color: colors.grayscaleBeautifulBlack,
    })};
  ${({ color }) => margins({ m: color ? "0" : "0 18" })};

  white-space: nowrap;
`;

export const PaperProps = {
  sx: {
    borderRadius: 4,
    boxShadow: shadow,
    padding: 1,

    "&:has(div)": { margin: "10px" },

    ".PrivatePickersYear-yearButton, .PrivatePickersMonth-root, .MuiPickersDay-root": {
      "&:hover, &:focus, &:active": {
        background: colors.grayscaleHoverBackground,
      },
    },

    ".PrivatePickersYear-yearButton.Mui-selected, .PrivatePickersMonth-root.Mui-selected, .MuiPickersDay-root.Mui-selected ":
      {
        background: colors.primaryActiveElement,
        color: colors.grayscaleLabel,

        "&:hover": {
          background: colors.grayscaleHoverBackground,
          cursor: "default",
        },

        "&:focus, &:active": {
          background: colors.primaryActiveElement,
          color: colors.grayscaleLabel,
        },
      },
  },
};

export const PeriodSwitcherContainer = styled.div`
  ${grid({ gap: 4, columns: "repeat(4, 1fr)" })};
  ${borders({ totalRadius: true })};
  ${margins({ m: "0 16 0 0", p: "4" })};

  width: 400px;
  height: 32px;
  background: ${colors.grayscaleInput};
`;

export const PeriodSwitcherButton = styled.div<{ enabled: boolean }>`
  ${flex({ totalCenter: true })};
  ${borders({ totalRadius: true })};

  background: ${({ enabled }) => (enabled ? colors.white : colors.transparent)};
`;

export const PeriodSwitcherButtonText = styled.p`
  ${text({ size: 13, height: 21, weight: 700, color: colors.grayscaleBeautifulBlack })};
`;

export const QuartersPicker = styled.div`
  ${text({ size: 13, height: 21, weight: 700, color: colors.grayscaleBeautifulBlack })};
`;

export const PopperProps = {
  sx: {
    ".MuiPaper-root": PaperProps.sx,
  },
} as Partial<PopperPropsType>;

export const DateIntervalWrapper = styled.div<{ color: string }>`
  ${flex({ gap: 8, totalCenter: true })};
  ${borders({ radius: 16 })};
  ${margins({ p: "16" })};

  position: relative;
  height: 56px;
  background: ${({ color }) => color};
  cursor: pointer;
`;

export const Error = styled.p`
  ${text({ size: 12, height: 12, weight: 400, color: colors.redDefault })};

  position: absolute;
  bottom: 2px;
  left: 16px;
  white-space: nowrap;
`;
