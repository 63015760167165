export const fetchPlans = {
  vs_OrganizationSettings: "organizationSettings-fetch-plan",
  vs_NotificationSettings: "notificationSettings-fetch-plan",
  vs_VaccinationPlan: "vaccinationPlan-fetch-plan",
  vs_VaccinationDate: "vaccinationDate-fetch-plan",
  vs_Employee: "employee-fetch-plan",
  vs_Appointment: "appointment-fetch-plan",
  vs_VaccinationFact: "vaccinationFact-fetch-plan",
  vs_Disease: "disease-fetch-plan",
  vs_Infection: "infection-fetch-plan",
  vs_Vaccine: "vaccine-with-components-fetch-plan",
};
