export const getUserInitials = (nameParts: NamePartsType) => {
  const { firstName, middleName, lastName } = nameParts;
  return `${firstName[0]}${middleName?.[0] ?? lastName?.[0] ?? firstName[1]}`;
};

export const getFullName = (props: PropsType) => {
  const { nameParts, withoutLastName = false, withoutMiddleName = false } = props;

  if (!nameParts) return "";

  const { firstName, middleName, lastName } = nameParts;

  const formattedFirstName = firstName ? ` ${firstName}` : "";
  const formattedMiddleName = withoutMiddleName ? "" : middleName ? ` ${middleName}` : "";
  const formattedLastName = withoutLastName ? "" : lastName ?? "";

  return `${formattedLastName}${formattedFirstName}${formattedMiddleName}`.trimStart();
};

type PropsType = {
  nameParts: NamePartsType | null;
  withoutLastName?: boolean;
  withoutMiddleName?: boolean;
};

type NamePartsType = { firstName: string; middleName?: string; lastName?: string };
