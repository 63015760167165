import React, { FC, useState } from "react";
import * as elements from "./AddList.styles";
import { OpenFormButton, OpenListButton } from "../../uiKit/Buttons";

export const AddList: FC<PropsType> = (props) => {
  const { label, onAdd, children } = props;
  const { Container, List, Header, Label } = elements;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container>
      <Header>
        <Label>{label}</Label>
        {onAdd && <OpenFormButton onClick={onAdd} />}
        <OpenListButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
      </Header>
      {isOpen && <List>{children}</List>}
    </Container>
  );
};

type PropsType = {
  label: string;
  onAdd?: () => void;
};
