import styled from "@emotion/styled/macro";
import { button, colors, flex } from "../../../styles";

export const Container = styled.button<GearButtonPropsType>`
  ${flex({ totalCenter: true })};

  ${button};

  width: ${({ needMenu }) => (needMenu ? "40px" : "28px")};
  height: ${({ needMenu }) => (needMenu ? "40px" : "28px")};
  position: absolute;
  top: ${({ needMenu }) => (needMenu ? "10px" : 0)};
  right: ${({ needMenu }) => (needMenu ? "7px" : 0)};
  z-index: 3;

  div {
    ${button};
  }

  svg {
    width: ${({ menuModalIsOpen, needMenu }) =>
      needMenu ? (menuModalIsOpen ? "20px" : "24px") : menuModalIsOpen ? "16px" : "20px"};
    height: ${({ menuModalIsOpen, needMenu }) =>
      needMenu ? (menuModalIsOpen ? "20px" : "24px") : menuModalIsOpen ? "16px" : "20px"};
  }

  svg > path {
    fill: ${colors.white};
  }

  :hover {
    background: ${({ needSelectingColumns }) =>
      needSelectingColumns ? colors.grayscaleHoverBackground : colors.transparent};

    svg > path {
      fill: ${({ needSelectingColumns }) =>
        needSelectingColumns ? colors.grayscaleBeautifulBlack : colors.white};
    }
  }
`;

type GearButtonPropsType = {
  menuModalIsOpen: boolean;
  needMenu: boolean;
  needSelectingColumns: boolean;
};
