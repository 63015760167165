import React, { FC, MouseEvent, ReactNode, useState } from "react";
import { PopoverModal } from "../../PopoverModal";
import { Container } from "./UniversalTextButton.styles";

export const UniversalTextButton: FC<PropsType> = (props) => {
  const { onClick, text, iconStart, iconEnd, menuElement } = props;
  const { needPoint = false, type = "primary", enabled = true } = props;

  // ------------------------------ < ВЫЗОВ МЕНЮ ЭКСПОРТА

  const [exportMenuElement, setExportMenuElement] = useState<HTMLElement | null>(null);

  const openProfileMenu = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setExportMenuElement(evt.currentTarget);
  };

  const closeExportMenu = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setExportMenuElement(null);
  };

  const exportMenuIsOpen = !!exportMenuElement;

  // ------------------------------ ВЫЗОВ МЕНЮ ЭКСПОРТА >

  return (
    <>
      <Container
        type="button"
        typeButton={type}
        needPoint={needPoint}
        enabled={enabled}
        onClick={menuElement ? openProfileMenu : enabled && !!onClick ? onClick : undefined}
        hasIconStart={!!iconStart}
        hasIconEnd={!!iconEnd}
      >
        {iconStart}
        <p>{text}</p>
        {iconEnd}
      </Container>

      {!!menuElement && (
        <PopoverModal
          id="exportMenu"
          isOpen={exportMenuIsOpen}
          element={exportMenuElement}
          onClose={closeExportMenu}
          anchorOrigin={{ vertical: "center", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: -10 }}
        >
          {menuElement}
        </PopoverModal>
      )}
    </>
  );
};

type PropsType = {
  text: string;
  iconStart?: ReactNode;
  iconEnd?: ReactNode;
  menuElement?: ReactNode;
  needPoint?: boolean;
  type?: "primary" | "secondary" | "text" | "attention" | "positive" | "outlined" | undefined;
  enabled?: boolean;
  onClick?: (arg?: MouseEvent<HTMLButtonElement>) => void;
};
