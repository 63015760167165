import React, { FC, ReactNode } from "react";
import { Container, Header, Title } from "./Card.styles";

export const Card: FC<PropsType> = (props) => {
  const { title, action, children } = props;

  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        {action}
      </Header>
      {children}
    </Container>
  );
};

type PropsType = {
  title: string;
  action?: ReactNode;
};
