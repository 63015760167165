import React, { FC } from "react";
import { AppointmentType } from "../../../../api/apiAppointment";
import { PopoverModal } from "../../../../uiKit";
import { TableMenuButton, TableMenuItemButton } from "../../../../uiKit/Buttons";
import { Pencil, Syringe, Trash } from "../../../../uiKit/Icons";
import { usePopoverHover } from "../../../../uiKit/PopoverHover/usePopoverHover";
import { MenuButtons } from "../../../../uiKit/Table/TableBodyRow/TableBodyRow.styles";
import { ActionCard } from "../ActionCard/ActionCard";
import { VaccinationButton } from "./VaccinationRecord.styles";
import { formatDate, formatParseDate, now, timeString } from "../../../../utils/workingWithDates";

export const VaccinationRecord: FC<PropsType> = (props) => {
  const { appointment, onEdit, onDelete, todayOrFuture, canEdit } = props;
  const { canAddFact, onAddFact } = props;

  const { time, date } = appointment || {};
  const { startTime, endTime } = time || {};

  const { closePopoverHover, propsPopoverHover, openPopoverHover } = usePopoverHover({
    anchorOrigin: { horizontal: "left", vertical: "top" },
    transformOrigin: { horizontal: "right", vertical: "top" },
  });
  const { isOpen } = propsPopoverHover;

  const todayRecord = formatParseDate(date) === formatDate(now);

  return (
    <>
      <ActionCard
        title={formatParseDate(date)}
        description={timeString(startTime, endTime)}
        type="default"
        end={
          <VaccinationButton onClick={canAddFact && todayRecord ? () => onAddFact() : undefined}>
            <Syringe />
          </VaccinationButton>
        }
      >
        {todayOrFuture && canEdit && (
          <TableMenuButton menuModalIsOpen={isOpen} onClick={openPopoverHover} />
        )}
      </ActionCard>

      <PopoverModal {...propsPopoverHover}>
        <MenuButtons>
          <TableMenuItemButton
            text="Редактирование и просмотр"
            icon={<Pencil />}
            xLong
            onClick={() => {
              onEdit(appointment);
              closePopoverHover();
            }}
          />

          {todayOrFuture && (
            <TableMenuItemButton
              text="Удалить запись"
              xLong
              icon={<Trash />}
              onClick={() => {
                onDelete(appointment);
                closePopoverHover();
              }}
            />
          )}
        </MenuButtons>
      </PopoverModal>
    </>
  );
};

type PropsType = {
  todayOrFuture: boolean;
  appointment: AppointmentType;
  canEdit: boolean;
  onEdit: (appointment?: AppointmentType) => void;
  onDelete: (appointment?: AppointmentType) => void;

  onAddFact: () => void;
  canAddFact: boolean;
};
