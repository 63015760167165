import { paginationState, searchQueryState } from "../atoms/atoms";
import { useAtomValue } from "jotai/utils";
import { Getter } from "jotai";
import { currentUserAtom } from "../atoms/atomCurrentUser";

/**
 *
 * ------------------------------------------------------------------------------------------
 * ПОЛУЧЕНИЕ КЛЮЧЕЙ ПАГИНАЦИИ
 *
 * @param get - getter atomWithQuery
 */
export const getPaginationKeys = (get: Getter) => {
  const { page, quantity } = get(paginationState);

  const startIndex = page * quantity;
  const size = quantity;

  return { startIndex, size, page: page + 1 };
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * ПОЛУЧЕНИЕ КЛЮЧЕЙ ПАГИНАЦИИ ДЛЯ КОМПОНЕНТА
 *
 */
export const usePaginationKeys = () => {
  const { page, quantity } = useAtomValue(paginationState);

  const startIndex = page * quantity;
  const size = quantity;

  return { startIndex, size, page: page + 1 };
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * ПОЛУЧЕНИЕ КЛЮЧА ПОИСКА
 *
 * @param get - getter atomWithQuery
 */
export const getQueryKey = (get: Getter) => {
  const queryState = get(searchQueryState);

  const query = queryState || undefined;

  return { query };
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * ПОЛУЧЕНИЕ КЛЮЧА ПОИСКА ДЛЯ КОМПОНЕНТА
 *
 */
export const useQueryKey = () => {
  const queryState = useAtomValue(searchQueryState);

  const query = queryState || undefined;

  return { query };
};

// ------------------------------
/**
 *
 * ------------------------------------------------------------------------------------------
 * ПОЛУЧЕНИЕ КЛЮЧЕЙ ПОИСКА И ПАГИНАЦИИ
 *
 * @param get - getter atomWithQuery
 */
export const getPaginationQueryKeys = (get: Getter) => {
  return { ...getPaginationKeys(get), ...getQueryKey(get) };
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * ПОЛУЧЕНИЕ КЛЮЧЕЙ ПОИСКА И ПАГИНАЦИИ ДЛЯ КОМПОНЕНТА
 *
 */
export const usePaginationQueryKeys = () => {
  const pagination = usePaginationKeys();
  const query = useQueryKey();

  return { ...pagination, ...query };
};

/**
 *
 * ------------------------------------------------------------------------------------------
 * ПОЛУЧЕНИЕ КЛЮЧА ОРГАНИЗАЦИИ ТЕКУЩЕГО ПОЛЬЗОВАТЕЛЯ
 *
 */
export const getUserOrganizationKey = (get: Getter) => {
  const { res } = get(currentUserAtom);
  return res.organization;
};
/**
 *
 * ------------------------------------------------------------------------------------------
 * ПОЛУЧЕНИЕ КЛЮЧА ОРГАНИЗАЦИИ ТЕКУЩЕГО ПОЛЬЗОВАТЕЛЯ
 *
 */
export const useUserOrganizationQueryKey = () => {
  const user = useAtomValue(currentUserAtom);

  return { organization: user.res.organization };
};
