import { css } from "@emotion/react";
import { ColorType } from "../api/types";

export const colors = {
  transparent: "transparent",
  black: "#000000",
  blackOpacity: "#00000015", // 15 - это 0.08 в RGBA
  white: "#FFFFFF",
  whiteOpacity: "#FFFFFFCC", // CC - это 0.8 в RGBA
  gray: "#EFEFEF",

  grayscaleLogo: "#5F6062",
  grayscaleChips: "#E3E9ED",
  grayscaleOffWhite: "#FCFCFC",
  grayscaleBackground: "#F7F7FC",
  grayscaleHoverBackground: "#F4F4F4",
  grayscaleDisabled: "#F2F2F2",
  grayscaleInput: "#EFF0F6",
  grayscaleInputDisabled: "#8a8a95",
  grayscaleLine: "#D9DBE9",
  grayscaleIcons: "#A4B3C6",
  grayscalePlaceholder: "#A0A3BD",
  grayscaleLabel: "#6E7191",
  grayscaleLabelOpacityA0: "#6E7191A0", // A0 - это 0.63 в RGBA
  grayscaleLabelOpacity77: "#6E719177", // 77 - это 0.47 в RGBA
  grayscaleBody: "#4E4B66",
  grayscaleAsh: "#262338",
  grayscaleBeautifulBlack: "#14142B",
  grayscaleBeautifulBlackOpacity: "#14142BCC", // CC - это 0.8 в RGBA

  primaryBackground: "#F9FBFF",
  primaryBackgroundOpacity: "#F9FBFF80", // 80 - это 0.5 в RGBA
  primaryActiveElement: "#E0F2FF",
  primaryActiveElementOpacity: "#E0F2FFCC", // CC - это 0.8 в RGBA
  primaryLight: "#CEEAFF",
  primaryDefault: "#118EFF",
  primaryDefaultDark: "#1473F2",
  primaryDark: "#0000FF",

  blue: "#4392CE",
  blueLight: "#95D3FF",
  blueWhite: "#E7F4FD",
  indigo: "#025EA1",
  purple: "#610BEF",

  redBackground: "#FAEBEB",
  redLight: "#F2CCCC",
  redWhite: "#FFF9F9",
  redDefault: "#E01010",
  redDark: "#B00000",

  greenBackground: "#EBFFE3",
  greenLight: "#A6F787",
  greenDefault: "#4BB34B",
  greenDark: "#067306",

  warningBackground: "#FFF8E9",
  warningLight: "#FFE6B0",
  warningDefault: "#EAAC30",
  warningDark: "#946300",

  yellowForPinkLogo: "#FCC30B",
  redForPinkLogo: "#FF3A21",
  pinkForPinkLogo: "#FF00FF",
  orangeForPinkLogo: "#FB7C59",
  crimsonForPinkLogo: "#FE3076",
  fuchsiaForPinkLogo: "#FF0CED",

  statesActive2: "#CAE0FF",
} as Record<string, ColorType>;

export const overflowOneLine = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const overflowOneLineByPixels = (size: number) => css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: ${size}px;
`;

export const overflowLines = (lines: number) => css`
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;
`;

export const shadow = `0 0 2px ${colors.blackOpacity}, 0 2px 24px ${colors.blackOpacity}`;
