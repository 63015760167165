import styled from "@emotion/styled/macro";
import { Dialog } from "@mui/material";
import { borders, colors, flex, margins, text } from "../../styles";

export const ConfirmContainer = styled(Dialog)`
  ${borders({ radius: 24 })};

  .MuiBackdrop-root {
    background: ${colors.primaryActiveElementOpacity};
    backdrop-filter: blur(10px);
    opacity: 0.6;
  }

  .MuiPaper-root {
    ${flex({ gap: 16, isColumn: true, horizontal: "center" })};
    ${borders({ radius: 24 })};
    ${margins({ m: "0", p: "16" })};

    width: 360px;
    min-height: 151px;
    box-shadow: none;
  }
` as typeof Dialog;

export const NotificationContainer = styled(ConfirmContainer)`
  .MuiPaper-root {
    align-items: normal;
  }
`;

export const ConfirmHeader = styled.p`
  ${text({ size: 20, height: 26, weight: 700, align: "center" })};

  width: 100%;
`;
export const ConfirmBody = styled.p`
  ${text({ size: 15, height: 20, weight: 400, align: "center", color: colors.grayscaleLabel })};

  width: 100%;
`;

export const ConfirmAdditionalButton = styled.div`
  ${flex()};
  ${margins({ m: "16 0 0" })};

  flex-flow: column;
`;
