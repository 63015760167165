import React from "react";
import { LittleOtherType } from "../../api/apiDictionaries";
import * as elements from "./AddList.styles";
import { TrashButton } from "../../uiKit/Buttons";

export const AddListElement = (props: PropsType) => {
  const { item, onDelete } = props;
  const { id: itemId, name } = item;

  const { ListElement, ItemLabel, VerticalLine } = elements;

  return (
    <ListElement>
      <ItemLabel>{name}</ItemLabel>
      <VerticalLine />
      <TrashButton onClick={() => onDelete(itemId)} />
    </ListElement>
  );
};

type PropsType = {
  item: LittleOtherType;
  onDelete: (id: string) => void;
};
