import React, { FC } from "react";
import { colors } from "../../styles";

export const Switch: FC<PropsType> = (props) => {
  const { checked } = props;

  return (
    <>
      {checked ? (
        <svg
          width="58"
          height="40"
          viewBox="0 0 58 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="8" y="8" width="42" height="24" rx="12" fill={colors.primaryDefault} />
          <circle cx="38" cy="20" r="10" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.4636 17.2636C41.8151 16.9121 42.3849 16.9121 42.7364 17.2636C43.0879 17.6151 43.0879 18.1849 42.7364 18.5364L38.1364 23.1364C37.7849 23.4879 37.2151 23.4879 36.8636 23.1364L34.2636 20.5364C33.9121 20.1849 33.9121 19.6151 34.2636 19.2636C34.6151 18.9121 35.1849 18.9121 35.5364 19.2636L37.5 21.2272L41.4636 17.2636Z"
            fill={colors.primaryDefault}
          />
        </svg>
      ) : (
        <svg
          width="58"
          height="40"
          viewBox="0 0 58 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="8" y="8" width="42" height="24" rx="12" fill={colors.grayscaleLine} />
          <circle cx="20" cy="20" r="10" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.3062 23.6939C15.8982 23.2859 15.8982 22.6243 16.3062 22.2163L18.5225 20.0001L16.306 17.7836C15.898 17.3756 15.898 16.714 16.306 16.306C16.714 15.898 17.3756 15.898 17.7836 16.306L20 18.5225L22.2165 16.306C22.6244 15.898 23.286 15.898 23.694 16.306C24.102 16.714 24.102 17.3756 23.694 17.7836L21.4776 20.0001L23.6938 22.2163C24.1019 22.6243 24.1019 23.2859 23.6938 23.6939C23.2858 24.102 22.6242 24.102 22.2162 23.6939L20 21.4777L17.7837 23.6939C17.3757 24.102 16.7142 24.102 16.3062 23.6939Z"
            fill={colors.grayscaleIcons}
          />
        </svg>
      )}
    </>
  );
};

type PropsType = {
  checked: boolean;
};
