import React, { FC, HTMLAttributes, SyntheticEvent, useEffect } from "react";
import { OptionsType, OptionType } from "../../../../utils/getOptions";
import * as elements from "./SearchChipsSelector.styles";
import { AutocompleteRenderInputParams, InputAdornment, MenuItem, TextField } from "@mui/material";
import { Loupe, TriangleDown } from "../../../../uiKit/Icons";

export const SearchChipsSelector: FC<PropsType> = (props) => {
  const { options, placeholder, values, onSelect, selectValue, onChange } = props;

  const { Container, Item, MenuItemWrapper, PaperProps, PopperComponent } = elements;
  const { SelectedChipsItem, SelectedOptions, OptionChipsItem } = elements;

  const selectedOptions = options.filter(({ id }) => values.includes(id));

  useEffect(() => {
    if (!selectValue && selectedOptions.length) onSelect(selectedOptions[0].id ?? "");
  }, []);

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      placeholder={placeholder}
      InputProps={{
        ...params.InputProps,
        startAdornment: <InputAdornment position="start">{<Loupe />}</InputAdornment>,
      }}
    />
  );

  const handleChange = (
    _evt: SyntheticEvent<Element, Event>,
    arg: string | OptionType | (string | OptionType)[] | null
  ) => {
    if (!arg || !Array.isArray(arg) || typeof arg === "string") return;

    const selected = (arg as unknown as OptionType[]).map(({ id }) => id);
    onChange(selected);
    if (selected.length === 1) onSelect(selected[0]);
  };

  const handleDelete = (removedId: string) => {
    const selected = selectedOptions.filter(({ id }) => id !== removedId).map(({ id }) => id);

    if (removedId === selectValue) onSelect(selected.length ? selected[0] : "");

    onChange(selected);
  };

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: OptionType) =>
    option.id !== "" ? (
      <MenuItem {...props} key={option.id}>
        <MenuItemWrapper>{option.name}</MenuItemWrapper>
      </MenuItem>
    ) : null;

  return (
    <Container>
      <Item
        value={selectedOptions}
        options={options}
        getOptionLabel={(option) => option?.name as string}
        onChange={handleChange}
        renderInput={renderInput}
        renderOption={renderOption}
        popupIcon={<TriangleDown />}
        closeText=""
        openText=""
        PopperComponent={PopperComponent}
        componentsProps={{ paper: PaperProps() }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        noOptionsText="Нет совпадений"
        multiple
      />

      <SelectedOptions>
        {values.map((value) =>
          value === selectValue ? (
            <SelectedChipsItem
              key={value}
              label={options.find(({ id }) => id === value)?.name ?? ""}
              onDelete={() => handleDelete(value)}
            />
          ) : (
            <OptionChipsItem key={value} onClick={() => onSelect(value)}>
              {options.find(({ id }) => id === value)?.name ?? ""}
            </OptionChipsItem>
          )
        )}
      </SelectedOptions>
    </Container>
  );
};

type PropsType = {
  options: OptionsType;
  placeholder: string;
  values: string[];
  onChange: (evt: string[]) => void;
  onSelect: (id: string) => void;
  selectValue: string;
};
