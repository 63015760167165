import React, { FC } from "react";
import { useAntibodies } from "../../Vaccinations/hooks/useAntibodies";
import { PopoverModal } from "../../../../uiKit";
import { MenuButtons } from "../../../../uiKit/Table/TableBodyRow/TableBodyRow.styles";
import { TableMenuButton, TableMenuItemButton } from "../../../../uiKit/Buttons";
import { Pencil, Trash } from "../../../../uiKit/Icons";
import { usePopoverHover } from "../../../../uiKit/PopoverHover/usePopoverHover";
import { formatParseDate } from "../../../../utils/workingWithDates";
import * as elements from "../VaccinationSettings/VaccinationSettings.styles";
import { AntibodiesType } from "../../../../api/apiEmployees";

export const AntibodiesItem: FC<PropsType> = (props) => {
  const { antibodies, setModalData } = props;
  const { setIsOpenForm, setIsOpenConfirmModal } = props;

  const { Settings, SubTitle, AdditionalButton, SecondaryText } = elements;

  const { closePopoverHover, propsPopoverHover, openPopoverHover } = usePopoverHover({});
  const { isOpen } = propsPopoverHover;

  return (
    <>
      <Settings>
        <SubTitle>{antibodies.description}</SubTitle>
        <SecondaryText>/ {formatParseDate(antibodies.date)}</SecondaryText>
        <AdditionalButton>
          <TableMenuButton menuModalIsOpen={isOpen} onClick={openPopoverHover} />
        </AdditionalButton>
      </Settings>

      <PopoverModal {...propsPopoverHover}>
        <MenuButtons>
          <TableMenuItemButton
            text="Изменить"
            icon={<Pencil />}
            onClick={() => {
              setModalData(antibodies);
              setIsOpenForm(true);
              closePopoverHover();
            }}
          />

          <TableMenuItemButton
            text="Удалить"
            icon={<Trash />}
            onClick={() => {
              setModalData(antibodies);
              setIsOpenConfirmModal(true);
              closePopoverHover();
            }}
          />
        </MenuButtons>
      </PopoverModal>
    </>
  );
};

type PropsType = Pick<
  ReturnType<typeof useAntibodies>,
  "setModalData" | "setIsOpenForm" | "setIsOpenConfirmModal"
> & { antibodies: AntibodiesType };
