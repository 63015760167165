import { atom } from "jotai";
import { healthCentersApi, HealthCenterType } from "../../../../api/apiDictionaries";
import { atomWithQuery } from "jotai/query";
import { fetchAll, fetch } from "../../../../utils/fetchData";
import { getPaginationQueryKeys, usePaginationQueryKeys } from "../../../../utils/useQueryKeys";
import { PaginationQueryType } from "../../../../api/types";
import { getQueryFilter } from "../../../../utils/getQueryFilter";
import { loadable } from "jotai/utils";

export const modalDataState = atom<HealthCenterType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

export const healthCentersAtom = atomWithQuery((get) => {
  const args = getPaginationQueryKeys(get);

  return healthCentersQuery(args);
});

export const healthCentersState = loadable(healthCentersAtom);

export const healthCentersQuery = (props: PaginationQueryType) => {
  const { startIndex, query, size } = props;

  const filter = query ? [getQueryFilter(query, ["name", "shortName"])] : undefined;

  const args = { startIndex, size, filter };

  return {
    queryKey: [startIndex, size, query, "healthCentersState"],
    queryFn: () => fetchAll(() => healthCentersApi().getAll(args)),
  };
};

export const useHealthCentersQuery = () => {
  const { size, query, startIndex } = usePaginationQueryKeys();

  return {
    queryKey: [startIndex, size, query, "healthCentersState"],
    api: healthCentersApi(),
  };
};

// ------------------------------ ВСЕ

export const healthCenterAllState = atomWithQuery(() => ({
  queryKey: ["healthCenterAllState"],
  queryFn: () => fetch(() => healthCentersApi().getAll()),
}));
