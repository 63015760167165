import React from "react";
import { InfectionDiseaseType } from "../../../../api/apiInfectionDiseases";
import { arrYears } from "../../../../constants/dates";
import { Gear } from "../../../../uiKit/Icons";
import { getInclinedWord } from "../../../../utils/getInclinedWord";
import { getFormattedInterval } from "../../../../utils/workingWithDates";
import { VaccinationComponent } from "../VaccinationComponent/VaccinationComponent";
import { useData } from "./useData";

export const useTable = () => {
  // ------------------------------ ХУКИ

  const { canEdit } = useData();

  // ------------------------------ КОНСТАНТЫ

  const tableSettings = {
    hideDelete: true,
    editButton: { icon: <Gear />, text: "Настроить" },
  };

  const titlesInit = [
    { id: 1, title: "№", name: "number" },
    { id: 2, title: "Наименование заболевания", name: "name" },
    { id: 3, title: "Код МКБ-10", name: "icd" },
    { id: 4, title: "Возраст", name: "ages" },
    { id: 5, title: "Вакцинация", name: "vaccination" },
    { id: 6, title: "Ревакцинация", name: "reVaccination" },
    { id: 7, title: "Периодичность между вакцинацией и ревакцинацией", name: "interval" },
    { id: 8, title: "Периодичность между вакцинациями", name: "reInterval" },
  ];

  // ------------------------------ ТАБЛИЦА - ШИРИНЫ ТАБЛИЦ

  const gridsInit = [
    { titleId: 1, size: "76px" },
    { titleId: 2, size: "1.5fr" },
    { titleId: 3, size: "1fr" },
    { titleId: 4, size: "1fr" },
    { titleId: 5, size: "2fr" },
    { titleId: 6, size: "2fr" },
    { titleId: 7, size: "1fr" },
    { titleId: 8, size: "1fr" },
    ...(canEdit ? [{ titleId: null, size: "56px" }] : []),
  ];

  // ------------------------------ ТАБЛИЦА - ДАННЫЕ ДЛЯ СТРОКИ

  const object = (
    { name, description, icd, startAge, endAge, vaccines }: InfectionDiseaseType,
    number?: number
  ): ObjectType => {
    const vaccine = vaccines.find(({ type }) => type === "VACCINATION");
    const reVaccine = vaccines.find(({ type }) => type === "REVACCINATION");

    return {
      ...(number ? { number: number } : {}),
      name,
      icd: icd?.map(({ code }) => code).join(", ") ?? "—",
      description: description || "—",
      ages: `От ${startAge}${endAge ? ` до ${endAge}` : ""} ${getInclinedWord(
        endAge ? endAge : startAge,
        arrYears
      )}`,
      vaccination: <VaccinationComponent components={vaccine?.components} />,
      reVaccination: <VaccinationComponent components={reVaccine?.components} />,
      interval: vaccine?.interval !== undefined ? `${getFormattedInterval(vaccine.interval)}` : "—",
      reInterval:
        reVaccine?.interval !== undefined ? getFormattedInterval(reVaccine.interval) : "—",
    };
  };

  return {
    titlesInit,
    gridsInit,
    object,

    tableSettings,
  };
};

export type ObjectType = {
  number?: number;
  name: string;
  description: string;
  icd: string;
  ages: string;
  vaccination: JSX.Element;
  reVaccination: JSX.Element;
  reInterval: string;
  interval: string;
};
