import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";

// ------------------------------ ФЛЕКСЫ

export const flex = (props?: {
  gap?: number;
  isColumn?: boolean;
  totalCenter?: boolean;
  horizontal?: "center" | "start" | "end" | "evenly";
  vertical?: "center" | "start" | "end" | "evenly";
}) => {
  if (!props)
    return css`
      display: flex;
    `;

  const { gap, isColumn = false, totalCenter, horizontal, vertical } = props;

  return css`
    display: flex;

    ${gap ? `gap: ${gap}px` : undefined};

    ${isColumn ? `flex-direction: column` : undefined};

    ${totalCenter ? `justify-content: center; align-items: center` : undefined};

    ${horizontal
      ? isColumn
        ? `align-items: ${horizontal === "evenly" ? "space-between" : horizontal}`
        : `justify-content: ${horizontal === "evenly" ? "space-between" : horizontal}`
      : undefined};

    ${vertical
      ? isColumn
        ? `justify-content: ${vertical === "evenly" ? "space-between" : vertical}`
        : `align-items: ${vertical === "evenly" ? "space-between" : vertical}`
      : undefined};
  `;
};

// ------------------------------ ГРИДЫ

export const grid = (props?: {
  gap?: number | string;
  columns?: string;
  rows?: string;
  autoRows?: number;
  autoFlowColumn?: boolean;
  itemsInCenter?: boolean;
}) => {
  if (!props)
    return css`
      display: flex;
    `;

  const { gap, columns, rows, autoRows, itemsInCenter, autoFlowColumn } = props;

  return css`
    display: grid;

    ${gap ? (typeof gap === "string" ? gap : `gap: ${gap}px`) : undefined};

    ${columns ? `grid-template-columns: ${columns}` : undefined};

    ${rows ? `grid-template-rows: ${rows}` : undefined};

    ${autoRows ? `grid-auto-rows: ${autoRows}px` : undefined};

    ${itemsInCenter ? "align-items: center" : undefined};

    ${autoFlowColumn ? "grid-auto-flow: column" : undefined};
  `;
};

// ------------------------------ БЛОК С КОЛОНКАМИ ОДНОЙ ШИРИНЫ

export const Columns = styled.div<{ columns: number }>`
  ${({ columns }) => grid({ gap: 16, columns: `repeat(${columns}, 1fr)` })};
  width: 100%;
`;
