import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable } from "jotai/utils";
import {
  infectionDiseasesServicesApi,
  InfectionDiseaseType,
  InfectionPropType,
} from "../../../../api/apiInfectionDiseases";
import { fetchAll } from "../../../../utils/fetchData";
import { getPaginationQueryKeys, usePaginationQueryKeys } from "../../../../utils/useQueryKeys";

export const modalDataState = atom<InfectionDiseaseType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

export const infectionsVaccinationsAtom = atomWithQuery((get) => {
  const { page, size, query } = getPaginationQueryKeys(get);

  return infectionsVaccinationsQuery({ name: query, size, page });
});

export const infectionVaccinationsState = loadable(infectionsVaccinationsAtom);

export const infectionsVaccinationsQuery = (props: InfectionPropType) => {
  const { name, page, size } = props;

  return {
    queryKey: [name, page, size, "infectionVaccinationsState"],
    queryFn: () => fetchAll(() => infectionDiseasesServicesApi().getAll(props)),
  };
};

export const useInfectionVaccinationsQuery = () => {
  const { page, size, query } = usePaginationQueryKeys();

  return {
    queryKey: [query, page, size, "infectionVaccinationsState"],
    api: infectionDiseasesServicesApi(),
  };
};
