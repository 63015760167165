import React, { FC } from "react";
import { downloadFile, FileLinkType } from "../../utils/getFile";
import { FileName } from "./FileLink.styles";
import { DocumentType } from "../../api/apiFile";
import { getFileName } from "../../utils/workingWithFiles";

export const FileLink: FC<PropsType> = (props) => {
  const { file, name } = props;

  const fileName = name || getFileName(file);
  const fileRef = typeof file === "string" ? file : file.file && undefined;

  if (!fileRef || !fileName) return <>—</>;

  return <FileName onClick={() => downloadFile(fileRef, fileName)}>{fileName}</FileName>;
};

type PropsType = {
  file: DocumentType | FileLinkType;
  name?: string;
};
