import styled from "@emotion/styled/macro";
import { button } from "../../../styles";

export const Container = styled.button`
  ${button};

  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
`;
