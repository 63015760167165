import React, { FC, MouseEvent } from "react";
import { shadow } from "../../styles";
import { Container, Title } from "./PopoverModal.styles";

export const PopoverModal: FC<PropsType> = (props) => {
  const { id, title = "", isOpen, element, onClose, children } = props;
  const { anchorOrigin, transformOrigin } = props;

  return (
    <Container
      id={id}
      open={isOpen}
      anchorEl={element}
      onClose={onClose}
      anchorOrigin={
        anchorOrigin ?? {
          vertical: "bottom",
          horizontal: "left",
        }
      }
      transformOrigin={
        transformOrigin ?? {
          vertical: "top",
          horizontal: "right",
        }
      }
      PaperProps={{
        sx: {
          boxShadow: shadow,
          borderRadius: 4,
        },
      }}
    >
      {!!title && <Title>{title}</Title>}
      {children}
    </Container>
  );
};

type PropsType = {
  id?: string;
  title?: string;
  isOpen: boolean;
  element: HTMLElement | null;
  onClose: (evt: MouseEvent<HTMLElement>) => void;
  anchorOrigin?: PositionType;
  transformOrigin?: PositionType;
};

type PositionType = {
  vertical: "top" | "center" | "bottom" | number;
  horizontal: "left" | "center" | "right" | number;
};
