import styled from "@emotion/styled/macro";
import { borders, colors, flex, margins, text } from "../../styles";
import { MarginsType } from "../../styles/marginsStyles";

export const Icon = styled.div<PropsType>`
  ${flex({ totalCenter: true })};
  ${borders({ totalRadius: true })};
  ${({ margin }) => margins({ m: margin })};

  width: ${({ width }) => (width ? `${width}px` : "40px")};
  height: ${({ height }) => (height ? `${height}px` : "40px")};
  flex-shrink: 0;
  background: ${({ backgroundImg }) =>
    backgroundImg ? `url(data:image/jpg+xml;base64,${backgroundImg})` : colors.purple};
  background-size: cover;
  cursor: pointer;

  p {
    ${text({ size: 15, height: 20, weight: 500, color: colors.white })};
  }
`;

type PropsType = {
  width?: string;
  height?: string;
  margin?: MarginsType;
  backgroundImg?: string;
};
