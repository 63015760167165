import styled from "@emotion/styled/macro";
import { Link as LinkRouter } from "react-router-dom";
import { colors, text } from "../../styles";

const { primaryDefault, primaryDefaultDark } = colors;

export const Link = styled(LinkRouter)<{ active: number }>`
  ${text({ size: 15, height: 24, weight: 700, color: primaryDefaultDark })};

  cursor: ${({ active }) => (active ? "pointer" : "default")};

  :hover {
    ${({ active }) => {
      const color = active ? primaryDefault : primaryDefaultDark;

      return text({ size: 15, height: 24, weight: 700, color: color });
    }};

    opacity: ${({ active }) => (active ? "1" : "0.6")};
  }
`;
