import { getQuestionText } from "../../../../utils/getQuestionText";
import React from "react";
import { diseaseSeveritiesState, modalDataState, useDiseaseSeveritiesQuery } from "../utils/atoms";
import { useAtom } from "jotai";
import { useAtomValue } from "jotai/utils";
import { currentUserAtom } from "../../../../atoms/atomCurrentUser";
import { HeaderNavigation } from "../../../../uiKit";
import { DICTIONARY } from "../../../../constants/routes";
import { searchQueryState } from "../../../../atoms/atoms";

export const useData = () => {
  // ------------------------------ АТОМЫ

  const [modalData, setModalData] = useAtom(modalDataState);
  const { roleModel } = useAtomValue(currentUserAtom);
  const query = useAtomValue(searchQueryState);

  let data = useAtomValue(diseaseSeveritiesState);

  // ------------------------------ ХУКИ

  const { queryKey, api } = useDiseaseSeveritiesQuery();
  const { canViewOtherDictionaries: canView, canEditOtherDictionaries: canEdit } = roleModel;

  // ------------------------------ КОНСТАНТЫ
  const titlePage = "Cтепень тяжести заболевания";

  const questionWords = [
    "степень тяжести заболевания",
    "степени тяжести заболевания",
    "степеней тяжести заболевания",
  ];
  const questionText = getQuestionText(questionWords);

  // ------------------------------ ДАННЫЕ - ФИЛЬТРАЦИЯ

  if (query) {
    data = data.filter(({ name, description }) => {
      const datumForSearch = { name, description };

      return Object.values(datumForSearch).join().toLowerCase().includes(query.toLowerCase());
    });
  }

  // ------------------------------ ШАПКА - НАВИГАЦИЯ

  const navigation = (
    <HeaderNavigation
      title={titlePage}
      questionText={questionText}
      needSearchButton
      needAddButton={canEdit}
      needDeleteButton={canEdit}
      section={{ name: "Справочники", rout: DICTIONARY }}
      queryKey={queryKey}
      api={api}
    />
  );

  return {
    data,

    navigation,

    questionWords,
    questionText,

    canView,
    canEdit,

    modalData,
    setModalData,

    api,
    queryKey,
  };
};
