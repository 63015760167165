import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { loadable, useAtomValue } from "jotai/utils";
import { AppointmentType } from "../../../../api/apiAppointment";
import {
  EmployeeModuleType,
  EmployeePropsType,
  employeeServiceApi,
} from "../../../../api/apiEmployees";
import {
  RecordTimeType,
  vaccinationDayApi,
  VaccinationFactType,
} from "../../../../api/apiVaccination";
import { componentApi, NextComponentPropsType, vaccineApi } from "../../../../api/apiVaccine";
import { fetch, fetchAll, fetchById } from "../../../../utils/fetchData";
import {
  getPaginationKeys,
  getUserOrganizationKey,
  usePaginationKeys,
} from "../../../../utils/useQueryKeys";
import { formatDateForBackend } from "../../../../utils/workingWithDates";
import { searchQueryState } from "../../../../atoms/atoms";

// ------------------------------ ФОРМА
export const currentTypeFormState = atom<
  "appointment" | "fact" | "appointment-deletion" | undefined
>(undefined);
export const currentFormRowState = atom<EmployeeModuleType | undefined>(undefined);

// ------------------------------ ЗАПИСЬ НА ВАКЦИНАЦИЮ

export const modalDataAppointmentState = atom<AppointmentType | undefined>(undefined);

// ------------------------------ ФАКТИЧЕСКАЯ ВАКЦИНАЦИЯ

export const modalDataFactState = atom<VaccinationFactType | undefined>(undefined);

// ------------------------------ ТЕКУЩАЯ ДАТА

export const currentDateDay = atom<Date | null>(null);

// ------------------------------ ПАРАМЕТРЫ СТРАНИЦЫ

export const paramsState = atom<ParamsType>({
  healthCenterId: "",
  infectionId: "",
  vaccinationPlanId: "",
});

export type ParamsType = {
  healthCenterId?: string;
  infectionId?: string;
  vaccinationPlanId?: string;
};

// ------------------------------ ФИЛЬТРЫ

export const selectedTimeState = atom<RecordTimeType | undefined>(undefined);

export const absoluteInitialValues: FiltersType = {
  departmentCode: "",
  hasAppointment: "",
  access: "",
  agreement: "",
  plannedExpired: "",
  exemption: "",
};

export const filtersState = atom(absoluteInitialValues);

export type FiltersType = {
  departmentCode: string;
  hasAppointment: string;
  access: string;
  agreement: string;
  plannedExpired: string;
  exemption: string;
};

// ------------------------------ ДАННЫЕ - ДЕНЬ

export const calendarDayState = atomWithQuery((get) => {
  const { infectionId, healthCenterId } = get(paramsState);
  const date = get(currentDateDay);

  const args = {
    infectionId: infectionId ?? "",
    date: date ? formatDateForBackend(date) : "",
    healthCenterId: healthCenterId ?? "",
  };

  return calendarDayQuery(args);
});

const calendarDayQuery = ({ infectionId, date, healthCenterId }: ParamsType & { date: string }) => {
  const filter = [
    { property: "vaccinationPlan.healthCenter.id", operator: "=", value: healthCenterId ?? "" },
    { property: "date", operator: "=", value: date ?? "" },
    { property: "vaccinationPlan.infection", operator: "=", value: infectionId ?? "" },
    { property: "vaccinationPlan.deleteBy", operator: "isNull" },
  ];

  const isEnabled = !!healthCenterId && !!date && !!infectionId;

  return {
    queryKey: [healthCenterId, date, infectionId, "calendarDayState"],
    queryFn: () => fetch(() => vaccinationDayApi().getAll({ filter })),
    enabled: isEnabled,
  };
};

export const useCalendarDayQuery = () => {
  const { healthCenterId, infectionId } = useAtomValue(paramsState);
  const dateDay = useAtomValue(currentDateDay);

  const date = dateDay ? formatDateForBackend(dateDay) : "";
  return {
    queryKey: [healthCenterId, date, infectionId, "calendarDayState"],
    api: vaccinationDayApi(),
  };
};

// ------------------------------ ДАННЫЕ - СЛЕДУЮЩИЙ КОМПОНЕНТ ДЛЯ КОНКРЕТНОГО РАБОТНИКА

export const nextComponentState = atomWithQuery((get) => {
  const { infectionId } = get(paramsState);
  const employee = get(currentFormRowState);

  const args = {
    infectionId: infectionId ?? "",
    employeeId: employee?.id ?? "",
  };

  return nextComponentQuery(args);
});

export const nextComponentQuery = (props: NextComponentPropsType) => {
  const { infectionId, employeeId } = props;

  return {
    queryKey: [infectionId, employeeId, "nextComponentState"],
    queryFn: () => fetchById(() => componentApi().getNext(props)),
    enabled: !!infectionId && !!employeeId,
  };
};

// ------------------------------ ДАННЫЕ - СПИСОК РАБОТНИКОВ

export const employeesDayAtom = atomWithQuery((get) => {
  const filter = get(filtersState);
  const search = get(searchQueryState);
  const { infectionId, vaccinationPlanId } = get(paramsState);

  const { size, page } = getPaginationKeys(get);

  const args = {
    infectionId,
    vaccinationPlanId,
    page,
    size,
    textSearch: search !== "" ? search : undefined,
    ...getFilters(filter),
  };

  return employeesDayQuery(args);
});

export const employeesDayState = loadable(employeesDayAtom);

const employeesDayQuery = (props: EmployeePropsType) => {
  const { vaccinationPlanId } = props;
  return {
    queryKey: [...Object.values(props), "employeesDayState"],
    queryFn: () => fetchAll(() => employeeServiceApi().getAll(props)),
    enabled: !!vaccinationPlanId,
  };
};

export const useEmployeesDayKeys = () => {
  const filter = useAtomValue(filtersState);
  const search = useAtomValue(searchQueryState);
  const { infectionId, vaccinationPlanId } = useAtomValue(paramsState);
  const { size, page } = usePaginationKeys();

  const args = {
    infectionId,
    vaccinationPlanId,
    page,
    size,
    search: search !== "" ? search : undefined,
    ...getFilters(filter),
  };
  return {
    queryKey: [...Object.values(args), "employeesDayState"],
    api: employeeServiceApi(),
  };
};

// ------------------------------ ДАННЫЕ - ВАКЦИНЫ И КОМОНЕНТЫ

export const vaccineInfectionState = atomWithQuery((get) => {
  const organization = getUserOrganizationKey(get);
  const { infectionId } = get(paramsState);

  return {
    queryKey: [organization, infectionId, "vaccineInfectionState"],
    queryFn: () => fetchById(() => vaccineApi().getByInfection(infectionId ?? "", organization)),
    enabled: !!infectionId,
  };
});

const getFilters = ({
  departmentCode,
  hasAppointment,
  access,
  agreement,
  plannedExpired,
  exemption,
}: FiltersType) => ({
  departmentCode: departmentCode !== "" ? departmentCode : undefined,
  hasAppointment: hasAppointment ? hasAppointment === "true" : undefined,
  agreement: agreement ? agreement === "true" : undefined,
  access: access ? access === "true" : undefined,
  exemption: exemption ? exemption === "true" : undefined,
  plannedExpired: plannedExpired ? plannedExpired === "true" : undefined,
});
