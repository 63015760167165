import React from "react";
import * as Yup from "yup";
import { InfectionDiseaseType } from "../../../../api/apiInfectionDiseases";
import { vaccineApi } from "../../../../api/apiVaccine";
import { TitleWithInfo } from "../../../../components";
import { multiRequired, notJustSpaces, required } from "../../../../constants/forValidationSchemes";
import {
  dateDayTypeOptions,
  dateTypeOptions,
  dateYearTypeOptions,
} from "../../../../constants/options";
import { withoutSpaces } from "../../../../constants/regexs";
import { FieldsBlock, FormSpacer } from "../../../../uiKit/Form/Form.styles";
import { QueryKeyType, useQuery } from "../../../../utils/useQuery";
import {
  getIntervalByUnit,
  getIntervalUnit,
  intervalToNumber,
} from "../../../../utils/workingWithDates";
import { ComponentsField } from "../ComponentsField/ComponentsField";
import { PeriodFields } from "../PeriodFields/PeriodFields";

export const useForms = (
  organization: string,
  queryKey: QueryKeyType,
  infection: InfectionDiseaseType | undefined
) => {
  // ------------------------------ КОНСТАНТЫ

  const addFormTitle = "Настройка вакцинации";
  const editFormTitle = "Настройка вакцинации";

  const formName = "infectionVaccination";

  // ------------------------------ ФОРМА - ПОЛЯ

  const fields = (
    <FieldsBlock>
      <TitleWithInfo title={infection?.name ?? ""} info={infection?.description} />

      <FormSpacer />

      <ComponentsField name="vaccineIntervals" outsideLabel="Вакцинация" />
      <ComponentsField name="reVaccineIntervals" outsideLabel="Ревакцинация" />
      <PeriodFields
        periodName="vaccinePeriod"
        options={dateTypeOptions}
        unitName="vaccinePeriodUnit"
        outsideLabel="Период между вакцинацией и ревакцинацией"
      />
      <PeriodFields
        periodName="reVaccinePeriod"
        options={dateYearTypeOptions}
        unitName="reVaccinePeriodUnit"
        outsideLabel="Период между вакцинациями"
      />
    </FieldsBlock>
  );

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesAddForm = {
    vaccineIntervals: [""],
    reVaccineIntervals: [""],
    vaccinePeriodUnit: dateTypeOptions[0].id,
    reVaccinePeriodUnit: dateYearTypeOptions[0].id,
  };

  const initialValuesEditForm = (infection: InfectionDiseaseType) => {
    const { vaccines } = infection;

    const vaccine = vaccines.find(({ type }) => type === "VACCINATION");
    const reVaccine = vaccines.find(({ type }) => type === "REVACCINATION");

    return {
      vaccineIntervals:
        vaccine?.components
          ?.sort((a, b) => a.number - b.number)
          .map(({ interval }) => interval.toString()) ?? [],
      reVaccineIntervals:
        reVaccine?.components
          ?.sort((a, b) => a.number - b.number)
          .map(({ interval }) => interval.toString()) ?? [],

      vaccinePeriod: vaccine?.interval ? intervalToNumber(vaccine.interval) : vaccine?.interval,
      vaccinePeriodUnit: vaccine?.interval
        ? getIntervalUnit(vaccine.interval)
        : dateDayTypeOptions[0].id,

      reVaccinePeriod: reVaccine?.interval ? Number((reVaccine.interval / 365).toFixed(1)) : 0,
      reVaccinePeriodUnit: dateYearTypeOptions[0].id,
      infection,
    };
  };

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchema = Yup.object().shape({
    vaccineIntervals: Yup.array().min(1, multiRequired).required(required),
    reVaccineIntervals: Yup.array().min(1, multiRequired).required(required),

    reVaccinePeriod: Yup.string(),
    reVaccinePeriodUnit: Yup.string().matches(withoutSpaces, notJustSpaces).required(required),
    vaccinePeriod: Yup.string(),
    vaccinePeriodUnit: Yup.string().matches(withoutSpaces, notJustSpaces).required(required),
  });

  // ------------------------------ ФОРМА - ЗНАЧЕНИЯ

  type InitialValuesType = {
    vaccineIntervals: string[];
    reVaccineIntervals: string[];

    vaccinePeriod?: number;
    vaccinePeriodUnit: string;

    reVaccinePeriod?: number;
    reVaccinePeriodUnit: string;
    infection?: InfectionDiseaseType;
  };

  const createVaccine = useQuery().useCreate({ api: vaccineApi(), queryKey: [] });
  const updateVaccine = useQuery().useUpdate({ api: vaccineApi(), queryKey });

  const onSubmit = async (props: InitialValuesType) => {
    const { infection, vaccinePeriodUnit } = props;
    const { vaccineIntervals, reVaccineIntervals } = props;
    const { vaccinePeriod, reVaccinePeriod } = props;

    const { id: infectionId, vaccines } = infection || {};

    const vaccine = vaccines?.find(({ type }) => type === "VACCINATION");
    const reVaccine = vaccines?.find(({ type }) => type === "REVACCINATION");

    const vaccineId = vaccine?.id ?? "";
    const reVaccineId = reVaccine?.id ?? "";

    const vaccineComponentsIds = vaccine?.components?.map(({ id }) => id);
    const reVaccineComponentsIds = reVaccine?.components?.map(({ id }) => id);

    if (!vaccinePeriod || !reVaccinePeriod) return;

    const vaccinationComponents = vaccineIntervals.map((interval, i) => ({
      ...(vaccineComponentsIds && vaccineComponentsIds[i] ? { id: vaccineComponentsIds[i] } : {}),
      number: i + 1,
      interval: vaccineIntervals.length === 1 ? 0 : Number(interval ?? 0),
      ...(vaccineId ? { vaccine: { id: vaccineId } } : {}),
    }));

    const reVaccinationComponents = reVaccineIntervals.map((interval, i) => ({
      ...(reVaccineComponentsIds && reVaccineComponentsIds[i]
        ? { id: reVaccineComponentsIds[i] }
        : {}),
      number: i + 1,
      interval: reVaccineIntervals.length === 1 ? 0 : Number(interval ?? 0),
      ...(reVaccineId ? { vaccine: { id: reVaccineId } } : {}),
    }));

    const data = [
      {
        organization,
        ...(vaccineId ? { id: vaccineId } : {}),
        ...(!reVaccineId ? { infection: { id: infectionId } } : {}),

        interval: getIntervalByUnit(vaccinePeriod, vaccinePeriodUnit),
        components: vaccinationComponents,
        type: "VACCINATION",
      },
      {
        organization,
        ...(reVaccineId ? { id: vaccineId } : {}),
        ...(!reVaccineId ? { infection: { id: infectionId } } : {}),

        interval: reVaccinePeriod * 365,
        components: reVaccinationComponents,
        type: "REVACCINATION",
      },
    ];

    //todoDa: не работает обновление компонентов через bulk, и вообще тут нужен сервис
    await (vaccineId ? updateVaccine : createVaccine).mutateAsync({
      data: data[0],
      ...(vaccineId ? { id: vaccineId } : {}),
    });
    await (vaccineId ? updateVaccine : createVaccine).mutateAsync({
      data: data[1],
      ...(vaccineId ? { id: reVaccineId } : {}),
    });
  };

  return {
    addFormTitle,
    editFormTitle,
    formName,

    fields,

    initialValuesAddForm,
    initialValuesEditForm,

    validationSchema,

    onSubmit,
  };
};
