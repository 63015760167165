import styled from "@emotion/styled/macro";
import { Popover } from "@mui/material";
import { colors, margins, text } from "../../styles";

export const Container = styled(Popover)`` as typeof Popover;

export const Title = styled.h4`
  ${text({ size: 17, height: 24, weight: 700, color: colors.grayscaleAsh })};
  ${margins({ m: "16 24" })};
`;
