import { FormikValues } from "formik";
import { useAtomValue, useUpdateAtom } from "jotai/utils";
import React, { ReactNode, Suspense, useEffect } from "react";
import { addFormState, paginationState } from "../../atoms/atoms";
import { isPaginationNeed } from "../../utils/isPaginationNeed";
import { ErrorBoundary } from "../ErrorBoundary";
import { ErrorText } from "../ErrorBoundary/ErrorBoundary.styles";
import { Filters } from "../Filters";
import { ExportFilters, ExportFiltersMethodsType } from "../Filters/ExportFilters";
import { Form } from "../Form";
import { Header } from "../Header";
import { HeaderPagesSwitcher } from "../Header/HeaderPagesSwitcher";
import { RouterType } from "../Header/HeaderPagesSwitcher/HeaderPagesSwitcher";
import { Loader } from "../Loader";
import { Pagination } from "../Pagination";
import { Table } from "../Table";
import { Main, PageContainer } from "./Page.styles";
import { FormMethodsType } from "../Form/Form";
import { TableMethodsType } from "../Table/Table";
import { FilterMethodsType } from "../Filters/Filters";

export const Page = <
  DATUM extends { id?: string },
  OBJ,
  FLDS extends FormikValues,
  VOBJ,
  FLTRS,
  EFLTRS
>(
  props: PagePropsType<DATUM, OBJ, FLDS, VOBJ, FLTRS, EFLTRS>
) => {
  const { children, methods, needScroll = false } = props;

  const { totalCount, canView, navigation, routes } = methods;

  const setPagination = useUpdateAtom(paginationState);
  const addFormIsOpen = useAtomValue(addFormState);

  useEffect(() => setPagination(({ page }) => ({ page, quantity: 10 })), []);

  const pagesSwitcher = routes ? <HeaderPagesSwitcher routers={routes} /> : null;

  const needPagination = isPaginationNeed(totalCount);

  return canView ? (
    <PageContainer>
      <Header navigation={navigation} pagesSwitcher={pagesSwitcher} />

      <Main
        className="main"
        needPagination={needPagination}
        needScroll={!!children && needScroll}
        id="pageContainer"
      >
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            {children ?? <Table {...props} needPagination={needPagination} />}
          </Suspense>
        </ErrorBoundary>
      </Main>

      {needPagination && <Pagination totalCount={totalCount ?? 0} />}

      <ExportFilters methods={methods} />
      <Filters methods={methods} />

      {addFormIsOpen && <Form methods={methods} />}
    </PageContainer>
  ) : (
    <ErrorText>Нет данных</ErrorText>
  );
};

export type PagePropsType<DATUM, OBJ, FLDS, VOBJ, FLTRS, EFLTRS> = {
  children?: ReactNode;
  methods: MethodsType<DATUM, OBJ, FLDS, VOBJ, FLTRS, EFLTRS>;

  needScroll?: boolean;
};

export type MethodsType<DATUM, OBJ, FLDS, VOBJ, FLTRS, EFLTRS> = {
  // ---------- страница (навигация)

  navigation: JSX.Element;
  routes?: RouterType[];

  // ---------- пагинация

  totalCount?: number;

  // ---------- данные (права)

  canView: boolean;

  // ---------- формы
} & FormMethodsType<DATUM, FLDS, VOBJ> &
  // ---------- фильтры для экспорта
  ExportFiltersMethodsType<EFLTRS> &
  // ---------- таблицы
  TableMethodsType<DATUM, OBJ> &
  // ---------- фильтры
  FilterMethodsType<FLTRS>;
