import { useAtomValue, useUpdateAtom } from "jotai/utils";
import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { menuIsExpandState } from "../../../atoms/atoms";
import { SectionType } from "../../../constants/sections";
import { Tooltip } from "../Tooltip/Tooltip";
import { useTooltip } from "../Tooltip/useTooltip";
import { openSubmenuState } from "../utils/atoms";
import { Button } from "./SectionButtons.styles";

export const SectionButton: FC<PropsType> = (props) => {
  const { section } = props;

  const { rout, text, icon, not, additionalRoutes } = section;

  const menuIsExpand = useAtomValue(menuIsExpandState);
  const setOpenSubMenu = useUpdateAtom(openSubmenuState);

  const popoverHoverMethods = useTooltip();
  const { popoverHoverIsOpen, openPopoverHover, closePopoverHover } = popoverHoverMethods;

  const navigate = useNavigate();
  const location = useLocation();

  const active =
    (location.pathname.includes(rout) ||
      additionalRoutes?.some((addRoute) => location.pathname.includes(addRoute))) &&
    (not ? !location.pathname.includes(not) : true);

  return (
    <>
      <Button
        isExpand={menuIsExpand}
        active={!!active}
        onClick={() => {
          navigate(rout);
          setOpenSubMenu("");
        }}
        aria-owns={popoverHoverIsOpen ? "popover-hover" : undefined}
        aria-haspopup="true"
        onMouseEnter={(evt) => openPopoverHover({ evt, type: "sectionButton", text: text })}
        onMouseLeave={closePopoverHover}
      >
        <div className="icon">{icon}</div>{" "}
        {menuIsExpand && <p onClick={() => navigate(rout)}>{text}</p>}
      </Button>

      <Tooltip type="sectionButton" popoverHoverMethods={popoverHoverMethods} />
    </>
  );
};

type PropsType = {
  section: SectionType;
};
