import styled from "@emotion/styled/macro";
import { borders, colors, flex, margins, overflowOneLine, text } from "../../../../styles";

const { grayscaleInput, grayscaleBeautifulBlack, greenDefault, redDefault } = colors;

export const Container = styled.section`
  ${flex()};
  ${borders({ radius: 12, color: grayscaleInput, width: 1 })};
  height: 56px;
`;

export const Content = styled.div`
  width: 100%;

  ${margins({ p: "8 8 8 12" })};
  ${flex({ isColumn: true, vertical: "center" })};
  position: relative;

  &::after {
    position: absolute;
    content: "";
    right: 0;
    height: 100%;
    width: 1px;
    background: ${grayscaleInput};
  }
`;

export const Title = styled.p`
  ${text({
    size: 15,
    height: 20,
    weight: 700,
    color: grayscaleBeautifulBlack,
  })};
`;

export const Description = styled.span<{ type: "default" | "error" | "access" }>`
  ${({ type }) =>
    text({
      weight: 400,
      size: 13,
      height: 17,
      color:
        type === "error" ? redDefault : type === "access" ? greenDefault : grayscaleBeautifulBlack,
    })};

  ${overflowOneLine};
  text-decoration: ${({ type }) => (type === "error" ? "line-through" : "none")};
`;

export const Action = styled.div`
  ${margins({ p: "8" })};

  & > button {
    ${margins({ m: "0" })}
  }
`;
