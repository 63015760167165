import React, { FC } from "react";
import { Line, Narrow, Wider } from "./Separator.styles";

export const Separator: FC<PropsType> = (props) => {
  const { type, marginTop, marginBottom } = props;

  return type === "titleBlock" ? (
    <Line marginBottom={marginBottom} marginTop={marginTop} />
  ) : type === "wider" ? (
    <Wider />
  ) : (
    <Narrow marginTop={marginTop} marginBottom={marginTop} />
  );
};

type PropsType = {
  type: "narrow" | "titleBlock" | "wider";
  marginTop?: string;
  marginBottom?: string;
};
