import { AxiosInstance, AxiosResponse } from "axios";
import { fetchPlans } from "./fetchPlans";
import { sorting } from "./sorting";
import { IdType, SortType } from "./types";

/**
 *
 * @param entityName - имя эндпоинта
 * @param instance - Экземпляр Axios для выполнения HTTP-запросов.
 * @template DATA: Получаемый тип
 * @template VOBJ: Отправляемый тип
 * @template PGAO: Отправляемые параметры для getAll
 * */
export const entitiesBase = <DATA, VOBJ, PGAO = undefined>(
  instance: AxiosInstance,
  entityName: EntityNameType
) => {
  const checkKey = (obj: Record<string, string>): obj is Record<EntityNameType, string> =>
    Object.keys(obj).includes(entityName);

  const fetchPlan = checkKey(fetchPlans) ? fetchPlans[entityName] : undefined;
  const sortForEntityName = checkKey(sorting) ? sorting[entityName] : undefined;

  return {
    getAll: async (
      props?: AllPropType<PGAO>
    ): Promise<AxiosResponse<EntitiesResponseType<DATA>>> => {
      const startIndex = props?.startIndex;
      const size = props?.size;
      const needFilter = !!props?.filter?.length;
      const sort = sortForEntityName ? sortForEntityName : props?.sort;
      const additional = props?.payload;

      const params = {
        params: {
          ...(needFilter ? { filter: JSON.stringify({ conditions: props.filter }) } : {}),
          ...(sort ? { sort } : {}),
          ...(startIndex || startIndex === 0 ? { offset: startIndex } : {}),
          ...(size || size === 0 ? { limit: size } : {}),

          ...(fetchPlan ? { fetchPlan } : {}),

          ...(additional ? additional : {}),
          returnCount: true,
        },
      };

      const response = await instance.get(`/${entityName}${needFilter ? "/search" : ""}`, params);

      const items = response.data;
      const totalCount = Number(response?.headers["x-total-count"]);

      return { ...response, data: { items, totalCount } };
    },

    get: async (id: string): Promise<AxiosResponse<DATA>> => {
      const params = {
        params: {
          ...(fetchPlan ? { fetchPlan } : {}),
        },
      };

      return instance.get(`/${entityName}/${id}`, params);
    },

    create: async (props: EntityCreateType<VOBJ>): Promise<AxiosResponse<IdType>> => {
      const { data } = props;
      return instance.post(`/${entityName}`, data);
    },

    update: async (props: EntityUpdateType<VOBJ>): Promise<AxiosResponse<VOBJ>> => {
      const { id, data } = props;
      return instance.put(`/${entityName}${id ? "/" + id : ""}`, data);
    },

    delete: async (id: string): Promise<AxiosResponse<DATA, DATA>> => {
      return instance.delete(`/${entityName}${id ? "/" + id : ""}`);
    },
  };
};

export type AllPropType<PGAO> = {
  payload?: PGAO;
  startIndex?: number;
  size?: number;
  filter?: ConditionsSearchType;
  sort?: SortType;
};

export type ConditionsSearchType = ConditionSearchType[];

type ConditionSearchType = {
  group?: "OR" | "AND" | string;
  conditions?: ConditionsSearchType;
  property?: string;
  operator?:
    | "="
    | "<>"
    | ">"
    | ">="
    | "<"
    | "<="
    | "startsWith"
    | "endsWith"
    | "contains"
    | "doesNotContain"
    | string;
  value?: string | number;
};

export type EntityCreateType<T> = {
  data: T;
};

export type EntityUpdateType<T> = IdType & {
  data: T;
};

export type EntitiesResponseType<T> = { items: EntitiesType<T>; totalCount: number };

export type EntitiesType<T> = T[];

export type EntityNameType =
  | "vs_Citizenship"
  | "vs_HealthCenter"
  | "vs_PersonnelCategory"
  | "vs_Severity"
  | "vs_TreatmentPlace"
  | "vs_ICD"
  | "vs_OrganizationSettings"
  | "vs_NotificationSettings"
  | "vs_VaccinationPlan"
  | "vs_Infection"
  | "vs_Employee"
  | "vs_Appointment"
  | "vs_VaccinationFact"
  | "vs_Disease"
  | "vs_deletionReasons"
  | "vs_organizationStat"
  | "vs_VaccinationDate"
  | "vs_EmployeeService"
  | "vs_RecordTime"
  | "vs_Component"
  | "vs_Vaccine"
  | "vs_departmentStat"
  | "vs_Document"
  | "vs_MedicalExemption"
  | "vs_Antibodies"
  | "resort_Country"
  | "VaccinationPlanService"
  | "infectionService"
  | "personnel-categories"
  | "vs_EmployeeVaccinationSettings"
  | "employees";
