import { css } from "@emotion/react";
import { opacify } from "polished";
import { borders } from ".";
import { flex } from "./displayStyles";
import { margins, MarginsType } from "./marginsStyles";
import { colors } from "./styles";
import { text, TextPropsType } from "./textStyles";
import { ColorType } from "../api/types";

export const button = css`
  ${flex({ totalCenter: true })};
  ${borders({ none: true, totalRadius: true })};
  ${margins({ p: "0" })};

  height: 48px;
  background: ${colors.transparent};
  cursor: pointer;

  p {
    ${text({ size: 16, height: 16, weight: 700 })};
  }
`;

export const buttonOnlyText = css`
  p {
    ${margins({ m: "0" })};
  }
`;

export const buttonTwoIcons = css`
  p {
    ${margins({ m: "0 8" })};
  }
`;

export const buttonLeftIcons = css`
  p {
    ${margins({ m: "0 0 0 8" })};
  }
`;

export const buttonRightIcons = css`
  p {
    ${margins({ m: "0 8 0 0" })};
  }
`;

export const buttonOnlyIcon = css`
  ${margins({ p: "0" })};

  width: 40px;
  height: 40px;
`;

// ------------------------------ ЧЁРНЫЕ КНОПКИ

export const buttonPrimaryDefault = css`
  ${margins({ p: "16" })};
  background: ${colors.grayscaleBeautifulBlack};
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${colors.white};
  }
  path {
    fill: ${colors.white};
  }
  :hover {
    background: ${opacify(-0.3, colors.grayscaleBeautifulBlack)};
  }
`;

export const buttonSecondaryDefault = css`
  ${margins({ p: "16" })};
  background: ${colors.grayscaleInput};
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${colors.grayscaleBeautifulBlack};
  }
  path {
    fill: ${colors.grayscaleBeautifulBlack};
  }
  :hover {
    background: ${opacify(-0.5, colors.grayscaleInput)};
  }
`;

export const buttonOutlinedDefault = css`
  ${margins({ p: "16" })};
  background: ${colors.white};
  outline: 2px solid ${colors.grayscaleBeautifulBlack};
  outline-offset: -2px;
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${colors.grayscaleBeautifulBlack};
  }
  path {
    fill: ${colors.grayscaleBeautifulBlack};
  }
  :hover {
    background: ${colors.grayscaleHoverBackground};
  }
`;

export const buttonTextDefault = css`
  ${margins({ p: "16" })};
  background: ${colors.white};
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${colors.grayscaleBeautifulBlack};
  }
  path {
    fill: ${colors.grayscaleBeautifulBlack};
  }
  :hover {
    background: ${colors.grayscaleHoverBackground};
  }
`;

export const buttonTransparentDefault = css`
  ${margins({ p: "16" })};
  background: ${colors.transparent};
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${colors.white};
  }
  path {
    fill: ${colors.white};
  }
  :hover {
    background: ${opacify(-0.85, colors.grayscaleBeautifulBlack)};
  }
`;

// ------------------------------ КРАСНЫЕ КНОПКИ

export const buttonPrimaryAttention = css`
  ${margins({ p: "16" })};
  background: ${colors.redDefault};
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${colors.white};
  }
  path {
    fill: ${colors.white};
  }
  :hover {
    opacity: 0.6;
  }
`;

export const buttonSecondaryAttention = css`
  ${margins({ p: "16" })};
  background: ${colors.redLight};
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${colors.redDark};
  }
  path {
    fill: ${colors.redDark};
  }
  :hover {
    opacity: 0.6;
  }
`;

export const buttonOutlinedAttention = css`
  ${margins({ p: "16" })};
  background: ${colors.white};
  outline: 2px solid ${colors.redDefault};
  outline-offset: -2px;
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${colors.redDefault};
  }
  path {
    fill: ${colors.redDefault};
  }
  :hover {
    opacity: 0.6;
  }
`;

export const buttonTextAttention = css`
  ${margins({ p: "16" })};
  background: ${colors.white};
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${colors.redDefault};
  }
  path {
    fill: ${colors.redDefault};
  }
  :hover {
    opacity: 0.6;
  }
`;

// ------------------------------ ЗЕЛЁНЫЕ КНОПКИ

export const buttonPrimaryPositive = css`
  ${margins({ p: "16" })};
  background: ${colors.greenDefault};
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${colors.white};
  }
  path {
    fill: ${colors.white};
  }
  :hover {
    opacity: 0.6;
  }
`;

export const buttonSecondaryPositive = css`
  ${margins({ p: "16" })};
  background: ${colors.greenBackground};
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${colors.greenDark};
  }
  path {
    fill: ${colors.greenDark};
  }
  :hover {
    opacity: 0.6;
  }
`;

export const buttonOutlinedPositive = css`
  ${margins({ p: "16" })};
  background: ${colors.white};
  outline: 2px solid ${colors.greenDefault};
  outline-offset: -2px;
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${colors.greenDefault};
  }
  path {
    fill: ${colors.greenDefault};
  }
  :hover {
    opacity: 0.6;
  }
`;

export const buttonTextPositive = css`
  ${margins({ p: "16" })};
  background: ${colors.white};
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${colors.greenDefault};
  }
  path {
    fill: ${colors.greenDefault};
  }
  :hover {
    opacity: 0.6;
  }
`;

// ------------------------------ НЕАКТИВНЫЕ КНОПКИ

export const buttonDisabled = css`
  ${margins({ p: "16" })};
  background: ${colors.grayscaleDisabled};
  cursor: no-drop;
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${colors.grayscaleLine};
  }
`;

export const buttonOutlinedDisabled = css`
  ${margins({ p: "16" })};
  background: ${colors.white};
  outline: 2px solid ${colors.grayscaleDisabled};
  outline-offset: -2px;
  cursor: no-drop;
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${colors.grayscaleLine};
  }
`;

export const buttonTextDisabled = css`
  ${margins({ p: "16" })};
  background: ${colors.white};
  cursor: no-drop;
  p {
    ${margins({ m: "0 0 0 8" })};
    color: ${colors.grayscaleLine};
  }
`;

// ------------------------------ НАСТРАИВАЕМЫЕ КНОПКИ

/**
 * default values
 * @param props
 * @param props.height - Обязательный параметр
 * @param props.radius - 'total'
 * @param props.color - transparent
 * @param props.cursor - pointer
 * @param props.text - { size: 16, height: 16, weight: 700 }
 * @param props.p - '0'
 */
export const buttonCustom = (props: ButtonCustomPropsType) => {
  const { radius = "total", color = "transparent", height, p = "0" } = props;
  const { cursor = "pointer", text: textProps = { size: 16, height: 16, weight: 700 } } = props;

  return css`
    ${flex({ totalCenter: true })};
    ${borders({
      none: true,
      radius: radius !== "total" ? radius : undefined,
      totalRadius: radius === "total",
    })};
    ${margins({ p })};

    height: ${height}px;
    background: ${color};
    cursor: ${cursor};

    p {
      ${text(textProps)};
    }
  `;
};

type ButtonCustomPropsType = {
  radius?: number | "total";
  color?: ColorType;
  p?: MarginsType;
  cursor?: "pointer" | "no-drop" | "not-allowed";
  height: number;
  text?: TextPropsType;
};
