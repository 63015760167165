import { DocumentsForFormType, DocumentType, fileApi } from "../api/apiFile";
import { FileLinkType } from "./getFile";

export const getFileIds = (files: DocumentsForFormType | []) =>
  Promise.all(
    files.map(async ({ file }) => {
      const formData = new FormData();
      formData.append("file", file as File);
      const fileRef = (await fileApi.upload(formData)).data.fileRef;
      return { ref: fileRef };
    })
  );

export const getFileName = (file: DocumentType | FileLinkType) =>
  typeof file === "string" ? decodeURI(file.split("name=")[1]).replaceAll("+", " ") : file.name;

export const getFileType = (file: DocumentType | FileLinkType) =>
  typeof file === "string" ? getFileName(file).split(".")[-1] : file.type;
