import React from "react";
import { VaccinationDatesType } from "../../../../api/apiVaccination";
import * as elements from "./CalendarView.styles";
import { weekday } from "../../../../constants/dates";
import { useCalendarView } from "./hooks/useCalendarView";
import { CalendarEmptyDays } from "./CalendarEmptyDays";
import { CalendarDay } from "./CalendarDay";
import { QueryKeyType } from "../../../../utils/useQuery";
import { useDeleteDay } from "./hooks/useDeleteDay";
import { UniversalTextButton } from "../../../../uiKit/Buttons";
import { MultiStepConfirmModal } from "../../../../components";

export const CalendarView = (props: PropsType) => {
  const { getDayVaccinations, loading, queryKey, canEdit, canViewDay } = props;

  const { Container, HeaderRow, BodyRows, HeaderCell } = elements;

  const { daysBefore, daysAfter, daysCurrent, isCurrentDay, getFullDate } = useCalendarView();

  const deleteMethods = useDeleteDay(queryKey);
  const { confirmModalText, closeConfirmModal } = deleteMethods;
  const { additionalButtonText, needAdditional, isOpen } = deleteMethods;
  const { confirmModalStage, handleDeletePlan, handleDeleteDay, openSecondPlanConfirm } =
    deleteMethods;

  return (
    <Container>
      <HeaderRow>
        {weekday.map((day, i) => (
          <HeaderCell key={i}>{day}</HeaderCell>
        ))}
      </HeaderRow>
      <BodyRows>
        <CalendarEmptyDays days={daysBefore} loading={loading} />
        {daysCurrent.map((dayNumber, i) => (
          <CalendarDay
            key={i}
            isCurrent={isCurrentDay(dayNumber)}
            dayNumber={dayNumber}
            dayData={getDayVaccinations(getFullDate(dayNumber))}
            loading={loading}
            canEdit={canEdit}
            canViewDay={canViewDay}
          />
        ))}
        <CalendarEmptyDays days={daysAfter} loading={loading} />
      </BodyRows>
      {isOpen && (
        <MultiStepConfirmModal
          questionText={confirmModalText}
          successText="Готово"
          isOpen={isOpen}
          onClose={closeConfirmModal}
          onDelete={
            confirmModalStage === "additionalPlan" || confirmModalStage === "firstPlan"
              ? handleDeletePlan
              : handleDeleteDay
          }
          additionalButton={
            needAdditional ? (
              <UniversalTextButton
                type="attention"
                text={additionalButtonText}
                onClick={openSecondPlanConfirm}
              />
            ) : undefined
          }
        />
      )}
    </Container>
  );
};

type PropsType = {
  getDayVaccinations: (date: string) => VaccinationDatesType | undefined;
  loading: boolean;
  queryKey: QueryKeyType;
  canEdit: boolean;
  canViewDay: boolean;
};
