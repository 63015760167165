import React, { FC } from "react";
import * as elements from "./VaccinationRecordFact.styles";
import { AppointmentType } from "../../../../api/apiAppointment";
import { VaccinationFactType } from "../../../../api/apiVaccination";
import { PlusChar } from "../../../../uiKit/Icons";
import { formatDateForBackend, parseDate } from "../../../../utils/workingWithDates";
import { VaccinationFact } from "../VaccinationFact/VaccinationFact";
import { VaccinationRecord } from "../VaccinationRecord/VaccinationRecord";
import { AppointmentVaccinations } from "../../../../api/apiEmployees";

export const VaccinationRecordFact: FC<PropsType> = (props) => {
  const { onEditAppointment, onDeleteAppointment, date } = props;
  const { onEditFact, appointmentsFacts } = props;
  const { futureDate, todayDate, canEdit, canEditAppointment } = props;

  const { Container, AddButton, AddButtonText, AddIcon } = elements;

  const appointmentsFactsSorted = (
    appointmentsFacts?.map(({ appointment, vaccinationFact }) => {
      const result = [];

      if (vaccinationFact?.id) result.push(vaccinationFact);
      else if (appointment) result.push(appointment);
      return result;
    }) ?? []
  )
    .flat()
    .sort((a, b) => parseDate(a.date).getTime() - parseDate(b.date).getTime());

  const todayOrFuture = futureDate || todayDate;
  const canAddFact = canEdit;
  const backendDate = date ? formatDateForBackend(parseDate(date)) : "";

  const canAdd =
    canEditAppointment &&
    !!date &&
    !appointmentsFacts?.some(({ appointment }) => appointment.date === backendDate);

  return (
    <Container>
      {todayOrFuture && (
        <AddButton enable={canAdd} onClick={canAdd ? () => onEditAppointment() : undefined}>
          <AddIcon>
            <PlusChar />
          </AddIcon>
          <AddButtonText>Добавить</AddButtonText>
        </AddButton>
      )}

      {appointmentsFactsSorted.map((appointmentOrFact) => (
        <>
          {"component" in appointmentOrFact ? (
            <VaccinationFact
              key={appointmentOrFact.id}
              onEdit={onEditFact}
              fact={appointmentOrFact}
              canEdit={canEdit}
            />
          ) : (
            <VaccinationRecord
              key={appointmentOrFact.id}
              todayOrFuture={todayOrFuture}
              appointment={appointmentOrFact}
              onEdit={onEditAppointment}
              onDelete={onDeleteAppointment}
              onAddFact={onEditFact}
              canAddFact={canAddFact}
              canEdit={canEditAppointment}
            />
          )}
        </>
      ))}
    </Container>
  );
};

type PropsType = {
  onEditAppointment: (appointment?: AppointmentType) => void;
  onDeleteAppointment: (appointment?: AppointmentType) => void;
  canEditAppointment: boolean;

  appointmentsFacts?: AppointmentVaccinations;
  onEditFact: (fact?: VaccinationFactType) => void;

  futureDate: boolean;
  todayDate: boolean;
  canEdit: boolean;

  date?: string;
};
