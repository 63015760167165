import React from "react";
import { colors } from "../../styles";

export const ShadedTrash = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6341 6.61822C18.7767 6.73867 18.8306 6.81921 18.8499 6.85714C18.8306 6.89507 18.7767 6.97561 18.6341 7.09607C18.3719 7.31737 17.9249 7.56817 17.2725 7.80118C15.9776 8.26362 14.1136 8.57143 12 8.57143C9.8864 8.57143 8.0224 8.26362 6.72753 7.80118C6.07512 7.56817 5.62809 7.31737 5.366 7.09607C5.22334 6.97561 5.16938 6.89507 5.15009 6.85714C5.16938 6.81921 5.22334 6.73867 5.366 6.61822C5.62809 6.39691 6.07512 6.14611 6.72753 5.91311C8.0224 5.45066 9.8864 5.14286 12 5.14286C14.1136 5.14286 15.9776 5.45066 17.2725 5.91311C17.9249 6.14611 18.3719 6.39691 18.6341 6.61822ZM4 6.85714C4 6.85714 4 14.2857 5.14286 18.2857C5.40296 19.1961 6.07967 20.0312 6.85714 20.5714C7.71457 21.1673 8.33171 21.1591 9.26705 21.1467C9.40824 21.1448 9.55667 21.1429 9.71429 21.1429H14.2857C14.3112 21.1429 14.3362 21.1429 14.361 21.1429C15.3085 21.1433 15.7849 21.1434 16.5714 20.5714C17.3371 20.0145 18.0256 19.1961 18.2857 18.2857C19.3275 14.6395 19.8944 8.14441 19.9866 7.02398C19.9955 6.96877 20 6.91314 20 6.85714C20 5.27919 16.4183 4 12 4C7.58173 4 4 5.27919 4 6.85714Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
