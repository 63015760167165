import { SelectChangeEvent } from "@mui/material";
import { useAtom } from "jotai";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { OpenListButton } from "../Buttons";
import { paginationState } from "../../atoms/atoms";

export const usePagination = (totalCount: number) => {
  const [pagination, setPagination] = useAtom(paginationState);
  const { quantity, page: pageFromPagination } = pagination;

  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get("page") ?? "1");

  const value = quantity > 100 || Number(quantity) >= Number(totalCount) ? "все" : quantity;

  const handleChange = (_: ChangeEvent<unknown>, value: number) =>
    setSearchParams({ page: String(value) });

  const handleChangeQuantity = (evt: SelectChangeEvent<unknown>) => {
    const quantity = evt.target.value === "все" ? totalCount : Number(evt.target.value);

    setPagination({ page: 0, quantity });
    setSearchParams({ page: "1" });
  };

  const count = Math.ceil(Number(totalCount) / quantity);

  const hasMoreOnePage = !!totalCount && count !== 1;

  // ------------------------------ < ИНПУТ ВЫБОРА КОЛИЧЕСТВО СТРОК НА СТРАНИЦЕ

  const [isOpenList, setIsOpenList] = useState(false);

  const handleOpenList = () => setIsOpenList((prevState) => !prevState);

  // const OpenListIcon = () => <OpenListButton isOpen={isOpenList} onClick={handleOpenList} />;
  const OpenListIcon = () => <OpenListButton isOpen={isOpenList} />;

  // ------------------------------ ИНПУТ ВЫБОРА КОЛИЧЕСТВО СТРОК НА СТРАНИЦЕ >

  // ------------------------------ < ВЫЧИСЛЕНИЕ ШИРИНЫ ОКНА ДЛЯ ТОГО, ЧТОБ ПРИПОДНЯТЬ ПАГИНАЦИЮ ПРИ ПОЯВЛЕНИИ СКРОЛЛА

  const [isWidthLessThanMinimum, setIsWidthLessThanMinimum] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const widthMeter = () => setIsWidthLessThanMinimum(window.innerWidth <= 1024);
    window.addEventListener("resize", widthMeter);

    return () => window.removeEventListener("resize", widthMeter);
  }, []);

  // ------------------------------ ВЫЧИСЛЕНИЕ ШИРИНЫ ОКНА ДЛЯ ТОГО, ЧТОБ ПРИПОДНЯТЬ ПАГИНАЦИЮ ПРИ ПОЯВЛЕНИИ СКРОЛЛА >

  // ------------------------------ < УСТАНОВКА НОМЕРА СТРАНИЦЫ ИЗ АДРЕСНОЙ СТРОКИ

  useEffect(() => {
    page - 1 !== pageFromPagination && setPagination({ page: page - 1, quantity });

    count < page && setSearchParams({ page: String(count) });
  }, [page, count]);

  // ------------------------------ УСТАНОВКА НОМЕРА СТРАНИЦЫ ИЗ АДРЕСНОЙ СТРОКИ >

  return {
    value,

    page,
    handleChange,
    handleChangeQuantity,

    count,

    hasMoreOnePage,

    isOpenList,
    handleOpenList,
    OpenListIcon,

    isWidthLessThanMinimum,
  };
};
