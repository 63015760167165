import React, { FC, MouseEvent } from "react";
import { CheckForSwitch, Cross } from "../../uiKit/Icons";
import { Container, Label, Switch } from "./SwitchLabelInput.styles";

export const SwitchLabel: FC<PropsType> = (props) => {
  const { label, onChange, value, disabled = false, variant = "free" } = props;

  return (
    <Container onClick={(evt) => !disabled && onChange(!value, evt)} disabled={disabled}>
      <Label disabled={disabled} variant={variant}>
        {label}
      </Label>

      <Switch checked={value}>
        <div>{value ? <CheckForSwitch /> : <Cross size={8} />}</div>
      </Switch>
    </Container>
  );
};

type PropsType = {
  label: string;
  onChange: (value: boolean, evt: MouseEvent) => void;
  value: boolean;
  disabled?: boolean;
  variant?: "upper" | "free";
};
