import styled from "@emotion/styled/macro";
import { Autocomplete, AutocompleteProps, Chip, ChipTypeMap, Popper } from "@mui/material";
import {
  borders,
  colors,
  flex,
  grid,
  margins,
  overflowOneLine,
  shadow,
  text,
} from "../../../../styles";
import { ElementType } from "react";

const { grayscaleHoverBackground, grayscaleLabel, grayscaleLine, grayscaleChips } = colors;

export const Container = styled.div`
  ${flex({ gap: 10, isColumn: true })};
  ${margins({ m: "0 0 24 0" })};
  position: relative;
`;

export const Item = styled(Autocomplete)`
  ${borders({ radius: 16 })};

  width: 100%;
  height: 56px;
  cursor: pointer;

  .MuiOutlinedInput-notchedOutline {
    ${borders({ none: true })};
  }

  .MuiOutlinedInput-root {
  }

  .MuiButtonBase-root {
    width: 40px;
    height: 40px;
    position: absolute;

    right: -8px;

    :hover {
      background: ${grayscaleHoverBackground};

      & > svg > path {
        fill: ${grayscaleLabel};
      }
    }
  }

  .MuiTextField-root {
    ${borders({ side: "bottom", width: 1, color: grayscaleLine })}
  }
  .MuiAutocomplete-popupIndicator {
    top: -4px;
  }
  .MuiAutocomplete-clearIndicator {
    display: none;
  }

  .MuiChip-root {
    display: none;
  }

  input {
    ${margins({ p: "0", needImportant: true })};

    z-index: 1;
  }

  label {
    ${text({ size: 12, height: 14, weight: 600, color: grayscaleLabel })};
    ${margins({ m: "0" })};

    position: absolute;
    left: 0;
    top: 0;
    transform: none;

    &.Mui-focused {
      color: ${grayscaleLabel};
    }
  }
` as <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap["defaultComponent"]
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>
) => JSX.Element;

export const PopperComponent = styled(Popper)`
  ${margins({ p: "16 0 0" })};
`;

export const MenuItemWrapper = styled.div`
  ${grid({ autoFlowColumn: true })};
  ${overflowOneLine};
  ${text({ size: 15, height: 20, weight: 400 })};
`;

export const PaperProps = () => {
  return {
    sx: {
      maxHeight: 264,
      borderRadius: 4,
      boxShadow: shadow,
      overflowY: "hidden",
      ml: -2,
      padding: 1,

      ul: {
        width: "100%",
        maxHeight: 247,
        overflowY: "auto",
        padding: 0,

        li: {
          height: 56,
          borderRadius: 2,
          marginBottom: 1,
          px: 2,
          py: 0,

          "&:hover": { background: grayscaleHoverBackground },
          "&:last-child": { marginBottom: 0 },
        },

        "::-webkit-scrollbar": {
          width: 8,
        },

        "::-webkit-scrollbar-thumb": {
          background: grayscaleLine,
          borderRadius: "99em",
        },
      },
    },
  } as const;
};

export const SelectedOptions = styled.div`
  ${flex({ gap: 8 })};

  flex-flow: row wrap;
`;

export const SelectedChipsItem = styled(Chip)`
  ${flex({ totalCenter: true })};
  ${borders({ totalRadius: true })};
  ${margins({ p: "11 11 11 16" })};

  height: 40px;
  color: ${colors.white};
  background: ${colors.grayscaleLabel};

  .MuiChip-label {
    ${text({ size: 13, height: 21, weight: 700 })};
    ${margins({ p: "0" })};
  }

  .MuiSvgIcon-root {
    ${margins({ m: "0 0 0 8" })};
    width: 29px;
    height: 29px;
  }

  &:hover {
    background: ${colors.grayscalePlaceholder};
  }
`;

export const OptionChipsItem = styled.span`
  ${text({ size: 13, height: 21, weight: 700 })};
  ${borders({ totalRadius: true })};
  ${margins({ p: "11 11 11 16" })};

  cursor: pointer;
  background: ${grayscaleChips};
`;
