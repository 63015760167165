import { DiseasesType } from "../../../../api/apiDisease";
import { getMonth, getYear } from "date-fns";
import {
  endOfToday,
  formatParseDate,
  now,
  parseDate,
  today,
} from "../../../../utils/workingWithDates";
import { MorbidityType } from "../../VaccinationPage/hooks/useData";
import {
  getSickDaysInMonth as getSickDaysInMonthByDiseases,
  getSickDaysInYear,
} from "../utils/getSickDays";
import { useAtomValue } from "jotai/utils";
import { currentDateInterval } from "../utils/atoms";

export const useChartsBar = (sickLeaves?: DiseasesType) => {
  const [startDate, endDate] = useAtomValue(currentDateInterval);

  const diseases =
    sickLeaves?.filter(
      ({ start, end }) =>
        getYear(parseDate(start)) === getYear(startDate ?? now) ||
        (!!end && getYear(parseDate(end)) === getYear(endDate ?? now))
    ) ?? [];

  // ------------------------------ СУММАРНОЕ КОЛИЧЕСТВО ДНЕЙ НА БОЛЬНИЧНОМ ЗА ГОД

  const sickDaysInYear = getSickDaysInYear(diseases ?? []);

  const getSickDaysInMonth = (monthNumber: number) =>
    getSickDaysInMonthByDiseases(diseases ?? [], monthNumber);

  // ------------------------------ ДОПОЛНИТЕЛЬНЕ ДАННЫЕ ПО МЕСЯЦУ

  const getSickLeaveByMonthNumber = (monthNumber: number) => {
    if (monthNumber > getMonth(endOfToday) + 1) return;

    return diseases?.filter(({ start, end }) => {
      const monthIndex = monthNumber - 1;

      const sinceMonth = getMonth(parseDate(start));
      const untilMonth = end ? getMonth(parseDate(end)) : getMonth(today);

      return sinceMonth <= monthIndex && monthIndex <= untilMonth;
    });
  };

  // ------------------------------ ДАННЫЕ ПО МЕСЯЦУ ДЛЯ ВСПЛЫВАЮЩЕГО ОКНА

  const getDiseasesInfo = (month: number): MorbidityType[] | undefined =>
    getSickLeaveByMonthNumber(month)?.map((monthInfo) => {
      const { start, end, code, infection } = monthInfo;

      const startDate = `С ${formatParseDate(start)}`;
      const endDate = end ? ` по ${formatParseDate(end)}` : "по н.в.";
      const period = `${startDate}${endDate}`;

      return {
        diseasesClass: code?.code ?? "—",
        infectionName: infection.name ?? "—",
        period,
      };
    });

  return {
    sickDaysInYear,
    getSickDaysInMonth,

    getDiseasesInfo,
  };
};

export type DateInterval = {
  start: Date;
  end: Date;
};
