import React, { useState } from "react";
import * as Yup from "yup";
import {
  employeeVaccinationSettingsApi,
  EmployeeVaccinationSettingType,
  ExemptionType,
  medicalExemptionApi,
} from "../../../../api/apiEmployees";
import { DocumentsForFormType } from "../../../../api/apiFile";
import {
  falseCountFiles,
  long,
  required,
  thisDateInFuture,
} from "../../../../constants/forValidationSchemes";
import { DateInput, DocumentsInput, TextInput } from "../../../../uiKit";
import { FieldsBlock } from "../../../../uiKit/Form/Form.styles";
import { QueryKeyType, useQuery } from "../../../../utils/useQuery";
import { validateFilesObj } from "../../../../utils/validateFiles";
import { formatDateForBackend, now, parseDate } from "../../../../utils/workingWithDates";
import { getFileIds, getFileName, getFileType } from "../../../../utils/workingWithFiles";
import { SubFieldLabel } from "../../UserProfile.styles";

export const useExemption = (
  queryKey: QueryKeyType,
  settings: EmployeeVaccinationSettingType | undefined,
  employeeId: string | undefined,
  infectionId: string
) => {
  // ------------------------------ АТОМЫ

  const [modalData, setModalData] = useState<ExemptionType | undefined>(undefined);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [openForm, setIsOpenForm] = useState(false);

  // ------------------------------ КОНСТАНТЫ

  const addFormTitle = "Добавление медотвода";
  const editFormTitle = "Медотвод";
  const formName = "exemption";

  const exemption = settings?.exemption[0];

  // ------------------------------ ФОРМА - ПОЛЯ

  const fields = (
    <FieldsBlock>
      <DateInput name="date" placeholder="ДД.ММ.ГГГГ" maxDate={now} outsideLabel="Дата*" />
      <SubFieldLabel>
        Укажите дату с какого момента имеются медицинские противопоказания к вакцинации
      </SubFieldLabel>
      <TextInput
        name="description"
        placeholder="Укажите причину медотвода..."
        multiline
        outsideLabel="Медицинские показания"
      />
      <DocumentsInput
        label="Загрузите файлы из папки или перетащите"
        description="Подходят файлы в формате "
        accept={[".pdf"]}
        maxSize={10485760}
        multiDocuments={false}
      />
    </FieldsBlock>
  );

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesAddForm: ValuesObjectType = {
    date: now,
    description: "",
    files: [] as DocumentsForFormType,
  };

  const initialValuesEditForm = ({ date, description, document }: ExemptionType) => ({
    date: parseDate(date),
    description: description ?? "",
    files: document
      ? ([
          { file: document, comment: "", type: getFileType(document), name: getFileName(document) },
        ] as DocumentsForFormType)
      : ([] as DocumentsForFormType),
  });

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchema = Yup.object().shape({
    date: Yup.date()
      .required(required)
      .test({
        name: "is-futureDate",
        message: thisDateInFuture,
        test: async (_: unknown, ctx: { parent: { date: Date | undefined } }) => {
          const { date } = ctx.parent;
          return date ? date <= now : true;
        },
      }),
    description: Yup.string().max(2000, long),
    files: Yup.array()
      .max(1, falseCountFiles(1))
      .of(
        Yup.object().shape({
          file: Yup.mixed().test(validateFilesObj({ accept: [".pdf"], maxSize: 4194304 })),
          name: Yup.string(),
        })
      ),
  });

  // ------------------------------ ФОРМА - ОТПРАВКА

  const api = medicalExemptionApi();

  const createExemption = useQuery().useCreate({ api: api, queryKey });
  const updateExemption = useQuery().useUpdate({ api: api, queryKey });
  const deleteExemption = useQuery().useDelete({ api: api, queryKey });
  const createSettings = useQuery().useCreate({ api: employeeVaccinationSettingsApi(), queryKey });
  const updateSettings = useQuery().useUpdate({ api: employeeVaccinationSettingsApi(), queryKey });

  const onSubmit = async (values: ValuesObjectType, isEdit?: boolean) => {
    const { files, date, description } = values;

    let fileRef = "";
    if (files[0]) fileRef = (await getFileIds(files))[0].ref;

    let settingsId = settings?.id;
    if (!settings) {
      settingsId = (
        await createSettings.mutateAsync({
          data: {
            employee: { id: employeeId },
            infection: { id: infectionId },
            access: false,
            agreement: false,
            exemption: [],
            antibodies: [],
          },
        })
      ).data.id;
    }

    const exemption = {
      date: formatDateForBackend(date),
      document: fileRef,
      description,
      vaccinationSettings: { id: settingsId },
    };

    let exemptionId = "";

    if (isEdit) await updateExemption.mutateAsync({ data: exemption, id: modalData?.id });
    else exemptionId = (await createExemption.mutateAsync({ data: exemption })).data.id;

    if (!isEdit && settingsId)
      await updateSettings.mutateAsync({
        data: {
          exemption: [...(settings ? settings.exemption : []), { id: exemptionId }],
        },
        id: settingsId,
      });
  };

  // ------------------------------ УДАЛЕНИЕ

  const handleDeletion = !exemption
    ? undefined
    : async () => await deleteExemption.mutateAsync([exemption?.id]);

  return {
    isOpenConfirmModal,
    setIsOpenConfirmModal,

    formName,
    openForm,
    setIsOpenForm,

    modalData,
    setModalData,

    addFormTitle,
    editFormTitle,

    fields,

    initialValuesAddForm,
    initialValuesEditForm,

    validationSchema,

    onSubmit,

    queryKey,
    api,

    handleDeletion,
    exemption,
  };
};

type ValuesObjectType = {
  date: Date;
  description: string;
  files: DocumentsForFormType;
};
