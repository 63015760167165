import React from "react";
import { Form, Page } from "../../../uiKit";
import { Panel } from "../components/Panel/Panel";
import { PageSwitcher } from "../components/PageSwitcher/PageSwitcher";
import { SearchChipsSelector } from "../components/SearchChipsSelector/SearchChipsSelector";
import {
  EmptyContainer,
  PageContainer,
  VaccinationPageContainer,
  VaccinationPageContent,
  VaccinationPageSide,
} from "../UserProfile.styles";
import { VaccinationHistory } from "../components/VaccinationHistory/VaccinationHistory";
import { useVaccinations } from "./useVaccinations";
import { EmptyBox } from "../../../uiKit/Icons";
import { VaccinationSettings } from "../components/VaccinationSettings/VaccinationSettings";
import { useViewForms } from "../VaccinationRecord/hooks/useForms";
import { ViewForm } from "../../../uiKit/Form/ViewForm";
import { Card } from "../components/Card/Card";
import { Appointments } from "../components/Appointments/Appointments";
import { PlanedDate } from "../components/PlanedDate/PlanedDate";

export const Vaccinations = () => {
  const methods = useVaccinations();
  const formViewMethods = useViewForms();

  const { queryKey, api, modalData, employeeProfile } = methods;
  const { setCurrentInfection, infectionOptions, currentInfection } = methods;
  const { selectedInfections, setSelectedInfection, getTypeRecord } = methods;
  const { modalDataView, queryKeyForOneEntity, canEdit } = methods;
  const { planetDate, appointments, historyVaccinated } = methods;

  return (
    <Page methods={methods} needScroll>
      <Panel />
      <PageContainer>
        <PageSwitcher />
        <SearchChipsSelector
          options={infectionOptions}
          values={selectedInfections}
          onChange={setSelectedInfection}
          onSelect={setCurrentInfection}
          selectValue={currentInfection}
          placeholder="Чтобы добавить заболевание, выберите его из списка"
        />
        {currentInfection ? (
          <VaccinationPageContainer>
            <VaccinationPageContent>
              <Card title="Записи на вакцинацию">
                <Appointments appointments={appointments} />
              </Card>
              <VaccinationHistory
                history={historyVaccinated}
                getTypeRecord={getTypeRecord}
                queryKeyForOneEntity={queryKeyForOneEntity}
                queryKey={queryKey}
                api={api}
                canEdit={canEdit}
              />
            </VaccinationPageContent>
            <VaccinationPageSide>
              <PlanedDate getDate={planetDate} />
              <VaccinationSettings
                employee={employeeProfile}
                selectedInfection={currentInfection}
                queryKey={queryKey}
                canEdit={canEdit}
              />
            </VaccinationPageSide>
          </VaccinationPageContainer>
        ) : (
          <EmptyContainer>
            <EmptyBox />
            <p>Данные о вакцинации отсутствуют</p>
          </EmptyContainer>
        )}
      </PageContainer>
      {modalDataView && <ViewForm methods={formViewMethods} />}
      {modalData && <Form id={modalData.id} methods={methods} isEdit />}
    </Page>
  );
};
