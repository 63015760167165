import { employeeAppointmentsState } from "../../utils/atoms";
import { useUserProfilePage } from "../../useUserProfilePage";
import { useAtomValue } from "jotai/utils";

export const useData = () => {
  const methods = useUserProfilePage();

  // ------------------------------ АТОМЫ

  const data = useAtomValue(employeeAppointmentsState);

  return {
    ...methods,

    data,
  };
};
