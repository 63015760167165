import React, { FC } from "react";
import { useDiagram } from "../../ChartsPage/hooks/useDiagram";
import { CardsContainer } from "../../InfographicPage.styled";
import { DepartmentCard } from "../DepartmentCard/DepartmentCard";
import { DepartmentDiagram } from "../DepartmentDiagram/DepartmentDiagram";
import { FiltersType } from "../../utils/atoms";

export const DepartmentChart: FC<ChartDepartmentProps> = (props) => {
  const { onClick, filters, forExport } = props;
  const methods = useDiagram(props);

  const { statsFilteredForDepartments, dataWrapper, statsFilteredForDepartmentsAll } = methods;
  const type = filters.page;

  return (
    <CardsContainer>
      {dataWrapper(
        (forExport ? statsFilteredForDepartmentsAll : statsFilteredForDepartments).map(
          (
            { mainDepartmentCode, mainDepartmentName, all, number, index, mainDepartmentFullName },
            i
          ) => {
            return (
              <DepartmentCard
                key={i}
                count={all?.toString() ?? "—"}
                name={mainDepartmentName}
                fullName={mainDepartmentFullName}
                sick={number?.toString() ?? "—"}
                screenshotMode={false}
                onClick={() => onClick(mainDepartmentCode)}
                type={type}
              >
                <DepartmentDiagram id={mainDepartmentCode} healthIndex={index} {...methods} />
              </DepartmentCard>
            );
          }
        )
      )}
    </CardsContainer>
  );
};

export type ChartDepartmentHookProps = { filters: FiltersType; selectedOrganizationName: string };
export type ChartDepartmentProps = ChartDepartmentHookProps & {
  onClick: (department: string) => void;
  forExport?: boolean;
};
