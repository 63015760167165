import styled from "@emotion/styled/macro";
import { button, colors } from "../../../styles";

export const Container = styled.button<{ isOpen: boolean; disabled: boolean }>`
  ${button};

  width: 40px;
  height: 40px;
  flex: 0 0 auto;

  :hover {
    background: ${({ disabled }) => (disabled ? undefined : colors.grayscaleHoverBackground)};

    & > svg > path {
      fill: ${({ disabled }) => (disabled ? undefined : colors.grayscaleLabel)};
    }
  }
`;
