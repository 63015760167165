import styled from "@emotion/styled/macro";
import { NavLink } from "react-router-dom";
import { borders, colors, flex, margins, text } from "../../../styles";

export const Container = styled.nav`
  ${flex()};
  ${margins({ m: "0 24 -1" })};

  height: 40px;
`;

export const NavigationLink = styled(NavLink)<{ blocked?: number }>`
  ${text({ size: 15, height: 24, weight: 600, color: colors.grayscalePlaceholder })};
  ${margins({ p: "8 16" })};

  display: block;

  :hover {
    color: ${colors.grayscaleBeautifulBlack};
    cursor: ${({ blocked }) => (blocked ? "not-allowed" : "pointer")};
  }

  &.active {
    ${borders({ side: "bottom", width: 2, color: colors.grayscaleBeautifulBlack })};

    color: ${colors.grayscaleBeautifulBlack};
    z-index: 1;
  }
`;

export const NavigationButton = styled.button<{ isActive: boolean }>`
  ${borders({ none: true })};
  ${({ isActive }) => {
    const color = isActive ? colors.grayscaleBeautifulBlack : colors.grayscalePlaceholder;

    return text({ size: 15, height: 24, weight: 600, color: color });
  }};
  ${margins({ p: "8 16" })};

  background: ${colors.transparent};
  border-bottom: ${({ isActive }) =>
    isActive ? `2px solid ${colors.grayscaleBeautifulBlack}` : "none"};
  outline: none;
  z-index: ${({ isActive }) => (isActive ? 1 : "auto")};

  :hover {
    color: ${colors.grayscaleLabel};
  }
`;
