import styled from "@emotion/styled/macro";
import { borders, colors, flex, grid, margins, text } from "../../../styles";

const { grayscaleBody, primaryActiveElement, grayscaleLabel } = colors;

export const ComponentCount = styled.p<{ border: boolean }>`
  ${margins({ p: "8 8 8 16" })};
  ${text({ size: 15, height: 20, weight: 700, color: grayscaleBody })};
  ${({ border }) => borders({ side: "bottom", width: +border, color: primaryActiveElement })};
`;

export const SubPeriod = styled.p`
  ${margins({ m: "12 0 0 0" })};
  ${text({ size: 14, weight: 400, height: 20, color: grayscaleLabel })};
`;

export const Period = styled.p`
  ${margins({ p: "4 0 0 0" })};
  ${text({ size: 15, height: 20, weight: 400, color: grayscaleBody })};
`;

export const AgesContainer = styled.div`
  ${flex({ gap: 8 })};
`;

export const ComponentsContainer = styled.div`
  ${flex({ gap: 8, isColumn: true })};
`;

export const PeriodContainer = styled.div`
  ${grid({ gap: 8, columns: "1fr 1fr" })};
`;
