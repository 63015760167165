import { Atom } from "jotai";
import { EntitiesResponseType, EntitiesType } from "../api/apiRequest";
import { useAtomValue } from "jotai/utils";

/**
 *
 * ------------------------------------------------------------------------------------------
 * РАБОТА С ЗАГРУЖАЕМЫМИ ДАННЫМИ
 *
 * @param state - loadable атом
 * @param initialValue - начальное значение
 *
 * @return - {data: начальное значение или данные, totalCount, loadableData}
 */
export function useLoadableData<T>(
  state: Atom<Loadable<EntitiesResponseType<T> | undefined>>,
  initialValue: T[]
): DataWithCountType<T>;
export function useLoadableData<T, I>(
  state: Atom<Loadable<T>>,
  initialValue: I
): ResolveType<T> | I;
export function useLoadableData<T, I>(
  state: Atom<Loadable<T | undefined>>,
  initialValue: I | T[]
): ResolveType<T> | I | DataWithCountType<T> {
  const loadableData = useAtomValue(state);
  const isArray = Array.isArray(initialValue);

  if (loadableData.state === "hasData") {
    const data = loadableData.data;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (isArray && typeof data === "object" && "items" in data) {
      const items = (data as EntitiesResponseType<T>).items;
      return {
        data: items,
        totalCount: (data as EntitiesResponseType<T>)?.totalCount ?? 0,
        loadableData: { state: loadableData.state, data: items },
      };
    }
    return { data: [], totalCount: 0, loadableData: { state: loadableData.state, data: [] } };
  }

  if (isArray) return { data: [], totalCount: 0, loadableData };

  return initialValue as I;
}

type DataWithCountType<T> = {
  data: T[];
  totalCount: number;
  loadableData: Loadable<EntitiesType<T>>;
};

export type Loadable<Value> =
  | {
      state: "loading";
    }
  | {
      state: "hasError";
      error: unknown;
    }
  | {
      state: "hasData";
      data: ResolveType<Value>;
    };

export type ResolveType<T> = T extends Promise<infer V> ? V : T;

/*

можно использовать вариант с атомом, для этого прийдется немного подредактировать пагинацию и страницу

const emptyNumberAtom = atom(0);

export function useLoadableData<T>(
  state: Atom<Loadable<EntitiesResponseType<T>>>,
  initialValue: T[],
  pagination?: WritableAtom<number | null, number, void> | undefined
): T[];
export function useLoadableData<T, I>(
  state: Atom<Loadable<T>>,
  initialValue: I
): ResolveType<T> | I;
export function useLoadableData<T, I>(
  state: Atom<Loadable<T>>,
  initialValue: I | T[],
  pagination?: WritableAtom<number | null, number, void> | undefined
): ResolveType<T> | T[] | I {
  const setCount = useUpdateAtom(pagination ?? emptyNumberAtom);
  const loadableData = useAtomValue(state);
  const isArray = Array.isArray(initialValue);

  if (loadableData.state === "hasData") {
    const data = loadableData.data;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (isArray && typeof data === "object" && "items" in data) {
      if (pagination) setCount((data as EntitiesResponseType<T>).totalCount);
      return (data as EntitiesResponseType<T>).items;
    }
    return loadableData.data;
  }

  if (isArray) return [];

  return initialValue as I;
}
* */
