import styled from "@emotion/styled/macro";
import * as styles from "../../../styles";

const { margins, button, buttonPrimaryDefault, buttonSecondaryDefault, buttonTwoIcons } = styles;

export const Container = styled.button<{ typeButton: "primary" | "secondary" }>`
  ${button};
  ${({ typeButton }) => (typeButton === "primary" ? buttonPrimaryDefault : buttonSecondaryDefault)};
  ${buttonTwoIcons};

  ${margins({ m: "-2 0" })};

  width: 100%;
`;
