import React, { FC } from "react";
import { Check } from "../Icons";
import { GeneralPropsType } from "./FilesInput";
import * as elements from "./FilesInput.styles";

export const FilesInputSuccess: FC<GeneralPropsType> = (props) => {
  const { multiFile } = props;

  const { Container, Icon, Title, Description, Text, LoadBarAndText, LoadBar } = elements;

  return (
    <Container status="success" multiFile={multiFile} as="div">
      <LoadBarAndText status="success" multiFile={multiFile}>
        <LoadBar status="success">
          <Check />
        </LoadBar>
        <Text>
          <Title status="success">{multiFile ? "Файлы загружены" : "Файл загружен"}</Title>
          <Description status="success">Обработка завершается...</Description>
        </Text>
      </LoadBarAndText>

      <Icon />
    </Container>
  );
};
