import React, { FC } from "react";
import { VaccinationRecord } from "./VaccinationRecord/VaccinationRecord";
import { Vaccinations } from "./Vaccinations/Vaccinations";
import { Infections } from "./Infections/Infections";
import { useParamAtom } from "../../utils/useParamAtom";
import { employeeIdState } from "./utils/atoms";

export const UserProfilePage: FC<PropsType> = (props) => {
  const { type } = props;

  useParamAtom("id", employeeIdState);

  return (
    <>
      {type === "vaccination-record" ? (
        <VaccinationRecord />
      ) : type === "infections" ? (
        <Infections />
      ) : (
        <Vaccinations />
      )}
    </>
  );
};

type PropsType = {
  type: "infections" | "vaccinations" | "vaccination-record";
};
