import styled from "@emotion/styled/macro";
import { Checkbox } from "@mui/material";
import { colors, flex, margins, text } from "../../styles";

export const Container = styled(Checkbox)<{ checked: boolean; disabled: boolean }>`
  &.MuiCheckbox-root {
    padding: 0;
  }

  & > svg > path {
    ${({ disabled }) => (disabled ? `fill: ${colors.grayscaleLabel}` : undefined)};
  }

  &:hover {
    background: ${({ checked }) =>
      checked ? colors.primaryBackground : colors.grayscaleHoverBackground};
    ${({ disabled }) => (disabled ? `cursor: no-drop` : undefined)};

    & > svg > path {
      fill: ${({ checked }) => (checked ? colors.primaryDefault : colors.grayscaleLabel)};
    }
  }
`;

export const CheckLabelContainer = styled.div`
  ${flex({ vertical: "center", gap: 16 })};
  width: 100%;
`;

export const EndIcon = styled.div`
  ${margins({ m: "0 0 0 auto" })};
`;
export const Label = styled.span`
  ${text({ size: 15, height: 20, weight: 400, color: colors.grayscaleBeautifulBlack })};
`;
