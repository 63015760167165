import React, { FC, MouseEvent } from "react";
import { Container } from "./IconButton.styles";

export const IconButton: FC<PropsType> = (props) => {
  const { onClick, size = 40, children } = props;

  return (
    <Container size={size} type="button" onClick={onClick}>
      {children}
    </Container>
  );
};

type PropsType = {
  onClick: (arg: MouseEvent<HTMLButtonElement>) => void;
  size?: number;
};
