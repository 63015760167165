import { atom } from "jotai";
import { atomWithImmer } from "jotai/immer";
import { StatusType } from "../api/types";
import { createJSONStorage } from "jotai/utils";

// ------------------------------ LOADER'Ы ДОБАВЛЕНИЯ/РЕДАКТИРОВАНИЯ/УДАЛЕНИЯ СТРОКИ ТАБЛИЦЫ

export const statusDeletionState = atom(false);
export const statusCreateOrUpdateState = atom(false);

// ------------------------------ СОСТОЯНИЕ КНОПКИ ЗАГРУЗКИ ФАЙЛА

export const statusFileInputState = atom<StatusType>("empty");

// ------------------------------ СОСТОЯНИЕ КНОПКИ ЗАГРУЗКИ ИКОНКИ

export const statusIconInputState = atom<StatusType>("empty");

// ------------------------------ ВЫБРАННЫЕ СТРОКИ

export const selectedRowsState = atomWithImmer<string[]>([]);

// ------------------------------ ВЫБРАННЫЕ СТОЛБЦЫ

export const selectedColumnsState = atomWithImmer<number[]>([]);

// ------------------------------ ОТКРЫТЫЕ СПИСКИ

export const openedListsState = atomWithImmer<(string | number)[]>([]);

// ------------------------------ ПОИСКОВЫЙ ЗАПРОС ПО СТРАНИЦАМ

export const searchQueryState = atom<string>("");

// ------------------------------ ПОИСКОВЫЙ ЗАПРОС ПО МОДАЛЬНЫМ ОКНАМ

export const modalSearchQueryState = atom<string>("");

// ------------------------------ СОСТОЯНИЕ ЛЕВОГО МЕНЮ

export const menuIsExpandState = atom(false);

// ------------------------------ ТИП ПЕРЕКЛЮЧАТЕЛЯ ДАТ

export const dateSwitcherTypeState = atom<DateSwitcherTypeType | "">("");

// ------------------------------ СОСТОЯНИЕ ПЕРЕКЛЮЧАТЕЛЯ ПЕРИОДОВ ДАТ

export const periodSwitcherTypeState = atom<DateSwitcherTypeType>("year");

// ------------------------------ ПАГИНАЦИЯ

export const paginationState = atom<PaginationStateType>({ page: 0, quantity: 10 });

// ------------------------------ ФОРМЫ - СОСТОЯНИЕ

export const addFormState = atom(false);

// ------------------------------ ЭКСПОРТ

export const exportFormState = atom(false);

// ------------------------------ ФИЛЬТРЫ
export const filtersFormState = atom(false);
export const hasActiveFiltersState = atom(false);

// ------------------------------ СОСТОЯНИЕ СТРОКИ ПОИСКА

export const visibleSearchState = atom(false);

// ------------------------------ СЕССИОНОЕ ХРАНИЛИЩЕ ДЛЯ ATOM WITH STORAGE

export const storageSession = createJSONStorage<string>(() => sessionStorage);

// ------------------------------ ТИПЫ

export type DateSwitcherTypeType = "day" | "month" | "quarter" | "year" | "period";
export type PaginationStateType = { page: number; quantity: number };
