import styled from "@emotion/styled/macro";
import { Popover } from "@mui/material";
import { borders, colors, margins, text } from "../../styles";

export const Container = styled(Popover)`
  pointer-events: none;

  .MuiPaper-root {
    ${borders({ radius: 8 })};

    max-width: 300px;
    background: ${colors.grayscaleBeautifulBlackOpacity};
    box-shadow: none;
  }
` as typeof Popover;

export const Text = styled.div`
  ${text({ size: 16, height: 21, weight: 400, color: colors.white })};
  ${margins({ m: "4 8" })};
`;
