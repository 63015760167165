import { useAtom } from "jotai";
import { useAtomValue } from "jotai/utils";
import React from "react";
import { currentUserAtom } from "../../../../atoms/atomCurrentUser";
import { DICTIONARY } from "../../../../constants/routes";
import { HeaderNavigation } from "../../../../uiKit";
import { useLoadableData } from "../../../../utils/useLoadableData";
import {
  infectionVaccinationsState,
  modalDataState,
  useInfectionVaccinationsQuery,
} from "../utils/atoms";

export const useData = () => {
  // ------------------------------ АТОМЫ

  const [modalData, setModalData] = useAtom(modalDataState);
  const { roleModel, res } = useAtomValue(currentUserAtom);

  const { totalCount, loadableData, data } = useLoadableData(infectionVaccinationsState, []);

  // ------------------------------ ХУКИ

  const { queryKey, api } = useInfectionVaccinationsQuery();

  // ------------------------------ КОНСТАНТЫ

  const {
    canViewInfectionVaccinationDictionary: canView,
    canEditInfectionVaccinationDictionary: canEdit,
  } = roleModel;

  const titlePage = "Настройка вакцинации";

  const organization = res.organization;

  // ------------------------------ ШАПКА - НАВИГАЦИЯ

  const navigation = (
    <HeaderNavigation
      title={titlePage}
      needSearchButton
      section={{ name: "Справочники", rout: DICTIONARY }}
    />
  );

  return {
    organization,

    data,
    loadableData,
    totalCount,

    navigation,

    canView,
    canEdit,

    modalData,
    setModalData,

    queryKey,
    api,
  };
};
