import styled from "@emotion/styled";
import { StatusType } from "../../api/types";
import { borders, button, colors, flex, margins, overflowOneLine, text } from "../../styles";

export const Container = styled.div<{ status: StatusType; little?: boolean }>`
  ${({ status }) =>
    status === "empty"
      ? flex({ totalCenter: true })
      : flex({ horizontal: "evenly", vertical: "center" })};
  ${borders({ radius: 16 })};
  ${margins({ m: "0 0 16 0", p: "16" })};

  width: 100%;
  height: ${({ little }) => (little ? "72px" : "157px")};
  position: relative;
  background: ${colors.transparent};
  border: ${({ status }) =>
    status === "empty"
      ? `2px dashed ${colors.primaryActiveElement}`
      : `2px solid ${colors.grayscaleInput}`};

  outline: none;
  cursor: ${({ status }) => (status === "empty" ? "pointer" : "default")};

  :hover {
    border: 2px
      ${({ status }) =>
        status === "empty" ? `dashed ${colors.primaryDefault}` : `solid ${colors.grayscaleInput}`};
  }
`;

export const LoadBarAndText = styled.div<{ status: StatusType }>`
  ${({ status }) =>
    status === "empty"
      ? flex({ isColumn: true, horizontal: "center" })
      : flex({ gap: 12, vertical: "center" })};
  width: ${({ status }) => (status === "empty" ? "100%" : "calc(100% - 52px)")};
  overflow: hidden;
`;

export const LoadBar = styled.div<{ status: StatusType }>`
  ${flex({ isColumn: true, totalCenter: true })};
  ${borders({ totalRadius: true })};
  ${text({ size: 10, height: 13, weight: 900 })};
  ${margins({ m: "0" })};

  width: 40px;
  height: 40px;
  border: ${({ status }) =>
    status === "loaded" || status === "empty"
      ? "none"
      : `3px solid ${
          status === "error"
            ? colors.redDefault
            : status === "success"
            ? colors.greenDefault
            : colors.grayscaleBackground
        }  `};
`;

export const Progress = styled.div`
  ${margins({ m: "0" })};

  width: 40px;
  height: 40px;
  position: relative;

  .MuiCircularProgress-root {
    color: ${colors.primaryDefault};
  }
`;

export const Percent = styled.div`
  ${flex({ totalCenter: true })};
  ${borders({ totalRadius: true })};
  ${text({ size: 10, height: 10, weight: 900, color: colors.grayscaleBeautifulBlack })};

  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  border: 3px solid ${colors.grayscaleBackground};
  z-index: -1;
`;

export const DocumentIconAndName = styled.div`
  ${flex({ gap: 12, vertical: "center" })};
  width: 100%;

  div:last-child {
    width: 85%;
  }
`;

export const Text = styled.div`
  ${flex({ isColumn: true, horizontal: "start" })};
  ${overflowOneLine};
`;

export const Title = styled.div<TitlePropsType>`
  ${({ status }) => {
    const notFile = status === "progress" || status === "error" || status === "success";
    const color = notFile ? colors.grayscaleLabel : colors.primaryDefault;

    return text({ size: 15, height: 20, weight: 700, color: color });
  }};

  ${overflowOneLine};

  width: ${({ fullWidth }) => (fullWidth ? "" : "calc(472px - 40px * 3 - 12px - 16px * 2)")};
  // (40px * 3) - ширины иконок
  // (12px) - промежуток между иконкой и текстом
  // (16px * 2) - 2 padding контейнера документа

  :hover {
    ${({ needDownload }) => needDownload && "cursor: pointer"};
  }
`;

export const Description = styled.p<{ status: StatusType }>`
  ${({ status }) => {
    const color = status === "error" ? colors.redDefault : colors.grayscaleLabel;

    return text({ size: 13, height: 23, weight: 400, color: color });
  }};
`;

export const Icon = styled.div`
  ${flex({ totalCenter: true })};
  ${margins({ m: "0" })};

  width: 40px;
  height: 40px;
`;

export const Button = styled.button`
  ${flex({ totalCenter: true })};
  ${button};
  ${margins({ m: "0 0 0 12" })};

  width: 40px;
  height: 40px;
`;

export const Input = styled.input`
  display: none;
  position: absolute;
  top: 24px;
`;

export const FileBlock = styled.div`
  ${margins({ m: "0 0 40 0" })};

  position: relative;
`;

export const Buttons = styled.div`
  ${flex({ gap: 2, horizontal: "center", vertical: "center" })};
`;

type TitlePropsType = {
  status: StatusType;
  needDownload?: boolean;
  fullWidth?: boolean;
};
