import styled from "@emotion/styled/macro";
import { button, buttonOnlyText, colors } from "../../../styles";

export const Container = styled.button<{ size: number }>`
  ${button};
  ${buttonOnlyText};

  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  :hover {
    background: ${colors.grayscaleHoverBackground};
  }

  & > svg > path {
    fill: ${colors.grayscaleIcons};
  }
`;
