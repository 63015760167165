import React, { FC } from "react";
import { PlusMinusButton, UniversalTextButton } from "../../../../uiKit/Buttons";
import * as elements from "../VaccinationSettings/VaccinationSettings.styles";
import { VaccinationSettingsPropsType } from "../VaccinationSettings/VaccinationSettings";
import { EmployeeVaccinationSettingType } from "../../../../api/apiEmployees";
import { useAntibodies } from "../../Vaccinations/hooks/useAntibodies";
import { ConfirmModal, Form, RightModal } from "../../../../uiKit";
import { AntibodiesItem } from "./AntibodiesItem";
import { AntibodiesClose, AntibodiesView } from "../../UserProfile.styles";

export const AntibodiesSetup: FC<PropsType> = (props) => {
  const { settings, queryKey, employee, selectedInfection, canEdit } = props;

  const { CardPart, Header, Title, TextButton } = elements;

  const antibodiesMethods = useAntibodies(queryKey, settings, employee?.id, selectedInfection);

  const { setModalData, modalData, isOpenConfirmModal, setIsOpenConfirmModal } = antibodiesMethods;
  const { setIsOpenForm, openForm, handleDeletion, setShowAll, showAll } = antibodiesMethods;

  const antibodies =
    settings?.antibodies.sort((a, b) =>
      a.date !== b.date ? (a.date < b.date ? 1 : -1) : a.id < b.id ? 1 : -1
    ) ?? [];
  const lastAntibodies = antibodies?.[0];
  const needShowMoreAntibodies = settings ? antibodies.length > 1 : false;

  return (
    <>
      <CardPart noneBorders>
        <Header>
          <Title>Анализ крови на антитела</Title>
          {canEdit && (
            <PlusMinusButton type="plus" disabled={false} onClick={() => setIsOpenForm(true)} />
          )}
        </Header>
        {lastAntibodies && (
          <AntibodiesItem
            antibodies={lastAntibodies}
            setIsOpenConfirmModal={setIsOpenConfirmModal}
            setModalData={setModalData}
            setIsOpenForm={setIsOpenForm}
          />
        )}
        {needShowMoreAntibodies && (
          <TextButton onClick={() => setShowAll(true)}>Все записи</TextButton>
        )}
      </CardPart>

      {openForm && (
        <Form
          methods={antibodiesMethods}
          onClose={() => {
            setModalData(undefined);
            setIsOpenForm(false);
          }}
          isEdit={!!modalData}
        />
      )}

      {showAll && (
        <RightModal
          title="Все результаты анализов крови на антитела"
          isOpen
          onClose={() => setShowAll(false)}
          needBlur
        >
          <AntibodiesView>
            {antibodies.map((antibodies) => (
              <AntibodiesItem
                key={antibodies.id}
                antibodies={antibodies}
                setIsOpenConfirmModal={setIsOpenConfirmModal}
                setModalData={setModalData}
                setIsOpenForm={setIsOpenForm}
              />
            ))}
          </AntibodiesView>
          <AntibodiesClose>
            <UniversalTextButton
              text="Закрыть"
              onClick={() => setShowAll(false)}
              type="secondary"
            />
          </AntibodiesClose>
        </RightModal>
      )}

      {isOpenConfirmModal && modalData && (
        <ConfirmModal
          questionText="Вы действительно хотите удалить результаты анализа на антитела"
          successText="Готово"
          isOpen={isOpenConfirmModal}
          onClose={() => {
            setIsOpenConfirmModal(false);
            setModalData(undefined);
          }}
          onDelete={handleDeletion(modalData.id)}
        />
      )}
    </>
  );
};

type PropsType = VaccinationSettingsPropsType & {
  settings: EmployeeVaccinationSettingType | undefined;
};
