import React, { FC } from "react";
import * as elements from "../../InfographicPage.styled";
import { usePopoverHover } from "../../../../uiKit/PopoverHover/usePopoverHover";
import { PopoverHover } from "../../../../uiKit";
import { MorbidityType } from "../../VaccinationPage/hooks/useData";

const EmployeeMorbidityBarMonth: FC<PropsType> = (props) => {
  const { monthNumber, sickDays, days, diseasesInfo } = props;
  const { HealthDays, Month, SickDays, MonthBar, PopoverDataContainer, TextMonth } = elements;

  const { propsPopoverHover, openPopoverHover, closePopoverHover } = usePopoverHover({
    anchorOrigin: { vertical: "bottom", horizontal: "left" },
    transformOrigin: { vertical: 0, horizontal: -20 },
  });

  const sickDaysPercent = sickDays ? (sickDays / days) * 100 : 0;

  const resultBar = sickDays ? (
    <>
      {sickDaysPercent < 100 && <HealthDays height={100 - sickDaysPercent} />}

      <SickDays height={sickDaysPercent} />
    </>
  ) : (
    <HealthDays height={sickDays === 0 ? 100 : 0} />
  );

  return (
    <MonthBar onMouseEnter={openPopoverHover} onMouseLeave={closePopoverHover}>
      <Month>{resultBar}</Month>
      <TextMonth>{monthNumber}</TextMonth>

      {!!diseasesInfo?.length && (
        <PopoverHover {...propsPopoverHover}>
          <PopoverDataContainer>
            {diseasesInfo.map(({ diseasesClass, infectionName, period }, i) => (
              <div key={i}>
                <p>Класс заболевания: {diseasesClass}</p>
                <p>заболевания: {infectionName}</p>
                <p>Продолжительность: {period}</p>
              </div>
            ))}
          </PopoverDataContainer>
        </PopoverHover>
      )}
    </MonthBar>
  );
};

export default EmployeeMorbidityBarMonth;

type PropsType = {
  sickDays: number;
  days: number;
  monthNumber: number;
  diseasesInfo?: MorbidityType[];
};
