import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useUpdateAtom } from "jotai/utils";
import { useAtom } from "jotai";
import { filtersState } from "./utils/atoms";
import { dateSwitcherTypeState } from "../../atoms/atoms";
import { ChartsPage } from "./ChartsPage/ChartsPage";
import { InfectionEmployeesPage } from "./InfectionPage/InfectionEmployeesPage";
import { VaccinationEmployeesPage } from "./VaccinationPage/VaccinationEmployeesPage";
import {
  absoluteInitialValues,
  filtersState as statisticFilterState,
} from "../VaccinationStatisticPage/utils/atoms";

export const InfographicPage: FC<TypePropsType> = (props) => {
  const { type, page } = props;

  const [filters, setFilters] = useAtom(filtersState);
  const setDateSwitcherType = useUpdateAtom(dateSwitcherTypeState);
  const setStatisticFilter = useUpdateAtom(statisticFilterState);
  const params = useParams();

  const infectionId = params["infection"];
  const departmentId = params["department"];

  useEffect(() => {
    setDateSwitcherType("year");
    setFilters({
      infection: infectionId === ":infection" || !infectionId ? filters.infection : infectionId,
      type,
      page,
      department: departmentId ?? "",
    });
    return () => {
      setStatisticFilter(absoluteInitialValues);
      setFilters((filters) => ({ ...filters, department: "" }));
    };
  }, [type, page]);

  return (
    <>
      {type === "users" ? (
        page === "infection" ? (
          <InfectionEmployeesPage />
        ) : (
          <VaccinationEmployeesPage />
        )
      ) : (
        <ChartsPage page={page} type={type} />
      )}
    </>
  );
};

export type TypePropsType = PageType & TypePageType;

export type PageType = {
  page: "infection" | "vaccination";
};
export type TypePageType = {
  type: "organization" | "department" | "users";
};
