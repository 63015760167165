import { useData } from "./useData";
import { useNameDescriptionDictionaryTable } from "../../../../utils/useNameDescriptionDictionary";

export const useTable = () => {
  // ------------------------------ ХУКИ

  const { canEdit } = useData();

  // ------------------------------ ХУКИ - СТАНДАРТНЫЕ ЗНАЧЕНИЯ

  const methods = useNameDescriptionDictionaryTable(canEdit);

  return methods;
};
