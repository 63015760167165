import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";
import { borders, button, colors, flex, margins, overflowOneLine, text } from "../../../styles";

export const Container = styled.div<{ onlyIcon?: boolean }>`
  ${flex({ horizontal: "evenly", vertical: "center" })};
  ${borders({ radius: 12 })};
  ${({ onlyIcon }) => margins({ m: onlyIcon ? "10 0" : "0", p: onlyIcon ? "0" : "10 0" })};

  cursor: pointer;

  :hover {
    background: ${colors.grayscaleHoverBackground};
  }
`;

export const Card = styled.div`
  ${flex({ vertical: "center" })};

  overflow: hidden;
`;

export const Icon = styled.div<{ isExpand: boolean; backgroundImg?: string }>`
  ${flex({ totalCenter: true })};
  ${borders({ totalRadius: true })};
  ${({ isExpand }) => margins({ m: isExpand ? "0 16 0 0" : "0" })};

  width: 40px;
  height: 40px;
  flex-shrink: 0;
  background: ${({ backgroundImg }) =>
    backgroundImg ? `url(data:image/jpg+xml;base64,${backgroundImg})` : colors.purple};
  background-size: cover;
  cursor: pointer;

  p {
    ${text({ size: 15, height: 20, weight: 500, color: colors.white })};
  }
`;

export const Name = styled.p`
  ${overflowOneLine};
  ${text({ size: 15, height: 20, weight: 700, color: colors.grayscaleAsh })};
`;

export const Roles = styled.p`
  ${overflowOneLine};
  ${text({ size: 14, height: 17, weight: 400, color: colors.grayscalePlaceholder })};
`;

export const NotificationsButton = styled.button<{ isExpand?: boolean }>`
  ${button};

  ${margins({ m: "0" })};

  position: relative;
  width: 40px;
  height: 40px;
  flex-shrink: 0;

  & > span {
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    background-color: ${colors.primaryDefault};
    border-radius: 50%;
  }
`;

export const ProfileMenuButtons = styled.div`
  ${flex({ gap: 8, isColumn: true })};
  ${margins({ p: "8" })};
`;

export const ProfileMenuButton = styled.a`
  ${button};

  ${flex({ horizontal: "evenly" })};
  ${borders({ radius: 8 })};

  width: 328px;
  height: 56px;

  p {
    ${text({ size: 15, height: 20, weight: 400, color: colors.grayscaleBeautifulBlack })};
    ${margins({ m: "0 0 0 8" })};
  }

  & > span {
    ${borders({ totalRadius: true })};
    ${text({ size: 12, height: 12, weight: 600, color: colors.white })};
    ${margins({ m: "22", p: "2 4" })};

    display: block;
    background: ${colors.primaryDefault};
  }

  &:hover {
    background: ${colors.grayscaleHoverBackground};

    svg > path {
      fill: ${colors.grayscaleLabel};
    }
  }
`;

export const ProfileMenuLink = styled(Link)`
  ${button};

  ${flex({ horizontal: "evenly", vertical: "center" })};
  ${borders({ radius: 8 })};

  width: 328px;
  height: 56px;

  p {
    ${text({ size: 15, height: 20, weight: 400, color: colors.grayscaleBeautifulBlack })};
    ${margins({ m: "0 0 0 8" })};
  }

  span {
    ${borders({ totalRadius: true })};
    ${text({ size: 12, height: 12, weight: 600, color: colors.white })};
    ${margins({ m: "22", p: "2 4" })};

    display: block;
    background: ${colors.grayscalePlaceholder};
  }

  &:hover {
    background: ${colors.grayscaleHoverBackground};

    svg > path {
      fill: ${colors.grayscaleLabel};
    }
  }
`;
