import { DatePicker } from "@mui/lab";
import { endOfYear, getYear, setYear, startOfYear } from "date-fns";
import React, { FC, useState } from "react";
import { today } from "../../utils/workingWithDates";
import { DateAtomType, DateIntervalAtomType } from "../Header/HeaderNavigation/HeaderNavigation";
import { DateSwitcher, DateSwitcherText, PaperProps } from "./DateSwitcher.styles";
import { useDateSwitcher } from "./useDateSwitcher";

export const YearSwitcher: FC<PropsType> = (props) => {
  const { dateAtom, dateIntervalAtom } = props;

  const methods = useDateSwitcher({ dateAtom, dateIntervalAtom });
  const { setDateInterval, startDate, minDate, maxDate, text, arrow } = methods;

  const [open, setOpen] = useState(false);

  return (
    <>
      {arrow("left").year}

      <DatePicker
        views={["year"]}
        value={setYear(today, getYear(startDate))}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={(newDate) =>
          setDateInterval([startOfYear(newDate as Date), endOfYear(newDate as Date)])
        }
        renderInput={(inputProps) => (
          <DateSwitcher onClick={() => setOpen(true)} ref={inputProps.inputRef}>
            <DateSwitcherText>{text({ inputProps }).year}</DateSwitcherText>
          </DateSwitcher>
        )}
        minDate={minDate}
        maxDate={maxDate}
        PaperProps={PaperProps}
      />

      {arrow("right").year}
    </>
  );
};

type PropsType = {
  dateAtom?: DateAtomType;
  dateIntervalAtom?: DateIntervalAtomType;
};
