import { useData } from "./hooks/useData";
import { useTable } from "./hooks/useTable";
import { useFilters } from "./hooks/useFilters";
import { useExport } from "./hooks/useExport";

export const useInfectionEmployees = () => {
  const data = useData();
  const { totalCount, canViewProfile, departmentTitle } = data;
  const table = useTable(canViewProfile);
  const filter = useFilters();
  const { getFieldsFilters } = filter;
  const exportFilters = useExport(getFieldsFilters, totalCount, departmentTitle);

  return {
    ...data,
    ...table,
    ...filter,
    ...exportFilters,
  };
};
