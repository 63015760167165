import styled from "@emotion/styled/macro";
import * as styles from "../../../styles";

const { flex, text, button, buttonDisabled, buttonOnlyText } = styles;
const { buttonPrimaryDefault, buttonSecondaryDefault, buttonTransparentDefault } = styles;

export const Container = styled.button<PropsType>`
  ${flex({ totalCenter: true })};
  ${text({ size: 16, height: 16, weight: 700 })};

  ${button};
  ${({ type, active, variant }) =>
    active
      ? variant === "default"
        ? type === "submit"
          ? buttonPrimaryDefault
          : buttonSecondaryDefault
        : buttonTransparentDefault
      : buttonDisabled};
  ${({ needIcon }) => !needIcon && buttonOnlyText};

  width: 100%;
  height: 48px;
`;

type PropsType = {
  type: "submit" | "button";
  needIcon: boolean;
  active: boolean;
  variant: "text" | "default";
};
