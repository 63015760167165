import styled from "@emotion/styled/macro";
import { colors, flex, text } from "../../styles";

export const Container = styled.div`
  ${flex({ vertical: "center", gap: 8 })};
  cursor: pointer;
`;

export const Label = styled.p`
  ${text({ size: 15, height: 20, weight: 400, color: colors.grayscaleBody })};
`;

export const Error = styled.p`
  ${text({ size: 12, height: 12, weight: 400, color: colors.redDefault })};

  position: absolute;
  bottom: -14px;
  left: 16px;
  white-space: nowrap;
`;
