import { useData } from "./hooks/useData";
import { useForms } from "./hooks/useForms";
import { useTable } from "./hooks/useTable";

export const useHealthCenterPage = () => {
  const data = useData();
  const forms = useForms();
  const table = useTable();

  return {
    ...data,
    ...table,
    ...forms,
  };
};
