import { fileApi } from "../api/apiFile";

export const getFileBlob = async (link: string) => {
  const res = await fileApi.download(encodeURIComponent(link));
  return new Blob([res.data], { type: res.headers["content-type"] });
};

export const downloadFile = async (link?: FileLinkType, name?: string) => {
  if (!link || !name) return;

  const blob = await getFileBlob(link);
  const a = document.createElement("a");
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = name;
  a.click();
  URL.revokeObjectURL(url);
};

export const getFileSizeMB = (size: number) => Math.round(size / (1024 * 1024));

export type FileLinkType = `fs://${string}`;
