import React, { FC } from "react";
import * as elements from "./VaccinationSettings.styles";
import { CardPart } from "./VaccinationSettings.styles";
import { Info } from "../../../../uiKit/Icons";
import { EmployeeType } from "../../../../api/apiEmployees";
import { UserSettingsCheckbox } from "../../../CalendarPages/CalendarDayPage/UserSettingsCheckbox/UserSettingsCheckbox";
import { QueryKeyType } from "../../../../utils/useQuery";
import { ExemptionSetup } from "../ExemptionSetup/ExemptionSetup";
import { AntibodiesSetup } from "../AntibodiesSetup/AntibodiesSetup";
import { Card } from "../Card/Card";
import { usePopoverHover } from "../../../../uiKit/PopoverHover/usePopoverHover";
import { PopoverHover } from "../../../../uiKit";

export const VaccinationSettings: FC<VaccinationSettingsPropsType> = (props) => {
  const { employee, selectedInfection, queryKey, canEdit } = props;

  const { openPopoverHover, propsPopoverHover, closePopoverHover } = usePopoverHover({
    anchorOrigin: { horizontal: "center", vertical: "top" },
    transformOrigin: { horizontal: "center", vertical: "bottom" },
  });
  const { Settings } = elements;

  const settings = employee?.vaccinationSettings?.find(
    ({ infection }) => infection.id === selectedInfection
  );

  return (
    <Card title="Разрешение">
      <CardPart>
        <Settings>
          <UserSettingsCheckbox
            employeeId={employee?.id ?? ""}
            infectionId={selectedInfection}
            queryKey={queryKey}
            label="Допуск"
            field="access"
            settings={settings}
            enabled={canEdit}
          />
        </Settings>
        <Settings>
          <UserSettingsCheckbox
            employeeId={employee?.id ?? ""}
            infectionId={selectedInfection}
            queryKey={queryKey}
            label="Согласие"
            field="agreement"
            settings={settings}
            enabled={canEdit}
          />
          <Info onMouseLeave={closePopoverHover} onMouseEnter={openPopoverHover} />
        </Settings>
      </CardPart>
      <ExemptionSetup {...props} settings={settings} />
      <AntibodiesSetup {...props} settings={settings} />
      <PopoverHover {...propsPopoverHover}>
        Согласие на вакцинацию предварительное и не обязывает сотрудника к вакцинации.
      </PopoverHover>
    </Card>
  );
};

export type VaccinationSettingsPropsType = {
  employee?: EmployeeType;
  selectedInfection: string;
  queryKey: QueryKeyType;
  canEdit: boolean;
};
