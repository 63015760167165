import styled from "@emotion/styled/macro";
import { borders, colors, flex, grid, margins, text } from "../../../../styles";
import { TextField } from "@mui/material";

const { grayscaleInput, redDefault, grayscaleBeautifulBlack } = colors;

export const Container = styled.div`
  position: relative;
  ${flex({ gap: 8, isColumn: true })};
  ${margins({ m: "8 0 0 0" })}
`;

export const TimeContainer = styled.div`
  ${flex({ horizontal: "evenly", vertical: "center" })};
  ${borders({ radius: 16 })};
  ${margins({ p: "14 16" })};

  height: 52px;
  background: ${grayscaleInput};
  position: relative;
`;

export const Header = styled.header`
  ${flex({ gap: 8, vertical: "center", horizontal: "evenly" })};
`;

export const Title = styled.p`
  ${text({ weight: 700, size: 17, height: 24, color: grayscaleBeautifulBlack })}
`;

export const Times = styled.div`
  ${grid({ columns: "1fr 1fr", gap: 8 })};
`;

export const Error = styled.p`
  ${text({ size: 12, height: 12, weight: 400, color: redDefault })};

  position: absolute;
  bottom: -14px;
  left: 16px;
  white-space: nowrap;
`;

export const TimeTextField = styled(TextField)`
  ${borders({ none: true, radius: 16 })};
  ${margins({ p: "0" })};

  .MuiInputBase-root {
    .MuiOutlinedInput-notchedOutline {
      ${borders({ none: true })};
    }
    .MuiInputBase-input {
      ${margins({ p: "0 4" })};
    }
  }
`;

export const PersonContainer = styled.div`
  ${margins({ p: "6 0 0 0" })};
`;
