import styled from "@emotion/styled/macro";
import { button, colors, margins } from "../../../styles";

export const Container = styled.button<PropsType>`
  ${button};

  ${({ fromCard }) => margins({ m: fromCard ? "0 auto" : "4 auto 0" })};

  width: 40px;
  height: 40px;
  ${({ menuModalIsOpen }) => (menuModalIsOpen ? `border: 1px solid ${colors.grayscaleIcons}` : "")};

  :hover {
    background: ${colors.grayscaleHoverBackground};

    & > svg > path {
      fill: ${colors.grayscaleLabel};
    }
  }

  & > svg > path {
    fill: ${colors.grayscaleIcons};
  }
`;

type PropsType = {
  menuModalIsOpen: boolean;
  fromCard: boolean;
};
