import { useData } from "./hooks/useData";
import { useTable } from "./hooks/useTable";
import { useFilters } from "../../VaccinationStatisticPage/hooks/useFilters";
import { useExport } from "../../VaccinationStatisticPage/hooks/useExport";

export const useVaccinationEmployeesPage = () => {
  const data = useData();
  const { canViewEmployeeCard, isOsr, activeInfections, totalCount } = data;
  const table = useTable(canViewEmployeeCard);
  const filters = useFilters(isOsr, true);
  const { fieldsFiltersWithAdditional } = filters;
  const downloadFilters = useExport(
    fieldsFiltersWithAdditional,
    activeInfections,
    false,
    totalCount
  );

  return {
    ...data,
    ...table,
    ...filters,
    ...downloadFilters,
  };
};
