import React, { FC } from "react";
import { usePopoverHover } from "../../../../uiKit/PopoverHover/usePopoverHover";
import { PopoverHover } from "../../../../uiKit";
import { PlanedDateText } from "../CalendarDay.styles";
import { formatParseDate } from "../../../../utils/workingWithDates";

export const PlanedDate: FC<PropsType> = (props) => {
  const { date, isOverdue } = props;

  const { closePopoverHover, propsPopoverHover, openPopoverHover } = usePopoverHover({
    transformOrigin: { vertical: "bottom", horizontal: "center" },
    anchorOrigin: { vertical: "top", horizontal: "center" },
  });

  return (
    <>
      <PlanedDateText
        isOverdue={isOverdue}
        onMouseEnter={isOverdue ? openPopoverHover : undefined}
        onMouseLeave={isOverdue ? closePopoverHover : undefined}
      >
        {date ? formatParseDate(date) : "—"}
      </PlanedDateText>
      <PopoverHover {...propsPopoverHover}>Просрочена дата планируемой вакцинации</PopoverHover>
    </>
  );
};

type PropsType = {
  date?: string;
  isOverdue: boolean;
};
