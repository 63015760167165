import { IdType } from "../api/types";
import { useAtomValue } from "jotai/utils";
import { diseaseClassesAllState } from "../pages/DictionaryPages/DiseasesClasses/utils/atoms";
import {
  countriesState,
  departmentsState,
  mainDepartmentsState,
  organizationsState,
} from "../atoms/atomsOrg";
import { infectionsDiseasesAllState } from "../pages/DictionaryPages/InfectionDisease/utils/atoms";
import { healthCenterAllState } from "../pages/DictionaryPages/HealthCenters/utils/atoms";
import { diseaseSeveritiesState } from "../pages/DictionaryPages/DiseaseSeverities/utils/atoms";
import { treatmentPlacesState } from "../pages/DictionaryPages/TreatmentPlaces/utils/atoms";
import { personnelCategoriesState } from "../pages/DictionaryPages/PersonnelCategories/utils/atoms";
import { nationalitiesState } from "../pages/DictionaryPages/Nationalities/utils/atoms";

// ------------------------------ ТИПЫ ДОКУМЕНТОВ

export const useDocumentTypeOptions = () => {
  return [];
};

// ------------------------------ ОРГАНИЗАЦИИ

export const useOrganizationOptions = () => {
  const organizations = useAtomValue(organizationsState);

  return organizations.map(({ id, name }) => ({ id, name })) ?? [];
};

// ------------------------------ СТРАНЫ

export const useCountryOptions = () => {
  const countries = useAtomValue(countriesState);

  return countries.map(({ id, name }) => ({ id, name }));
};

// ------------------------------ ГРАЖДАНСТВО

export const useCitizenshipOptions = () => {
  const nationalities = useAtomValue(nationalitiesState);

  return nationalities.map(({ id, name }) => ({ id, name }));
};

// ------------------------------ СТРАНЫ

export const usePersonnelCategoriesOptions = () => {
  const countries = useAtomValue(personnelCategoriesState);

  return countries.map(({ id, name }) => ({ id, name }));
};

// ------------------------------ КОДЫ МКБ-10

export const useDiseaseClassOptions = () => {
  const diseasesClass = useAtomValue(diseaseClassesAllState);
  return diseasesClass.map(({ id, code }) => ({ id, name: code }));
};

// ------------------------------ ОСНОВНЫЕ ПОДРАЗДЕЛЕНИЯ

export const useMainDepartmentOptions = () => {
  const mainDepartments = useAtomValue(mainDepartmentsState);

  return mainDepartments?.map(({ id, name, shortName }) => ({ id, name, shortName })) ?? [];
};

// ------------------------------ ПОДРАЗДЕЛЕНИЯ

export const useDepartmentOptions = () => {
  const departments = useAtomValue(departmentsState);

  return departments?.map(({ id, name }) => ({ id, name })) ?? [];
};

// ------------------------------ ЗАБОЛЕВАНИЯ

export const useInfectionDiseaseOptions = () => {
  const infections = useAtomValue(infectionsDiseasesAllState);

  return infections?.map(({ id, name }) => ({ id, name })) ?? [];
};

// ------------------------------ СТЕПЕНИ ТЯЖЕСТИ ЗАБОЛЕВАНИЯ

export const useDiseaseSeveritiesOptions = () => {
  const severities = useAtomValue(diseaseSeveritiesState);

  return severities?.map(({ id, name }) => ({ id, name })) ?? [];
};

// ------------------------------ МЕСТА ПРОХОЖДЕНИЯ ЛЕЧЕНИЯ

export const useTreatmentPlaceOptions = () => {
  const treatmentPlaces = useAtomValue(treatmentPlacesState);

  return treatmentPlaces?.map(({ id, name }) => ({ id, name })) ?? [];
};

// ------------------------------ МЕДИЦИНСКИЕ ОРГАНИЗАЦИИ

export const useHealthCenterOptions = () => {
  const healthCenters = useAtomValue(healthCenterAllState);

  return healthCenters?.map(({ id, name }) => ({ id, name })) ?? [];
};

// ------------------------------ ТИПЫ

export type OptionType = IdType & {
  name: string | JSX.Element;
  description?: string;
};

export type OptionsType = OptionType[];
