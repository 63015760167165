import styled from "@emotion/styled/macro";
import { borders, colors, flex, margins, text } from "../../../../styles";

const { grayscaleOffWhite, grayscaleInput, grayscaleBeautifulBlack } = colors;

export const Container = styled.div`
  ${borders({ radius: 16, width: 1, color: grayscaleInput })};
  ${margins({ p: "24" })};
  background: ${grayscaleOffWhite};
`;

export const Title = styled.p`
  ${text({ size: 20, height: 26, weight: 700, color: grayscaleBeautifulBlack })};
`;

export const Header = styled.div`
  ${flex({ horizontal: "evenly", vertical: "center" })};
  ${margins({ m: "0 0 16 0" })};
`;
