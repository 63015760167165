import { useData } from "./useData";
import React from "react";
import { useQuery } from "../../../../utils/useQuery";
import { IdType } from "../../../../api/types";
import { InfectionsDiseaseType } from "../../../../api/apiInfectionDiseases";
import { InfectionList } from "../InfectionList/InfectionList";
import { NotificationSettingsType } from "../../../../api/apiVaccination";

export const useTable = () => {
  // ------------------------------ ХУКИ

  const { canEdit, setModalData, queryKey, api, notificationSettings } = useData();

  // ------------------------------ КОНСТАНТЫ

  const update = useQuery().useUpdate({ api, queryKey });

  // ------------------------------ ТАБЛИЦА - ЗАГОЛОВКИ

  const titlesInit = [
    { id: 1, title: "№", name: "number" },
    { id: 2, title: "Наименование", name: "name" },
    { id: 3, title: "Инфекционное заболевание", name: "infections" },
  ];

  // ------------------------------ ТАБЛИЦА - ШИРИНЫ ТАБЛИЦ

  const gridsInit = [
    { titleId: 1, size: "76px" },
    { titleId: 2, size: "2fr" },
    { titleId: 3, size: "1fr" },
  ];

  // ------------------------------ ТАБЛИЦА - ДАННЫЕ ДЛЯ СТРОКИ

  const object = (datum: NotificationSettingsType, number?: number): ObjectType => {
    const { id, name, infections, field } = datum as unknown as NotificationTableType;

    return {
      ...(number ? { number } : {}),
      name,
      infections: (
        <InfectionList
          infections={infections}
          field={field}
          id={id}
          settings={notificationSettings}
          canEdit={canEdit}
          deletion={update}
          setModalData={setModalData}
          name={name}
        />
      ),
    };
  };

  return {
    titlesInit,
    gridsInit,

    object,
  };
};

type ObjectType = {
  number?: number;
  name: string;
  infections: JSX.Element;
};

export type NotificationTableType = IdType & {
  name: string;
  infections: InfectionsDiseaseType;
  field: keyof NotificationSettingsType;
};
