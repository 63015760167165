import { Formik } from "formik";
import { useAtom } from "jotai";
import React, { useEffect } from "react";
import { filtersFormState, hasActiveFiltersState } from "../../atoms/atoms";
import { FormButtons } from "../Form";
import { FormContainer } from "../Form/Form.styles";
import { RightModal } from "../RightModal";
import { RightModalActions } from "../RightModal/RightModalActions";
import { RightModalBody } from "../RightModal/RightModalBody";
import { useUpdateAtom } from "jotai/utils";
import { ObjectSchema } from "yup";
import { Assign, ObjectShape } from "yup/lib/object";

export const Filters = <FLTRS,>(props: FilterPropsType<FLTRS>) => {
  const { children, methods } = props;

  const {
    filtersFormName = "filtersForm",
    filtersFormTitle,
    fieldsFilters,
    setFilters,
    filters,
  } = methods;
  const { absoluteInitialValues, initialValuesFilters, validationSchemaFilters } = methods;

  const [filtersFormIsOpen, setFiltersFormIsOpen] = useAtom(filtersFormState);
  const setActiveFilters = useUpdateAtom(hasActiveFiltersState);

  useEffect(() => {
    setActiveFilters(JSON.stringify(filters) !== JSON.stringify(absoluteInitialValues));
    return () => setActiveFilters(false);
  }, []);

  const handleClose = (updatedValues?: FLTRS) => {
    if (updatedValues && !("target" in updatedValues)) {
      setActiveFilters(JSON.stringify(updatedValues) !== JSON.stringify(absoluteInitialValues));
    }
    setFiltersFormIsOpen(false);
  };

  if (!fieldsFilters) return null;

  return (
    <RightModal
      title={filtersFormTitle ?? "Фильтры"}
      isOpen={filtersFormIsOpen}
      onClose={handleClose}
      size="small"
    >
      <Formik
        initialValues={initialValuesFilters ?? {}}
        validationSchema={validationSchemaFilters}
        onSubmit={async (values) => {
          if (!setFilters) return;

          setFilters(values as FLTRS);

          handleClose(values as FLTRS);
        }}
      >
        {({ handleSubmit, setValues }) => (
          <>
            {children ? (
              children
            ) : (
              <RightModalBody>
                <form id={filtersFormName} onSubmit={handleSubmit}>
                  <FormContainer>{fieldsFilters}</FormContainer>
                </form>
              </RightModalBody>
            )}

            <RightModalActions>
              <FormButtons
                formName={filtersFormName}
                onClose={handleClose}
                saveOrAdd="clear"
                onClear={() => setValues(absoluteInitialValues ?? {})}
              />
            </RightModalActions>
          </>
        )}
      </Formik>
    </RightModal>
  );
};

type FilterPropsType<FLTRS> = {
  methods: FilterMethodsType<FLTRS>;
  children?: JSX.Element;
};

export type FilterMethodsType<FLTRS> = Required<FiltersType<FLTRS>> | Partial<FiltersType<FLTRS>>;

type FiltersType<FLTRS> = {
  filtersFormName?: string;
  filtersFormTitle?: string;
  fieldsFilters?: JSX.Element;
  filters?: FLTRS;
  setFilters?: (arg: FLTRS) => void;
  absoluteInitialValues?: FLTRS;
  initialValuesFilters?: FLTRS;
  validationSchemaFilters?: ObjectSchema<Assign<ObjectShape, object>>;
};
