import React from "react";
import { Columns } from "../../styles";
import { UniversalTextButton } from "../Buttons";
import { ConfirmModalPropsType } from "./ConfirmModal";
import {
  ConfirmAdditionalButton,
  ConfirmBody,
  ConfirmContainer,
  ConfirmHeader,
} from "./ConfirmModal.styles";

export const SimpleConfirmModalPart = <DATA, VOBJ>(
  props: SimpleConfirmModalPropsType<DATA, VOBJ>
) => {
  const { questionText, confirmText, additionalButton } = props;
  const { onClose, onDelete, onSend, onApprove } = props;

  const acceptText = confirmText
    ? confirmText
    : onDelete
    ? "Удалить"
    : onSend
    ? "Отправить"
    : onApprove
    ? "Согласовать"
    : "";

  const headerText = typeof questionText === "string" ? questionText : questionText.header;
  const bodyText = typeof questionText === "string" ? "" : questionText.body;

  return (
    <>
      <ConfirmHeader>{headerText}</ConfirmHeader>
      {bodyText && <ConfirmBody>{bodyText}</ConfirmBody>}

      <Columns columns={2}>
        <UniversalTextButton text="Отменить" type="outlined" onClick={onClose} />

        <UniversalTextButton
          text={acceptText}
          type={onDelete ? "attention" : onSend ? "positive" : onApprove ? "positive" : undefined}
          onClick={onDelete || onSend || onApprove}
        />
      </Columns>

      {additionalButton && <ConfirmAdditionalButton>{additionalButton}</ConfirmAdditionalButton>}
    </>
  );
};

export const SimpleConfirmModal = <DATA, VOBJ>(props: SimpleConfirmModalPropsType<DATA, VOBJ>) => {
  const { isOpen } = props;

  return (
    <ConfirmContainer open={isOpen}>
      <SimpleConfirmModalPart {...props} />
    </ConfirmContainer>
  );
};

export type SimpleConfirmModalPropsType<DATA, VOBJ> = Omit<
  ConfirmModalPropsType<DATA, VOBJ>,
  "successText"
>;
