import { useData } from "./hooks/useData";
import { useFilters } from "./hooks/useFilters";
import { useTable } from "./hooks/useTable";
import { useExport } from "./hooks/useExport";

export const useVaccinationStatisticPage = () => {
  const data = useData();
  const { isOsr, canViewEmployeeCard, activeInfections, totalCount, currentSettings } = data;
  const filters = useFilters(isOsr, false);
  const { fieldsFiltersWithAdditional } = filters;
  const downloadFilters = useExport(
    fieldsFiltersWithAdditional,
    activeInfections,
    true,
    totalCount
  );

  const table = useTable(canViewEmployeeCard, false, currentSettings);

  return {
    ...data,
    ...filters,
    ...table,
    ...downloadFilters,
  };
};
