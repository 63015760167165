import { getQuestionText } from "../../../../utils/getQuestionText";
import React from "react";
import { diseaseClassesState, modalDataState, useDiseasesClassesQuery } from "../utils/atoms";
import { useAtom } from "jotai";
import { useAtomValue } from "jotai/utils";
import { currentUserAtom } from "../../../../atoms/atomCurrentUser";
import { HeaderNavigation } from "../../../../uiKit";
import { DICTIONARY } from "../../../../constants/routes";
import { useLoadableData } from "../../../../utils/useLoadableData";

export const useData = () => {
  // ------------------------------ АТОМЫ

  const [modalData, setModalData] = useAtom(modalDataState);
  const { roleModel } = useAtomValue(currentUserAtom);

  const { totalCount, data, loadableData } = useLoadableData(diseaseClassesState, []);

  // ------------------------------ ХУКИ

  const { queryKey, api } = useDiseasesClassesQuery();
  const { canViewOtherDictionaries: canView, canEditOtherDictionaries: canEdit } = roleModel;

  // ------------------------------ КОНСТАНТЫ
  const titlePage = "Коды МБК-10";

  const questionWords = ["код", "кода", "кодов"];
  const questionText = getQuestionText(questionWords);

  // ------------------------------ ШАПКА - НАВИГАЦИЯ

  const navigation = (
    <HeaderNavigation
      title={titlePage}
      questionText={questionText}
      needSearchButton
      needAddButton={canEdit}
      needDeleteButton={canEdit}
      section={{ name: "Справочники", rout: DICTIONARY }}
      queryKey={queryKey}
      api={api}
    />
  );

  return {
    totalCount,
    loadableData,
    data,

    navigation,

    questionWords,
    questionText,

    canView,
    canEdit,

    modalData,
    setModalData,

    api,
    queryKey,
  };
};
