import { useField } from "formik";
import React, { FC } from "react";
import { colors } from "../../styles";
import { FieldContainer } from "../Form/Form.styles";
import { LabelField } from "../Form/LabelField";
import { DateInput } from "./DateInput";
import { Container, Hyphen } from "./DateInput.styles";
import { useDateInput } from "./useDateInput";

export const StartAndEndDatesInput: FC<PropsType> = (props) => {
  const { label, column = false, merge = false, outsideLabel } = props;
  const { color = colors.grayscaleInput } = props;
  const { minDate, maxDate, disableStart = false, disableStop = false } = props;

  const methods = useDateInput(props.name, props.placeholder, label);
  const { maxStartDate, minEndDate, name, placeholder } = methods;

  const [{ value: startDate }] = useField<Date>(name.start);
  const [{ value: endDate }] = useField<Date>(name.stop);

  return (
    <FieldContainer>
      <LabelField outsideLabel={outsideLabel} />
      <Container column={column} merge={merge} backgroundcolor={color}>
        <DateInput
          name={name.start}
          placeholder={placeholder.start}
          insideLabel={label?.start}
          disabled={disableStart}
          minDate={minDate}
          maxDate={maxStartDate(endDate, maxDate)}
          color={color}
        />

        {merge && <Hyphen needLabel={!!label?.stop}>—</Hyphen>}

        <DateInput
          name={name.stop}
          placeholder={placeholder.stop}
          insideLabel={label?.stop}
          disabled={disableStop}
          minDate={minEndDate(startDate, minDate)}
          maxDate={maxDate}
          color={color}
        />
      </Container>
    </FieldContainer>
  );
};

type PropsType = {
  outsideLabel?: string;
  name?: { start: string; stop: string };
  label?: { start: string; stop: string };
  placeholder?: { start: string; stop: string };
  column?: boolean;
  disableStart?: boolean;
  disableStop?: boolean;
  minDate?: Date;
  maxDate?: Date;
  merge?: boolean;
  color?: string;
};
