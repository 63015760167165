import { DiseaseClassType, diseasesClassesApi } from "../../../../api/apiDictionaries";
import { atom } from "jotai";
import { atomWithQuery } from "jotai/query";
import { fetch, fetchAll } from "../../../../utils/fetchData";
import { getPaginationQueryKeys, usePaginationQueryKeys } from "../../../../utils/useQueryKeys";
import { PaginationQueryType } from "../../../../api/types";
import { getQueryFilter } from "../../../../utils/getQueryFilter";
import { loadable } from "jotai/utils";

export const modalDataState = atom<DiseaseClassType | undefined>(undefined);

// ------------------------------ ДАННЫЕ

const diseasesClassesAtom = atomWithQuery((get) => {
  const args = getPaginationQueryKeys(get);

  return diseasesClassesQuery(args);
});

export const diseaseClassesState = loadable(diseasesClassesAtom);

const diseasesClassesQuery = (props: PaginationQueryType) => {
  const { startIndex, query, size } = props;

  const filter = query ? [getQueryFilter(query, ["name", "code"])] : undefined;

  const args = { startIndex, size, filter };

  return {
    queryKey: [startIndex, size, query, "diseaseClassesState"],
    queryFn: () => fetchAll(() => diseasesClassesApi().getAll(args)),
  };
};

export const useDiseasesClassesQuery = () => {
  const { size, query, startIndex } = usePaginationQueryKeys();
  return {
    queryKey: [startIndex, size, query, "diseaseClassesState"],
    api: diseasesClassesApi(),
  };
};

// ------------------------------ ДАННЫЕ - ВСЕ

export const diseaseClassesAllState = atomWithQuery(() => ({
  queryKey: ["diseaseClassesAllState"],
  queryFn: () => fetch(() => diseasesClassesApi().getAll()),
}));
