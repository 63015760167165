import React, { FC, useEffect, useRef } from "react";
import { TypePropsType } from "../InfographicPage";
import { Page } from "../../../uiKit";
import { BodyPage, HeaderBody, HeaderExport, HeaderPage, Title } from "../InfographicPage.styled";
import { OrganizationInfectionSelect } from "../components/OrganizationInfectionSelect/OrganizationInfectionSelect";
import { Summary } from "../../../components";
import { OrganizationDepartmentSwitch } from "../components/OrganizationDepartmentSwitch/OrganizationDepartmentSwitch";
import { OrganizationChart } from "../components/OrganizationChart/OrganizationChart";
import { DepartmentChart } from "../components/DepartmentChart/DepartmentChart";
import { useData } from "./hooks/useData";
import { Logo } from "../../../uiKit/LeftMenu/Logo/Logo";
import { Logo as LogoIcon } from "../../../uiKit/Icons";

export const ChartsPage: FC<TypePropsType> = (props) => {
  const { type, page } = props;

  const methods = useData(props);
  const { summaryData, summaryTitle, handleClickDepartment } = methods;
  const { selectedOrganizationName, selectedInfectionName, selectedDepartmentName } = methods;
  const { organizationStats, isDepartment, filters, setExportElement } = methods;

  const refForExport = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setExportElement(refForExport.current);

    return () => setExportElement(null);
  }, []);

  return (
    <Page methods={methods} needScroll>
      <HeaderPage>
        <OrganizationInfectionSelect />
        <Summary title={summaryTitle} content={summaryData} minWidth={99} />
      </HeaderPage>
      <BodyPage>
        <HeaderBody>
          <Title>{selectedOrganizationName}</Title>
          {selectedDepartmentName && <Title>{selectedDepartmentName}</Title>}
          <Title>{selectedInfectionName}</Title>
          <OrganizationDepartmentSwitch
            type={page}
            isDepartment={isDepartment}
            onClickDepartment={handleClickDepartment}
          />
        </HeaderBody>

        {type === "organization" ? (
          <OrganizationChart
            selectedOrganizationName={selectedOrganizationName}
            filters={filters}
            stats={organizationStats}
          />
        ) : (
          <DepartmentChart
            selectedOrganizationName={selectedOrganizationName}
            filters={filters}
            onClick={handleClickDepartment}
          />
        )}
      </BodyPage>

      <BodyPage ref={refForExport} forExport>
        <HeaderExport>
          <Logo logo={<LogoIcon />} />
          <Title forExport>
            {`График ${page === "infection" ? "заболеваемости" : "вакцинации"} по ${
              type === "organization"
                ? selectedDepartmentName
                  ? selectedDepartmentName
                  : "организации"
                : "подразделениям"
            }`}
          </Title>
        </HeaderExport>
        <HeaderBody>
          <Title>{selectedOrganizationName}</Title>
          <Title>{selectedInfectionName}</Title>
        </HeaderBody>
        {type === "organization" ? (
          <OrganizationChart
            selectedOrganizationName={selectedOrganizationName}
            filters={filters}
            stats={organizationStats}
          />
        ) : (
          <DepartmentChart
            selectedOrganizationName={selectedOrganizationName}
            filters={filters}
            onClick={handleClickDepartment}
            forExport
          />
        )}
      </BodyPage>
    </Page>
  );
};
