import { atomWithQuery } from "jotai/query";
import { atomWithStorage, loadable, useAtomValue } from "jotai/utils";
import { fetch, fetchAll, fetchById } from "../../../utils/fetchData";
import { atom } from "jotai";
import { DateRange } from "@mui/lab";
import { endOfYear, startOfYear } from "date-fns";
import { formatDateForBackend, now, today } from "../../../utils/workingWithDates";
import {
  infographicDepartmentApi,
  infographicOrganizationApi,
  OrganizationProps,
  PartialPaginationType,
  summaryDataInfographicApi,
} from "../../../api/apiInfographic";
import { getPaginationKeys } from "../../../utils/useQueryKeys";
import { PaginationType } from "../../../api/types";

// ------------------------------ ТЕКУЩАЯ ДАТА

export const currentDateInterval = atom<DateRange<Date>>([startOfYear(today), endOfYear(today)]);

// ------------------------------ ЭКСПОРТИРУЕМЫЙ ЭЛЕМЕНТ

export const exportElementAtom = atom<HTMLDivElement | null>(null);

// ------------------------------ ФИЛЬТРЫ

export const absoluteInitialValues: FiltersType = {
  infection: "",
  type: "organization",
  page: "",
  department: "",
};

export const filtersState = atomWithStorage("infographicFilters", absoluteInitialValues);

export type FiltersType = {
  infection: string;
  type: string;
  page: string;
  department: string;
};

// ------------------------------ ДАННЫЕ - ОРГАНИЗАЦИЯ

export const organizationStatsAtom = atomWithQuery((get) => {
  const [startDate, endDate] = get(currentDateInterval);

  const filters = get(filtersState);

  const start = startDate || now;
  const end = endDate || now;

  const inOneYear = startOfYear(start) === startOfYear(end);

  return organizationStatsQuery({
    ...filters,
    startDate: formatDateForBackend(inOneYear ? startOfYear(start) : start),
    endDate: formatDateForBackend(inOneYear ? endOfYear(start) : end),
  });
});

export const organizationStatsState = loadable(organizationStatsAtom);

export const organizationStatsQuery = (props: OrganizationProps) => {
  const { startDate, endDate, infection, page } = props;

  return {
    queryKey: [startDate, endDate, infection, page, "organizationStatsState"],
    queryFn: () => fetchById(() => infographicOrganizationApi().getService(props)),
    keepPreviousData: true,
    enabled: !!infection,
  };
};

export const useOrganizationStatsQuery = () => {
  const { infection, page } = useAtomValue(filtersState);
  const [startDate, endDate] = useAtomValue(currentDateInterval);

  const parseStartDate = formatDateForBackend(startDate || now);
  const parseEndDate = formatDateForBackend(endDate || now);

  return {
    queryKey: [parseStartDate, parseEndDate, infection, page, "organizationStatsState"],
    api: infographicOrganizationApi(),
  };
};

// ------------------------------ ДАННЫЕ - СВОДНЫЕ ДАННЫЕ

export const summaryDataState = atomWithQuery((get) => {
  const [startDate, endDate] = get(currentDateInterval);

  const filters = get(filtersState);

  const args = {
    ...filters,
    startDate: formatDateForBackend(startDate || now),
    endDate: formatDateForBackend(endDate || now),
  };

  return summaryDataQuery(args);
});

const summaryDataQuery = (props: OrganizationProps) => {
  const { infection, page, startDate, endDate } = props;

  return {
    queryKey: [startDate, endDate, infection, page, "summaryDataState"],
    queryFn: () => fetchById(() => summaryDataInfographicApi().get(props)),
    enabled: !!infection,
  };
};

// ------------------------------ ДАННЫЕ - ПОДРАЗДЕЛЕНИЕ

export const departmentsStatsAtom = atomWithQuery((get) => {
  const [startDate, endDate] = get(currentDateInterval);
  const { size, startIndex } = getPaginationKeys(get);

  const filters = get(filtersState);

  return departmentStatsQuery({
    ...filters,
    size,
    startIndex,
    startDate: formatDateForBackend(startDate || now),
    endDate: formatDateForBackend(endDate || now),
  });
});

export const departmentsStatsState = loadable(departmentsStatsAtom);

export const departmentStatsQuery = (props: OrganizationProps & PaginationType) => {
  return {
    queryKey: [...Object.values(props), "departmentsStatsState"],
    queryFn: () => fetchAll(() => infographicDepartmentApi().get(props)),
  };
};

// ------------------------------ ДАННЫЕ - ВСЕ ПОДРАЗДЕЛЕНИЯ

export const departmentsStatAllState = atomWithQuery((get) => {
  const [startDate, endDate] = get(currentDateInterval);

  const filters = get(filtersState);

  return departmentStatAllQuery({
    ...filters,
    size: undefined,
    startIndex: 0,
    startDate: formatDateForBackend(startDate || now),
    endDate: formatDateForBackend(endDate || now),
  });
});

export const departmentStatAllQuery = (props: OrganizationProps & PartialPaginationType) => {
  return {
    queryKey: [...Object.values(props), "departmentsStatAllState"],
    queryFn: () => fetch(() => infographicDepartmentApi().get(props)),
    keepPreviousData: true,
  };
};
