import { useAtomValue } from "jotai/utils";
import React, { MouseEvent, useState } from "react";
import { paginationState } from "../../../atoms/atoms";
import { useQuery } from "../../../utils/useQuery";
import { TableMenuButton, TableMenuItemButton } from "../../Buttons";
import { Check } from "../../Check";
import { ConfirmModal } from "../../ConfirmModal";
import { Pencil, Trash } from "../../Icons";
import { PopoverModal } from "../../PopoverModal";
import { useTable } from "../useTable";
import { Container, MenuButtons, TableBodyItem } from "./TableBodyRow.styles";
import { TableBodyRowItem } from "./TableBodyRowItem";
import { TableMethodsType } from "../Table";
import { IdType } from "../../../api/types";
import { FormMethodsType } from "../../Form/Form";

export const TableBodyRow = <DATUM, OBJ, FLDS, VOBJ>(props: PropsType<DATUM, OBJ, FLDS, VOBJ>) => {
  const { id, datum, number, methods, needPagination } = props;
  const { needCheck = false, needMenu = false } = props;

  const { page, quantity } = useAtomValue(paginationState);

  const { questionText, successText, api, queryKey, queryKeyForOneEntity } = methods;

  const { gridsInit, titlesInit, object, setModalData, notEditableIds, tableSettings } = methods;
  const { editButton, hideEdit = false, hideDelete = false } = tableSettings || {};

  const { getGrids, formatData, selectingRows, isRowSelected, setSelectedRows } = useTable();

  // ------------------------------ < ВЫЗОВ КОНТЕКСТНОГО МЕНЮ

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const openMenuModal = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setAnchorElement(evt.currentTarget);
  };

  const closeMenuModal = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setAnchorElement(null);
  };

  const menuModalIsOpen = !!anchorElement;
  const idMenuModal = menuModalIsOpen ? "menu-modal" : "";

  // ------------------------------ ВЫЗОВ КОНТЕКСТНОГО МЕНЮ >

  // ------------------------------ < ОКНО ПОДТВЕРЖДЕНИЯ УДАЛЕНИЯ

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const openDeleteConfirmModal = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();
    closeMenuModal(evt);
    setOpenConfirmModal(true);
    setSelectedRows([id]);
  };

  const closeDeleteConfirmModal = () => setOpenConfirmModal(false);

  const deletion = useQuery().useDelete({ api, queryKey, queryKeyForOneEntity });
  const handleDeleteRows = async () => await deletion?.mutateAsync([id]);

  // ------------------------------ ОКНО ПОДТВЕРЖДЕНИЯ УДАЛЕНИЯ >

  if (!titlesInit || !gridsInit || !object) return null;

  // ------------------------------ НАСТРОЙКИ СТРОКИ >

  const calculatedNumber = needPagination ? number + 1 + (page ?? "1") * quantity : number + 1;

  const datumObject = object(datum, calculatedNumber);
  const { background = "default" } = datumObject?.props || {};

  const nonEditableId = notEditableIds?.includes(id);

  // ------------------------------ < НАСТРОЙКИ СТРОКИ

  return (
    <>
      <Container grid={getGrids(gridsInit)} needBackground={background}>
        {needCheck &&
          (nonEditableId ? (
            <TableBodyItem />
          ) : (
            <TableBodyItem
              className="check"
              onClick={needCheck && !!handleDeleteRows ? () => selectingRows(id) : undefined}
            >
              {!!handleDeleteRows && <Check checked={isRowSelected(id)} />}
            </TableBodyItem>
          ))}

        {Object.values(formatData(datumObject ?? {}, titlesInit)).map((value, i) => (
          <TableBodyRowItem key={i} data={value} />
        ))}

        {needMenu &&
          (nonEditableId ? (
            <TableBodyItem />
          ) : (
            <TableMenuButton menuModalIsOpen={menuModalIsOpen} onClick={openMenuModal} />
          ))}
      </Container>

      <PopoverModal
        id={idMenuModal}
        isOpen={menuModalIsOpen}
        element={anchorElement}
        onClose={closeMenuModal}
      >
        <MenuButtons>
          {!hideEdit && (
            <TableMenuItemButton
              text={editButton?.text ?? "Изменить"}
              icon={editButton?.icon ?? <Pencil />}
              onClick={(evt) => {
                setAnchorElement(null);
                evt.stopPropagation();
                setModalData ? setModalData(datum) : undefined;
              }}
            />
          )}

          {!hideDelete && (
            <TableMenuItemButton text="Удалить" icon={<Trash />} onClick={openDeleteConfirmModal} />
          )}
        </MenuButtons>
      </PopoverModal>

      {openConfirmModal && (
        <ConfirmModal
          questionText={
            questionText
              ? typeof questionText === "string"
                ? `Вы действительно хотите удалить ${questionText}?`
                : `Вы действительно хотите удалить ${questionText({
                    entity: (datumObject as { name?: string }).name || "",
                  })}?`
              : ""
          }
          successText={successText ? successText : "Запись удалена"}
          isOpen={openConfirmModal}
          onClose={closeDeleteConfirmModal}
          onDelete={handleDeleteRows}
        />
      )}
    </>
  );
};

type PropsType<DATUM, OBJ, FLDS, VOBJ> = IdType & {
  datum: DATUM;
  number: number;
  methods: TableMethodsType<DATUM, OBJ> & FormMethodsType<DATUM, FLDS, VOBJ>;
  needMenu?: boolean;
  needCheck?: boolean;
  needPagination: boolean;
};

export type SettingsTableRowType = {
  background?: "default" | "error" | "notification";
};
