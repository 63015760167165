import styled from "@emotion/styled/macro";
import { Chip, Select, SelectProps } from "@mui/material";
import { borders, colors, flex, margins, overflowOneLine, shadow, text } from "../../styles";

export const Container = styled.div<{ disabled: boolean }>`
  position: relative;
  cursor: ${({ disabled }) => (disabled ? "no-drop" : "auto")};

  * {
    cursor: inherit !important;
  }
`;

export const Item = styled(Select)<ItemPropsType>`
  ${borders({ radius: 16 })};
  ${({ haslabel }) => margins({ p: haslabel ? "8 16" : "16" })};

  width: 100%;
  height: 56px;
  background: ${({ haserror, backgroundcolor, disabled }) =>
    haserror ? colors.redBackground : disabled ? colors.grayscaleDisabled : backgroundcolor};
  cursor: pointer;

  .MuiSelect-select {
    ${({ haslabel }) =>
      margins({ m: haslabel ? "16 0 0" : "0", p: "0 32 0 0", needImportant: true })};

    z-index: 1;
  }

  .MuiOutlinedInput-notchedOutline {
    ${borders({ none: true })};
    ${margins({ p: "0" })};
  }
` as (
  props: SelectProps & { haslabel: number; haserror: number; backgroundcolor: string }
) => JSX.Element;

export const Placeholder = styled.p<{ haslabel: number }>`
  ${overflowOneLine};
  ${text({ size: 16, height: 24, weight: 400, color: colors.grayscalePlaceholder })};

  width: calc(100% - 40px - 8px - 16px * 2);
  // (40px) - ширина кнопки справа
  // (8px) - ширина отступа отступ от кнопки справа
  // (16px * 2) - 2 паддинга инпута
  position: absolute;
  top: ${({ haslabel }) => (haslabel ? "24px" : "16px")};
  left: 16px;
`;

export const MenuItemWrapper = styled.div`
  ${overflowOneLine};
  ${text({ size: 15, height: 20, weight: 400, color: colors.grayscaleAsh })};
`;

export const Error = styled.p`
  ${text({ size: 12, height: 12, weight: 400, color: colors.redDefault })};

  position: absolute;
  bottom: -14px;
  left: 16px;
  white-space: nowrap;
`;

export const MenuProps = (size: string | number) => ({
  sx: {
    height: 232,

    ".MuiPaper-root": {
      borderRadius: 4,
      boxShadow: shadow,
      overflowY: "scroll",
      padding: 1,

      ul: {
        width: "100%",
        padding: 0,

        li: {
          height: 56,
          borderRadius: 2,
          marginBottom: 1,
          px: 2,
          py: 0,

          "&:hover": { background: colors.grayscaleHoverBackground },
          "&:last-child": { marginBottom: 0 },
        },
      },

      "&::-webkit-scrollbar": {
        width: 8,
      },

      "&::-webkit-scrollbar-thumb": {
        background: colors.grayscaleLine,
        borderRadius: "99em",
      },
    },

    ".MuiPopover-paper": { width: typeof size === "number" ? size : size === "small" ? 372 : 472 },
  },
  anchorOrigin: {
    vertical: 40,
    horizontal: 0,
  },
  transformOrigin: {
    vertical: -8,
    horizontal: 16,
  },
});

export const SelectedOptionsChips = styled.div`
  ${flex({ gap: 8 })};
  ${margins({ m: "8 0 0 0" })};

  flex-flow: row wrap;
`;

export const SelectedChipsItem = styled(Chip)`
  ${flex({ totalCenter: true })};
  ${borders({ totalRadius: true })};
  ${margins({ p: "10 10 10 16" })};

  height: 40px;
  color: ${colors.white};
  background: ${colors.grayscaleLabel};

  .MuiChip-label {
    ${text({ size: 13, height: 21, weight: 700 })};
    ${margins({ p: "0" })};
  }

  .MuiSvgIcon-root {
    ${margins({ m: "0 0 0 8" })};
    width: 29px;
    height: 29px;
  }

  &:hover {
    background: ${colors.grayscalePlaceholder};
  }
`;

type ItemPropsType = {
  multiline: boolean;
  haslabel: number;
  haserror: number;
  backgroundcolor: string;
  disabled: boolean;
};
