import { useNameDescriptionDictionaryForms } from "../../../../utils/useNameDescriptionDictionary";

export const useForms = () => {
  // ------------------------------ ХУКИ - СТАНДАРТНЫЕ ЗНАЧЕНИЯ

  const methods = useNameDescriptionDictionaryForms();

  // ------------------------------ КОНСТАНТЫ

  const addFormTitle = "Добавить категорию персонала";
  const editFormTitle = "Изменение категории персонала";

  const formName = "personnelCategory";

  return {
    ...methods,

    addFormTitle,
    editFormTitle,
    formName,
  };
};
