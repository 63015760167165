import { useData } from "./useData";
import { HealthCenterType } from "../../../../api/apiDictionaries";

export const useTable = () => {
  // ------------------------------ ХУКИ

  const { canEdit } = useData();

  // ------------------------------ КОНСТАНТЫ

  const titlesInit = [
    { id: 1, title: "№", name: "number" },
    { id: 2, title: "Наименование", name: "name" },
    { id: 3, title: "Краткое наименование", name: "shortName" },
    { id: 4, title: "ФМБА", name: "fmba" },
  ];

  // ------------------------------ ТАБЛИЦА - ШИРИНЫ ТАБЛИЦ

  const gridsInit = [
    ...(canEdit ? [{ titleId: null, size: "56px" }] : []),
    { titleId: 1, size: "76px" },
    { titleId: 2, size: "1fr" },
    { titleId: 3, size: "1fr" },
    { titleId: 4, size: "1fr" },
    ...(canEdit ? [{ titleId: null, size: "56px" }] : []),
  ];

  // ------------------------------ ТАБЛИЦА - ДАННЫЕ ДЛЯ СТРОКИ

  const object = ({ name, shortName, fmba }: HealthCenterType, number?: number): ObjectType => ({
    ...(number ? { number } : {}),
    name,
    shortName: shortName || "—",
    fmba: fmba ? "да" : "нет",
  });

  return {
    titlesInit,
    gridsInit,
    object,
  };
};

export type ObjectType = Omit<HealthCenterType, "id" | "fmba"> & { number?: number; fmba: string };
