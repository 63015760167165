import styled from "@emotion/styled/macro";
import { borders, colors, flex, margins, text } from "../../../../styles";

const { grayscaleBeautifulBlack, grayscaleLabel, primaryDefault, grayscaleInput, white } = colors;

export const CardPart = styled.div<{ noneBorders?: boolean }>`
  ${flex({ gap: 16, isColumn: true })};
  ${margins({ p: "24 0" })};
  ${({ noneBorders }) =>
    noneBorders ? "" : borders({ side: "bottom", width: 1, color: grayscaleInput })};
`;

export const Header = styled.header`
  ${flex({ horizontal: "evenly", vertical: "center" })};
`;

export const Title = styled.p`
  ${text({ weight: 700, size: 20, height: 26, color: grayscaleBeautifulBlack })};
`;

export const Settings = styled.div<{ onChange?: boolean }>`
  ${flex({ vertical: "center", horizontal: "evenly" })};
  ${borders({ width: 1, radius: 12, color: grayscaleInput })};
  ${margins({ p: "8 16" })};

  background: ${white};
  cursor: ${({ onChange }) => (onChange ? "pointer" : "default")};
  height: 56px;
`;

export const SubTitle = styled.p`
  ${text({ weight: 700, size: 15, height: 20, color: grayscaleBeautifulBlack })};

  width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AdditionalButton = styled.div`
  & > button {
    ${margins({ m: "0" })};
  }
`;

export const SecondaryText = styled.span`
  ${text({ size: 15, weight: 400, height: 20, color: grayscaleLabel })};
  white-space: nowrap;
`;

export const TextButton = styled.p`
  ${text({ size: 16, weight: 700, height: 16, color: primaryDefault, align: "center" })};

  cursor: pointer;
  align-content: center;
  width: 100%;
  height: 48px;
`;
