import { useField, useFormikContext } from "formik";
import React, { FC } from "react";
import { Star } from "../Icons";
import { Container } from "./StarInput.styles";

export const StarInput: FC<PropsType> = (props) => {
  const { name, label } = props;

  const { setFieldValue } = useFormikContext();

  const [{ value }] = useField<boolean>(name);

  return (
    <Container onClick={() => setFieldValue(name, !value)}>
      <Star active={value} />
      {label}
    </Container>
  );
};

type PropsType = {
  name: string;
  label: string;
};
