import styled from "@emotion/styled/macro";
import { borders, button, colors, flex, margins, overflowOneLine, text } from "../../../styles";

export const Wrapper = styled.div<{ active?: boolean }>`
  position: relative;

  &::after {
    content: "";
    width: 4px;
    height: 40px;
    position: absolute;
    top: 0;
    display: ${({ active }) => (active ? "block" : "none")};
    left: -16px;
    background: ${colors.grayscaleAsh};
    border-radius: 0 99em 99em 0;
    z-index: 5;
  }
`;

export const TextAndArrow = styled.div<{ active?: boolean }>`
  ${flex({ horizontal: "evenly", vertical: "center" })};
  ${margins({ m: "0 12 0 0" })};

  width: 216px;

  svg > path {
    fill: ${({ active }) => (active ? colors.grayscaleAsh : colors.grayscalePlaceholder)};
  }
`;

export const SubSections = styled.div<{ isOpen?: boolean }>`
  height: ${({ isOpen }) => (isOpen ? "fit-content" : "0")};
  overflow: hidden;
  transition: height 0.3s ease-in-out;
`;

export const Button = styled.button<{ isExpand: boolean; active: boolean }>`
  ${button};

  ${({ isExpand }) => (isExpand ? flex({ horizontal: "start" }) : undefined)};
  ${borders({ radius: 12 })};
  ${margins({ m: "0 0 8" })};

  width: 100%;
  height: 40px;
  position: relative;
  cursor: pointer;

  div.icon {
    ${flex({ totalCenter: true })};

    width: 40px;
  }

  div.withoutIcon {
    ${flex({ totalCenter: true })};

    width: 40px;
  }

  div.count {
    ${borders({ totalRadius: true })};
    ${text({ size: 12, height: 12, weight: 600, color: colors.white })};
    ${margins({ p: "2 4" })};

    position: absolute;
    right: 32px;

    display: block;
    background: ${colors.primaryDefault};
  }

  p {
    ${overflowOneLine};
    ${({ active }) => {
      const color = active ? colors.grayscaleAsh : colors.grayscalePlaceholder;
      return text({ size: 14, height: 28, weight: 600, color, align: "start" });
    }};

    ${margins({ m: "0 0 0 12", p: "0 8 0 0" })};

    width: calc(100% - 12px - 8px - 16px * 2);
    // (12px) - левый padding
    // (8px) - правый margin
    // (16px * 2) - 2 padding контейнера панели
  }

  svg > path {
    fill: ${({ active }) => (active ? colors.grayscaleAsh : colors.grayscalePlaceholder)};
  }

  :hover {
    background: ${colors.grayscaleHoverBackground};

    p {
      color: ${colors.grayscaleAsh};
    }

    svg > path {
      fill: ${colors.grayscaleAsh};
    }
  }

  &::after {
    content: "";
    width: 4px;
    height: 40px;
    position: absolute;
    top: 0;
    display: ${({ active }) => (active ? "block" : "none")};
    left: -16px;
    background: ${colors.grayscaleAsh};
    border-radius: 0 99em 99em 0;
    z-index: 5;
  }
`;
