import styled from "@emotion/styled/macro";
import { CircularProgress } from "@mui/material";
import { borders, colors, flex, margins } from "../../styles";

export const Overlay = styled.div<{ over: boolean }>`
  ${flex({ totalCenter: true })};

  ${({ over }) =>
    over
      ? `border-radius: 18px 18px 0 0;   background: ${colors.primaryActiveElementOpacity};  backdrop-filter: blur(2px);  opacity: 0.5;`
      : ""};

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
`;

export const Container = styled.div`
  ${borders({ totalRadius: true })};
  ${margins({ m: "100 auto" })};

  width: 40px;
  height: 40px;
  z-index: 999;
`;

export const Item = styled(CircularProgress)`
  height: 100%;
  animation-duration: 5s;
  color: ${colors.primaryDefaultDark};
  z-index: 999;
`;
