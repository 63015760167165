import { useAtom } from "jotai";
import React from "react";
import * as Yup from "yup";
import { InfoText } from "../../../components";
import { notJustSpaces } from "../../../constants/forValidationSchemes";
import { yesAndNoOptions } from "../../../constants/options";
import { withoutSpaces } from "../../../constants/regexs";
import { CheckInput, SelectAutocompleteInput, SelectInput } from "../../../uiKit";
import { FilterBody } from "../../../uiKit/Filters/Filters.styles";
import { useMainDepartmentOptions } from "../../../utils/getOptions";
import { absoluteInitialValues, filtersState } from "../utils/atoms";

export const useFilters = (isOsr: boolean, hideDepartment: boolean) => {
  // ------------------------------ АТОМЫ

  const [filters, setFilters] = useAtom(filtersState);

  // ------------------------------ ХУКИ

  const mainDepartmentOptions = useMainDepartmentOptions();

  // ------------------------------ ФОРМА - ПОЛЯ

  const fieldsFiltersBase = (additional?: JSX.Element) => (
    <FilterBody>
      {!hideDepartment && isOsr && (
        <SelectAutocompleteInput
          name="departmentCode"
          options={mainDepartmentOptions}
          size="small"
          outsideLabel="Подразделение"
        />
      )}

      <SelectInput
        name="hasAppointment"
        size="small"
        options={yesAndNoOptions}
        outsideLabel="Запись"
      />

      <SelectInput
        name="agreement"
        size="small"
        options={yesAndNoOptions}
        outsideLabel="Согласие"
      />

      <SelectInput name="access" size="small" options={yesAndNoOptions} outsideLabel="Допуск" />
      <SelectInput
        name="exemption"
        size="small"
        options={yesAndNoOptions}
        outsideLabel="Медотвод"
      />

      <SelectInput
        name="plannedExpired"
        size="small"
        options={yesAndNoOptions}
        outsideLabel="Просрочена дата планируемой вакцинации"
      />

      <CheckInput name="plannedComes" label="Сотрудники, у которых подходит срок вакцинации" />
      <InfoText text="Сотрудники у которых до даты планируемой вакцинации осталось менее 1\4 периода времени, но не более 180 дней." />

      {additional}
    </FilterBody>
  );

  const fieldsFilters = fieldsFiltersBase();

  const fieldsFiltersWithAdditional = (jsx: JSX.Element) => fieldsFiltersBase(jsx);

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesFilters = {
    departmentCode: filters.departmentCode,
    hasAppointment: filters.hasAppointment,
    access: filters.access,
    agreement: filters.agreement,
    exemption: filters.exemption,
    plannedExpired: filters.plannedExpired,
    startDate: filters.startDate,
    endDate: filters.endDate,
    plannedComes: filters.plannedComes,
  };

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchemaFilters = Yup.object().shape({
    departmentCode: Yup.string().matches(withoutSpaces, notJustSpaces),
    hasAppointment: Yup.string().matches(withoutSpaces, notJustSpaces),
    access: Yup.string().matches(withoutSpaces, notJustSpaces),
    agreement: Yup.string().matches(withoutSpaces, notJustSpaces),
    exemption: Yup.string().matches(withoutSpaces, notJustSpaces),
    plannedExpired: Yup.string().matches(withoutSpaces, notJustSpaces),
    plannedComes: Yup.boolean(),
  });

  return {
    fieldsFiltersWithAdditional,

    fieldsFilters,

    filters,
    setFilters,
    absoluteInitialValues,

    initialValuesFilters,
    validationSchemaFilters,
  };
};
