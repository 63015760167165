import React from "react";
import { OrganizationSettingsType } from "../../../../api/apiVaccination";
import { CheckInput } from "../../../../uiKit";

export const useTable = () => {
  // ------------------------------ ТАБЛИЦА - НАСТРОЙКИ

  const wrapper = (component: JSX.Element) => <form id="vaccinationSettingsForm">{component}</form>;

  const tableSettings = {
    wrapper,
  };

  // ------------------------------ ТАБЛИЦА - ЗАГОЛОВКИ

  const titlesInit = [
    { id: 1, title: "№", name: "number" },
    { id: 2, title: "Инфекционное заболевание", name: "name" },
    { id: 3, title: "Допуск к вакцинации", name: "access" },
    { id: 4, title: "Согласие на вакцинацию", name: "agreement" },
    { id: 5, title: "Активировать на экране Календарь", name: "active" },
  ];

  // ------------------------------ ТАБЛИЦА - ШИРИНЫ ТАБЛИЦ

  const gridsInit = [
    { titleId: 1, size: "76px" },
    { titleId: 2, size: "1fr" },
    { titleId: 3, size: "1fr" },
    { titleId: 4, size: "1fr" },
    { titleId: 5, size: "1fr" },
  ];

  // ------------------------------ ТАБЛИЦА - ДАННЫЕ ДЛЯ СТРОКИ

  const object = (
    { infection, access, agreement, active, id }: OrganizationSettingsType,
    number?: number
  ): ObjectType => ({
    ...(number ? { number } : {}),
    name: infection.name,
    access: <CheckInput name={`${id}_access_${infection.id === id}`} defaultValue={access} />,
    agreement: (
      <CheckInput name={`${id}_agreement_${infection.id === id}`} defaultValue={agreement} />
    ),
    active: <CheckInput name={`${id}_active_${infection.id === id}`} defaultValue={active} />,
  });

  return {
    titlesInit,
    gridsInit,

    object,
    tableSettings,
  };
};

type ObjectType = {
  number?: number;
  name: string;
  access: JSX.Element;
  agreement: JSX.Element;
  active: JSX.Element;
};
