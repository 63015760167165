import React, { FC, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { SettingType } from "../hooks/useForms";
import { FormButton } from "../../../../uiKit/Buttons";
import { useAtom } from "jotai";
import { needCleanupFormAtom } from "../utils/atoms";
import { useAtomValue } from "jotai/utils";
import { statusCreateOrUpdateState } from "../../../../atoms/atoms";

export const ApplyButton: FC<PropsType> = (props) => {
  const { hasDifferent } = props;
  const { values, setValues, submitForm, dirty } = useFormikContext<SettingType>();
  const [isEditedPage, setIsEditedPage] = useState(false);
  const isRequest = useAtomValue(statusCreateOrUpdateState);
  const [needCleanupForm, setNeedCleanupForm] = useAtom(needCleanupFormAtom);

  useEffect(() => {
    if (Object.keys(values).length) {
      if (!hasDifferent(values)) {
        setValues({});
        setIsEditedPage(false);
      } else setIsEditedPage(true);
    }
  }, [values]);

  useEffect(() => {
    if (needCleanupForm) {
      setNeedCleanupForm(false);
      setValues({});
    }
  }, [needCleanupForm]);

  return (
    <FormButton
      active={isEditedPage && !isRequest && dirty}
      onClick={submitForm}
      type="submit"
      text="Применить настройки"
    />
  );
};

type PropsType = {
  hasDifferent: (settings: SettingType) => boolean;
};
