import React, { FC, MouseEvent } from "react";
import { Cross, Gear } from "../../Icons";
import { Container } from "./GearButton.styles";

export const GearButton: FC<PropsType> = (props) => {
  const { menuModalIsOpen, needMenu, onClick, needSelectingColumns } = props;

  return (
    <Container
      onClick={onClick}
      menuModalIsOpen={menuModalIsOpen}
      needMenu={needMenu}
      needSelectingColumns={needSelectingColumns}
    >
      <div>{menuModalIsOpen ? <Cross /> : <Gear />}</div>
    </Container>
  );
};

type PropsType = {
  onClick: (evt: MouseEvent<HTMLButtonElement>) => void;
  menuModalIsOpen: boolean;
  needMenu: boolean;
  needSelectingColumns: boolean;
};
