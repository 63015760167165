import styled from "@emotion/styled/macro";
import { Drawer } from "@mui/material";
import { borders, colors, flex, margins, shadow, text } from "../../styles";

export const Container = styled(Drawer)<PropsType>`
  .MuiModal-root {
    position: relative;
  }

  .MuiBackdrop-root {
    ${({ needblur }) =>
      needblur
        ? `background: ${colors.primaryActiveElementOpacity}; backdrop-filter: blur(10px); opacity: 0.6`
        : "background: none"}
  }

  .MuiPaper-root {
    ${margins({ m: "0", p: "0" })};

    width: ${({ size }) => (size === "small" ? "500px" : "600px")};
    box-shadow: ${({ needblur }) => (needblur ? "none" : shadow)};
    overflow-y: overlay;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      ${borders({ totalRadius: true })};

      background: ${colors.grayscaleLabelOpacity77};
    }
  }
`;

export const Head = styled.div`
  ${margins({ m: "64 64 40" })};
`;

export const TitleAndCloseButton = styled.div`
  ${flex({ horizontal: "evenly", vertical: "center" })};
  ${margins({ m: "0" })};
`;

export const Title = styled.h4`
  ${text({ size: 24, height: 34, weight: 800, color: colors.grayscaleBeautifulBlack })};
`;

export const Description = styled.p`
  ${text({ size: 15, height: 20, weight: 400, color: colors.grayscaleLabel })};
  ${margins({ m: "8 0 0" })};
`;

export const Main = styled.div`
  ${flex({ isColumn: true, vertical: "evenly" })};

  height: 100%;
`;

type PropsType = { size: string; needblur: number };
