import { InfectionDiseaseType } from "../../../../api/apiInfectionDiseases";
import { arrYears } from "../../../../constants/dates";
import { getInclinedWord } from "../../../../utils/getInclinedWord";

export const useTable = (canEdit: boolean) => {
  // ------------------------------ КОНСТАНТЫ

  const titlesInit = [
    { id: 1, title: "№", name: "number" },
    { id: 2, title: "Наименование заболевания", name: "name" },
    { id: 3, title: "Код МКБ-10", name: "icd" },
    { id: 4, title: "Возраст", name: "ages" },
  ];

  // ------------------------------ ТАБЛИЦА - ШИРИНЫ ТАБЛИЦ

  const gridsInit = [
    { titleId: 1, size: "76px" },
    { titleId: 2, size: "1fr" },
    { titleId: 3, size: "1fr" },
    { titleId: 4, size: "1fr" },
    ...(canEdit ? [{ titleId: null, size: "56px" }] : []),
  ];

  // ------------------------------ ТАБЛИЦА - ДАННЫЕ ДЛЯ СТРОКИ

  const object = (
    { name, icd, startAge, endAge }: InfectionDiseaseType,
    number?: number
  ): ObjectType => ({
    ...(number ? { number: number } : {}),
    name,
    icd: icd?.length ? icd.map(({ code }) => code).join(", ") : "—",
    ages: `От ${startAge}${endAge ? ` до ${endAge}` : ""}  ${getInclinedWord(
      endAge ? endAge : startAge,
      arrYears
    )}`,
  });

  return {
    titlesInit,
    gridsInit,
    object,
  };
};

export type ObjectType = {
  number?: number;
  name: string;
  icd: string;
  ages: string;
};
