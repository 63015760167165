import React from "react";
import { colors } from "../../styles";

export const BuildAndTreeFilled = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M11.3201 3H5.0901C3.1001 3 2.1001 4.01 2.1001 6.02V22H7.5001V18.25C7.5001 18.0511 7.57912 17.8603 7.71977 17.7197C7.86042 17.579 8.05119 17.5 8.2501 17.5C8.44901 17.5 8.63978 17.579 8.78043 17.7197C8.92108 17.8603 9.0001 18.0511 9.0001 18.25V22H14.3001V6.02C14.3001 4.01 13.3101 3 11.3201 3ZM10.7501 12.75H5.8001C5.3901 12.75 5.0501 12.41 5.0501 12C5.0501 11.59 5.3901 11.25 5.8001 11.25H10.7501C11.1601 11.25 11.5001 11.59 11.5001 12C11.5001 12.41 11.1601 12.75 10.7501 12.75ZM10.7501 9H5.8001C5.3901 9 5.0501 8.66 5.0501 8.25C5.0501 7.84 5.3901 7.5 5.8001 7.5H10.7501C11.1601 7.5 11.5001 7.84 11.5001 8.25C11.5001 8.66 11.1601 9 10.7501 9Z"
      fill={colors.grayscaleIcons}
    />
    <path
      d="M23 21.251H20.73V18.251C21.68 17.941 22.37 17.051 22.37 16.001V14.001C22.37 12.691 21.3 11.621 19.99 11.621C18.68 11.621 17.61 12.691 17.61 14.001V16.001C17.61 17.041 18.29 17.921 19.22 18.241V21.251H1C0.59 21.251 0.25 21.591 0.25 22.001C0.25 22.411 0.59 22.751 1 22.751H19.93C19.95 22.751 19.96 22.761 19.98 22.761C20 22.761 20.01 22.751 20.03 22.751H23C23.41 22.751 23.75 22.411 23.75 22.001C23.75 21.591 23.41 21.251 23 21.251Z"
      fill={colors.grayscaleIcons}
    />
  </svg>
);
