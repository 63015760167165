import React, { FC, useEffect } from "react";
import { useInfectionEmployees } from "./useInfectionEmployees";
import { Page } from "../../../uiKit";

export const InfectionEmployeesPage: FC = () => {
  const methods = useInfectionEmployees();

  const { setDateSwitcherType } = methods;

  useEffect(() => {
    setDateSwitcherType("year");
  }, []);

  return <Page methods={methods} />;
};
