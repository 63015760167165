import { useState } from "react";
import { useData } from "./useData";
import { useQuery } from "../../../../utils/useQuery";
import { IdType } from "../../../../api/types";
import { needCleanupFormAtom } from "../utils/atoms";
import { useUpdateAtom } from "jotai/utils";

export const useForms = () => {
  const { queryKey, api, queryKeys, organization } = useData();

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const setNeedCleanupForm = useUpdateAtom(needCleanupFormAtom);
  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesForm = {};

  // ------------------------------ ФОРМА - ОТПРАВКА

  const update = useQuery().useUpdate({ queryKey, api, queryKeyForOneEntity: queryKeys });
  const create = useQuery().useCreate({ queryKey, api, queryKeyForOneEntity: queryKeys });

  const onSubmit = async (value: SettingType) => {
    const createObjects: ValuesObjectType[] = [];
    const updateObjects: ValuesObjectType[] = [];

    Object.keys(value).forEach((keyValue) => {
      const key = keyValue as keyof SettingType;
      const valueId = key.split("_")[0];
      const field = key.split("_")[1] as keyof Omit<
        ValuesObjectType,
        "id" | "infection" | "organization"
      >;
      const isCreate = key.split("_")[2] === "true";

      const inCreate = createObjects.find(({ infection }) => infection?.id === valueId);
      const inUpdate = updateObjects.find(({ id }) => id === valueId);

      if (inCreate) inCreate[field] = value[key];
      else if (inUpdate) inUpdate[field] = value[key];
      else if (isCreate)
        createObjects.push({
          infection: { id: valueId },
          organization,
          access: false,
          agreement: false,
          active: false,
          [`${field}`]: value[key],
        });
      else updateObjects.push({ id: valueId, [`${field}`]: value[key] });
    });

    if (createObjects.length) await create.mutateAsync({ data: createObjects });
    if (updateObjects.length) await update.mutateAsync({ data: updateObjects, id: "" });
    setIsOpenConfirmModal(true);
    setNeedCleanupForm(true);
  };

  const onConfirm = () => {
    setIsOpenConfirmModal(false);
  };

  return {
    onSubmit,
    isOpenConfirmModal,
    onConfirm,
    initialValuesForm,
  };
};

export type SettingType = {
  [key: `${string}_access_${boolean}`]: boolean;
  [key: `${string}_agreement_${boolean}`]: boolean;
  [key: `${string}_active_${boolean}`]: boolean;
};

type ValuesObjectType = {
  id?: string;
  infection?: IdType;
  organization?: string;
  access?: boolean;
  agreement?: boolean;
  active?: boolean;
};
