import React, { ReactNode } from "react";
import { RoleModelType } from "../atoms/atomCurrentUser";
import { Calendar, Folder, Gear, InfoPaper, List, Statistics } from "../uiKit/Icons";
import * as routes from "./routes";

export const sections = (roles: RoleModelType) => {
  const { canViewHealthCenterDictionary } = roles;
  const {
    canViewInfectionDiseaseDictionary,
    canViewOtherDictionaries,
    canViewDictionaries,
    canViewAdministration,
    canViewVaccinationSettings,
    canViewVaccinationNotifications,
    canViewCalendar,
    canViewDeleteReasons,
    canViewVaccinationStatistic,
    canViewInfographic,
    canViewInfectionVaccinationDictionary,
  } = roles;

  return [
    // ------------------------------ КАЛЕНДАРЬ

    ...(canViewCalendar
      ? [
          {
            type: "section",
            name: "Calendar",
            position: "start",
            text: "Календарь",
            rout: routes.CALENDAR,
            icon: <Calendar />,
          },
        ]
      : []),

    // ------------------------------ ИНФОГРАФИКА

    ...(canViewInfographic
      ? [
          {
            type: "folder",
            name: "infographic",
            position: "start",
            text: "Инфографика",
            rout: routes.INFOGRAPHIC,
            icon: <Statistics />,
          },
          {
            folder: "infographic",
            type: "subsection",
            name: "infographic-diseases",
            text: "Заболеваемость",
            rout: routes.INFOGRAPHIC_DISEASES("organization"),
            additionalRoutes: [
              routes.INFOGRAPHIC_DISEASES("department"),
              routes.INFOGRAPHIC_DISEASES("users"),
            ],
          },
          {
            folder: "infographic",
            type: "subsection",
            name: "infographic-vaccination",
            text: "Вакцинация",
            rout: routes.INFOGRAPHIC_VACCINATION("organization"),
            additionalRoutes: [
              routes.INFOGRAPHIC_VACCINATION("department"),
              routes.INFOGRAPHIC_VACCINATION("users"),
            ],
          },
        ]
      : []),

    // ------------------------------ СПРАВОЧНИКИ

    ...(canViewDictionaries
      ? [
          {
            type: "folder",
            name: "dictionaries",
            text: "Справочники",
            position: "start",
            rout: routes.DICTIONARY,
            icon: <Folder />,
          },
        ]
      : []),

    ...(canViewOtherDictionaries
      ? [
          {
            folder: "dictionaries",
            type: "subsection",
            name: "citizenship",
            text: "Гражданство",
            rout: routes.DICTIONARY_NATIONALITY,
          },
          {
            folder: "dictionaries",
            type: "subsection",
            name: "personal-category",
            text: "Категория персонала",
            rout: routes.DICTIONARY_PERSONAL_CATEGORY,
          },
          {
            folder: "dictionaries",
            type: "subsection",
            name: "disease-severities",
            text: "Степень тяжести заболевания",
            rout: routes.DICTIONARY_DISEASE_SEVERITIES,
          },
          {
            folder: "dictionaries",
            type: "subsection",
            name: "diseases-class",
            text: "Коды МКБ-10",
            rout: routes.DICTIONARY_DISEASES_CLASS,
          },
        ]
      : []),

    ...(canViewInfectionDiseaseDictionary
      ? [
          {
            folder: "dictionaries",
            type: "subsection",
            name: "infection-diseases",
            text: "Инфекционные заболевания",
            rout: routes.DICTIONARY_INFECTION_DISEASES,
          },
        ]
      : []),
    ...(canViewInfectionVaccinationDictionary
      ? [
          {
            folder: "dictionaries",
            type: "subsection",
            name: "infection-vaccination",
            text: "Настройка вакцинации",
            rout: routes.DICTIONARY_INFECTION_VACCINATIONS,
          },
        ]
      : []),
    ...(canViewOtherDictionaries
      ? [
          {
            folder: "dictionaries",
            type: "subsection",
            name: "treatments-place",
            text: "Место прохождения лечения",
            rout: routes.DICTIONARY_TREATMENTS_PLACE,
          },
        ]
      : []),

    ...(canViewHealthCenterDictionary
      ? [
          {
            folder: "dictionaries",
            type: "subsection",
            name: "organizations",
            text: "Медицинские организации",
            rout: routes.DICTIONARY_HEALTH_CENTER,
          },
        ]
      : []),

    // ------------------------------ АДМИНИСТРИРОВАНИЕ

    ...(canViewAdministration
      ? [
          {
            type: "folder",
            name: "administration",
            text: "Администрирование",
            rout: routes.ADMINISTRATION,
            icon: <Gear />,
          },
        ]
      : []),

    ...(canViewVaccinationSettings
      ? [
          {
            folder: "administration",
            type: "subsection",
            name: "vaccination-settings",
            text: "Настройка разрешений",
            rout: routes.VACCINATION_SETTINGS,
          },
        ]
      : []),
    ...(canViewVaccinationNotifications
      ? [
          {
            folder: "administration",
            type: "subsection",
            name: "vaccination-notification",
            text: "Настройка отправки уведомлений ",
            rout: routes.VACCINATION_NOTIFICATION,
          },
        ]
      : []),

    // ------------------------------ ПРИЧИНЫ УДАЛЕНИЯ

    ...(canViewDeleteReasons
      ? [
          {
            type: "section",
            name: "reasons",
            position: "end",
            text: "Причины удаления сотрудника с вакцинации",
            rout: routes.DELETION_REASONS,
            icon: <List />,
          },
        ]
      : []),

    // ------------------------------ ПРИЧИНЫ УДАЛЕНИЯ

    ...(canViewVaccinationStatistic
      ? [
          {
            type: "section",
            name: "statistic",
            position: "start",
            text: "Данные о вакцинации",
            rout: routes.VACCINATION_STATISTIC,
            icon: <InfoPaper />,
          },
        ]
      : []),
  ] as SectionType[];
};

export type SectionType = {
  type: "folder" | "section" | "subsection";
  name: string;
  text: string;
  rout: string;
  additionalRoutes?: string[];
  icon?: ReactNode;
  folder?: "dictionaries" | "applications" | "administration";
  position?: "start" | "end";
  not?: string;
};
