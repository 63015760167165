import {
  calendarDayState,
  currentDateDay,
  employeesDayState,
  useCalendarDayQuery,
  useEmployeesDayKeys,
} from "../utils/atoms";
import { useAtomValue } from "jotai/utils";
import { currentUserAtom } from "../../../../atoms/atomCurrentUser";
import { HeaderNavigation } from "../../../../uiKit";
import React from "react";
import { Header, SubTitle, TextDate } from "../CalendarDay.styles";
import { NavigateButton } from "../../../../uiKit/Buttons";
import { CALENDAR } from "../../../../constants/routes";
import { vaccinationSettingsByParamState } from "../../../AdministrationPages/VaccinationSettings/utils/atoms";
import { useLoadableData } from "../../../../utils/useLoadableData";
import { MultiplyFactButton } from "../MultiplyFactButton/MultiplyFactButton";
import { formatDate, formatParseDate, now } from "../../../../utils/workingWithDates";

export const useData = () => {
  // ------------------------------ ХУКИ

  const { roleModel } = useAtomValue(currentUserAtom);
  const date = useAtomValue(currentDateDay);

  const dayDataLoadable = useAtomValue(calendarDayState);
  const { data, loadableData, totalCount } = useLoadableData(employeesDayState, []);
  const infectionSettingsArray = useAtomValue(vaccinationSettingsByParamState);

  const infectionSettings = infectionSettingsArray?.[0] || undefined;

  const dayData = dayDataLoadable?.find(
    ({ vaccinationPlan }) => vaccinationPlan.infection.id === infectionSettings?.infection.id
  );

  const { queryKey, api } = useEmployeesDayKeys();
  const { queryKey: additionalQueryKey } = useCalendarDayQuery();

  const queryKeyForOneEntity = [additionalQueryKey];

  // ------------------------------ КОНСТАНТЫ

  const {
    canViewCalendarDay: canView,
    canEditCalendarDay: canEdit,
    canViewProfile: canViewProfile,
    canAdditionalFilterCalendarDay: canViewAdditionalFilter,
  } = roleModel;

  const access = infectionSettings?.access;
  const agreement = infectionSettings?.agreement;

  const { vaccinationPlan } = dayData || {};
  const { healthCenter } = vaccinationPlan || {};

  const healthCenterName = healthCenter?.name ?? "";
  const titlePage = `Запись на вакцинацию против заболевания ${
    infectionSettings?.infection?.name ?? ""
  }`;

  const dayDate = dayData ? formatParseDate(dayData.date) : "";

  const notEditableIds =
    dayData && dayDate === formatDate(now)
      ? data
          .filter(
            ({ appointmentVaccinations }) =>
              !appointmentVaccinations?.find(
                ({ appointment, vaccinationFact }) =>
                  appointment &&
                  formatParseDate(appointment.date) === dayDate &&
                  !vaccinationFact?.id
              )
          )
          .map(({ id }) => id)
      : data.map(({ id }) => id);

  // ------------------------------ ШАПКА - НАВИГАЦИЯ

  const navigation = (
    <HeaderNavigation
      title={{
        element: (
          <Header>
            <NavigateButton link={CALENDAR} title={titlePage} />
            <SubTitle>{healthCenterName}</SubTitle>
          </Header>
        ),
        text: titlePage,
      }}
      buttonsStart={canEdit && <MultiplyFactButton queryKey={queryKey} data={data} day={dayData} />}
      needSearchButton
      needFilterButton
      buttonsRight={<TextDate>{formatDate(date ?? now)}</TextDate>}
    />
  );

  return {
    dayData,
    data,
    loadableData,
    totalCount,

    access,
    agreement,

    navigation,
    api,
    queryKey,
    queryKeyForOneEntity,

    canView,
    canEdit,
    canViewProfile,

    canViewAdditionalFilter,

    infectionSettings,

    notEditableIds,
    healthCenterName,
  };
};
