import React, { FC, useState } from "react";
import { TimeTextField } from "./TimesInput.styles";
import { TimePicker } from "@mui/lab";
import { isValid } from "date-fns";

export const TimeField: FC<PropsType> = (props) => {
  const { value, onChange, disabled = false } = props;

  // на mui7 например нет никаких проблем с вводом времени, в 5v оно очень сырое(

  const [viewTime, setViewTime] = useState<string>();

  const handleBlur = () => {
    if (value && isValid(value)) setViewTime(value.toTimeString());
    else setViewTime("");
  };
  const handleFocus = () => setViewTime(undefined);

  const handleChange = (time: Date | null) => {
    if (time && isValid(time)) onChange(time);
    else onChange(null);
  };

  return (
    <TimePicker
      value={value}
      onChange={handleChange}
      disableOpenPicker={true}
      disabled={disabled}
      renderInput={(params) => {
        return (
          <TimeTextField
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder: "чч:мм",
              value: viewTime === "" ? viewTime : params.inputProps?.value,
            }}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
        );
      }}
    />
  );
};

type PropsType = {
  value: Date | null;
  onChange: (time: Date | null) => void;
  disabled?: boolean;
};
