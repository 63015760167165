import React from "react";
import { VaccinationDateType } from "../../../../api/apiVaccination";
import * as elements from "./CalendarView.styles";
import { PersonEdit, PersonOutline, TimeEdit } from "../../../../uiKit/Icons";
import { TrashButton } from "../../../../uiKit/Buttons";
import { modalDataDayState, modalDataState } from "../utils/atoms";
import { useUpdateAtom } from "jotai/utils";
import { useNavigate } from "react-router-dom";
import { CALENDAR_DAY } from "../../../../constants/routes";
import { formatParseDate, now, parseDate, timeString } from "../../../../utils/workingWithDates";
import { IconButton } from "../../../../uiKit/Buttons/IconButton/IconButton";
import { startOfDay } from "date-fns";

export const VaccinationCard = (props: PropsType) => {
  const { vaccinationData, isOpen, onEdit, canEdit: canEditDay, canViewDay } = props;
  const { used, limit, vaccinationPlan, startTrueDate, endDate, id, date } = vaccinationData;
  const { healthCenter, id: planId, infection } = vaccinationPlan;
  const { shortName, id: healthCenterId } = healthCenter;
  const { id: infectionId, name: infectionName } = infection;

  const { Vaccination, VaccinationPanel, VaccinationName } = elements;
  const { VaccinationDate, UsedCount, VaccinationActions } = elements;

  const setModalData = useUpdateAtom(modalDataState);
  const setDeletionData = useUpdateAtom(modalDataDayState);
  const navigate = useNavigate();

  const canEdit = canEditDay && parseDate(date).getTime() >= startOfDay(now).getTime();

  const handleEditTime = () => {
    onEdit?.();
    setModalData({ ...vaccinationData, type: "time" });
  };
  const handleEditLimit = () => {
    onEdit?.();
    setModalData({ ...vaccinationData, type: "limit" });
  };

  const handleDelete = () => {
    onEdit?.();
    setDeletionData({
      dayId: id,
      planId,
      startDate: startTrueDate,
      endDate,
      infectionName,
      centerName: shortName,
      date: formatParseDate(date),
    });
  };

  return (
    <Vaccination>
      <VaccinationPanel>
        <UsedCount
          onClick={() =>
            onEdit && canViewDay && navigate(CALENDAR_DAY(date, infectionId, healthCenterId))
          }
        >
          <PersonOutline />
          {used ?? "0"}/{limit}
        </UsedCount>
        {isOpen && canEdit && (
          <VaccinationActions>
            <IconButton size={24} onClick={handleEditTime}>
              <TimeEdit />
            </IconButton>
            <IconButton size={24} onClick={handleEditLimit}>
              <PersonEdit />
            </IconButton>
            <TrashButton size={24} onClick={handleDelete} />
          </VaccinationActions>
        )}
      </VaccinationPanel>
      <VaccinationName
        onClick={() =>
          onEdit && canViewDay && navigate(CALENDAR_DAY(date, infectionId, healthCenterId))
        }
      >
        {shortName}
      </VaccinationName>
      <VaccinationDate>{timeString(startTrueDate, endDate)}</VaccinationDate>
    </Vaccination>
  );
};

type PropsType = {
  vaccinationData: VaccinationDateType;
  isOpen?: boolean;
  onEdit?: () => void;
  canEdit?: boolean;
  canViewDay?: boolean;
};
