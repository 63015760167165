import styled from "@emotion/styled/macro";
import {
  borders,
  colors,
  flex,
  grid,
  margins,
  overflowLines,
  shadow,
  text,
} from "../../../../styles";

const { white, grayscaleInput, grayscaleLabel, black } = colors;

export const Container = styled.div<{ needHover: boolean }>`
  ${grid({ columns: "1fr 200px", gap: 16 })};
  ${borders({ radius: 24, color: grayscaleInput, width: 1 })};
  ${margins({ p: "24" })}
  background: ${white};
  height: 248px;

  ${({ needHover }) =>
    needHover
      ? `:hover { box-shadow: ${shadow}; cursor: pointer; }`
      : `:hover { cursor: default; }`}
`;

export const Content = styled.section`
  ${flex({ isColumn: true, vertical: "evenly" })};
`;

export const Title = styled.p`
  ${text({ weight: 800, size: 20, height: 26, color: black })};
  ${overflowLines(2)};

  cursor: pointer;
`;

export const Counts = styled.footer`
  ${flex({ isColumn: true, gap: 8 })}
`;

export const Description = styled.header`
  ${text({ weight: 400, size: 13, height: 17, color: grayscaleLabel })};
`;

export const Count = styled.span`
  ${text({ weight: 700, size: 16, height: 22, color: black })};
`;
