import React, { FC, MouseEvent, useState } from "react";
import { GearButton, SelectAllButton } from "../../Buttons";
import { Check } from "../../Check";
import { PopoverHover } from "../../PopoverHover";
import { PopoverModal } from "../../PopoverModal";
import { TitlesList } from "../Table.styles";
import { GridsType, TitlesType, useTable } from "../useTable";
import { Container, TableHeadItem } from "./TableHeadRow.styles";

export const TableHeadRow: FC<PropsType> = (props) => {
  const { ids, titlesInit, gridsInit, setUserSettings } = props;
  const { needCheck = false, needMenu = false, needSelectingColumns = true } = props;

  const tableMethods = useTable();
  const { getTitles, getGrids, selectedRows, setSelectedRows } = tableMethods;
  const { selectingColumns, isColumnSelected } = tableMethods;

  // ------------------------------ < ВЫБОР ВСЕХ СТРОК ДЛЯ УДАЛЕНИЯ

  const selectAllRows = () => setSelectedRows(!!ids && selectedRows.length === 0 ? ids : []);

  // ------------------------------ ВЫБОР ВСЕХ СТРОК ДЛЯ УДАЛЕНИЯ >

  // ------------------------------ < ПОДСКАЗКИ К ЗАГОЛОВКАМ СТОЛБЦОВ

  const [anchorPopoverHoverElement, setAnchorPopoverHoverElement] = useState<HTMLElement | null>(
    null
  );
  const [textPopoverHover, setTextPopoverHover] = useState("");

  const openPopoverHover = ({ currentTarget }: MouseEvent<HTMLElement>) => {
    setAnchorPopoverHoverElement(currentTarget);

    const isMenu = currentTarget.className?.includes("menu");
    const isCheck = currentTarget.className?.includes("check");
    const innerHTML = currentTarget.getElementsByTagName("p")[0]?.innerHTML;

    setTextPopoverHover(isMenu ? "Меню" : isCheck ? "Выбор" : innerHTML);
  };

  const closePopoverHover = () => {
    setAnchorPopoverHoverElement(null);
    setTextPopoverHover("");
  };

  const popoverHoverIsOpen = !!anchorPopoverHoverElement;
  const idPopoverHover = popoverHoverIsOpen ? "popover-hover" : undefined;

  // ------------------------------ ПОДСКАЗКИ К ЗАГОЛОВКАМ СТОЛБЦОВ >

  // ------------------------------ < ВЫЗОВ МЕНЮ НАСТРОЙКИ СТОЛБЦОВ

  // todoLater: смена порядка столбцов не очень быстрая задача - оставлю на потом, если время останется

  const [anchorOptionRowsElement, setAnchorOptionRowsElement] = useState<HTMLElement | null>(null);

  const openOptionRowsModal = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setAnchorOptionRowsElement(evt.currentTarget);
  };

  const closeMenuModal = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setAnchorOptionRowsElement(null);
  };

  const menuModalIsOpen = !!anchorOptionRowsElement;

  // ------------------------------ ВЫЗОВ МЕНЮ НАСТРОЙКИ СТОЛБЦОВ >

  return (
    <>
      <Container needCheck={needCheck} needMenu={needMenu} grid={getGrids(gridsInit)}>
        {needCheck && (
          <TableHeadItem
            className="check"
            aria-owns={popoverHoverIsOpen ? "popover-hover" : undefined}
            aria-haspopup="true"
            onMouseEnter={openPopoverHover}
            onMouseLeave={closePopoverHover}
          >
            <SelectAllButton onClick={selectAllRows} />
          </TableHeadItem>
        )}

        {getTitles(titlesInit).map(({ title, icon, iconPosition }, i) => (
          <TableHeadItem
            key={i}
            aria-owns={popoverHoverIsOpen ? "popover-hover" : undefined}
            aria-haspopup="true"
            onMouseEnter={openPopoverHover}
            onMouseLeave={closePopoverHover}
          >
            {iconPosition === "left" && icon}
            <p>{title}</p>
            {iconPosition === "right" && icon}
          </TableHeadItem>
        ))}

        {needMenu && <TableHeadItem className="menu" />}

        {(needMenu || (!needMenu && needSelectingColumns)) && (
          <GearButton
            onClick={(evt: MouseEvent<HTMLElement>) =>
              needSelectingColumns ? openOptionRowsModal(evt) : undefined
            }
            menuModalIsOpen={menuModalIsOpen}
            needMenu={needMenu}
            needSelectingColumns={needSelectingColumns}
          />
        )}
      </Container>

      <PopoverHover
        id={idPopoverHover}
        isOpen={popoverHoverIsOpen}
        element={anchorPopoverHoverElement}
        onClose={closePopoverHover}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: 6, horizontal: "center" }}
      >
        {textPopoverHover}
      </PopoverHover>

      <PopoverModal
        id="optionRowsModal"
        title="Колонки таблицы"
        isOpen={menuModalIsOpen}
        element={anchorOptionRowsElement}
        onClose={closeMenuModal}
      >
        <TitlesList>
          {titlesInit.map(({ id, title }, i) => (
            <div key={i} onClick={() => selectingColumns(id, setUserSettings)}>
              <Check checked={isColumnSelected(id)} />
              <p>{title}</p>
            </div>
          ))}
        </TitlesList>
      </PopoverModal>
    </>
  );
};

type PropsType = BaseType &
  ({ needCheck?: never; ids?: never } | { needCheck: boolean; ids: string[] });

type BaseType = {
  titlesInit: TitlesType;
  gridsInit: GridsType;
  needMenu?: boolean;
  needSelectingColumns?: boolean;
  setUserSettings?: (arg: number[]) => void;
};
