import React, { FC } from "react";
import { EmployeeType } from "../../api/apiEmployees";
import { getFullName } from "../../utils/workingWithNames";
import { TitleRows } from "../TitleRows";
import { differenceDate, formatParseDate } from "../../utils/workingWithDates";
import { EmployeeContainer } from "./EmployeesAdditional.styles";
import { USER_VACCINATION_RECORDS } from "../../constants/routes";
import { Link } from "../Link/Link.styles";

export const EmployeeAdditional: FC<PropsType> = (props) => {
  const { employee, canView, hideDepartment = false, hideAge = false, age = "age" } = props;
  const { personnelNumber, positionName, birthDate, mainDepartment, position } = employee;

  const rows = [
    { title: "ТН:", description: personnelNumber },
    ...(hideAge
      ? []
      : [
          {
            title: `${age === "age" ? "Возраст" : "Дата рождения"}:`,
            description: birthDate
              ? `${formatParseDate(birthDate)} (${differenceDate(birthDate)})`
              : "—",
          },
        ]),
    {
      title: "Должность:",
      description: positionName ? positionName : typeof position === "string" ? position : "—",
    },
    ...(hideDepartment
      ? []
      : [
          {
            title: "Подразделение:",
            description:
              mainDepartment?.shortName ?? position?.department?.mainDepartmentName ?? "—",
          },
        ]),
  ];

  return (
    <EmployeeContainer>
      <Link to={canView ? USER_VACCINATION_RECORDS(employee.id) : ""} active={+canView}>
        {getFullName({ nameParts: employee })}
      </Link>
      <TitleRows rows={rows} isRows />
    </EmployeeContainer>
  );
};

type PropsType = {
  employee: EmployeeType;
  canView: boolean;
  hideDepartment?: boolean;
  hideAge?: boolean;
  age?: "date" | "age";
};
