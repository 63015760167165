import styled from "@emotion/styled/macro";
import { borders, colors, flex, grid, margins, text } from "../../styles";
import { overflowOneLineByPixels } from "../../styles/styles";

const {
  primaryActiveElement,
  grayscaleLabel,
  grayscaleAsh,
  grayscaleBeautifulBlack,
  greenBackground,
  white,
  grayscalePlaceholder,
  grayscaleLine,
  grayscaleDisabled,
  redBackground,
  redDefault,
  grayscaleInput,
} = colors;

export const Record = styled.div<{
  onClick?: object;
  type: "correct" | "expired" | "record" | "fact" | "empty";
}>`
  ${flex({ gap: 8 })};
  ${margins({ p: "16" })};

  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
  ${({ type }) =>
    borders({
      radius: 16,
      color: type === "fact" ? grayscaleInput : undefined,
      width: type === "fact" ? 1 : undefined,
    })};
  background: ${({ type }) =>
    type === "correct"
      ? greenBackground
      : type === "record"
      ? primaryActiveElement
      : type === "expired"
      ? redBackground
      : type === "empty"
      ? grayscaleDisabled
      : white};

  ${({ type }) =>
    type === "empty"
      ? text({
          color: grayscaleLabel,
          weight: 400,
          size: 15,
          height: 20,
        })
      : ""}
`;

export const Text = styled.span<{
  disableTwoSelection?: boolean;
  notBold?: boolean;
  isError?: boolean;
}>`
  ${text({ size: 15, weight: 400, color: grayscaleLabel, height: 20 })};
  ${overflowOneLineByPixels(200)};

  &:nth-of-type(-n + ${({ disableTwoSelection }) => (disableTwoSelection ? 1 : 2)}) {
    ${({ notBold, isError }) =>
      notBold
        ? ""
        : text({ size: 15, weight: 700, color: isError ? redDefault : grayscaleAsh, height: 20 })};
  }

  &:not(:last-of-type):after {
    content: "/";
    margin-left: 9px;
  }
`;

export const EmptyContainer = styled.div`
  ${flex({ gap: 16, isColumn: true, totalCenter: true })};

  & > p {
    ${text({ size: 17, height: 24, weight: 700, color: grayscaleBeautifulBlack, align: "center" })};
  }
`;

export const NavigationInfection = styled.nav`
  ${margins({ m: "0 0 16 0" })};
`;

export const SubFieldLabel = styled.span`
  ${text({ size: 12, height: 16, weight: 500, color: grayscalePlaceholder })};
  ${margins({ p: "0 16" })};
`;

export const AntibodiesView = styled.div`
  ${flex({ gap: 16, isColumn: true })};
  ${margins({ m: "40 64 0 64" })};
`;
export const AntibodiesClose = styled.div`
  ${flex({ gap: 16, isColumn: true })};
  ${borders({ side: "top", width: 1, color: grayscaleLine })};
  ${margins({ p: "16 64" })};
`;

export const VaccinationPageContainer = styled.div`
  ${grid({ columns: "1fr 300px", gap: 16 })};
  grid-template-areas: "content settigns";
`;
export const VaccinationPageContent = styled.div`
  ${flex({ isColumn: true, gap: 16 })};
  grid-area: content;
`;
export const VaccinationPageSide = styled.div`
  ${flex({ isColumn: true, gap: 16 })};
  grid-area: settigns;
`;

export const PageContainer = styled.div`
  ${borders({ radius: 16 })};
  ${margins({ m: "0 0 16 0", p: "24" })};

  background: ${colors.white};
`;

export const RecordContainer = styled.div`
  ${flex({ gap: 16, isColumn: true })};
`;
