import React, { FC } from "react";
import * as elements from "./DepartmentCard.styled";
import { usePopoverHover } from "../../../../uiKit/PopoverHover/usePopoverHover";
import { PopoverHover } from "../../../../uiKit";

export const DepartmentCard: FC<PropsType> = (props) => {
  const { count, children, sick, name, screenshotMode = false, onClick, fullName, type } = props;

  const { Container, Content, Title, Description, Counts, Count } = elements;

  const { openPopoverHover, closePopoverHover, propsPopoverHover } = usePopoverHover({
    anchorOrigin: { vertical: "bottom", horizontal: "left" },
    transformOrigin: { vertical: "top", horizontal: "left" },
  });

  return (
    <Container needHover={screenshotMode}>
      <Content>
        <Title onMouseEnter={openPopoverHover} onMouseLeave={closePopoverHover} onClick={onClick}>
          {name}
        </Title>
        <Counts>
          <Description>Кол-во работников</Description>
          <Count>{count}</Count>
          <Description>{type === "infection" ? "Болеет" : "Вакцинированы"} </Description>
          <Count>{sick}</Count>
        </Counts>
      </Content>
      {children}
      <PopoverHover {...propsPopoverHover}>{fullName}</PopoverHover>
    </Container>
  );
};

type PropsType = {
  count: string;
  sick: string;
  name: string;
  fullName: string;
  screenshotMode?: boolean;
  onClick: () => void;
  type: string;
};
