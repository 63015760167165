import styled from "@emotion/styled/macro";
import { borders, button, colors, flex, margins, text } from "../../styles";

export const Container = styled.div`
  ${margins({ m: "16 24 12" })};

  display: flex;
`;

export const SearchInputLabel = styled.label<{ loupePosition: string }>`
  width: 100%;
  height: 40px;
  display: block;
  position: relative;

  & > svg {
    position: absolute;
    top: 0;
    left: -10px;
  }
`;

export const MatchesCount = styled.p`
  ${text({ size: 12, height: 12, weight: 600, color: colors.grayscalePlaceholder })};

  position: absolute;
  top: 14px;
  right: 48px;
`;

export const SearchInputElement = styled.input`
  ${borders({ none: true, side: "bottom", color: colors.grayscaleLine })};
  ${text({ size: 16, height: 23, weight: 400, color: colors.grayscaleBeautifulBlack })};
  ${margins({ p: "0 0 0 32" })};

  width: 100%;
  height: 40px;
  background: ${colors.transparent};
  outline: none;

  &::placeholder {
    ${text({ size: 16, height: 23, weight: 400, color: colors.grayscalePlaceholder })};
  }
`;

export const PrevNextButtons = styled.div`
  ${margins({ m: "0 0 0 16" })};

  display: flex;
`;

export const Button = styled.div`
  ${button};

  ${flex({ totalCenter: true })};

  width: 40px;
  height: 40px;
`;
