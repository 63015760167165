import styled from "@emotion/styled/macro";
import * as styles from "../../../styles";

const { borders, button, buttonOnlyIcon, buttonTextDefault, colors } = styles;
const { flex, grid: gridStyles, margins, overflowOneLine, text } = styles;

export const Container = styled.div<ContainerPropsType>`
  ${({ grid }) => gridStyles({ columns: grid })};
  ${borders({ side: "bottom", color: colors.grayscaleInput })};

  min-width: 0;
  height: 64px;
  position: sticky;
  top: 0;
  background: ${colors.grayscaleLabel};
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  z-index: 2;
`;

export const TableHeadItem = styled.div`
  ${flex({ gap: 14, vertical: "center" })};
  ${margins({ p: "19 2 19 20" })};

  border-right: 1px solid ${colors.grayscaleInput};
  min-width: 0;
  overflow: hidden;

  &.check,
  &.menu {
    ${flex({ totalCenter: true })};
    ${margins({ p: "0" })};
  }

  &:last-of-type {
    ${borders({ none: true })};
  }

  p {
    ${overflowOneLine};
    ${text({ size: 17, height: 24, weight: 700, color: colors.grayscaleOffWhite })};
  }

  svg > path {
    fill: ${colors.white};
  }
`;

export const DragHandleButton = styled.div`
  ${button}
  ${buttonOnlyIcon}
  ${buttonTextDefault}
  
  ${text({ size: 17, height: 24, weight: 700, color: colors.grayscaleIcons })};
  ${margins({ p: "4" })};

  cursor: grab;
`;

type ContainerPropsType = {
  needCheck: boolean;
  needMenu: boolean;
  grid: string;
};
