import React, { FC } from "react";
import { RoleModelType } from "../../../atoms/atomCurrentUser";
import { sections } from "../../../constants/sections";
import { FolderButton } from "./FolderButton";
import { SectionButton } from "./SectionButton";

export const SectionButtons: FC<PropsType> = (props) => {
  const { roleModel } = props;

  const filteredSections = sections(roleModel);

  const folders = filteredSections.filter(({ type }) => type === "folder");
  const startSections = filteredSections.filter(
    ({ type, position }) => type === "section" && position === "start"
  );
  const endSections = filteredSections.filter(
    ({ type, position }) => type === "section" && position === "end"
  );

  return (
    <>
      {startSections?.map((section, i) => (
        <SectionButton key={i} section={section} />
      ))}

      {folders?.map((folder, i) => {
        const subsections =
          filteredSections.filter(
            ({ type, folder: subsectionFolder }) =>
              type === "subsection" && subsectionFolder === folder.name
          ) ?? [];

        return <FolderButton key={i} section={folder} subSections={subsections} />;
      })}

      {endSections?.map((section, i) => (
        <SectionButton key={i} section={section} />
      ))}
    </>
  );
};

type PropsType = {
  roleModel: RoleModelType;
};
