import styled from "@emotion/styled/macro";
import { borders, colors, flex, grid as gridStyles, margins, text } from "../../../styles";

const { grayscaleInput, transparent, blueLight, redWhite, grayscaleBody, warningLight } = colors;

export const Container = styled.div<ContainerProps>`
  ${({ grid }) => gridStyles({ columns: grid })};
  ${borders({ side: "bottom", color: grayscaleInput })};

  min-width: 0;
  overflow: hidden;
  background: ${({ needBackground }) =>
    needBackground === "default"
      ? transparent
      : needBackground === "notification"
      ? blueLight
      : redWhite};
`;

export const TableBodyItem = styled.div`
  ${borders({ side: "right", color: grayscaleInput })};
  ${margins({ p: "14 20" })};

  min-width: 0;
  word-wrap: break-word;
  overflow: hidden;

  &.check {
    ${flex({ horizontal: "center", vertical: "start" })};
    ${margins({ p: "4 0 0 0" })};

    height: 100%;

    .MuiCheckbox-root {
      width: 40px;
      height: 40px;
    }
  }

  &:last-child {
    ${borders({ none: true })};
  }
`;

export const TableBodyItemTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableBodyItemText = styled.div`
  ${text({ size: 15, height: 20, weight: 400, color: grayscaleBody })};

  span {
    background: ${warningLight};
  }
`;

export const MenuButtons = styled.div`
  ${flex({ gap: 8, isColumn: true })};
  ${margins({ p: "8" })};
`;

type ContainerProps = {
  grid: string;
  needBackground: "default" | "error" | "notification";
};
