import React, { FC } from "react";
import { CheckLabelContainer, EndIcon, Label } from "./Check.styles";
import { Check, CheckPropsType } from "./Check";

export const CheckLabel: FC<PropsType> = (props) => {
  const { label, endIcon, onClick, ...checkProps } = props;

  return (
    <CheckLabelContainer onClick={onClick}>
      <Check {...checkProps} />
      <Label>{label}</Label>
      {endIcon && <EndIcon>{endIcon}</EndIcon>}
    </CheckLabelContainer>
  );
};

type PropsType = CheckPropsType & { label?: string; endIcon?: JSX.Element; onClick?: () => void };
