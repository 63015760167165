import styled from "@emotion/styled/macro";
import React, { FC, MouseEvent } from "react";
import * as buttons from ".";
import { borders, colors, flex, grid, margins, text } from "../../styles";
import { Download, Export, Loupe, Sieve, Trash } from "../Icons";

export const Buttons: FC = () => (
  <Container>
    <h3>При нажатии ЛКМ по тегу необходимой компоненты, он будет скопирован в буфер обмена</h3>

    <ButtonsContainer>
      <ButtonsBlock
        button={
          <buttons.UniversalTextButton
            text="Добавить"
            type="primary"
            onClick={() => console.info("UniversalTextButton")}
          />
        }
        name="<UniversalTextButton text='Добавить' type='primary' onClick={} />"
      />
      <div className="border" />
      <ButtonsBlock
        button={
          <buttons.UniversalTextButton
            text="Импорт"
            type="secondary"
            onClick={() => console.info("UniversalTextButton")}
          />
        }
        name="<UniversalTextButton text='Импорт' type='secondary' onClick={} />"
      />

      {/* ------------------------------ */}

      <ButtonsBlock
        button={
          <buttons.UniversalTextButton
            text="Отчёт"
            type="text"
            iconStart={<Export />}
            onClick={() => console.info("UniversalTextButton")}
          />
        }
        name="<UniversalTextButton text='Отчёт' type='text' iconStart={<Export />} onClick={} />"
      />
      <div className="border" />
      <ButtonsBlock
        button={
          <buttons.UniversalTextButton
            text="Реестр"
            iconEnd={<Download />}
            onClick={() => console.info("UniversalTextButton")}
          />
        }
        name="<UniversalTextButton text='Реестр' iconEnd={<Download />} onClick={} />"
      />

      {/* ------------------------------ */}

      <ButtonsBlock
        button={
          <buttons.UniversalTextButton
            text="Фильтры"
            type="text"
            iconStart={<Sieve />}
            onClick={() => console.info("UniversalTextButton")}
          />
        }
        name="<UniversalTextButton text='Фильтры' type='text' iconStart={<Sieve />} onClick={} />"
      />
      <div className="border" />
      <ButtonsBlock
        button={
          <buttons.UniversalTextButton
            text="Фильтры"
            type="text"
            needPoint
            iconStart={<Sieve />}
            onClick={() => console.info("UniversalTextButton")}
          />
        }
        name="<UniversalTextButton text='Фильтры' type='text' needPoint iconStart={<Sieve />} onClick={} />"
      />

      {/* ------------------------------ */}

      <ButtonsBlock
        button={
          <buttons.UniversalTextButton
            text="Удалить"
            type="attention"
            onClick={() => console.info("UniversalTextButton")}
          />
        }
        name="<UniversalTextButton text='Удалить' type='attention' onClick={} />"
      />
      <div className="border" />
      <ButtonsBlock
        button={
          <buttons.UniversalTextButton
            text="Подтвердить"
            type="positive"
            onClick={() => console.info("UniversalTextButton")}
          />
        }
        name="<UniversalTextButton text='Подтвердить' type='positive' onClick={} />"
      />

      {/* ------------------------------ */}

      <ButtonsBlock
        button={
          <buttons.UniversalTextButton
            text="Отменить"
            type="outlined"
            onClick={() => console.info("UniversalTextButton")}
          />
        }
        name="<UniversalTextButton text='Отменить' type='outlined' onClick={} />"
      />
      <div className="border" />
      <ButtonsBlock
        button={
          <buttons.UniversalTextButton
            text="Удалить"
            enabled={false}
            onClick={() => console.info("UniversalTextButton")}
          />
        }
        name="<UniversalTextButton text='Удалить' enabled={false} onClick={} />"
      />

      {/* ------------------------------ */}

      <Emptiness />
      <div className="border" />
      <Emptiness />

      {/* ------------------------------ */}

      <ButtonsBlock
        button={
          <buttons.ExportBlankButton
            typeButton="primary"
            onClick={() => console.info("ExportBlankButton")}
          />
        }
        name="<ExportBlankButton typeButton='primary' onClick={} />"
      />
      <div className="border" />
      <ButtonsBlock
        button={
          <buttons.ExportBlankButton
            typeButton="secondary"
            onClick={() => console.info("ExportBlankButton")}
          />
        }
        name="<ExportBlankButton typeButton='secondary' onClick={} />"
      />

      {/* ------------------------------ */}

      <>
        <div style={{ background: colors.primaryDefault }}>
          {
            <buttons.FormButton
              text="Найти"
              type="submit"
              icon={<Loupe />}
              formName=""
              variant="text"
            />
          }
        </div>
        <div className="border" />
        <div>
          <span onClick={copyText}>
            {`<FormButton text="Найти" type="submit" icon={<Loupe />} formName="" variant="text" />`}
          </span>
        </div>
      </>
      <div className="border" />
      <ButtonsBlock
        button={
          <buttons.FormButton
            text="Сохранить"
            type="button"
            formName=""
            active={true}
            onClick={() => console.info("FormButton")}
          />
        }
        name="<FormButton text='Сохранить' type='button' formName='' active={true} variant='default' onClick={} />"
      />

      {/* ------------------------------ */}

      <>
        <div style={{ background: colors.grayscaleBody }}>
          {
            <buttons.GearButton
              menuModalIsOpen={false}
              needMenu={false}
              needSelectingColumns={false}
              onClick={() => console.info("GearButton")}
            />
          }
        </div>
        <div className="border" />
        <div>
          <span onClick={copyText}>
            {`<GearButton menuModalIsOpen={false} needMenu={false} needSelectingColumns={false} onClick={} />`}
          </span>
        </div>
      </>
      <div className="border" />
      <>
        <div style={{ background: colors.grayscaleBody }}>
          {
            <buttons.GearButton
              menuModalIsOpen={false}
              needMenu={true}
              needSelectingColumns={true}
              onClick={() => console.info("GearButton")}
            />
          }
        </div>
        <div className="border" />
        <div>
          <span onClick={copyText}>
            {`<GearButton menuModalIsOpen={false} needMenu={true} needSelectingColumns={true} onClick={} />`}
          </span>
        </div>
      </>

      {/* ------------------------------ */}

      <>
        <div style={{ background: colors.grayscaleBody }}>
          {
            <buttons.GearButton
              menuModalIsOpen={true}
              needMenu={false}
              needSelectingColumns={false}
              onClick={() => console.info("GearButton")}
            />
          }
        </div>
        <div className="border" />
        <div>
          <span onClick={copyText}>
            {`<GearButton menuModalIsOpen={true} needMenu={false} needSelectingColumns={false} onClick={} />`}
          </span>
        </div>
      </>
      <div className="border" />
      <>
        <div style={{ background: colors.grayscaleBody }}>
          {
            <buttons.GearButton
              menuModalIsOpen={true}
              needMenu={true}
              needSelectingColumns={true}
              onClick={() => console.info("GearButton")}
            />
          }
        </div>
        <div className="border" />
        <div>
          <span onClick={copyText}>
            {`<GearButton menuModalIsOpen={true} needMenu={true} needSelectingColumns={true} onClick={} />`}
          </span>
        </div>
      </>

      {/* ------------------------------ */}

      <>
        <div style={{ background: colors.grayscaleBody }}>
          {<buttons.SelectAllButton onClick={() => console.info("SelectAllButton")} />}
        </div>
        <div className="border" />
        <div>
          <span onClick={copyText}>{`<SelectAllButton onClick={} />`}</span>
        </div>
      </>
      <div className="border" />
      <ButtonsBlock
        button={<buttons.NavigateButton link="" title="Назад" />}
        name="<NavigateButton link='' title='Назад' />"
      />

      {/* ------------------------------ */}

      <ButtonsBlock
        button={<buttons.TrashButton onClick={() => console.info("TrashButton")} />}
        name="<TrashButton onClick={} />"
      />
      <div className="border" />
      <ButtonsBlock
        button={<buttons.RemoveSearchButton onClick={() => console.info("RemoveSearchButton")} />}
        name="<RemoveSearchButton onClick={} />"
      />

      {/* ------------------------------ */}

      <ButtonsBlock
        button={<buttons.CloseButton onClick={() => console.info("CloseButton")} />}
        name="<CloseButton onClick={} />"
      />
      <div className="border" />
      <ButtonsBlock
        button={<buttons.EditButton onClick={() => console.info("EditButton")} />}
        name="<EditButton onClick={} />"
      />

      {/* ------------------------------ */}

      <ButtonsBlock
        button={<buttons.OkButton onClick={() => console.info("OkButton")} />}
        name="<OkButton onClick={} />"
      />
      <div className="border" />
      <ButtonsBlock
        button={<buttons.OpenFormButton onClick={() => console.info("OpenFormButton")} />}
        name="<OpenFormButton onClick={} />"
      />

      {/* ------------------------------ */}

      <ButtonsBlock
        button={
          <buttons.StarButton
            hintText="Дизлайк"
            isActive={true}
            onClick={() => console.info("StarButton")}
          />
        }
        name="<StarButton hintText='Дизлайк' isActive={true} onClick={} />"
      />
      <div className="border" />
      <ButtonsBlock
        button={
          <buttons.StarButton
            hintText="Лайк"
            isActive={false}
            onClick={() => console.info("StarButton")}
          />
        }
        name="<StarButton hintText='Лайк' isActive={true} onClick={} />"
      />

      {/* ------------------------------ */}

      <ButtonsBlock
        button={
          <buttons.LeftAndRightArrowsButtons
            disabled={true}
            orientation="left"
            onClick={() => console.info("LeftAndRightArrowsButtons")}
          />
        }
        name="<LeftAndRightArrowsButtons disableBack={true} disableNext={true} orientation='left' onClick={} />"
      />
      <div className="border" />
      <ButtonsBlock
        button={
          <buttons.LeftAndRightArrowsButtons
            disabled={false}
            orientation="right"
            onClick={() => console.info("LeftAndRightArrowsButtons")}
          />
        }
        name="<LeftAndRightArrowsButtons disableBack={true} disableNext={true} orientation='right' onClick={} />"
      />

      {/* ------------------------------ */}

      <ButtonsBlock
        button={
          <buttons.OpenListButton
            isOpen={true}
            openRight={true}
            disabled={true}
            onClick={() => console.info("OpenListButton")}
          />
        }
        name="<OpenListButton isOpen={true} openRight={true} disabled={true} onClick={} />"
      />
      <div className="border" />
      <ButtonsBlock
        button={
          <buttons.OpenListButton
            isOpen={false}
            openRight={true}
            disabled={false}
            onClick={() => console.info("OpenListButton")}
          />
        }
        name="<OpenListButton isOpen={false} openRight={true} disabled={false} onClick={} />"
      />

      {/* ------------------------------ */}

      <ButtonsBlock
        button={
          <buttons.OpenListButton
            isOpen={true}
            openRight={false}
            disabled={true}
            onClick={() => console.info("OpenListButton")}
          />
        }
        name="<OpenListButton isOpen={true} openRight={false} disabled={true} onClick={} />"
      />
      <div className="border" />
      <ButtonsBlock
        button={
          <buttons.OpenListButton
            isOpen={false}
            openRight={false}
            disabled={false}
            onClick={() => console.info("OpenListButton")}
          />
        }
        name="<OpenListButton isOpen={false} openRight={false} disabled={false} onClick={} />"
      />

      {/* ------------------------------ */}

      <ButtonsBlock
        button={<buttons.PlusMinusButton type="plus" disabled={true} />}
        name="<PlusMinusButton type='plus' disabled={true} />"
      />
      <div className="border" />
      <ButtonsBlock
        button={<buttons.PlusMinusButton type="minus" disabled={false} />}
        name="<PlusMinusButton type='minus' disabled={false} />"
      />

      {/* ------------------------------ */}

      <ButtonsBlock
        button={
          <buttons.SearchButton visibleSearch={true} onClick={() => console.info("SearchButton")} />
        }
        name="<SearchButton visibleSearch={true} onClick={} />"
      />
      <div className="border" />
      <ButtonsBlock
        button={
          <buttons.SearchButton
            visibleSearch={false}
            onClick={() => console.info("SearchButton")}
          />
        }
        name="<SearchButton visibleSearch={false} onClick={} />"
      />

      {/* ------------------------------ */}

      <ButtonsBlock
        button={
          <buttons.TableMenuButton
            menuModalIsOpen={true}
            fromCard={true}
            onClick={() => console.info("TableMenuButton")}
          />
        }
        name="<TableMenuButton menuModalIsOpen={true} fromCard={true}  onClick={} />"
      />
      <div className="border" />
      <ButtonsBlock
        button={
          <buttons.TableMenuButton
            menuModalIsOpen={false}
            fromCard={false}
            onClick={() => console.info("TableMenuButton")}
          />
        }
        name="<TableMenuButton menuModalIsOpen={false} fromCard={false}  onClick={} />"
      />

      {/* ------------------------------ */}

      <ButtonsBlock
        button={
          <buttons.TableMenuItemButton
            text="Обычная"
            onClick={() => console.info("TableMenuItemButton")}
          />
        }
        name="<TableMenuItemButton text='Обычная' onClick={} />"
      />
      <div className="border" />
      <ButtonsBlock
        button={
          <buttons.TableMenuItemButton
            text="Длинная"
            long
            onClick={() => console.info("TableMenuItemButton")}
          />
        }
        name="<TableMenuItemButton text='Длинная' long onClick={} />"
      />

      {/* ------------------------------ */}

      <ButtonsBlock
        button={
          <buttons.TableMenuItemButton
            text="Ещё длиннее"
            xLong
            onClick={() => console.info("TableMenuItemButton")}
          />
        }
        name="<TableMenuItemButton text='Ещё длиннее' xLong onClick={} />"
      />
      <div className="border" />
      <ButtonsBlock
        button={
          <buttons.TableMenuItemButton
            text="С иконкой"
            icon={<Trash />}
            onClick={() => console.info("TableMenuItemButton")}
          />
        }
        name="<TableMenuItemButton text='Иконка' icon={<Trash />} onClick={} />"
      />
    </ButtonsContainer>
  </Container>
);

const ButtonsBlock: FC<{ button: JSX.Element; name: string }> = ({ button, name }) => (
  <>
    <div>{button}</div>
    <div className="border" />
    <div>
      <span onClick={copyText}>{name}</span>
    </div>
  </>
);

const Emptiness: FC = () => (
  <>
    <div></div>
    <div className="border" />
    <div></div>
  </>
);

const copyText = async (evt: MouseEvent<HTMLParagraphElement>) =>
  await navigator.clipboard.writeText(evt.currentTarget.innerText);

const Container = styled.div`
  ${flex({ isColumn: true, horizontal: "center" })};

  height: 100vh;
  overflow: auto;

  h3 {
    ${text({ size: 24, height: 20, weight: 400 })};
    ${margins({ m: "40 0" })};
  }
`;

const ButtonsContainer = styled.div`
  ${grid({ columns: "200px 1px 600px 1px 200px 1px 600px", autoRows: 86 })};
  ${borders({ radius: 16 })};
  ${margins({ m: "16", p: "16" })};

  width: fit-content;
  height: fit-content;
  border: 1px solid ${colors.grayscaleInput};

  div {
    ${flex({ horizontal: "start", vertical: "center" })};
    ${borders({ side: "bottom", color: colors.grayscaleInput })};
    ${margins({ p: "16" })};

    position: relative;

    span {
      ${text({ size: 20, height: 20, weight: 400 })};

      cursor: pointer;
    }
  }

  div.border {
    ${margins({ p: "0" })};

    background: ${colors.grayscaleInput};
  }

  div:nth-last-of-type(1) {
    ${borders({ none: true })};
  }
  div:nth-last-of-type(3) {
    ${borders({ none: true })};
  }
  div:nth-last-of-type(5) {
    ${borders({ none: true })};
  }
  div:nth-last-of-type(7) {
    ${borders({ none: true })};
  }
`;
