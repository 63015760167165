import React from "react";
import * as Yup from "yup";
import { diseaseApi, DiseaseType } from "../../../../api/apiDisease";
import { employeesApi, EmployeeType } from "../../../../api/apiEmployees";
import { DocumentsForFormType } from "../../../../api/apiFile";
import { SwitchInput } from "../../../../components";
import {
  falseCountFiles,
  IsCorrectStartEndDate,
  IsDateNotPastFuture,
  long,
  notJustSpaces,
  required,
  thisDateIsToCorrect,
} from "../../../../constants/forValidationSchemes";
import { healthCenterTreatmentPlaceId } from "../../../../constants/ids";
import { employeeDeathStatus } from "../../../../constants/options";
import { withoutSpaces } from "../../../../constants/regexs";
import {
  DateInput,
  DocumentsInput,
  SelectAutocompleteInput,
  StartAndEndDatesInput,
  TextInput,
} from "../../../../uiKit";
import { AdditionalFormField } from "../../../../uiKit/Form/AdditionalFormField/AdditionalFormField";
import { FieldsBlock, FormSubTitle } from "../../../../uiKit/Form/Form.styles";
import {
  useCitizenshipOptions,
  useDiseaseClassOptions,
  useDiseaseSeveritiesOptions,
  useHealthCenterOptions,
  useInfectionDiseaseOptions,
  usePersonnelCategoriesOptions,
  useTreatmentPlaceOptions,
} from "../../../../utils/getOptions";
import { QueryKeyType, useQuery } from "../../../../utils/useQuery";
import { validateFilesObj } from "../../../../utils/validateFiles";
import {
  formatDateForBackend,
  minusOneDay,
  now,
  parseDate,
} from "../../../../utils/workingWithDates";
import { getFileIds, getFileName, getFileType } from "../../../../utils/workingWithFiles";
import { getFullName } from "../../../../utils/workingWithNames";
import { useEmployeeDiseasesKeys } from "../../utils/atoms";

export const useForms = (employeeProfile: EmployeeType | undefined, queryKey: QueryKeyType) => {
  // ------------------------------ АТОМЫ

  const citizenshipOptions = useCitizenshipOptions();
  const personnelCategoriesOptions = usePersonnelCategoriesOptions();
  const infectionDiseasesOptions = useInfectionDiseaseOptions();
  const diseaseClassesOptions = useDiseaseClassOptions();
  const severityOptions = useDiseaseSeveritiesOptions();
  const treatmentPlaceOptions = useTreatmentPlaceOptions();
  const healthCenterOptions = useHealthCenterOptions();

  // ------------------------------ КОНСТАНТЫ

  const addFormTitle = "Добавить данные о заболевании";
  const editFormTitle = "Изменение данных о заболевании";

  const formName = "employeeDisease";

  // ------------------------------ ФОРМА - ПОЛЯ

  const fields = (
    <FieldsBlock>
      <FormSubTitle>Сотрудник</FormSubTitle>
      <TextInput name="name" insideLabel="ФИО" disabled />
      <TextInput name="personnelNumber" insideLabel="Табельный номер" disabled />
      <TextInput name="organization" insideLabel="Организация" disabled />
      <TextInput name="department" insideLabel="Подразделение" disabled />
      <TextInput name="position" insideLabel="Должность" disabled />
      <SelectAutocompleteInput
        name="citizenship"
        insideLabel="Гражданство*"
        options={citizenshipOptions}
      />
      <SelectAutocompleteInput
        name="category"
        insideLabel="Категория персонала*"
        options={personnelCategoriesOptions}
      />
      <SwitchInput name="isDead" label="Умер" />
      <AdditionalFormField name="isDead" equals={true}>
        <DateInput name="deathDate" placeholder="дд.мм.гггг" insideLabel="Дата смерти*" />
      </AdditionalFormField>

      <FormSubTitle>Заболевание</FormSubTitle>
      <SwitchInput name="sickLeave" label="Без больничного листа" />
      <SelectAutocompleteInput
        name="infection"
        insideLabel="Инфекционное заболевание*"
        options={infectionDiseasesOptions}
        placeholder="Выберите из доступных"
      />
      <SelectAutocompleteInput
        name="code"
        insideLabel="Код по МКБ-10"
        options={diseaseClassesOptions}
        limit={200}
      />
      <SelectAutocompleteInput
        name="severity"
        insideLabel="Степень тяжести заболевания*"
        options={severityOptions}
      />
      <SelectAutocompleteInput
        name="treatmentPlace"
        insideLabel="Место прохождения лечения*"
        options={treatmentPlaceOptions}
      />
      <AdditionalFormField name="treatmentPlace" equals={healthCenterTreatmentPlaceId}>
        <SelectAutocompleteInput
          name="healthCenter"
          insideLabel="Наименование организации*"
          options={healthCenterOptions}
          limit={200}
        />
      </AdditionalFormField>

      <TextInput name="comment" insideLabel="Комментарий" placeholder="—" multiline />
      <DocumentsInput
        label="Загрузите файлы из папки или перетащите"
        description="Подходят файлы в формате "
        accept={[".pdf"]}
        maxSize={10485760}
        multiDocuments={false}
      />
      <StartAndEndDatesInput
        label={{ start: "Дата открытия БЛ*", stop: "Дата закрытия БЛ" }}
        maxDate={now}
      />
    </FieldsBlock>
  );

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const initialValuesAddForm: ValuesObjectType = {
    name: employeeProfile ? getFullName({ nameParts: employeeProfile }) : "",
    personnelNumber: employeeProfile?.personnelNumber ?? "—",
    organization: employeeProfile?.position?.department.organization.name ?? "—",
    department: employeeProfile?.position?.department.mainDepartmentName ?? "—",
    position: employeeProfile?.position?.name ?? "—",
    citizenship: employeeProfile?.citizenship?.id ?? "",
    category: employeeProfile?.category?.id ?? "",
    isDead: false,
    sickLeave: false,
    deathDate: null,
    infection: "",
    code: "",
    severity: "",
    treatmentPlace: "",
    healthCenter: "",
    comment: "",
    files: [] as DocumentsForFormType,
    startDate: minusOneDay(now),
    endDate: now,
  };

  const initialValuesEditForm = (props: DiseaseType) => {
    const { sickLeave, infection, severity, treatmentPlace, healthCenter, code } = props;
    const { comment, start, end, document } = props;

    return {
      name: employeeProfile ? getFullName({ nameParts: employeeProfile }) : "—",
      personnelNumber: employeeProfile?.personnelNumber ?? "—",
      organization: employeeProfile?.position?.department.organization.name ?? "—",
      department: employeeProfile?.position?.department.mainDepartmentName ?? "—",
      position: employeeProfile?.position?.name ?? "—",
      citizenship: employeeProfile?.citizenship?.id ?? "",
      category: employeeProfile?.category?.id ?? "",
      isDead: employeeProfile?.status === employeeDeathStatus.id,
      sickLeave,
      deathDate: employeeProfile?.deathDate ? parseDate(employeeProfile.deathDate) : null,
      infection: infection.id,
      code: code?.id,
      severity: severity.id,
      treatmentPlace: treatmentPlace.id,
      healthCenter: healthCenter?.id ?? "",
      comment,
      files: document
        ? ([
            {
              file: document,
              comment: "",
              type: getFileType(document),
              name: getFileName(document),
            },
          ] as DocumentsForFormType)
        : ([] as DocumentsForFormType),
      startDate: parseDate(start),
      endDate: end ? parseDate(end) : null,
    };
  };

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    personnelNumber: Yup.string(),
    organization: Yup.string(),
    department: Yup.string(),
    position: Yup.string(),
    citizenship: Yup.string().required(required),
    category: Yup.string().required(required),
    isDead: Yup.boolean(),
    sickLeave: Yup.boolean(),
    deathDate: Yup.date()
      .nullable()
      .when("isDead", (isDead: boolean, schema) => (isDead ? schema.required(required) : schema)),
    infection: Yup.string().required(required),
    code: Yup.string(),
    severity: Yup.string().required(required),
    treatmentPlace: Yup.string().required(required),
    healthCenter: Yup.string().when("treatmentPlace", (treatmentPlace: string, schema) =>
      treatmentPlace && healthCenterTreatmentPlaceId === treatmentPlace
        ? schema.required(required)
        : schema
    ),
    comment: Yup.string().matches(withoutSpaces, notJustSpaces).max(2000, long),
    files: Yup.array()
      .max(1, falseCountFiles(1))
      .of(
        Yup.object().shape({
          file: Yup.mixed().test(validateFilesObj({ accept: [".pdf"], maxSize: 10485760 })),
          name: Yup.string(),
        })
      ),
    startDate: Yup.date()
      .typeError(thisDateIsToCorrect)
      .test(IsCorrectStartEndDate)
      .required(required),
    endDate: Yup.date()
      .nullable()
      .typeError(thisDateIsToCorrect)
      .test(IsDateNotPastFuture("future", "endDate")),
  });

  // ------------------------------ ФОРМА - ОТПРАВКА
  const { queryKey: queryKeyAdditional } = useEmployeeDiseasesKeys();

  const create = useQuery().useCreate({
    api: diseaseApi(),
    queryKey,
    queryKeyForOneEntity: [queryKeyAdditional],
  });
  const update = useQuery().useUpdate({
    api: diseaseApi(),
    queryKey,
    queryKeyForOneEntity: [queryKeyAdditional],
  });
  const updateUser = useQuery().useUpdate({
    api: employeesApi(),
    queryKey,
    queryKeyForOneEntity: [queryKeyAdditional],
  });

  const onSubmit = async (values: ValuesObjectType, isEdit?: boolean, id?: string) => {
    const {
      sickLeave,
      infection,
      code,
      severity,
      treatmentPlace,
      healthCenter,
      comment,
      files,
      startDate,
      endDate,
    } = values;

    const { category, isDead, citizenship, deathDate } = values;

    await updateUser.mutateAsync({
      data: {
        ...(isDead && deathDate
          ? { deathDate: formatDateForBackend(deathDate) }
          : { deathDate: null }),
        category: { _entityName: "vs_PersonnelCategory", id: category },
        citizenship: { _entityName: "vs_Citizenship", id: citizenship },
      },
      id: employeeProfile?.id,
    });

    const file = files?.[0];
    let fileRef = typeof file?.file === "string" ? file?.file ?? "" : "";
    if (!fileRef && file) fileRef = (await getFileIds(files))[0].ref;

    const disease = {
      ...(id ? { diseaseId: id } : {}),
      employee: { id: employeeProfile?.id },
      ...(code ? { code: { id: code } } : {}),
      infection: { id: infection },
      start: formatDateForBackend(startDate),
      end: endDate ? formatDateForBackend(endDate) : null,
      ...(comment ? { comment } : { comment: null }),
      ...(fileRef ? { document: fileRef } : { document: null }),
      treatmentPlace: { id: treatmentPlace },
      ...(healthCenter ? { healthCenter: { id: healthCenter } } : { healthCenter: null }),
      severity: { id: severity },
      sickLeave,
    };

    if (isEdit && id) await update.mutateAsync({ id, data: disease });
    else await create.mutateAsync({ data: disease });
  };

  return {
    addFormTitle,
    editFormTitle,
    formName,

    fields,

    initialValuesAddForm,
    initialValuesEditForm,

    validationSchema,
    onSubmit,
  };
};

type ValuesObjectType = {
  name: string;
  personnelNumber: string;
  organization: string;
  department: string;
  position: string;
  citizenship: string;
  category: string;
  isDead: boolean;
  sickLeave: boolean;
  infection: string;
  code: string;
  severity: string;
  treatmentPlace: string;
  healthCenter: string;
  comment: string;
  files: DocumentsForFormType;
  startDate: Date;
  endDate: Date | null;
  deathDate: Date | null;
};
