import { css } from "@emotion/react";
import { ColorType } from "../api/types";

/**
 ** side = "top" | "bottom" | "left" | "right"
 ** width = number
 ** color = ColorType
 ** none = "top" | "bottom" | "left" | "right" | boolean
 ** radius = number
 ** totalRadius = boolean
 */
export const borders = (props: PropsType) => {
  const { side, width, color, none = false, radius, totalRadius = false } = props;

  return css`
    ${none
      ? `border${typeof none === "string" ? `-${none}` : ""}: none;  outline: none`
      : undefined}; // todo: уточнить последовательность none и border со значением

    ${color ? `border${side ? `-${side}` : ""}: ${width ?? 1}px solid ${color}` : undefined};

    ${radius ? `border-radius: ${radius}px` : undefined};

    ${totalRadius ? "border-radius: 99em" : undefined};
  `;
};

type PropsType = {
  side?: SideType;
  width?: number;
  color?: ColorType;
  none?: SideType | boolean;
  radius?: number;
  totalRadius?: boolean;
};

type SideType = "top" | "bottom" | "left" | "right";
