import { useData } from "./hooks/useData";
import { useTable } from "./hooks/useTable";
import { useForms } from "./hooks/useForms";

export const useInfections = () => {
  const data = useData();
  const { canEdit, queryKey, employeeProfile, canAdd } = data;
  const table = useTable(canEdit, canAdd);
  const forms = useForms(employeeProfile, queryKey);

  return {
    ...data,
    ...table,
    ...forms,
  };
};
